import { useLazyGetaAutocompleteLocationQuery } from "../../app/data/location/locationApi";
import {  ResponseAddressLocation } from "./types";
import { ioErrorOf } from "../../app/data/errors";
import _ from "lodash";
import { AddressLocation } from "../../app/data/location/schema";

export  const useAddressAutocomplete = (): ResponseAddressLocation => {
  const [response, {isLoading, error, data}] = useLazyGetaAutocompleteLocationQuery()
  const searchAddress = (region: AddressLocation, input: string) => {
    response({
      result_amount: 15,
      partial_address: input,
      city_fias_id: region.city_fias_id,
      settlement_fias_id: region.settlement_fias_id,
      region_fias_id: region.region_fias_id,
    })
  }
  return {
    addressList: data?.data ?? [],
    error: ioErrorOf(error),
    isLoading: isLoading,
    search: _.debounce(searchAddress, 300)
  }
}