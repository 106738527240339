import { CSSProperties } from "react"

export const cardBtnExtra = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "34.5px",
  background: "var(--btn-border-gradient)",
  padding: "5px 8px",
  border: "1.5px solid transparent",
  borderRadius: "20px",
  fontSize: "14px",
  marginLeft: "0 !important",
}

export const cardBtnExtraText: CSSProperties = {
  backgroundImage: "var(--btn-gradient)",
  backgroundClip: "text",
  color: "transparent",
}
