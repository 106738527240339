import ru from "date-fns/locale/ru"
import { CSSProperties, FC } from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { EventFilter } from "../../app/data/tribeSwaggerApi"
import { AdultCheckedBox } from "./items/AdultCheckBox"
import { AlcoholCheckedBox } from "./items/AlcoholCheckedBox"
import FilterByCity from "./items/FilterByCity"
import { FilterChooseDay } from "./items/FilterChooseDay"
import { FilterDateRange } from "./items/FilterDateRange"
import { FilterDurationBox } from "./items/FilterDurationBox"
import { FilterMemberCount } from "./items/FilterMemberCount"
import FilterTitle from "./items/FilterTitle"
import { FreeCheckedBox } from "./items/FreeCheckedBox"
import { ResetBox } from "./items/ResetBox"
import s from "./styles.module.css"

registerLocale("ru", ru) // для чего?

export type FilteredProps = {
  onChangeFilter?: (newFilter: EventFilter) => void
  extraStyles?: CSSProperties
  isOpenFilter?: boolean
  setIsOpenFilter?: (v: boolean) => void
} & EventFilter

const Filtered: FC<FilteredProps> = (props) => {
  const { isOpenFilter, setIsOpenFilter = () => {}, extraStyles } = props

  return (
    <div className={s.filtered} style={extraStyles}>
      <FilterTitle
        isOpenFilter={isOpenFilter}
        setIsOpenFilter={setIsOpenFilter}
      />

      <div className={s.filtered__cont}>
        <FilterByCity />
        <FilterDateRange />
        <FilterMemberCount />
        <FilterChooseDay />
        <FreeCheckedBox />
        <AdultCheckedBox />
        <AlcoholCheckedBox />
        <FilterDurationBox />
        <ResetBox />
      </div>
    </div>
  )
}

export default Filtered
