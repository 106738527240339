import { AddressLocation, AutocompleteResult } from "./schema";

export const transformCityResponse = (response: AutocompleteResult):AutocompleteResult => {
  const {data} = response
  if (data && data.length > 0) {
    response.data = data.filter(isRegionAddressValid).reduce(reduceCityResponse, [])
  }
  return response
}

const reduceCityResponse = (response: AddressLocation[], address: AddressLocation):AddressLocation[] => {
  if (hasMatchingLocation(address, response)){
    return response
  }
  return [...response, address]
}
/*
Эта функция принимает объект address и массив locations, проверяет наличие совпадений по полям city и region, и возвращает true или false.
 */
const hasMatchingLocation = (address: AddressLocation, locations: AddressLocation[]): boolean => {
  return locations.some(location => location.city === address.city && location.region === address.region);
};

const isRegionAddressValid = (address: AddressLocation): boolean => {
  return !(address.region.trim() === '' || address.city.trim() === '' || address.city_fias_id.trim() === '');
};

export const transformAddressResponse = (response: AutocompleteResult):AutocompleteResult => {
  const {data} = response
  if(data && data.length > 0) {
    response.data = data.filter((item)=>item.street.trim()!== '')
  }
  return  response
}
export const isAddressValid = (location: AddressLocation): boolean => {
  return location.street.trim() !== '' && location.house.trim() !== '';
};