import { Link } from "react-router-dom"
import ShareIconCard from "/src/assets/svg/card-icons-pack/share-icon.svg?react"
import { EventDialogFormats } from "../../../popups/EventDialog"

type ShareEventBtnProps = {
  onClickSetModal: (format: EventDialogFormats) => void
}

const ShareEventBtn = ({ onClickSetModal }: ShareEventBtnProps) => {
  const onClickSetDialog = (format: EventDialogFormats) => {
    onClickSetModal(format)
  }

  return (
    <Link
      to="#"
      style={{ flexShrink: "0", marginLeft: "11px" }}
      onClick={() => onClickSetDialog(EventDialogFormats.share)}
    >
      <ShareIconCard fill={"var(--secondary-text)"} />
    </Link>
  )
}

export default ShareEventBtn
