import type { SVGProps } from "react"

export function CalendarIconChecked(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none">
        <path
          stroke="var(--profile-ntfc-text)"
          fill="var(--profile-ntfc-text)"
          d="M2 9c0-1.886 0-2.828.586-3.414S4.114 5 6 5h12c1.886 0 2.828 0 3.414.586S22 7.114 22 9c0 .471 0 .707-.146.854C21.707 10 21.47 10 21 10H3c-.471 0-.707 0-.854-.146C2 9.707 2 9.47 2 9"
        ></path>
        <path
          stroke="var(--profile-ntfc-text)"
          fill="var(--profile-ntfc-text)"
          fillRule="evenodd"
          d="M2 18c0 1.886 0 2.828.586 3.414S4.114 22 6 22h12c1.886 0 2.828 0 3.414-.586S22 19.886 22 18v-5c0-.471 0-.707-.146-.854C21.707 12 21.47 12 21 12H3c-.471 0-.707 0-.854.146C2 12.293 2 12.53 2 13zm5-3c0-.471 0-.707.146-.854C7.293 14 7.53 14 8 14h2c.471 0 .707 0 .854.146c.146.147.146.383.146.854s0 .707-.146.854C10.707 16 10.47 16 10 16H8c-.471 0-.707 0-.854-.146C7 15.707 7 15.47 7 15m.146 3.146C7 18.293 7 18.53 7 19s0 .707.146.854C7.293 20 7.53 20 8 20h2c.471 0 .707 0 .854-.146C11 19.707 11 19.47 11 19s0-.707-.146-.854C10.707 18 10.47 18 10 18H8c-.471 0-.707 0-.854.146M13 15c0-.471 0-.707.146-.854C13.293 14 13.53 14 14 14h2c.471 0 .707 0 .854.146c.146.147.146.383.146.854s0 .707-.146.854C16.707 16 16.47 16 16 16h-2c-.471 0-.707 0-.854-.146C13 15.707 13 15.47 13 15m.146 3.146C13 18.293 13 18.53 13 19s0 .707.146.854c.147.146.383.146.854.146h2c.471 0 .707 0 .854-.146C17 19.707 17 19.47 17 19s0-.707-.146-.854C16.707 18 16.47 18 16 18h-2c-.471 0-.707 0-.854.146"
          clipRule="evenodd"
        ></path>
        <path
          stroke="var(--profile-ntfc-text)"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M7 3v3m10-3v3"
        ></path>
      </g>
    </svg>
  )
}
