import { Box, Card, Skeleton } from "@mui/material"
import {
  card,
  cardBtnContSceleton,
  cardBtnSceleton,
  cardBtnsSubscrContSceleton,
  cardContent,
  cardDescSceleton,
  cardImg,
  cardInfoCont,
  cardInfoContSceleton,
  cardInfoSceleton,
  cardSceleton,
  cardShareSceleton,
  cardSubscrSceleton,
  cardTitleCont,
  cardTitleContSceleton,
  cardTitleSceleton,
} from "../CardEvent/CardEvent/CardEventStyles"

const CardSkeleton = () => {
  return (
    <Card sx={card} style={cardSceleton}>
      <>
        <Skeleton sx={cardImg} animation="wave" variant="rectangular" />

        <Box sx={cardContent} style={cardTitleContSceleton}>
          <Box sx={cardTitleCont}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={cardTitleSceleton}
            />

            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={cardShareSceleton}
            />
          </Box>

          <Box sx={cardInfoCont} style={cardInfoContSceleton}>
            <Skeleton animation="wave" sx={cardInfoSceleton} />

            <Skeleton animation="wave" sx={cardInfoSceleton} />
          </Box>
        </Box>

        <Box>
          <Skeleton animation="wave" sx={cardDescSceleton} />

          <Skeleton animation="wave" sx={cardDescSceleton} />
          <Skeleton animation="wave" sx={cardDescSceleton} />
          <Skeleton animation="wave" sx={cardDescSceleton} />
        </Box>
      </>

      <Box>
        <Box sx={cardBtnsSubscrContSceleton}>
          <Box>
            <Skeleton animation="wave" sx={cardSubscrSceleton} />
          </Box>

          <Box sx={cardBtnContSceleton}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={cardBtnSceleton}
            />

            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={cardBtnSceleton}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default CardSkeleton
