import { Box } from "@mui/material"
import { Fragment, useState } from "react"
import {
  PotentialPerson,
  useAddCashier,
  useDeleteCashier,
  useGetAllCashiersQuery,
} from "../../../app/data/promotion/promotionApi"
import GradientBtn from "../../../components/GradientBtn"
import EmployeeCard from "../EmployeeCard"
import ErrorNotification from "../ErrorNotification"
import Loading from "../Loading"
import NoPromotionEmployees from "../NoPromotionEmployees"
import { Action } from "../PromotionModal"
import PromotionModal from "../PromotionModal/index"
import SearchParticipantsCard from "../PromotionModal/SearchParticipantsCard"
import * as s from "../PromotionModal/style"
import SubmitAddPerson from "../PromotionModal/SubmitAddPerson"
import SearchEmployeesInput from "../SearchEmployeesInput"
import { contentContainer, contentWrapper } from "./style"

export default function PromoutersPage() {
  const [modalNumber, setModalNumber] = useState<number>(1)
  const [animation, setAnimation] = useState(s.fadeIn)
  const [selectedPerson, setSelectedPerson] = useState<PotentialPerson | null>(
    null,
  )
  const [isShowModal, setIsShowModal] = useState(false)
  const [query, setQuery] = useState("")
  const [deleteCashier] = useDeleteCashier()
  const [addCashier, { isLoading: isLoadingAdd }] = useAddCashier()
  const { data, isError, isLoading } = useGetAllCashiersQuery({
    page: 0,
    size: 100,
    name: query.trim(),
  })

  const handleClickAction = (action: Action) => {
    switch (action) {
      case "close":
        setSelectedPerson(null)
        setModalNumber(1)
        setIsShowModal(false)
        setAnimation(s.fadeIn)
        break
      case "right":
        setAnimation(s.slideRight)
        setModalNumber((prev) => prev + 1)
        break
      case "left":
        setModalNumber((prev) => prev - 1)
        setAnimation(s.slideLeft)
        break
      case "submit":
        if (selectedPerson) {
          addCashier(selectedPerson.user_id)
            .then(() => setSelectedPerson(null))
            .finally(() => {
              setModalNumber(1), setAnimation(s.fadeIn), setIsShowModal(false)
            })
        }
        break
      default:
        break
    }
  }

  const modalTitles = [
    {
      slide: 1,
      title: "Назначить участника кассиром:",
      card: (
        <SearchParticipantsCard
          employees={data}
          handleClickAction={handleClickAction}
          person={selectedPerson}
          setPerson={setSelectedPerson}
        />
      ),
    },
    {
      slide: 2,
      title: "Подтвердите:",
      card: (
        <SubmitAddPerson
          btnTitle="Назначить кассиром"
          handleClickAction={handleClickAction}
          person={selectedPerson}
        />
      ),
    },
  ]

  if (isError && !isLoading) {
    return <ErrorNotification />
  }

  if (isLoading && !isError) {
    return <Loading />
  }

  return (
    <>
      <PromotionModal
        errorTitle=""
        cardTitle={modalTitles[modalNumber - 1].title}
        handleClickAction={() => handleClickAction("close")}
        isModal={isShowModal}
        isLoading={isLoadingAdd}
        animation={animation}
      >
        {isShowModal &&
          modalTitles.map(
            (cardType) =>
              cardType.slide === modalNumber && (
                <Fragment key={cardType.title}>{cardType.card}</Fragment>
              ),
          )}
      </PromotionModal>

      {!query && data && data.content.length < 1 && !isLoading && !isError ? (
        <NoPromotionEmployees
          onClick={() => setIsShowModal(true)}
          title="Вы не добавили ни одного кассира"
          btnTitle="+ Добавить кассира"
        />
      ) : (
        <Box sx={contentContainer}>
          <Box sx={contentWrapper}>
            <SearchEmployeesInput
              query={query}
              setQuery={setQuery}
              style={{ m: 0 }}
            />

            <GradientBtn
              contStyle={{ width: "max-content" }}
              btnStyle={{ width: "max-content", padding: "10px 20px" }}
              title="+ Добавить кассира"
              handleAction={() => setIsShowModal(true)}
            />
          </Box>

          <EmployeeCard
            deleteUser={deleteCashier}
            employees={data ? data.content : []}
          />
        </Box>
      )}
    </>
  )
}
