import React, { FC } from "react"
import s from "../styles.module.css"
import EmailIcon from "../../../assets/svg/email_icon.svg?react"
import { ResetFormSteps } from "../index"
import {
  useIsEmailExistCheckQuery,
  useSendResetCodeToEmailMutation,
} from "../../../app/data/tribeSwaggerApi"

type StepProps = {
  back: () => void
  setStep: (step: ResetFormSteps) => void
  email: string
}
const Step1: FC<StepProps> = ({ email, back, setStep }) => {
  const { data } = useIsEmailExistCheckQuery({
    email: email,
  })
  const [code] = useSendResetCodeToEmailMutation()
  return (
    <div className={s.step}>
      <p className={s.title_step_1}>Восстановление пароля</p>
      {!data ? (
        <p className={s.title_step_1}>Указанный email не существует</p>
      ) : null}
      <div className={s.icon}>
        <EmailIcon />
      </div>

      <div className={s.btn_action}>
        <button
          disabled={!data}
          className={s.form_button}
          onClick={() => {
            code({
              resetPasswordDto: {
                email: email,
              },
            })
            setStep(2)
          }}
        >
          Сбросить пароль
        </button>

        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            back()
          }}
          className={s.form_link}
        >
          Вход/Регистрация
        </a>
      </div>
    </div>
  )
}

export default Step1
