import { Box, Typography } from "@mui/material"
import { FC, useState } from "react"
import {
  PromoCard,
  PromoterData,
} from "../../../../app/data/promotion/promotionApi"
import CardAvatar from "../../../../components/CardEvent/CardAvatar"
import PromotionModal from "../../PromotionModal"
import InvitedPeopleCard from "../../PromotionModal/InvitedPeopleCard"
import ParticipantsPeopleCard from "../../PromotionModal/ParticipantsPeopleCard"
import * as s from "../../PromotionModal/style"
import * as st from "./style"

type Props = {
  promoter: PromoterData
  event: PromoCard
}

export const PromoutersCardDetails: FC<Props> = ({ promoter, event }) => {
  const {
    attendance_rate_in_percent: rateInPercent,
    avatar_url: avatarUrl,
    firstname,
    lastname,
    invited_count,
    participant_count,
    phone_number,
    promoter_id,
  } = promoter
  const PromoterFullName = `${firstname} ${lastname}`
  const [animation, setAnimation] = useState(s.fadeIn)
  const [isShow, setIsShow] = useState<"invited" | "participants" | "">("")
  const cardTitle =
    isShow === "invited"
      ? `${PromoterFullName} пригласил на:`
      : `${PromoterFullName} добавил участников на:`

  const handleClose = () => {
    setIsShow("")
    setAnimation(s.fadeIn)
  }

  return (
    <>
      <PromotionModal
        cardTitle={cardTitle}
        cardName={event.name}
        isLoading={false}
        handleClickAction={handleClose}
        isModal={!!isShow}
        animation={animation}
      >
        {isShow === "invited" && (
          <InvitedPeopleCard eventId={event.id} promoterId={promoter_id} />
        )}

        {isShow === "participants" && (
          <ParticipantsPeopleCard eventId={event.id} promoterId={promoter_id} />
        )}
      </PromotionModal>

      <Box sx={st.card}>
        <Box sx={st.userInfoCont}>
          <CardAvatar
            alt={PromoterFullName}
            src={avatarUrl}
            styles={st.avatar}
          />

          <Box sx={st.btnCont}>
            <Typography sx={st.btnTitle}>{PromoterFullName}</Typography>

            <Typography sx={st.btnInfo2}>{phone_number}</Typography>
          </Box>
        </Box>

        <Box
          sx={st.btnInfoCont}
          onClick={() => invited_count > 0 && setIsShow("invited")}
        >
          <Typography sx={st.btnTitle}>Приглашенные</Typography>

          <Typography sx={st.btnInfo}>{invited_count}</Typography>
        </Box>

        <Box
          sx={st.btnInfoCont}
          onClick={() => participant_count > 0 && setIsShow("participants")}
        >
          <Typography sx={st.btnTitle}>Участники</Typography>

          <Typography sx={st.btnInfo}>{participant_count}</Typography>
        </Box>

        <Box sx={st.rateStyle}>
          <Typography sx={st.btnTitle}>Результат работы:</Typography>

          <Typography sx={st.btnInfo}>{rateInPercent}%</Typography>
        </Box>

        {/* <Box sx={st.deleteUser} onClick={() => deletePromoter(promoter_id)}>
          <Pencil
            color="var(--filter-range-active)"
            width="20px"
            height="20px"
          />

          <Typography sx={st.deleteUserText}>Разжаловать</Typography>
        </Box> */}
      </Box>
    </>
  )
}

export default PromoutersCardDetails
