import { Avatar } from "@mui/material"
import ImageAva from "../../assets/svg/Avatar_test.svg"
import CancelBtn from "../../assets/svg/cancel.svg"
import s from "./style.module.css"

const AddresseeItem = () => {
  return (
    <li className={s.addresse_item}>
      <Avatar
        src={ImageAva}
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "100px",
        }}
      />
      <div className="addresse_name">@Аляулю</div>
      <Avatar
        src={CancelBtn}
        style={{
          width: "16px",
          height: "16px",
        }}
      />
    </li>
  )
}

export default AddresseeItem
