import { FC, ReactNode, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { top100Films } from "./mock";
import TextField from "@mui/material/TextField";
import { AutocompleteChangeReason, CircularProgress } from "@mui/material";
import React from "react";
import s from "./styles.module.css";
import { styles } from "./styles";
import { AddressLocation } from "../../../../../../app/data/location/schema";
import { OptionsItem, OptionsItemState } from "./OptionsItem";
import { isAddressValid } from "../../../../../../app/data/location/transforms";

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

interface AutocompleteInputAddressProps {
  title?: string;
  errorText?: string;
  placeholder?: string;
  addressList: AddressLocation[];
  isLoading: boolean;
  labelBuilder: (label: AddressLocation) => string;
  onClose?: () => void;
  onInputChange?: (input: string) => void;
  onOpen?: () => void;
  onChange?: (value: AddressLocation | null, reason: AutocompleteChangeReason, isFullAddress: boolean) => void;
  optionBuilder: (item: React.HTMLAttributes<HTMLLIElement>, option: AddressLocation, state: OptionsItemState) => ReactNode;
  defaultList?: readonly AddressLocation[];
}

const AutocompleteInputAddress: FC<AutocompleteInputAddressProps> = ({
                                                                       title,
                                                                       errorText,
                                                                       placeholder,
                                                                       addressList,
                                                                       isLoading,
                                                                       labelBuilder,
                                                                       onInputChange,
                                                                       onClose,
                                                                       onOpen,
                                                                       onChange,
                                                                       optionBuilder,
                                                                       defaultList
                                                                     }: AutocompleteInputAddressProps) => {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState<string>("");
  const onChangeHandler = (_: React.SyntheticEvent, value: AddressLocation | null, reason: AutocompleteChangeReason) => {
    if (onChange) {
      if (value) {
        onChange(value, reason, isAddressValid(value));
      } else {
        onChange(value, reason, false);

      }
    }
  };
  const onOpenHandler = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }
  const optionList = (): readonly AddressLocation[] => {
    if (addressList.length == 0 && inputValue.trim()==="" && defaultList){
      return defaultList;
    }
    return addressList
  }
  const onInputChangeHandler = (_: React.SyntheticEvent, value: string) => {
    setInputValue(value)
    if (onInputChange) {
      onInputChange(value);
    }
  }
  return (
    <div style={{ width: "100%" }}>
      {title && <span className={s.title}>{title}</span>}
      <Autocomplete
        id="asynchronous"
        sx={{ width: "100%" }}
        onChange={onChangeHandler}
        onInputChange={onInputChangeHandler}
        open={open}
        onOpen={onOpenHandler}
        onClose={() => {
          setOpen(false);
          if (onClose) {
            onClose();
          }
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={labelBuilder}
        options={optionList()}
        loading={isLoading}
        renderOption={optionBuilder}
        renderInput={(params) => (
          <TextField
            variant={"standard"}
            type={"search"}
            sx={styles}
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      {errorText && <p className={s.error_text}>{errorText}</p>}
    </div>
  );
};

export default AutocompleteInputAddress;

const isOptionEqualToValue = (option: AddressLocation, value: AddressLocation): boolean => {
  return option.city === value.city && option.settlement === value.settlement && option.street === value.street && option.house === value.house;
};