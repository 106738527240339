import { baseUrl } from "../../../app/data/avatarUrl";

export const downloadDocument = (id: number, onError: ()=>void): ()=>Promise<void>=> {
  return async () => {
    const ls = localStorage.getItem("LocalCurrentUser")
    const token = ls && JSON.parse(ls).applicationToken
    const headers = { Authorization: `Bearer ${token}` }

    if (!token) {
      return
    }

    const url = `${baseUrl()}/api/v1/organization/export/potential-participant/invited/event/${id}`
    fetch(url, {
      headers: headers,
    })
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = `Участники ивента.xlsx`
        link.click()
      })
      .catch(() => {
        onError()
      })
  }

}