import { CSSProperties } from "react"
import { menuIconsPack } from "../../../consts"
import { Visibility } from "../headerNavStyles"

export const sideMenuCont = {
  display: "flex",
  flexDirection: "column",
  minHeight: "327px",
  width: "100vw",
  position: "absolute",
  left: "-8px",
  top: "65px",
  zIndex: 99,
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  transition: "all 0.3s ease",
  "@media (min-width: 638px)": {
    left: "0px",
    width: "279px",
  },
}

export const menuBlurCont = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  zIndex: -1,
  top: 0,
  left: 0,
}

export const sideMenuItem = {
  display: "flex",
  gap: "10px",
  padding: "16px",
  borderRadius: "8px",
  transition: "background 0.3s ease",
  color: "var(--secondary-text)",
  fill: "var(--filter-range-active)",
  stroke: "var(--filter-range-active)",
  "&:hover": {
    background: "var(--btns-hover)",
  },
}

export const handleMenuItemStyle = (
  i: number,
  isOpenMenu: boolean,
): CSSProperties => ({
  width: "100%",

  position: "absolute",
  top: `${55 * i + -5}px`,
  left: isOpenMenu ? `${i * 0}px` : `-${(i + 1) * 150}px`,

  borderTopRightRadius: i === 0 ? "8px" : "0",
  borderBottomLeftRadius: i === menuIconsPack.length - 1 ? "8px" : "0",
  borderBottomRightRadius: i === menuIconsPack.length - 1 ? "8px" : "0",
  background: "var(--menu-header-bg)",
  opacity: isOpenMenu ? "1" : "0",
  visibility: isOpenMenu ? "visible" : "hidden",
  transition: "all 0.3s ease",
  boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.58)",
})

export const handleOpenCloseMenu = (isOpen: boolean): CSSProperties => ({
  opacity: isOpen ? "1" : "0",
  visibility: isOpen ? Visibility.visible : Visibility.hidden,
})
