import { swaggerSplitApi as api, favTag } from "../swaggerApi"

const injectedFavoriteApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getFavoriteList: build.query<string, number>({
      query: (id: number) => `/api/v1/events/favorite/${id}`,
      providesTags: favTag,
    }),
  }),
})

export const { useGetFavoriteListQuery } = injectedFavoriteApi
