import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FriendsList, FriendsState } from "./types"

const initialState: FriendsState = {
  currentFriendList: [],
  friendsCount: 0,
  isLoading: false,
}

export const friendsSlice = createSlice({
  name: "friends",
  initialState: initialState,
  reducers: {
    addFriends: (state, action: PayloadAction<FriendsList>) => {
      state.currentFriendList.push(...action.payload)
    },
    setFriendsCount: (state, action: PayloadAction<number>) => {
      state.friendsCount = action.payload
    },
    setLoadState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setError: (state, error: PayloadAction<string>) => {
      state.error = error.payload
    },
  },
})
export const { addFriends, setFriendsCount, setLoadState, setError } =
  friendsSlice.actions
export default friendsSlice.reducer
