import { FC } from "react"
import { Box, MenuList } from "@mui/material"

import { profileIconsPack } from "../../../../consts"
import HeaderProfileItem from "../HeaderProfileItem"
import HeaderProfileThemeItem from "../HeaderProfileThemeItem"
import {
  handleOpenCloseMenu,
  menuBlurCont,
  sideMenuCont,
} from "./headerProfileMenuStyles"

type Props = {
  isOpenMenu: boolean
}

const HeaderProfileMenu: FC<Props> = ({ isOpenMenu }) => {
  return (
    <MenuList sx={sideMenuCont} style={handleOpenCloseMenu(isOpenMenu)}>
      {profileIconsPack.map((item, i) =>
        item.icon ? (
          <HeaderProfileItem
            item={item}
            i={i}
            isOpenMenu={isOpenMenu}
            key={i}
          />
        ) : (
          <HeaderProfileThemeItem
            i={i}
            item={item}
            isOpenMenu={isOpenMenu}
            key={i}
          />
        ),
      )}
      <Box sx={menuBlurCont} onClick={() => handleOpenCloseMenu(false)} />
    </MenuList>
  )
}

export default HeaderProfileMenu
