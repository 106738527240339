import { useEffect, useState } from "react"

const useResize = (): number => {
  const [size, setSize] = useState<number>(window.screen.width)
  const windowListener = () =>{
    setSize(window.screen.width)
  }
  useEffect(() => {
    const getSize = () => setSize(window.screen.width)
    getSize()
    window.addEventListener("resize", windowListener)
    return () => window.removeEventListener("resize", windowListener)
  }, [])
  return size
}
export default useResize
export type calculateFunc <T> = (size: number) => T

export const useResizeComputed = <T extends any>(func: calculateFunc<T>): T => {
  const size = useResize()
  return func(size)
}



