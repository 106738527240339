import {  z } from "zod";
import { EventsTypeSchema } from "./schema";
import { IoError } from "../../../../../app/data/errors";

export type EventType = z.infer<typeof EventsTypeSchema>
export type EventsTypesResponse = {
  isLoading: boolean
  error?: IoError
  eventTypesList: EventType[]
}
export type EventTypeSelection = {
  label: string
  value: number
}

export type  EventPrivateSelect = {
  label: "Закрытое" | "Открытое"
  value: boolean
}
export type EventPrivateSelectList = Array<EventPrivateSelect>

export const EventModeVariants = [
  { value: false, label: "Открытое" },
  { value: true, label: "Закрытое" }
]
