import { FC } from "react"
import { Avatar, Box, Typography } from "@mui/material";
import * as st from "./style";
import Pencil from "../../../assets/svg/promotion-icon/pencil";

export type  EmployeeCardInfoProps = {
  id: number
  firstname: string
  lastname: string
  avatar_url: string
  userName: string
  handleDeleteUser: (id: number) => void
}
export const EmployeeCardInfo: FC<EmployeeCardInfoProps> = ({userName, id, lastname, firstname, avatar_url, handleDeleteUser}) => {


  return (
    <Box onClick={() => {}}  sx={st.employeeCard}>
        <Box sx={st.employeeCardCont}>
          <Avatar
            src={avatar_url}
            alt={userName}
            sx={st.employeeCardAvatar}
          />

          <Box sx={st.employeeCardInfo}>
            <Typography
              variant="body1"
              fontWeight={500}
              color={"var(--primary-text)"}
            >
              {firstname && lastname ? userName : "Неизвестный"}
            </Typography>
          </Box>
        </Box>

        <Box
          onClick={() => handleDeleteUser(id)}
          sx={st.employeeCardAction}
        >
          <Pencil color="var(--filter-range-active)" />

          <Typography
            variant="caption"
            color={"var(--filter-range-active)"}
            fontWeight={400}
            textTransform="uppercase"
          >
            Разжаловать
          </Typography>
        </Box>
      </Box>
  )

}
