import { useState } from "react"
import { selectUserId } from "../../app/data/appTokensStore"
import { useGetFavoriteListQuery } from "../../app/data/favorite/favoriteApi"
import { useAppSelector } from "../../app/hooks"
import FavoritePage from "../../components/favorite_page"
import Filtered from "../../components/filtered"
import InputFilter from "../../components/inputFilter"
import { EventModel } from "../../types"
import ErrorPage from "../ErrorPage/error-page"
import LoadingPage from "../LoadingPage/loading-page"
import { useGetAllEvents } from "../fid/hooks/useGetAllEvents"
import s from "./styles.module.css"

type SelectedTab = "favoriteTab" | "mapTab"
const Favorite = () => {
  const [activeBtn, setActiveBtn] = useState<SelectedTab>("favoriteTab")
  const [filtered, setFiltered] = useState<EventModel[] | null>(null)
  const credentials = useAppSelector(selectUserId)

  const { data: fav } = useGetFavoriteListQuery(credentials ?? 0)

  const favoriteFeedClick = () => {
    setActiveBtn("favoriteTab")
  }
  const mapClick = () => {
    setActiveBtn("mapTab")
  }
  const getFilteredData = (data: EventModel[] | null) => {
    setFiltered(data)
  }

  const { isLoading, isError, errorMessage, data } = useGetAllEvents()

  if (isLoading) {
    return <LoadingPage />
  }
  if (isError) {
    // return <ErrorPage error={errorMessage ?? "Unknow error"} />
  }
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.button_box}>
          <button
            className={`${s.button_feed}  ${
              activeBtn === "favoriteTab" ? s.active : ""
            }`}
            onClick={favoriteFeedClick}
          >
            Лента
          </button>
          <button
            className={`${s.button_feed}  ${
              activeBtn === "mapTab" ? s.active : ""
            }`}
            onClick={mapClick}
          >
            Показать на карте
          </button>
        </div>
        <InputFilter
          eventData={data ?? null}
          setFilteredData={getFilteredData}
        />

        <div className={s.content_box}>
          {activeBtn === "favoriteTab" ? (
            <FavoritePage userId={credentials?.toString()!!} />
          ) : (
            <div className={s.content}>Map</div>
          )}
        </div>
      </div>
      <Filtered />
    </div>
  )
}

export default Favorite
