import { swaggerSplitApi as api } from "../swaggerApi"
import { AutocompleteInputArgs, AutocompleteResult, convertJsonToQueryString, schema } from "./schema";
import {  z } from "zod";
import { transformAddressResponse, transformCityResponse } from "./transforms";

export type LocationResponse = z.infer<typeof schema>

export const injectedLocationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLocation: builder.query<LocationResponse, void>({
      query: () => ({
        url: "/api/v1/location/user",
        method: "GET",
      }),
    }),
    getaAutocompleteLocation: builder.query<AutocompleteResult, AutocompleteInputArgs>({
      query(args){
        return {
          url: `/api/v1/location/autocomplete`,
          method: "GET",
          params: {
            city_fias_id: args.city_fias_id,
            partial_address: args.partial_address,
            settlement_fias_id: args.settlement_fias_id,
            result_amount: 10,
          },
        }
      },
      transformResponse: transformAddressResponse,
    }),
    getAutocompleteCity: builder.query<AutocompleteResult, AutocompleteInputArgs>({
      query(args){
        return {
          url: `/api/v1/location/autocomplete?${convertJsonToQueryString(args)}`,
          method: "GET",
        }
      },
      transformResponse: transformCityResponse,
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetLocationQuery,
  useLazyGetAutocompleteCityQuery,
  useLazyGetaAutocompleteLocationQuery } = injectedLocationApi
