import s from "./styles.module.css"

type CheckBoxProps = {
  name?: string
}

const CheckBoxTwo = ({ name }: CheckBoxProps) => {
  return (
    <label className={s.checkbox_title}>
      {name ?? "Какой-то чекбокс"}
      <input type="checkbox" className={s.hobbies_settings_item} />
    </label>
  )
}

export default CheckBoxTwo
