interface Person {
  id: number
  user_avatar: string
  username: string
  last_name: string
  first_name: string
}

const people: Person[] = [
  {
    id: 1,
    user_avatar:
      "https://yt3.ggpht.com/a/AATXAJy7QyMGj5pB-VNl5tXTm657WNLc-r5PEU1c8g=s900-c-k-c0xffffffff-no-rj-mo",
    username: "@user1",
    last_name: "Иван Иванович",
    first_name: "Иван Иванович",
  },
  {
    id: 2,
    user_avatar:
      "https://sun9-61.userapi.com/impf/o87a8-ygpu1OBx-PG7-pbcqVz0HEtYBmHVhzVg/LT89A1qYQuw.jpg?size=774x1032&quality=96&sign=062579801b03614f63ccf7817ce0b0c6&c_uniq_tag=JqqG5CeNlytGBPpSFLyZ-3w6VTLOeO-2PR37T8g0q5s&type=album",
    username: "@user2",
    last_name: "Петр Петрович",
    first_name: "Иван Иванович",
  },
  {
    id: 3,
    user_avatar:
      "https://ru-static.z-dn.net/files/ddd/02bd3a23f077cda4cc1843b6467a4db1.jpg",
    username: "@user3",
    last_name: "Мария Ивановна",
    first_name: "Иван Иванович",
  },
  {
    id: 4,
    user_avatar:
      "https://cdn1.flamp.ru/cfdf66132b3994cd538e328a62eca0d8_1920.jpg",
    username: "@user4",
    last_name: "Александр Александрович",
    first_name: "Иван Иванович",
  },
  {
    id: 5,
    user_avatar:
      "https://distribution.faceit-cdn.net/images/e7571172-f384-4eb4-a170-27770b12e873.jpeg",
    username: "@user5",
    last_name: "Екатерина Петровна",
    first_name: "Иван Иванович",
  },
  {
    id: 6,
    user_avatar:
      "https://distribution.faceit-cdn.net/images/4a7cc86b-3a1a-4750-87de-ebfdedc0e9f5.jpeg",
    username: "@user6",
    last_name: "Николай Николаевич",
    first_name: "Иван Иванович",
  },
  {
    id: 7,
    user_avatar:
      "https://wallpapers.com/images/hd/guardians-of-the-galaxy-abstract-art-1oxaa4zvdmaab6hx.jpg",
    username: "@user7",
    last_name: "София Александровна",
    first_name: "Иван Иванович",
  },
  {
    id: 8,
    user_avatar:
      "https://flomaster.top/uploads/posts/2022-07/1658190796_32-flomaster-club-p-muzhchina-v-ochkakh-risunok-krasivo-37.jpg",
    username: "@user8",
    last_name: "Алексей Алексеевич",
    first_name: "Иван Иванович",
  },
  {
    id: 9,
    user_avatar:
      "https://yt3.googleusercontent.com/ytc/APkrFKYA6QFSw0sbpEE6UCoYWbmph51MJz5SU7OSrg2k2A=s900-c-k-c0x00ffffff-no-rj",
    username: "@user9",
    last_name: "Ольга Ивановна",
    first_name: "Иван Иванович",
  },
  {
    id: 10,
    user_avatar:
      "https://yt3.googleusercontent.com/ytc/AOPolaQYKWazqaGhy4w8Z6PHgwjSO4VK48zcyrJXUZ4s=s900-c-k-c0x00ffffff-no-rj",
    username: "@user10",
    last_name: "Дмитрий Дмитриевич",
    first_name: "Иван Иванович",
  },
  {
    id: 11,
    user_avatar:
      "https://ru-static.z-dn.net/files/d82/ffa86fe03c40f2a82a8b2967c74fa462.jpg",
    username: "@user11",
    last_name: "Анна Петровна",
    first_name: "Иван Иванович",
  },
  {
    id: 12,
    user_avatar:
      "https://shopgarena.net/wp-content/uploads/2023/06/Hinh-avatar-Minecraft-3D-de-thuong.webp",
    username: "@user12",
    last_name: "Сергей Сергеевич",
    first_name: "Иван Иванович",
  },
  {
    id: 13,
    user_avatar:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/b36d8e73-e501-40a4-a96f-2a47ceace7bc/d7gkojg-1c825c9e-aaaa-4f05-b8d3-9fad05134176.png/v1/fill/w_894,h_894,q_70,strp/_samurai__steam_avatar_by_sammysapphire_d7gkojg-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTAwMCIsInBhdGgiOiJcL2ZcL2IzNmQ4ZTczLWU1MDEtNDBhNC1hOTZmLTJhNDdjZWFjZTdiY1wvZDdna29qZy0xYzgyNWM5ZS1hYWFhLTRmMDUtYjhkMy05ZmFkMDUxMzQxNzYucG5nIiwid2lkdGgiOiI8PTEwMDAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.t-Dq-6VYOOiZr9aNHPJH18Is0aH9k9Y1qndI2-stlFM",
    username: "@user13",
    last_name: "Маргарита Николаевна",
    first_name: "Иван Иванович",
  },
  {
    id: 14,
    user_avatar:
      "https://i.pinimg.com/originals/60/92/db/6092db9dcfe396764ebdafd9cc46dd4a.jpg",
    username: "@user14",
    last_name: "Владимир Владимирович",
    first_name: "Иван Иванович",
  },
  {
    id: 15,
    user_avatar:
      "https://iroha.doratoa.net/i/art001/thb/preview-NGNL_-_Shuvi_i00004-1440x1200.jpeg",
    username: "@user15",
    last_name: "Елена Александровна",
    first_name: "Иван Иванович",
  },
  {
    id: 16,
    user_avatar:
      "https://avatars.mds.yandex.net/i?id=64ccad766add548e0359475795b3c592fcc39959-10329275-images-thumbs&n=13",
    username: "@user16",
    last_name: "Игорь Игоревич",
    first_name: "Иван Иванович",
  },
  {
    id: 17,
    user_avatar:
      "https://gas-kvas.com/grafic/uploads/posts/2023-10/1696601361_gas-kvas-com-p-kartinki-iz-robloksa-50.jpg",
    username: "@user17",
    last_name: "Татьяна Ивановна",
    first_name: "Иван Иванович",
  },
  {
    id: 18,
    user_avatar:
      "https://ru-static.z-dn.net/files/dc2/ea5c7b5e52b892c9e7c9166edb471f83.jpg",
    username: "@user18",
    last_name: "Андрей Андреевич",
    first_name: "Иван Иванович",
  },
  {
    id: 19,
    user_avatar:
      "https://avatars.mds.yandex.net/i?id=6d44622fa1ad120a839b644305c2ab28f897fbbc-10158105-images-thumbs&n=13",
    username: "@user19",
    last_name: "Наталья Петровна",
    first_name: "Иван Иванович",
  },
  {
    id: 20,
    user_avatar:
      "https://yt3.googleusercontent.com/al4o1LlHXQDDyeJNmBPvH2MVUj7aXEEWr1w5mKA09Qq5Hw1ZVaqYqt5ep4MDAwHJUep9i2LJ6Q=s900-c-k-c0x00ffffff-no-rj",
    username: "@user20",
    last_name: "Александра Александровна",
    first_name: "Иван Иванович",
  },
  // {
  //   id: 91,
  //   user_avatar:
  //     "https://yt3.ggpht.com/a/AATXAJy7QyMGj5pB-VNl5tXTm657WNLc-r5PEU1c8g=s900-c-k-c0xffffffff-no-rj-mo",
  //   username: "@user1",
  //   last_name: "Иван Иванович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 92,
  //   user_avatar:
  //     "https://sun9-61.userapi.com/impf/o87a8-ygpu1OBx-PG7-pbcqVz0HEtYBmHVhzVg/LT89A1qYQuw.jpg?size=774x1032&quality=96&sign=062579801b03614f63ccf7817ce0b0c6&c_uniq_tag=JqqG5CeNlytGBPpSFLyZ-3w6VTLOeO-2PR37T8g0q5s&type=album",
  //   username: "@user2",
  //   last_name: "Петр Петрович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 93,
  //   user_avatar:
  //     "https://ru-static.z-dn.net/files/ddd/02bd3a23f077cda4cc1843b6467a4db1.jpg",
  //   username: "@user3",
  //   last_name: "Мария Ивановна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 94,
  //   user_avatar:
  //     "https://cdn1.flamp.ru/cfdf66132b3994cd538e328a62eca0d8_1920.jpg",
  //   username: "@user4",
  //   last_name: "Александр Александрович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 95,
  //   user_avatar:
  //     "https://distribution.faceit-cdn.net/images/e7571172-f384-4eb4-a170-27770b12e873.jpeg",
  //   username: "@user5",
  //   last_name: "Екатерина Петровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 96,
  //   user_avatar:
  //     "https://distribution.faceit-cdn.net/images/4a7cc86b-3a1a-4750-87de-ebfdedc0e9f5.jpeg",
  //   username: "@user6",
  //   last_name: "Николай Николаевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 97,
  //   user_avatar:
  //     "https://wallpapers.com/images/hd/guardians-of-the-galaxy-abstract-art-1oxaa4zvdmaab6hx.jpg",
  //   username: "@user7",
  //   last_name: "София Александровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 98,
  //   user_avatar:
  //     "https://flomaster.top/uploads/posts/2022-07/1658190796_32-flomaster-club-p-muzhchina-v-ochkakh-risunok-krasivo-37.jpg",
  //   username: "@user8",
  //   last_name: "Алексей Алексеевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 99,
  //   user_avatar:
  //     "https://yt3.googleusercontent.com/ytc/APkrFKYA6QFSw0sbpEE6UCoYWbmph51MJz5SU7OSrg2k2A=s900-c-k-c0x00ffffff-no-rj",
  //   username: "@user9",
  //   last_name: "Ольга Ивановна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 910,
  //   user_avatar:
  //     "https://yt3.googleusercontent.com/ytc/AOPolaQYKWazqaGhy4w8Z6PHgwjSO4VK48zcyrJXUZ4s=s900-c-k-c0x00ffffff-no-rj",
  //   username: "@user10",
  //   last_name: "Дмитрий Дмитриевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 191,
  //   user_avatar:
  //     "https://ru-static.z-dn.net/files/d82/ffa86fe03c40f2a82a8b2967c74fa462.jpg",
  //   username: "@user11",
  //   last_name: "Анна Петровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 192,
  //   user_avatar:
  //     "https://shopgarena.net/wp-content/uploads/2023/06/Hinh-avatar-Minecraft-3D-de-thuong.webp",
  //   username: "@user12",
  //   last_name: "Сергей Сергеевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 913,
  //   user_avatar:
  //     "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/b36d8e73-e501-40a4-a96f-2a47ceace7bc/d7gkojg-1c825c9e-aaaa-4f05-b8d3-9fad05134176.png/v1/fill/w_894,h_894,q_70,strp/_samurai__steam_avatar_by_sammysapphire_d7gkojg-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTAwMCIsInBhdGgiOiJcL2ZcL2IzNmQ4ZTczLWU1MDEtNDBhNC1hOTZmLTJhNDdjZWFjZTdiY1wvZDdna29qZy0xYzgyNWM5ZS1hYWFhLTRmMDUtYjhkMy05ZmFkMDUxMzQxNzYucG5nIiwid2lkdGgiOiI8PTEwMDAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.t-Dq-6VYOOiZr9aNHPJH18Is0aH9k9Y1qndI2-stlFM",
  //   username: "@user13",
  //   last_name: "Маргарита Николаевна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 194,
  //   user_avatar:
  //     "https://i.pinimg.com/originals/60/92/db/6092db9dcfe396764ebdafd9cc46dd4a.jpg",
  //   username: "@user14",
  //   last_name: "Владимир Владимирович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 195,
  //   user_avatar:
  //     "https://iroha.doratoa.net/i/art001/thb/preview-NGNL_-_Shuvi_i00004-1440x1200.jpeg",
  //   username: "@user15",
  //   last_name: "Елена Александровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 196,
  //   user_avatar:
  //     "https://avatars.mds.yandex.net/i?id=64ccad766add548e0359475795b3c592fcc39959-10329275-images-thumbs&n=13",
  //   username: "@user16",
  //   last_name: "Игорь Игоревич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 197,
  //   user_avatar:
  //     "https://gas-kvas.com/grafic/uploads/posts/2023-10/1696601361_gas-kvas-com-p-kartinki-iz-robloksa-50.jpg",
  //   username: "@user17",
  //   last_name: "Татьяна Ивановна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 198,
  //   user_avatar:
  //     "https://ru-static.z-dn.net/files/dc2/ea5c7b5e52b892c9e7c9166edb471f83.jpg",
  //   username: "@user18",
  //   last_name: "Андрей Андреевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 199,
  //   user_avatar:
  //     "https://avatars.mds.yandex.net/i?id=6d44622fa1ad120a839b644305c2ab28f897fbbc-10158105-images-thumbs&n=13",
  //   username: "@user19",
  //   last_name: "Наталья Петровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 290,
  //   user_avatar:
  //     "https://yt3.googleusercontent.com/al4o1LlHXQDDyeJNmBPvH2MVUj7aXEEWr1w5mKA09Qq5Hw1ZVaqYqt5ep4MDAwHJUep9i2LJ6Q=s900-c-k-c0x00ffffff-no-rj",
  //   username: "@user20",
  //   last_name: "Александра Александровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 91,
  //   user_avatar:
  //     "https://yt3.ggpht.com/a/AATXAJy7QyMGj5pB-VNl5tXTm657WNLc-r5PEU1c8g=s900-c-k-c0xffffffff-no-rj-mo",
  //   username: "@user1",
  //   last_name: "Иван Иванович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 9152,
  //   user_avatar:
  //     "https://sun9-61.userapi.com/impf/o87a8-ygpu1OBx-PG7-pbcqVz0HEtYBmHVhzVg/LT89A1qYQuw.jpg?size=774x1032&quality=96&sign=062579801b03614f63ccf7817ce0b0c6&c_uniq_tag=JqqG5CeNlytGBPpSFLyZ-3w6VTLOeO-2PR37T8g0q5s&type=album",
  //   username: "@user2",
  //   last_name: "Петр Петрович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 9153,
  //   user_avatar:
  //     "https://ru-static.z-dn.net/files/ddd/02bd3a23f077cda4cc1843b6467a4db1.jpg",
  //   username: "@user3",
  //   last_name: "Мария Ивановна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 9415,
  //   user_avatar:
  //     "https://cdn1.flamp.ru/cfdf66132b3994cd538e328a62eca0d8_1920.jpg",
  //   username: "@user4",
  //   last_name: "Александр Александрович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 9155,
  //   user_avatar:
  //     "https://distribution.faceit-cdn.net/images/e7571172-f384-4eb4-a170-27770b12e873.jpeg",
  //   username: "@user5",
  //   last_name: "Екатерина Петровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 9156,
  //   user_avatar:
  //     "https://distribution.faceit-cdn.net/images/4a7cc86b-3a1a-4750-87de-ebfdedc0e9f5.jpeg",
  //   username: "@user6",
  //   last_name: "Николай Николаевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 9157,
  //   user_avatar:
  //     "https://wallpapers.com/images/hd/guardians-of-the-galaxy-abstract-art-1oxaa4zvdmaab6hx.jpg",
  //   username: "@user7",
  //   last_name: "София Александровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 9158,
  //   user_avatar:
  //     "https://flomaster.top/uploads/posts/2022-07/1658190796_32-flomaster-club-p-muzhchina-v-ochkakh-risunok-krasivo-37.jpg",
  //   username: "@user8",
  //   last_name: "Алексей Алексеевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 1599,
  //   user_avatar:
  //     "https://yt3.googleusercontent.com/ytc/APkrFKYA6QFSw0sbpEE6UCoYWbmph51MJz5SU7OSrg2k2A=s900-c-k-c0x00ffffff-no-rj",
  //   username: "@user9",
  //   last_name: "Ольга Ивановна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 91510,
  //   user_avatar:
  //     "https://yt3.googleusercontent.com/ytc/AOPolaQYKWazqaGhy4w8Z6PHgwjSO4VK48zcyrJXUZ4s=s900-c-k-c0x00ffffff-no-rj",
  //   username: "@user10",
  //   last_name: "Дмитрий Дмитриевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 19151,
  //   user_avatar:
  //     "https://ru-static.z-dn.net/files/d82/ffa86fe03c40f2a82a8b2967c74fa462.jpg",
  //   username: "@user11",
  //   last_name: "Анна Петровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 19152,
  //   user_avatar:
  //     "https://shopgarena.net/wp-content/uploads/2023/06/Hinh-avatar-Minecraft-3D-de-thuong.webp",
  //   username: "@user12",
  //   last_name: "Сергей Сергеевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 91153,
  //   user_avatar:
  //     "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/b36d8e73-e501-40a4-a96f-2a47ceace7bc/d7gkojg-1c825c9e-aaaa-4f05-b8d3-9fad05134176.png/v1/fill/w_894,h_894,q_70,strp/_samurai__steam_avatar_by_sammysapphire_d7gkojg-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTAwMCIsInBhdGgiOiJcL2ZcL2IzNmQ4ZTczLWU1MDEtNDBhNC1hOTZmLTJhNDdjZWFjZTdiY1wvZDdna29qZy0xYzgyNWM5ZS1hYWFhLTRmMDUtYjhkMy05ZmFkMDUxMzQxNzYucG5nIiwid2lkdGgiOiI8PTEwMDAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.t-Dq-6VYOOiZr9aNHPJH18Is0aH9k9Y1qndI2-stlFM",
  //   username: "@user13",
  //   last_name: "Маргарита Николаевна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 19154,
  //   user_avatar:
  //     "https://i.pinimg.com/originals/60/92/db/6092db9dcfe396764ebdafd9cc46dd4a.jpg",
  //   username: "@user14",
  //   last_name: "Владимир Владимирович",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 11595,
  //   user_avatar:
  //     "https://iroha.doratoa.net/i/art001/thb/preview-NGNL_-_Shuvi_i00004-1440x1200.jpeg",
  //   username: "@user15",
  //   last_name: "Елена Александровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 19156,
  //   user_avatar:
  //     "https://avatars.mds.yandex.net/i?id=64ccad766add548e0359475795b3c592fcc39959-10329275-images-thumbs&n=13",
  //   username: "@user16",
  //   last_name: "Игорь Игоревич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 15197,
  //   user_avatar:
  //     "https://gas-kvas.com/grafic/uploads/posts/2023-10/1696601361_gas-kvas-com-p-kartinki-iz-robloksa-50.jpg",
  //   username: "@user17",
  //   last_name: "Татьяна Ивановна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 11598,
  //   user_avatar:
  //     "https://ru-static.z-dn.net/files/dc2/ea5c7b5e52b892c9e7c9166edb471f83.jpg",
  //   username: "@user18",
  //   last_name: "Андрей Андреевич",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 11599,
  //   user_avatar:
  //     "https://avatars.mds.yandex.net/i?id=6d44622fa1ad120a839b644305c2ab28f897fbbc-10158105-images-thumbs&n=13",
  //   username: "@user19",
  //   last_name: "Наталья Петровна",
  //   first_name: "Иван Иванович",
  // },
  // {
  //   id: 21590,
  //   user_avatar:
  //     "https://yt3.googleusercontent.com/al4o1LlHXQDDyeJNmBPvH2MVUj7aXEEWr1w5mKA09Qq5Hw1ZVaqYqt5ep4MDAwHJUep9i2LJ6Q=s900-c-k-c0x00ffffff-no-rj",
  //   username: "@user20",
  //   last_name: "Александра Александровна",
  //   first_name: "Иван Иванович",
  // },
]

export default people
