import { LngLatBounds } from "@yandex/ymaps3-types"

export function calculateDistance(coordinates: LngLatBounds): number {
  // Предполагается, что координаты являются массивом из двух элементов, представляющих долготу и широту
  const [lon1, lat1] = coordinates[0]
  const [lon2, lat2] = coordinates[1]

  // Расчет расстояния в метрах с использованием формулы Гаусса-Крюгера
  const R = 6371000 // Средний радиус Земли в метрах
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLon = ((lon2 - lon1) * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c

  return Math.trunc(distance / 2)
}
