import s from "./styles.module.scss"
import UnknownAvatar from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import { ViewsNames } from "../.."
import GradientButton, { GradienButtonStyles } from "../../../../GradientStateBtn"
import { UserDataForAddToBaseParticipant } from "../../../../../app/data/promotion/promotionApi"
import { useEffect } from "react"

type AddUserAccessProps = {
	userData: UserDataForAddToBaseParticipant
	onSetView: (view: ViewsNames) => void
	onAddParticipant: (userData: UserDataForAddToBaseParticipant) => void
	addParticipantStatus: boolean;
}

const AddUserAccess = ( {userData, onSetView, onAddParticipant, addParticipantStatus} : AddUserAccessProps ) => {
	const {lastname, phone_number, firstname, telegram_username} = userData

	useEffect(() => {
		addParticipantStatus && onClickBtnGoToView(ViewsNames.SendQr)
	},[addParticipantStatus])


	const onAddButtonAddParticipatn = async() => {
		onAddParticipant(userData)
	}

	const onClickBtnGoToView = (view: ViewsNames) =>  {
		onSetView(view)
	}
	return (
			<div className={s.add_access_user}>
					<div className={s.user_data}>
						<UnknownAvatar className={s.user_avatar} />
						<h4 className="user_name">
							{`${firstname} ${lastname}`}
						</h4>
						<p className={s.user_phone}>
							{phone_number}
						</p>
					</div>
					<div className={s.buttons_block}>
						<GradientButton<Object> onAction={() => onClickBtnGoToView(ViewsNames.AddNewUser)} style={GradienButtonStyles.borderGradient} params={{text: "Назад" , width: 79, height: 48} }  />
						<GradientButton<Object> onAction={() => onAddButtonAddParticipatn()} style={GradienButtonStyles.fullGradient} params={{text: "Добавить человека в базу" }}  />
					</div>
			</div>
	)
}

export default AddUserAccess