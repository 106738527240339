export type SvgType = {
  color?: string
  width?: string
  height?: string
}

export default function HomeIcon({ color, width, height }: SvgType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12.7615C5 11.4037 5 10.7249 5.27446 10.1281C5.54892 9.53139 6.06437 9.08958 7.09525 8.20596L8.09525 7.34882C9.95857 5.75169 10.8902 4.95313 12 4.95312C13.1098 4.95312 14.0414 5.75169 15.9047 7.34882L16.9047 8.20596C17.9356 9.08958 18.4511 9.53139 18.7255 10.1281C19 10.7249 19 11.4037 19 12.7615V17.0019C19 18.8875 19 19.8303 18.4142 20.4161C17.8284 21.0019 16.8856 21.0019 15 21.0019H9C7.11438 21.0019 6.17157 21.0019 5.58579 20.4161C5 19.8303 5 18.8875 5 17.0019V12.7615Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M14.5 21V16C14.5 15.4477 14.0523 15 13.5 15H10.5C9.94772 15 9.5 15.4477 9.5 16V21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
