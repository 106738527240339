import  {z} from "zod"


export const useValidateFormikData  = <T extends z.ZodTypeAny> (schema: T, data: unknown): boolean => {
  console.log(data)
  const validation = schema.safeParse(data)
  if (!validation.success) {
    console.error(validation.error.message)
  }
  return !validation.success;
}
