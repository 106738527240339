import { z } from "zod"

export const schema = z.object({
  country: z.string(),
  city: z.string(),
  longitude: z.number(),
  latitude: z.number(),
});

export type AutocompleteInputArgs = {
  partial_address: string
  result_amount: number
  from_bound?: string
  to_bound?: string
  region?: string
  region_fias_id?: string
  area?: string
  area_fias_id?: string
  city?: string
  city_fias_id?: string
  settlement?: string
  settlement_fias_id?: string
  street?: string
  street_fias_id?: string
}
export type AddressLocation = {
  region: string
  region_type: string
  region_fias_id: string
  area: string
  area_type: string
  area_fias_id: string
  city: string
  city_type: string
  city_fias_id: string
  settlement: string
  settlement_type: string
  settlement_fias_id: string
  street: string
  street_type: string
  street_fias_id: string
  house: string
  block: string
  block_type: string
  lat: string
  lon: string
}
export type AutocompleteResult = {
  status: string
  data: AddressLocation[]
}

export function convertJsonToQueryString(json: Record<string, string | number>): string {
  const queryStringParams: string[] = [];

  for (const key in json) {
    // @ts-ignore
    if (typeof json[key] === 'string' && json[key].trim() !== '') {
      queryStringParams.push(`${key}=${json[key]}`);
    } else if (typeof json[key] === 'number' && json[key] !== 0) {
      queryStringParams.push(`${key}=${json[key]}`);
    }
  }
  return queryStringParams.join('&');
}



