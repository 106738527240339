import _ from "lodash"
import { isCheckEmail, isCheckUsername } from "./validationFetch"

const throttleUser = _.throttle(isCheckUsername, 500)
const throttleEmail = _.throttle(isCheckEmail, 500)
export const validLoginForRegistration = async (
  username: string | undefined,
): Promise<string | undefined> => {
  const result = throttleUser(username)
  if (result) {
    return (await result).error
  } else {
    return
  }
}

export const validEmailForRegistration = async (
  email: string | undefined,
): Promise<string | undefined> => {
  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return "Невалидная почта"
  }
  const result = throttleEmail(email)
  if (result) {
    return (await result).error
  } else {
    return
  }
}
export const validEmailForAuth = async (
  email: string | undefined,
): Promise<string | undefined> => {
  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return "Невалидная почта"
  }
  const result = throttleEmail(email)
  if (result) {
    return (await result).data ? undefined : "Пользователь не сущетвует"
  } else {
    return result
  }
}
