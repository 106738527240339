import { SxProps, Theme } from "@mui/material"

export const contentWrapper: SxProps<Theme> = {
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  width: "100%",
  flexWrap: "wrap",
  flexDirection: "column",
  alignItems: "center",
}
