import { SxProps, Theme } from "@mui/material"

export const promoEventCardCont: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  flexBasis: "154px",
  maxWidth: "325px",
  "@media (min-width: 1023px)": {
    display: "flex",
    flexDirection: "row",
  },
}

export const promoEventCardImage = {
  flexShrink: 0,
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0.5rem",
  maxWidth: "154px",
  backgroundColor: "var(--surface-bg)",
  width: "100%",
  aspectRatio: "1 / 1",
  img: {
    width: "100%",
    aspectRatio: "1 / 1",
    objectFit: "cover",
    borderRadius: "8px",
  },
  svg: {
    borderRadius: "8px",
  },
  "@media (max-width: 1024px)": {
    maxWidth: "84px",
  },
}

export const promoEventCardText = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "10px",
}

export const promoEventCardDescr = { display: "flex", gap: "10px" }

export const promonEventCardIcon = "var(--secondary-text)"
