import { CSSProperties } from "react"
import { Visibility } from "../../headerNavStyles"

export const sideMenuCont = {
  display: "flex",
  flexDirection: "column",
  minHeight: "327px",
  width: "100vw",
  position: "absolute",
  right: "-8px",
  top: "65px",
  zIndex: 99,
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  transition: "all 0.3s ease",
  "@media (min-width: 638px)": {
    right: "0px",
    width: "279px",
  },
}

export const menuBlurCont = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  zIndex: -1,
  top: 0,
  right: 0,
}

export const handleOpenCloseMenu = (isOpen: boolean): CSSProperties => ({
  opacity: isOpen ? "1" : "0",
  visibility: isOpen ? Visibility.visible : Visibility.hidden,
})
