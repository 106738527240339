import { FC } from "react"
import { useValidateFormikData } from "../../../hooks/useValidateFormikData"
import { TStepProps } from "../../../types"
import Buttons from "../../common/buttons"
import Title from "../../common/title"
import UploadPhoto from "../../common/uploadPhoto"
import s from "./styles.module.css"

type TStep3Props = TStepProps & {
  addPhoto: (imgArrBuf: string, imgDataUrl: string, pos: number) => void
  photo: Array<string>
}

const Step3: FC<TStep3Props> = ({
  makeStep,
  step,
  formik,
  addPhoto,
  photo,
}) => {
  const avatars = formik.values.avatars_for_adding as Array<string>
  // const isFieldEmpty = useValidateFormikData([img1, img2, img3])

  return (
    <div className={s.container}>
      <Title align={"flex-start"} title={"Загрузите фото"} />
      <div className={s.content}>
        <UploadPhoto
          photo={photo[0]}
          setPhoto={(imgArrBuf: string, imgDataUrl: string) => {
            addPhoto(imgArrBuf, imgDataUrl, 0)
          }}
        />

        <div className={s.sub_content}>
          <UploadPhoto
            photo={photo[1]}
            setPhoto={(imgArrBuf: string, imgDataUrl: string) => {
              addPhoto(imgArrBuf, imgDataUrl, 1)
            }}
          />
          <UploadPhoto
            photo={photo[2]}
            setPhoto={(imgArrBuf: string, imgDataUrl: string) => {
              addPhoto(imgArrBuf, imgDataUrl, 2)
            }}
          />
        </div>
      </div>
      <Buttons
        step={step}
        next={() => {
          // if (isFieldEmpty) return
          makeStep("+")
        }}
        back={() => makeStep("-")}
      />
    </div>
  )
}

export default Step3
