import s from "./style.module.css"
import SortMessageArrow from "../../assets/svg/sortMessageArrow.svg?react"

const MessagesSort = () => {
  return (
    <div className={s.message__sorting}>
      <span className={s.message__sorting_type} tabIndex={0}>
        Все сообщения
      </span>
      <SortMessageArrow />
      <ul className={(s.message__options, s.places__options_closed)}>
        <li className={s.message_sort__item} tabIndex={0}></li>
      </ul>
    </div>
  )
}

export default MessagesSort
