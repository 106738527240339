import { Box, Button, Stack } from "@mui/material"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { FC, useEffect, useState } from "react"
import { Link, useParams, useNavigate, useLocation } from "react-router-dom"
import { useAppDispatch } from "../../../app/hooks"
import { CalendarCardIcon } from "../../../consts"
import { useIsAuthorizedUser } from "../../../hooks/useIsAuthorizedUser"
import { show } from "../../../pages/signIn/SignInSlice"
import { EventModel } from "../../../types"
import {
  card,
  cardBtnExtra,
  cardBtnExtraText,
  cardBtnImGoing,
  cardBtnsCont,
  cardCalendarContainer,
  cardCalendarDayCont,
  cardCalendarDayText,
  cardCalendarMonthCont,
  cardCalendarMonthText,
  cardContent,
  cardImg,
  cardInfo,
  cardInfoCont,
  cardInfoIcon,
  cardTitle,
  cardTitleCont,
} from "./CardEventStyles"
import WalletIconCard from "/src/assets/svg/card-icons-pack/Wallet-icon.svg?react"
import CalendarIconCard from "/src/assets/svg/card-icons-pack/calendar-icon.svg?react"
import ClockIconCard from "/src/assets/svg/card-icons-pack/clock-icon.svg?react"
import LocationIconCard from "/src/assets/svg/card-icons-pack/location-icon.svg?react"
import ShareEventBtn from "./ShareEventBtn"
import {
  setEventData,
  setEventDialogFormat,
  setPromotionPopup,
} from "../../../app/data/popupSlice/popupSlice"
import { EventDialogFormats } from "../../popups/EventDialog"
import CardAvatarRow from "../CardAvatarRow"
import formatDateFromString from "../../../app/data/formatDateFromString"
import { useLazyGetEventParticipants } from "../../../app/data/promotion/promotionApi"

//#region AVATAR_IMAGES
export enum CardEventFormats {
  MyEvents = "MyEvents",
  ModalThumb = "ModalThumb",
  Promotion = "Promotion",
}

type EventItemProps = {
  format?: CardEventFormats
  event: EventModel
  isRemove?: boolean
  modalToggler?: () => void
  setModalLink?: (eventLink: string) => void
}

const CardEvent: FC<EventItemProps> = ({
  event,
  format,
  modalToggler,
  setModalLink,
}) => {
  const {
    description,
    id,
    img,
    location,
    time: timeStamp,
    title,
    participants,
    isFree,
  } = event
  const locationNav = useNavigate()
  const { date, time, dayNum, monthNum } = formatDateFromString(timeStamp)
  const [getUsersBaseByEventId, { data }] = useLazyGetEventParticipants()
  const [isWantGo, setIsWantGo] = useState<boolean>(false)
  const isUserAuth = useIsAuthorizedUser()
  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log(data)
  }, [data])

  const renderBoxOrLink = (format: CardEventFormats | undefined) =>
    format === CardEventFormats.ModalThumb ? "div" : Link
  const handleClickDetails = () => {
    locationNav(`fid/${id}`)
  }
  // IsSuccess не возвращает тру, всегда фолс
  const handleClick = () => {
    if (!isUserAuth) {
      dispatch(show())
    } else {
      onClickSetDataForModal(EventDialogFormats.invite)
    }
  }

  const onClickSetDataForModal = (format: EventDialogFormats) => {
    dispatch(setEventData(event))
    dispatch(setEventDialogFormat(format))
    modalToggler && modalToggler()
    setModalLink && setModalLink(`fid/${id}`)
  }

  const handleSetPromotionPopup = () => {
    format === CardEventFormats.Promotion && dispatch(setPromotionPopup(event))
    getUsersBaseByEventId({ eventId: id })
  }

  return (
    <Card
      sx={
        format === CardEventFormats.ModalThumb
          ? {
              ...card,
              background: "#FEF7FF",
              boxShadow: "none",
              border: "#C7C5D0 1px solid",
            }
          : card
      }
    >
      <Box>
        <Box
          component={renderBoxOrLink(format)}
          onClick={() => handleSetPromotionPopup()}
          to={format !== CardEventFormats.Promotion ? `fid/${id}` : "#"}
        >
          <CardMedia sx={cardImg} image={img} title={title} />
        </Box>

        {format !== CardEventFormats.ModalThumb && (
          <>
            <Box sx={cardCalendarContainer}>
              <Box sx={cardCalendarMonthCont}>
                <p style={cardCalendarMonthText}>
                  {CalendarCardIcon.ru[monthNum]}
                </p>
              </Box>

              <Box sx={cardCalendarDayCont}>
                <p style={cardCalendarDayText}>{dayNum}</p>
              </Box>
            </Box>
          </>
        )}
        <CardContent sx={cardContent}>
          <Box sx={cardTitleCont}>
            <Box
              component={renderBoxOrLink(format)}
              to={`fid/${id}`}
              style={cardTitleCont}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={cardTitle}
              >
                {title.slice(0, 1).toUpperCase() + title.slice(1)}
              </Typography>
            </Box>
            {format !== CardEventFormats.ModalThumb && (
              <ShareEventBtn onClickSetModal={onClickSetDataForModal} />
            )}
          </Box>
          <Box
            sx={
              format === CardEventFormats.ModalThumb
                ? { ...cardInfoCont, borderBottom: "none" }
                : cardInfoCont
            }
          >
            <Box sx={cardInfo}>
              <Box
                component={renderBoxOrLink(format)}
                to="#"
                style={cardInfoIcon}
              >
                <LocationIconCard fill={"var(--secondary-text)"} />
                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {location}
                </Typography>
              </Box>
            </Box>
            <Box sx={cardInfo}>
              <Box
                component={renderBoxOrLink(format)}
                to="#"
                style={cardInfoIcon}
              >
                <CalendarIconCard stroke={"var(--secondary-text)"} />

                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {date}
                </Typography>
              </Box>
            </Box>
            <Box sx={cardInfo}>
              <Box
                component={renderBoxOrLink(format)}
                to="/"
                style={cardInfoIcon}
              >
                <ClockIconCard stroke={"var(--secondary-text)"} />

                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {time}
                </Typography>
              </Box>
            </Box>
            <Box sx={cardInfo}>
              <Box
                component={renderBoxOrLink(format)}
                to="#"
                style={cardInfoIcon}
              >
                <WalletIconCard stroke={"var(--secondary-text)"} />
                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {isFree ? "Бесплатно" : "Требуется плата за вход"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {format === undefined ||
            (format === CardEventFormats.MyEvents && (
              <Link to={`fid/${id}`}>
                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {description.slice(0, 116) + "..."}
                </Typography>
              </Link>
            ))}
        </CardContent>
      </Box>
      <Box>
        {format !== CardEventFormats.Promotion && (
          <CardAvatarRow avatars={participants ?? []} />
        )}
        {(format === undefined || format === CardEventFormats.Promotion) && (
          <>
            <CardActions sx={cardBtnsCont}>
              {format === CardEventFormats.Promotion ? (
                <CardAvatarRow avatars={participants ?? []} />
              ) : (
                <Button sx={cardBtnImGoing} onClick={handleClick}>
                  <p>Я пойду</p>
                </Button>
              )}
              <Button
                variant="contained"
                onClick={
                  format === CardEventFormats.Promotion
                    ? handleSetPromotionPopup
                    : handleClickDetails
                }
                sx={cardBtnExtra}
              >
                <p style={cardBtnExtraText}>
                  {format === CardEventFormats.Promotion
                    ? "ВЫСЛАТЬ QR-КОД"
                    : "Подробнее"}
                </p>
              </Button>
            </CardActions>
          </>
        )}
      </Box>
    </Card>
  )
}

export default CardEvent
