import { Box } from "@mui/material"
import { useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import useWheelScroll from "../../hooks/useWheelScroll"
import CategoryItem from "./CategoryItem"
import { FaAngleLeft } from "../../assets/svg/button-icons/FaAngleLeft"
import { FaAngleRight } from "../../assets/svg/button-icons/FaAngleRight"
import { categoryTabsCont, categoryTabsWrapper } from "./categoryTabsStyles"
import style from "./styles.module.css"
import { useCategoryProvider } from "../../common/category/useCategoryProvider";
import { selectCurrentTypeIds } from "../../shared/filter/selectors";
import { updateCategoryId } from "../../shared/filter/filterSlice";

const CategoryTabs = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const dispatch = useAppDispatch()
  const {categories} = useCategoryProvider()
  const currentIds = useAppSelector(selectCurrentTypeIds)

  const handleNavigateTabs = (value: number) => {
    dispatch(updateCategoryId(value))
  }

  useEffect(() => {
    if (!currentIds){
      dispatch(updateCategoryId(categories.map(item=>item.id)))
    }
  }, [])



  useWheelScroll(containerRef)

  const scrollContainer = containerRef.current
  const scrollContainerWidth =
    scrollContainer?.getBoundingClientRect().width || 0
  const tabWidth = 108 //1 tab + 1 columngap
  const scrollAmount = scrollContainerWidth - (scrollContainerWidth % tabWidth)
  const handleArrowClick = (direction: "left" | "right"): void => {
    if (scrollContainer) {
      scrollContainer.scrollLeft +=
        direction === "left" ? -scrollAmount : scrollAmount
    }
  }

  return (
    <Box sx={categoryTabsWrapper}>
      <Box sx={categoryTabsCont} ref={containerRef}>
        {categories.map((cat) => (
          <CategoryItem
            key={cat.id}
            category={cat}
            click={handleNavigateTabs}
            isSelected={currentIds?.includes(cat.id) ?? false}
          />
        ))}
        <button
          className={style.category_arrow}
          aria-label="scroll left arrow"
          onClick={() => handleArrowClick("left")}
        >
          <FaAngleLeft style={{ marginLeft: "8px" }} />
        </button>
        <button
          className={style.category_arrow}
          aria-label="scroll right arrow"
          onClick={() => handleArrowClick("right")}
        >
          <FaAngleRight style={{ marginLeft: "16px" }} />
        </button>
      </Box>
    </Box>
  )
}

export default CategoryTabs
