import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const userAvatar: SxProps<Theme> = {
  width: "160px",
  height: "160px",
  borderRadius: "50%",
  "@media (max-width: 500px)": {
    width: "120px",
    height: "120px",
  },
}
