import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FavoriteEvent, UserFavoriteEvents } from "../types"
import { RootState } from "../../../app/store"

const initialState: UserFavoriteEvents = []

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState: initialState,
  reducers: {
    add: (state, action: PayloadAction<FavoriteEvent>) => {
      state.push(action.payload)
    },
    remove: (state, action: PayloadAction<number>) => {
      const index = state.findIndex((item) => {
        return item.event_id === action.payload
      })
      state.slice(index, 0)
    },
  },
})

export const { add, remove } = favoriteSlice.actions
export const selectFavorites = (state: RootState): UserFavoriteEvents =>
  state.favorites
export default favoriteSlice.reducer
