import {  createSlice, PayloadAction } from "@reduxjs/toolkit"
import { defaultEventDialogState } from "./types";
import { UiAddress, UiRegion } from "../layout/stepers/step_2";
import { EventPrivateSelect, EventTypeSelection } from "../layout/stepers/step_1/types";



const eventDialogSlice = createSlice({
  name: "eventDialog",
  initialState: defaultEventDialogState(),
  reducers: {
    show: (state) => {
      state.showEvent = true
    },
    hide: () => {
      return defaultEventDialogState()
    },
    setRegion: (state, region: PayloadAction<UiRegion>) =>{
      state.region = region.payload
    },
    setAddress: (state, address: PayloadAction<UiAddress>) =>{
      state.address = address.payload
    },
    setEventMode: (state, action: PayloadAction<EventPrivateSelect>) => {
      state.eventModePrivate = action.payload
    },
    setEventTypeId: (state, action: PayloadAction<EventTypeSelection>) => {
      state.eventType = action.payload
    },
  },
})

export const {
  show,
  hide,
  setRegion,
  setAddress,
  setEventMode,
  setEventTypeId,} = eventDialogSlice.actions
export default eventDialogSlice.reducer
