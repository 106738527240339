import { Alert, Box, SxProps, Theme } from "@mui/material"
import { notifCont, warnStyle } from "./style"

type Props = {
  styles?: SxProps<Theme>
}
export default function ErrorNotification({ styles }: Props) {
  const notificationCont = { ...notifCont, ...styles }
  return (
    <Box sx={notificationCont}>
      <Alert sx={warnStyle} variant="filled" severity="error">
        {`Упс, что то пошло не так...`}
      </Alert>
    </Box>
  )
}
