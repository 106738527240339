import Chat from "../chat"
import s from "./styles.module.css"
import { Avatar } from "@mui/material"
import AddAdresses from "../../assets/svg/add_adresses.svg"
import PinEmoji from "../../assets/svg/pin_emoji.svg"
import PinFile from "../../assets/svg/pin.svg"
import SendMessage from "../../assets/svg/send_message.svg"
import { Outlet } from "react-router"
import { useEffect, useRef, useState } from "react"
import { ChatUserModel } from "../chat_user/DataType"
import { mock } from "../../pages/chat_user/mock"
import Item from "../chat_user/item/index"
import AddresseeItem from "../../components/addressee"
import MessageBar from "../../components/message_bar"

const WebChat = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState("")
  const [currentChat, setCurrentChat] = useState<ChatUserModel>(mock[1])

  return (
    <section className={s.web_chat}>
      <div className={s.shared__container}>
        <Chat />
        <div className={s.web_chat__container}>
          <header className={s.web_chat__header}>
            <div className={s.addressees__legend}>Кому:</div>
            <div className={s.addressees__list}>
              <ul className="addressees__body">
                <AddresseeItem />
              </ul>
            </div>
            <button className={s.addressees__add}>
              <Avatar
                src={AddAdresses}
                style={{ width: "20px", height: "20px" }}
              />
            </button>
          </header>
          <Outlet />
          <MessageBar />
        </div>
      </div>
    </section>
  )
}

export default WebChat
