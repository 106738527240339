import classNames from "classnames"
import s from "./styles.module.css"

type OverlayHobbiesBlockProps = {
  hobbies: string[]
  index: number
  setHobbies: (attr: string) => void
  hobbiesData: string[]
}

const OverlayHobbiesBlock = ({
  hobbies,
  index,
  setHobbies,
  hobbiesData,
}: OverlayHobbiesBlockProps) => {
  const isRevers = index % 2 !== 0
  const secondElIdx = 1 // если блок isRevers переопределяем позицию контента внутри элемента с индексом 1

  return (
    <div
      className={s.overlay}
      style={{ direction: `${isRevers ? "rtl" : "ltr"}` }}
    >
      {hobbies.map((hobbie, i) => {
        return (
          <div
            onClick={(e) => setHobbies(e.currentTarget.textContent as string)}
            key={i}
            className={s.overlay_item}
            style={{
              justifyContent: `${
                i === secondElIdx && isRevers && "flex-start"
              }`,
              paddingLeft: `${i === secondElIdx && isRevers && "30px"}`,
            }}
          >
            {hobbiesData.join().includes(hobbie) && (
              <div className={s.border_active}></div>
            )}
            <p className={s.overlay_item_name}>{hobbie}</p>
          </div>
        )
      })}
    </div>
  )
}

export default OverlayHobbiesBlock
