import { useSearchEventsMutation } from "../../app/data/events/eventsApi"
import { IoError, ioErrorOf } from "../../app/data/errors"
import type {
  EventInfo,
  FilterConfig,
  SearchEventsData,
} from "../events-feed/types"
import { Location } from "../../components/feed-map/types"
import { useState } from "react"
import { calculateDistance } from "../../common/yandex/RadiusCalculator"

export type MapSearchEvents = {
  isLoading: boolean
  error?: IoError
  query: (location: Location, filter?: FilterConfig) => void
  data?: EventInfo[]
}
type Page = { page: number; pageSize: number }
const defaultPage: Page = { page: 0, pageSize: 50 }
export const useMapSearchEvents = (): MapSearchEvents => {
  const [page, setCurrentPage] = useState<Page>(defaultPage)
  const [events, setEvents] = useState<EventInfo[]>([])
  const [trigger, { isLoading, error, data }] = useSearchEventsMutation()
  const fetch = (location: Location, filter?: FilterConfig) => {
    trigger({
      size: page.pageSize,
      page: page.page,
      // @ts-ignore
      filter: decorateFilter(location, filter),
    })
      .unwrap()
      .then((data) => {
        setEvents(data.content)
      })
  }

  return {
    isLoading: isLoading,
    query: fetch,
    error: ioErrorOf(error),
    data: events,
  }
}

const decorateFilter = (
  location: Location,
  filterConfig?: FilterConfig,
): FilterConfig => {
  if (!filterConfig) return { partsOfDay: [] }
  const newFilterConfig = { ...filterConfig }
  newFilterConfig.latitude = location.center[1]
  newFilterConfig.longitude = location.center[0]
  newFilterConfig.distanceInMeters = calculateDistance(location.bounds)
  return newFilterConfig
}
