import { FC } from "react"
import { useInvitedEvents } from "../../../shared/user-profile/hooks"
import ProfileEventCard from "../profile_event_card"

const InvitedEventList: FC = () => {
  const { isLoading, error, state } = useInvitedEvents()
  if (isLoading) return <div>Loading...</div>
  if (error) return <div>{error.error}</div>

  return (
    <>
      {state?.map((event) => {
        return <ProfileEventCard info={event} key={event.id} />
      })}
    </>
  )
}

export default InvitedEventList
