import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Modal from "../../components/modal"
import { hide } from "./slice/MakeEventSlice"
import LayoutEvent from "./layout"
import { selectDialog } from "./slice/selectors";

const MakeEvent = () => {
  const showDialog = useAppSelector(selectDialog)
  const dispatch = useAppDispatch()
  const handlerClose = () => {
    dispatch(hide())
  }

  return showDialog ? (
    <Modal onClose={handlerClose}>
      <LayoutEvent handlerClose={handlerClose} />
    </Modal>
  ) : null
}

export default MakeEvent
