import { SearchEventsData } from "../../../shared/events-feed/types";
import { SearchEventInfo } from "./schemas/eventsInfoSchema";

export const searchFilteredEventsTransform = (response: SearchEventsData<SearchEventInfo>): SearchEventsData<SearchEventInfo> => {
  const validContent = response.content
  return {
    ...response,
    content: validContent.map((item) => {
      if (item.avatarUrl) {
        const { avatarUrl: avatars } = item
        item.avatarUrl = avatars.map(mapAvatars)
        return item
      } else {
        return item
      }
    }),
  }
}

const mapAvatars = (avatars: string): string => {
  return `${import.meta.env.VITE_AVATAR_URL}/v1/events/avatars/${avatars}`
}