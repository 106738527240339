import { FC } from "react"

import { selectSchema } from "../FilteredSelectedBox/types";
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";
import FilteredSelectedBox from "../FilteredSelectedBox";

const schema: selectSchema<boolean | undefined> = {
  items: [
    {
      name: "Все",
      value: undefined,
    },
    {
      name: "18+",
      value: true,
    },
    {
      name: "Нет",
      value: false,
    },
  ]
}
export const AdultCheckedBox: FC = () => {
  const {currentFilter, updateAgeLimit} = useEventFilter()
  const selectedHandler = (value: boolean | undefined) => {
    updateAgeLimit(value)
  }
  return <FilteredSelectedBox<boolean | undefined>
    tabsMap={schema}
    value={currentFilter.isEighteenYearLimit}
    title="Ограничения"
    onSelect={selectedHandler}
  />
}
