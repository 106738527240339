import s from "./styles.module.css"
import classNames from "classnames"

const LocationSpinner = () => {
  return (
    <div className={s.location_spinner_wrapper}>
      <div className={classNames(s.spinner_item, s.spinner_item_1)}></div>
      <div className={classNames(s.spinner_item, s.spinner_item_2)}></div>
      <div className={classNames(s.spinner_item, s.spinner_item_3)}></div>
    </div>
  )
}

export default LocationSpinner
