import { Box, CircularProgress, SxProps, Theme } from "@mui/material"
import { loadingStyle, notifCont } from "./style"

type Props = {
  styles?: SxProps<Theme>
}
export default function Loading({ styles }: Props) {
  const contStyles = { ...notifCont, ...styles }
  return (
    <Box sx={contStyles}>
      <CircularProgress sx={loadingStyle} />
    </Box>
  )
}
