import React, { useState, useRef, useEffect } from "react"
import s from "./style.module.css"
import Pin from "../../assets/svg/pin.svg?react"
import Emoji from "../../assets/svg/pin_emoji.svg?react"
import Send from "../../assets/svg/send_message.svg?react"

function MessageBar() {
  const [val, setVal] = useState("")
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const mesageBarContainerRef = useRef<HTMLDivElement | null>(null)
  const [textareaTop, setTextareaTop] = useState<number>(0)
  const [currentHeight, setCurrentHeight] = useState<number>(0)

  const resizeTextArea = () => {
    if ((textAreaRef.current?.scrollHeight ?? 0) <= 53) {
      setTextareaTop(0)
    }

    if (val.length === 0) {
      setTextareaTop(0)
    }

    if (textAreaRef.current && textAreaRef.current.scrollHeight > 53) {
      textAreaRef.current.style.borderRadius = "13px"
      // @ts-ignore
      mesageBarContainerRef.current.style.borderRadius = "13px"
    } else {
      // @ts-ignore
      textAreaRef.current.style.borderRadius = "100px"
      // @ts-ignore
      mesageBarContainerRef.current.style.borderRadius = "100px"
    }

    if (
      currentHeight < (textAreaRef.current?.scrollHeight ?? 0) &&
      (textAreaRef.current?.scrollHeight ?? 0) > 53
    ) {
      setCurrentHeight(textAreaRef.current?.scrollHeight ?? 0)
      setTextareaTop((prev) => prev - 18.5)
    } else if (currentHeight > (textAreaRef.current?.scrollHeight ?? 0)) {
      setCurrentHeight(textAreaRef.current?.scrollHeight ?? 0)
      setTextareaTop((prev) => prev + 18.5)
    }

    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px"
    }
  }

  useEffect(resizeTextArea, [val])

  const onChange = (value: string) => {
    setVal(value)
  }

  return (
    <div className={s.action}>
      <div className={s.ui_container} style={{ top: `${textareaTop}px` }}>
        <div
          className={s.mesage_bar__container}
          ref={mesageBarContainerRef}
          style={{
            alignItems:
              (textAreaRef.current?.scrollHeight ?? 0) >= 53
                ? "flex-end"
                : "center",
          }}
        >
          <textarea
            className={s.mesage_bar__text_field}
            ref={textAreaRef}
            value={val}
            onChange={(e) => onChange(e.target.value)}
            rows={1}
          />
          <div
            className={s.pin_button__container}
            style={{
              flexDirection:
                (textAreaRef.current?.scrollHeight ?? 0) >= 108
                  ? "column"
                  : "row",
            }}
          >
            <div className={s.pin}>
              <Emoji />
            </div>
            <div className={s.pin}>
              <Pin />
            </div>
          </div>
        </div>
        <div className={s.btn}>
          <Send />
        </div>
      </div>
    </div>
  )
}
export default MessageBar
