import { FC } from "react"
import s from "./styles.module.css"
import { TStepProps } from "../../../types"
import Title from "../../common/title"
import ConfirmIcon from "../../../../../assets/svg/confirm.svg?react"
import classNames from "classnames"
import LocationIcon from "../../../../../assets/svg/location_item.svg?react"
import CalendarIcon from "../../../../../assets/svg/calendar_item.svg?react"
import TimeIcon from "../../../../../assets/svg/time_item.svg?react"
import { getRussianDate } from "../step_5/utils"
import {
  cardBtnExtra,
  cardBtnExtraText,
  cardBtnImGoing,
  cardBtnsCont,
} from "../../../../../components/CardEvent/CardEvent/CardEventStyles"
import { Button, CardActions } from "@mui/material"
import { useNavigate } from "react-router-dom"

const Confirm: FC<TStepProps> = ({ makeStep, handlerClose, step, formik }) => {
  const navigate = useNavigate()

  // @ts-ignore TODO: Косячные типы
  const { event_name, is_free, event_address, start_time, event_id } =
    formik.values
  const dateStart = new Date(start_time)

  const handleCreateEvent = () => {
    navigate(`fid/${event_id}`)
    if (handlerClose) {
      handlerClose()
    }
  }
  const handleInvite = () => {
    makeStep("invite")
  }

  return (
    <div className={s.container}>
      <Title align={"center"} title={"Поздравляем!"} />

      <div className={s.icon_box}>
        <ConfirmIcon />
      </div>

      <div className={s.description}>
        <p className={s.text}>Мероприятие успешно созданно!!!</p>
        <p className={classNames(s.text, s.text_bold)}>{event_name}</p>
        <div className={s.description_info}>
          <div className={s.card_info_item}>
            <LocationIcon />
            <p className={s.text}>{`${event_address.city}`}</p>
          </div>
          <div className={classNames(s.card_info_item)}>
            <CalendarIcon />
            <p className={s.text}>{getRussianDate(start_time)}</p>
          </div>
          <div className={s.card_info_item}>
            <TimeIcon />
            <p className={s.text}>
              {`${dateStart?.getHours()}:${dateStart?.getMinutes()}` || "-"}
            </p>
          </div>
        </div>
        <p className={s.text}>
          Участие: <b>{`${is_free ? "бесплатно" : "платно"}`}</b>
        </p>
        <p className={s.text}>
          Администрация tribe проверяет событие на соответствие установленным
          нормам и законам РФ. После проверки событие опубликуется.
          <br />
          Если нарушения будут выявленны, мы оповестим вас об этом и статус
          события сменится на “На доработку”.
        </p>
      </div>

      {/*<CardActions sx={{*/}
      {/*  ...cardBtnsCont,*/}
      {/*  width: "100%",*/}
      {/*  marginTop: "50px",*/}
      {/*  flexDirection: "column",*/}
      {/*  maxWidth: "220px",*/}
      {/*  margin: "0 auto"*/}
      {/*}}>*/}
      {/*  <Button onClick={handleInvite} sx={{*/}
      {/*    ...cardBtnImGoing, height: "48px"*/}
      {/*  }}>*/}
      {/*    <p>Добавить участников</p>*/}
      {/*  </Button>*/}

      {/*  <Button onClick={handleCreateEvent} variant="contained"*/}
      {/*          sx={{ ...cardBtnExtra, minHeight: "48px" }}>*/}
      {/*    <p style={cardBtnExtraText}>*/}
      {/*      Перейти в мероприятие*/}
      {/*    </p>*/}
      {/*  </Button>*/}

      {/*</CardActions>*/}
    </div>
  )
}

export default Confirm
