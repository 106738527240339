import { ChangeEvent, useState } from "react"
import { PotentiaParticipantUserPreData, ViewsNames } from "../.."
import GradientButton, {
  GradienButtonStyles,
} from "../../../../GradientStateBtn"
import s from "./styles.module.scss"
import { UserDataForAddToBaseParticipant } from "../../../../../app/data/promotion/promotionApi"
import { IMaskInput } from "react-imask"
import validation from "./validation"

type AddNewUserProps = {
  userData: Omit<
    UserDataForAddToBaseParticipant,
    "telegramUsername" | "eventId"
  >
  onSetVeiew: (popupVeiew: ViewsNames) => void
  onWriteUserData: (
    inputName: keyof PotentiaParticipantUserPreData,
    targetValue: string,
  ) => void
  onChangeSetPhone: (phoneNumbers: string) => void
}
const inputMaskPhone = "+{7}(000)000-00-00"

const AddNewUser = ({
  userData,
  onSetVeiew,
  onWriteUserData,
  onChangeSetPhone,
}: AddNewUserProps) => {
  const { firstname, phone_number, lastname, telegram_username } = userData
  const [isValid, setIsValid] = useState({ firstname: true, lastname: true })

  const onClickBtnGoToView = (view: ViewsNames) => {
    onSetVeiew(view)
  }

  const onSetPhone = (evt: React.FormEvent<HTMLInputElement>) => {
    onChangeSetPhone(evt.currentTarget.value)
  }

  const setIsDisabledBtn = () => {
    if (
      !/^\d{1,3}\(\d{3}\)\d{3}-\d{2}-\d{2}$/.test(phone_number.slice(1)) ||
      telegram_username === "" ||
      firstname === "" ||
      lastname === ""
    ) {
      return true
    } else return false
  }

  const onChangeWriteUserData = (
    inputName: keyof PotentiaParticipantUserPreData,
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    if (evt.target.value === "") {
      onWriteUserData(inputName, evt.target.value)
    }

    if (validation(evt.target.value, "OnlyLetters")) {
      onWriteUserData(inputName, evt.target.value)
      isValidStyling(inputName, true)
    } else {
      isValidStyling(inputName, false)
    }
  }

  const isValidStyling = (inputName: string, thisValid: boolean) => {
    switch (inputName) {
      case "firstname":
        return setIsValid({ ...isValid, firstname: thisValid })
      case "lastname":
        return setIsValid({ ...isValid, lastname: thisValid })
    }
  }

  return (
    <section className={s.add_new_user}>
      <form action="" className={s.add_new_user_body}>
        <div className={s.input_wrapper}>
          <input
            type="text"
            className={s.new_user_info}
            placeholder="Имя"
            value={firstname}
            onChange={(e) => onChangeWriteUserData("firstname", e)}
          />
          <label hidden={isValid.firstname}>Только буквы*</label>
        </div>
        <div className={s.input_wrapper}>
          <input
            type="text"
            className={s.new_user_info}
            placeholder="Фамилия"
            value={lastname}
            onChange={(e) => onChangeWriteUserData("lastname", e)}
          />
          <label hidden={isValid.lastname}> Только буквы*</label>
        </div>
        <div className={s.input_wrapper}>
          <IMaskInput
            mask={inputMaskPhone}
            type="text"
            className={s.new_user_info}
            value={phone_number}
            onChange={(e) => onSetPhone(e)}
            placeholder="Номер телефона"
          />
        </div>
        <div className={s.input_wrapper}>
          <input
            type="text"
            className={s.new_user_info}
            value={telegram_username}
            placeholder="@Telegram"
            onChange={(e) => onChangeWriteUserData("telegram_username", e)}
          />
        </div>
        <div className={s.buttons_block}>
          <GradientButton<Object>
            onAction={() => onClickBtnGoToView(ViewsNames.SearchUser)}
            style={GradienButtonStyles.borderGradient}
            params={{ text: "Назад", width: 79, height: 48 }}
          />
          <GradientButton<Object>
            onAction={() => onClickBtnGoToView(ViewsNames.AccessNewUser)}
            style={GradienButtonStyles.fullGradient}
            params={{
              text: "Далее",
              width: 79,
              height: 48,
              disabled: setIsDisabledBtn(),
            }}
          />
        </div>
      </form>
    </section>
  )
}

export default AddNewUser
