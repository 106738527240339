/* При верстке мобильного оверлея заметил что в случае, если "интерес" состоит больше чем 10 букв - текст выходит за границы элемента 
(это касается мальнких элементов) */
type sortLengthWords = {
  lowLength: string[]
  heightLength: string[]
}

/* Решил проблему (а точнее свел к минимум через сортировку "интересов") - функция возвращает массив строк в порядке
 2 элемента которые больше 10 символов, после них идут 2 элемента которые меньше 10 символов */
const sortByLongestWord = (hobbiesArray: string[]) => {
  const copyHobbies = [...hobbiesArray]
  const arrForSort: sortLengthWords = {
    lowLength: [],
    heightLength: [],
  }

  const sortedArray: string[] = []

  for (let i = 0; i < copyHobbies.length; i++) {
    copyHobbies[i].length < 10
      ? arrForSort.lowLength.push(copyHobbies[i])
      : arrForSort.heightLength.push(copyHobbies[i])
  }

  while (
    arrForSort.lowLength.length > 0 ||
    arrForSort.heightLength.length > 0
  ) {
    if (arrForSort.heightLength) {
      sortedArray.push(arrForSort.heightLength.splice(0, 1)[0])
      sortedArray.push(arrForSort.heightLength.splice(0, 1)[0])
    }
    if (arrForSort.lowLength.length > 0) {
      sortedArray.push(arrForSort.lowLength.splice(0, 1)[0])
      sortedArray.push(arrForSort.lowLength.splice(0, 1)[0])
    }
  }

  return sortedArray
}

/* тут разделяю элементы для отрисовки в блоке - в самом блоке 4 элемента */
export const divideByArrays = (array: string[]) => {
  const sortedArray = sortByLongestWord(array).filter((el) => el !== void 0)
  const groupsArray = []
  for (let i = 0; i < array.length; i++) {
    groupsArray.push(sortedArray.splice(0, 4 ?? sortedArray.length))
  }
  return groupsArray
}
