import React, { ChangeEvent } from "react"
import style from "./style.module.css"

interface CheckBoxProps {
  checked?: boolean
  text?: string
  onChange: (checked: boolean) => void
}
export const CheckBox: React.FC<CheckBoxProps> = ({
  checked,
  text,
  onChange,
}) => {
  const toggleCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    onChange(isChecked)
  }

  return (
    <label className={style.checkbox_container}>
      {text && <span className={style.checkbox_label}>{text}</span>}
      <input
        type="checkbox"
        name="myCheckbox"
        checked={checked}
        onChange={toggleCheckBox}
        className={style.checkbox_input}
      />
    </label>
  )
}
