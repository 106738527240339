import { FC } from "react"
import { Link } from "react-router-dom"
import { MenuItem } from "@mui/material"

import { handleMenuItemStyle, sideMenuItem } from "../headerMenuStyles"

type MenuItem = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  alt: {
    ru: string
    en: string
  }
  path: string
  text: string
}

type Props = {
  item: MenuItem
  i: number
  isOpenMenu: boolean
}

const HeaderItem: FC<Props> = ({ item, i, isOpenMenu }) => {
  const { path, icon: Icon, text } = item || {}

  if (!path || !Icon || !text) {
    return null
  }

  return (
    <Link to={path} key={text} style={handleMenuItemStyle(i, isOpenMenu)}>
      <MenuItem style={sideMenuItem}>
        <Icon />
        {text}
      </MenuItem>
    </Link>
  )
}

export default HeaderItem
