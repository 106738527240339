import { createApi } from "@reduxjs/toolkit/query/react"
import { refreshBaseQuery } from "./refreshBaseQuery"

export const favTag = ["Favorite"]
export const eventsTag = ["Events"]
export const cashier = ["Cashier"]
export const promotion = ["Promotion"]
export  const profile = ["Profile"]
export const swaggerSplitApi = createApi({
  tagTypes: [...favTag, ...eventsTag, ...promotion, ...cashier, ...profile],
  baseQuery: refreshBaseQuery,
  endpoints: () => ({}),
})
