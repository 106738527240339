import React, { FC } from "react"
import s from "./styles.module.css"
import { Avatar } from "@mui/material"
import classNames from "classnames"
import UnRead from "../../../assets/svg/notViewedMark.svg?react"
import Read from "../../../assets/svg/viewedMark.svg?react"
import { ChatMode } from "../../../types"

export interface ChatUserItemProps {
  type: number
  time?: string
  isRead?: boolean
  avatar: string
  text: string
  mode?: ChatMode
}
const Item: FC<ChatUserItemProps> = ({
  type,
  avatar,
  text,
  isRead,
  time,
  mode,
}) => {
  return (
    <div
      className={s.item}
      style={{ justifyContent: type === 0 ? "flex-end" : "flex-start" }}
    >
      {type === 0 && (
        <div className={s.me}>
          <div
            className={s.text__web}
            style={{
              maxWidth: `${mode === ChatMode.Web ? "500px" : "200px"}`,
            }}
          >
            <p className={s.text_body}>{text}</p>
            <div className={s.sub_info}>
              <div className={s.time}>{time}</div>
              {isRead ? <Read /> : <UnRead />}
            </div>
          </div>
          <Avatar src={avatar} sx={{ height: "32px", width: "32px" }} />
        </div>
      )}
      {type === 1 && (
        <div className={s.me}>
          <Avatar
            src={avatar}
            sx={{ height: "32px", width: "32px", zIndex: 1 }}
          />
          <div
            className={classNames(s.text__web, s.text_unswer__web)}
            style={{
              maxWidth: `${mode === ChatMode.Web ? "500px" : "200px"}`,
            }}
          >
            {text}
            <div className={classNames(s.sub_info, s.sub_info_answer)}>
              <span className={s.time}>{time}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Item
