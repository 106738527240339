import { FC, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { hide, selectDialog } from "./SignInSlice"
import Modal from "../../components/modal"
import LayoutSignDialog from "./layout/LayoutSignDialog"
import { isAuthorizationSelector } from "../../features/authorization_page/AuthorizationSlice"

const SignRoot: FC = () => {
  const showDialog = useAppSelector(selectDialog)
  const dispatch = useAppDispatch()
  const isAuthorization = useAppSelector(isAuthorizationSelector)

  const handlerClose = () => {
    dispatch(hide())
  }
  useEffect(() => {
    if (isAuthorization) {
      dispatch(hide())
    }
  }, [isAuthorization])

  return showDialog ? (
    <Modal onClose={handlerClose}>
      <LayoutSignDialog />
    </Modal>
  ) : null
}

export default SignRoot
