import { FC } from "react"
import { costTab } from "../../../consts"
import FilterBySelectTab from "../FilterBySelectTab"
import { selectSchema } from "../FilteredSelectedBox/types";
import FilteredSelectedBox from "../FilteredSelectedBox";
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";
const schema: selectSchema<boolean | undefined> = {
  items: [
    {
      name: "Все",
      value: undefined,
    },
    {
      name: "Платные",
      value: false,
    },
    {
      name: "Бесплатные",
      value: true,
    }
  ]
}
export const FreeCheckedBox: FC = () => {
  const {currentFilter, updateIsFree} = useEventFilter()
  const selectHandler = (value: boolean | undefined) => {
    updateIsFree(value)
  }
  return <FilteredSelectedBox<boolean | undefined>
    title="Стоимость"
    tabsMap={schema}
    value={currentFilter.isFree}
    onSelect={selectHandler}
  />
}
