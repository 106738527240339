import { FC, useEffect } from "react"
import { useWillGoEvents } from "../../../shared/user-profile/hooks"
import ProfileEventCard from "../profile_event_card"

const WillGoEventList: FC = () => {
  const { isLoading, error, state } = useWillGoEvents(0, 10)

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error.error}</div>
  return (
    <>
      {state?.events.map((event) => (
        <ProfileEventCard info={event} key={event.id} />
      ))}
    </>
  )
}

export default WillGoEventList
