import { EventTypeInfoDto, tribeApi } from "../../../../../app/data/tribeSwaggerApi";
import { EventsTypesResponse, EventType } from "./types";
import { ioErrorOf } from "../../../../../app/data/errors";
import { EventsTypeSchema } from "./schema";

const verifyData = (data: EventTypeInfoDto[] | undefined):  EventType[] => {
  if (!data) return []
  return data.filter(f=>{
    return EventsTypeSchema.safeParse(f).success
  }).map(m=>EventsTypeSchema.parse(m))
}
export const useEventsTypes = (): EventsTypesResponse => {
  const {isLoading, error, data} = tribeApi.useGetEventTypeInfoQuery()

  return {
    isLoading: isLoading,
    error: ioErrorOf(error),
    eventTypesList: verifyData(data)
  }
}