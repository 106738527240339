import { CSSProperties } from "react"
import { profileIconsPack } from "../../../../consts"

export const sideMenuItem = {
  display: "flex",
  gap: "10px",
  padding: "16px",
  borderRadius: "8px",
  transition: "background 0.3s ease",
  color: "var(--secondary-text)",
  fill: "var(--filter-range-active)",
  stroke: "var(--filter-range-active)",
  width: "100%",
  "&:hover": {
    background: "var(--btns-hover)",
  },
}

export const handleProfileItemStyle = (
  i: number,
  isOpenMenu: boolean,
): CSSProperties => ({
  width: "100%",
  position: "absolute",
  top: `${55 * i + -5}px`,
  right: isOpenMenu ? `${i * 0}px` : `-${(i + 1) * 150}px`,
  borderTopLeftRadius: i === 0 ? "8px" : "0",
  borderBottomLeftRadius: i === profileIconsPack.length - 1 ? "8px" : "0",
  borderBottomRightRadius: i === profileIconsPack.length - 1 ? "8px" : "0",
  background: "var(--menu-header-bg)",
  opacity: isOpenMenu ? "1" : "0",
  visibility: isOpenMenu ? "visible" : "hidden",
  transition: "all 0.3s ease",
  boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.58)",
})
