export const promoEventStyle = {
  "@media (min-width: 470px)": {
    flexDirection: "row",
  },
}

export const downloadStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxHeight: "50px",
  gap: "10px",
  padding: "8px",
  border: "1px solid var(--borders)",
  borderRadius: "12px",
  transition: "all 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    borderColor: "var(--filter-range-active)",
    background: "var(--filter-selected)",
  },
}

export const cont = { height: "100vh", p: 2 }

export const cardsCont = {
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
  pt: 2,
  pb: 2,
  mt: 2,
}
