import { FC } from "react"
import { Outlet } from "react-router-dom"
import "../../App.css"
import MakeEvent from "../../pages/makeEvent"
import SignRoot from "../../pages/signIn"
import Footer from "../footer"
import Header from "../header"

const Layout: FC = () => {
  return (
    <div className="App">
      <SignRoot />
      <MakeEvent />
      <Header />
      <div className={"content app__wrapper"}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
export default Layout
