import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const geoKey = import.meta.env.VITE_YANDEX_GEO_STAGE

export const cityQueryApi = createApi({
  reducerPath: "yandex_geo",
  baseQuery: fetchBaseQuery({ baseUrl: "https://suggest-maps.yandex.ru" }),
  endpoints: (builder) => ({
    getCity: builder.query<string, string>({
      query: (name) =>
        `/v1/suggest?text=${name}&country_codes=RU&lang=ru&type=locality&apikey=${geoKey}`,
    }),
    findCity: builder.mutation<string, string>({
      query(name) {
        return {
          url: `/v1/suggest?text=${name}&type=locality&apikey=${geoKey}`,
          method: "GET",
        }
      },
    }),
  }),
})

export const { useGetCityQuery, useFindCityMutation } = cityQueryApi
