import { SvgType } from "./homeIcon"

export default function HalfTimeCircle({ color, width, height }: SvgType) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.333 6.5C12.333 6.26077 12.333 6.14115 12.4137 6.06687C12.4945 5.99259 12.6071 6.002 12.8324 6.02082C13.7114 6.09424 14.5656 6.36078 15.333 6.80385C16.2451 7.33046 17.0026 8.08788 17.5292 9C18.0558 9.91212 18.333 10.9468 18.333 12C18.333 13.0532 18.0558 14.0879 17.5292 15C17.0026 15.9121 16.2451 16.6695 15.333 17.1962C14.4209 17.7228 13.3862 18 12.333 18C11.2798 18 10.2451 17.7228 9.33301 17.1962C8.56559 16.7531 7.90767 16.1466 7.4046 15.4221C7.27565 15.2364 7.21118 15.1435 7.23514 15.0365C7.25909 14.9294 7.36268 14.8696 7.56987 14.75L12.083 12.1443C12.205 12.0739 12.266 12.0387 12.2995 11.9807C12.333 11.9226 12.333 11.8522 12.333 11.7113V6.5Z"
        fill={color}
      />
      <circle cx="12.333" cy="12" r="9" stroke={color} strokeWidth="2" />
    </svg>
  )
}
