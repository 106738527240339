import type { LocationIp } from "./types"

export function saveLocation(location: LocationIp): void {
  try {
    const locationString = JSON.stringify(location);
    localStorage.setItem('location', locationString);
  } catch (error) {
    console.error("Ошибка при сохранении в localStorage:", error);
  }
}

export function getLocation(): LocationIp {
  const defaultLocation: LocationIp = {
    latitude: 0,
    longitude: 0,
    city: "Unknown",
    country: "Unknown"
  };

  try {
    const locationString = localStorage.getItem('location');
    if (locationString) {
      return JSON.parse(locationString) as LocationIp;
    } else {
      return defaultLocation;
    }
  } catch (error) {
    console.error("Ошибка при получении из localStorage:", error);
    return defaultLocation;
  }
}

