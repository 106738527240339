import { Avatar } from "@mui/material"
import KeyIcon from "../../../assets/svg/KeyIcon.svg?react"
import PhoneIcon from "../../../assets/svg/PhoneIcon.svg?react"
import MailIcon from "../../../assets/svg/MailIcon.svg?react"
import Arrow from "../../../assets/svg/sortMessageArrow.svg?react"
import s from "./styles.module.css"

type SecurityProps = {
  mobileNum?: number
  mail?: string
  password?: string
}
// все пропсы примерные, возможно будет лучше сделать фетч запрос
const Security = ({ mail, mobileNum, password }: SecurityProps) => {
  return (
    <div className={s.security_setting__body}>
      <div className={s.security_item} tabIndex={0}>
        <PhoneIcon />
        <div className={s.item_text}>
          <p className={s.title_item}>Телефон</p>
          <p className={s.description_item}>8800888888</p>
        </div>
        <Arrow style={{ transform: "rotate(-90deg)" }} />
      </div>
      <div className={s.security_item} tabIndex={0}>
        <MailIcon />
        <div className={s.item_text}>
          <p className={s.title_item}>Почта</p>
          <p className={s.description_item}>pupa@lupa.com</p>
        </div>
        <Arrow style={{ transform: "rotate(-90deg)" }} />
      </div>
      <div className={s.security_item} tabIndex={0}>
        <KeyIcon />
        <div className={s.item_text}>
          <p className={s.title_item}>Пароль</p>
          <p className={s.description_item}>********</p>
        </div>
        <Arrow style={{ transform: "rotate(-90deg)" }} />
      </div>
    </div>
  )
}

export default Security
