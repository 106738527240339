import { Box, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { PromoCard } from "../../../../app/data/promotion/promotionApi"
import HalfTimeCircle from "../../../../assets/svg/promotion-icon/half-circle"
import "../../../../index.css"
import PromoEventCard from "../PromoEventCard"
import { cardCont, halfIconStyle, rowCont, textStyle } from "./EventRowStyle"

export const transformDateAndIsEventBegin = (d: string) => {
  const matchDate = d.match(/(\d+)T([\d:]+)-(\d+)-(\d+)/)

  if (matchDate) {
    const [day, time, offset, year] = matchDate.slice(1)
    const formattedDateString = `${year}-${offset}-${day}T${time}`
    const eventDate = new Date(formattedDateString)
    const date = `${day}.${offset}.${year} ${time.slice(0, 5)}`
    const currentDate = new Date()
    let isEventBegin = true

    if (eventDate > currentDate) {
      isEventBegin = false
    }

    return {
      date,
      isEventBegin,
    }
  } else {
    return {
      date: "00.00.0000 00:00",
      isEventBegin: true,
    }
  }
}

type Props = {
  content: PromoCard[]
}

export default function PromotionEventRow({ content }: Props) {
  const navigate = useNavigate()
  const [animation, setAnimation] = useState(false)
  const [qntyOfLoadedImg, setQntyOfLoadedImg] = useState(content.length)
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const onLoad = () => {
    qntyOfLoadedImg > 0 && setQntyOfLoadedImg((prev) => prev - 1)
  }

  const handleCardDetails = (card: PromoCard) => {
    navigate(`/profile/promotion/events/${card.id}`, {
      state: {
        ...card,
      },
    })
  }

  useEffect(() => {
    setQntyOfLoadedImg(content.length)
    setAnimation(true)
  }, [])

  return (
    <CSSTransition
      in={animation}
      classNames="appearance"
      nodeRef={nodeRef}
      mountOnEnter
      timeout={300}
    >
      <Box sx={rowCont} ref={nodeRef}>
        {content.map((card) => (
          <Box
            sx={cardCont}
            onClick={() => handleCardDetails(card)}
            key={card.id}
          >
            <PromoEventCard
              date={card.start_at}
              img={card.img_url}
              title={card.name}
            />

            <Typography sx={textStyle}>{card.invited_count}</Typography>

            {card.participants_count > 0 ? (
              <Typography sx={textStyle}>{card.participants_count}</Typography>
            ) : (
              <Box sx={halfIconStyle}>
                <HalfTimeCircle color="var(--secondary-text)" />
              </Box>
            )}

            <Typography sx={textStyle}>{card.promoters_count}</Typography>
          </Box>
        ))}
      </Box>
    </CSSTransition>
  )
}
