import { Box, List, Typography } from "@mui/material"
import { NavLink, useLocation } from "react-router-dom"
import { sideMenuPromotionTabs } from "../../../consts"
import { menuCont, menuList } from "./SideMenuStyle"
import "./index.css"

const handleActive = (s: { isActive: boolean }) =>
  s.isActive ? "active default" : "default"

export default function SideMenu() {
  const { pathname } = useLocation()

  return (
    <Box>
      <Box sx={menuCont}>
        <List sx={menuList} disablePadding>
          {sideMenuPromotionTabs.map((tab, i) => (
            <NavLink
              to={tab.link}
              className={(status) =>
                `${handleActive(status)} ${
                  pathname === "/profile/promotion" && i === 0
                    ? "active"
                    : "default"
                }`
              }
              key={tab.link}
            >
              {
                <tab.Icon
                  width="24"
                  height="24"
                  color={
                    pathname.startsWith(tab.link) ||
                    (pathname === "/profile/promotion" && i === 0)
                      ? "var(--filter-range-active)"
                      : "var(--primary-text)"
                  }
                />
              }

              <Typography variant="body2" fontWeight={500}>
                {tab.title}
              </Typography>
            </NavLink>
          ))}
        </List>
      </Box>
    </Box>
  )
}
