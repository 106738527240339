import { Alert, Avatar, Box, CircularProgress, Typography } from "@mui/material"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { Action } from ".."
import {
  EmployeesDataExtended,
  PotentialPerson,
  useGetPotentialPeople,
} from "../../../../app/data/promotion/promotionApi"
import Done from "../../../../assets/svg/promotion-icon/done.svg?react"
import GradientBtn from "../../../../components/GradientBtn"
import SearchEmployeesInput from "../../SearchEmployeesInput"
import * as s from "./style"

type Props = {
  person: PotentialPerson | null
  handleClickAction: (v: Action) => void
  setPerson: Dispatch<SetStateAction<PotentialPerson | null>>
  employees: EmployeesDataExtended | undefined
}

const SearchParticipantsCard: FC<Props> = ({
  person,
  handleClickAction,
  setPerson,
  employees,
}) => {
  const [name, setName] = useState("")
  const [isErrorPopup, setIsErrorPopUp] = useState("")
  const { data, isLoading, isError } = useGetPotentialPeople({
    page: 0,
    size: 100,
    name: encodeURIComponent(name.trim()),
  })

  const handleSelectPerson = (user: PotentialPerson) => {
    if (!employees) return
    const promotersIds = employees.promotersIds

    if (promotersIds.includes(user.user_id)) {
      setIsErrorPopUp("Пользователь уже состоит в Вашей организации...")
      return
    }

    if (person && user.user_id === person.user_id) {
      setPerson(null)
      return
    }

    setPerson(user)
  }

  return (
    <>
      <SearchEmployeesInput
        errorTitle={isErrorPopup}
        serErrorTitle={setIsErrorPopUp}
        query={name}
        setQuery={setName}
      />

      <Box sx={s.contactsContOuter}>
        <Box
          sx={{
            ...s.contactsContInner,
            justifyContent: isLoading ? "center" : "unset",
            alignItems: isLoading ? "center" : "unset",
          }}
        >
          {isError && (
            <Alert severity="error">{`Упс, что то пошло не так....`}</Alert>
          )}

          {!data && isLoading && !isError && <CircularProgress />}

          {data && data.content.length === 0 && !isLoading && !isError && (
            <Alert severity="warning">
              <Typography>Пользователь не найден</Typography>
            </Alert>
          )}

          {data &&
            !isLoading &&
            !isError &&
            data.content.map((user) => {
              const { user_id, user_avatar, username } = user
              const personId = person?.user_id

              return (
                <Box
                  key={user_id}
                  onClick={() => handleSelectPerson(user)}
                  sx={s.contactStyle}
                >
                  <Done
                    style={{
                      position: "absolute",
                      right: 10,
                      visibility: personId === user_id ? "visible" : "hidden",
                    }}
                  />

                  <Avatar
                    alt={username}
                    src={user_avatar}
                    sx={s.contactAvatarStyle}
                  />

                  <Box sx={s.contactInfoStyle}>
                    <Typography variant="body1" fontWeight={500}>
                      {username}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
        </Box>
      </Box>

      <GradientBtn
        title="Далее"
        handleAction={() => (person ? handleClickAction("right") : () => {})}
        btnStyle={person ? s.btnStyle : s.btnDisabled}
      />
    </>
  )
}

export default SearchParticipantsCard
