import _ from "lodash"
import { RegisterFormValues } from "./LoginForm"
import {
  validEmailForRegistration,
  validLoginForRegistration,
} from "../../app/common/validations"

export const validateForm = async (
  values: RegisterFormValues,
): Promise<Partial<RegisterFormValues>> => {
  const errors: Partial<RegisterFormValues> = {}
  const userNameResult = await validLoginForRegistration(values.userName)
  if (userNameResult) {
    errors.userName = userNameResult
  }
  const emailResult = await validEmailForRegistration(values.email)
  if (emailResult) {
    errors.email = emailResult
  }
  if (!values.password) {
    errors.password = "Обязательное поле"
  }
  if (!values.rePassword) {
    errors.rePassword = "Обязательное поле"
  } else if (values.password !== values.rePassword) {
    errors.rePassword = "Пароли не совпадают"
  }

  return errors
}
