export type PaginationState = {
  currentPage: number
  totalElements: number
}

export const defaultPaginationState = (): PaginationState => {
  return  {
    currentPage: 0,
    totalElements: 0,
  }
}