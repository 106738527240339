import React, { FC, useState } from "react";
import s from "./styles.module.css";
import Select, { StylesConfig } from "react-select";

interface MyFormValues {
  type: string;
  placeholder: string;
  value: any;
  handleChange: (e: any) => void;
  name: string;
  title?: string;
  onBlur: (e: any) => void;
  searchClick?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  errorText: string | null;
  isSearch?: boolean;
  min?: string;
  max?: string;
  isLoading?: any;
  defaultInputValue?: any
  onChange: (e: any) => void;
  options: any;
  onInputChange?: (e: string) => void;
}

const customStyles: StylesConfig = {
  control: (styles: any, state) => {
    return ({
      ...styles,
      minHeight: "40px",
      width: "100%",
      backgroundColor: state?.menuIsOpen ? "var(--inputs-bg)" : "var(--white)",
      borderRadius: "0.5rem",
      border: "none",
      outline: "none",
      boxShadow: "none"
    });
  },
  singleValue: (styles: any, state) => {
    return ({
      ...styles,
      color: "var(--primary-color)",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      fontStyle: "normal"
    });
  },
  placeholder: (styles: any, state) => {
    return ({
      ...styles,
      color: "var(--secondary-color)",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400"
    });
  },
  menu: (styles: any, state) => {
    return ({
      ...styles,
      backgroundColor: "var(--white)",
      color: "#000"
    });
  }
};

const InputSelect: FC<MyFormValues> = ({
                                        onInputChange,
                                         errorText,
                                         placeholder,

                                         value,
                                         title = null,
                                         onChange,
                                         isLoading,
                                         options,
                                         defaultInputValue,
                                       }) => {


  return (
    <div style={{ width: "100%" }}>
      {title && <span className={s.title}>{title}</span>}
      <div className={s.input_box}>
        <Select
          onInputChange={(e: string)=>{
            if (onInputChange) {
              onInputChange(e)
            }
          }}
          isLoading={isLoading}
          onChange={onChange}
          value={value}
          styles={customStyles}
          options={options}
          placeholder={placeholder}
          defaultInputValue={defaultInputValue}
        />
      </div>
      {errorText && <p className={s.error_text}>{errorText}</p>}
    </div>
  );
};

export default InputSelect;
