import React, { FormEvent, FormEventHandler, useEffect, useState } from "react"
import style from "./styles.module.css"
import InputPassword from "../../common/ui-kit/inputs/input_password"
import { useFormik } from "formik"
import Code from "./code"
import useRegistrationFetch from "./useRegistrationFetch"
import { useAppDispatch } from "../../app/hooks"
import { hide } from "../../pages/signIn/SignInSlice"
import { isCheckUsername } from "../../app/common/validationFetch"
import {
  validEmailForRegistration,
  validLoginForRegistration,
} from "../../app/common/validations"
import { validateForm } from "./FormValidation"

export interface RegisterFormValues {
  userName: string
  email: string
  password: string
  rePassword: string
}

const RegistrationForm = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const { tryRegistration, registrantId, isError } = useRegistrationFetch()
  const dispatch = useAppDispatch()
  const formik = useFormik<RegisterFormValues>({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      rePassword: "",
    },
    validate: validateForm,
    onSubmit: (values) => {
      tryRegistration(values)
      setIsSuccess(true)
    },
  })
  const handleCodeFetch = () => {
    dispatch(hide())
  }
  if (isSuccess && registrantId) {
    return (
      <Code
        email={formik.values.email}
        handleSubmit={handleCodeFetch}
        registrantId={registrantId}
      />
    )
  }

  const isFormValid =
    formik.values.userName &&
    formik.values.email &&
    formik.values.password &&
    formik.values.rePassword &&
    formik.values.password === formik.values.rePassword

  return (
    <form className={style.form_box} onSubmit={formik.handleSubmit}>
      <div className={style.form_box_container}>
        <div className={style.input_box}>
          <InputPassword
            errorText={
              formik.touched.userName && formik.errors.userName
                ? formik.errors.userName
                : null
            }
            name={"userName"}
            value={formik.values.userName}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type={"text"}
            placeholder={"@username"}
          />
          <InputPassword
            errorText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
            name={"email"}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type={"text"}
            placeholder={"email"}
          />
          <InputPassword
            errorText={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
            name={"password"}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type={"password"}
            placeholder={"password"}
          />
          <InputPassword
            errorText={
              formik.touched.password && formik.errors.rePassword
                ? formik.errors.rePassword
                : null
            }
            name={"rePassword"}
            value={formik.values.rePassword}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type={"password"}
            placeholder={"password"}
          />
        </div>
        {isError ? <p className={style.error_text}>Ошибка сервера</p> : null}
        <button
          type="submit"
          disabled={!isFormValid}
          className={style.form_button}
          style={
            isFormValid
              ? { backgroundColor: "#9a52b5", color: "white" }
              : { backgroundColor: "var(--btns-hover)" }
          }
        >
          Регистрация
        </button>
      </div>
    </form>
  )
}
export default RegistrationForm
