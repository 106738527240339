import { useState } from "react"
import { notificationSettings } from "../../../consts"
import {
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from "@mui/material"
import {
  notificationList,
  notificationListItem,
  notificationListButton,
} from "./notificationSettingsStyle"
import s from "./styles.module.scss"

const NotificationSettings = () => {
  const [checked, setChecked] = useState(
    notificationSettings.map(({ text }) => text),
  )

  const handleToggle = (value: string) =>
    setChecked((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value],
    )

  return (
    <div className={s.notification_container}>
      <List sx={notificationList}>
        {notificationSettings.map((item) => (
          <ListItem key={item.text} sx={notificationListItem} disablePadding>
            <ListItemButton
              sx={notificationListButton}
              role={undefined}
              onClick={() => handleToggle(item.text)}
              dense
              disableGutters
            >
              <item.icon />
              <ListItemText
                id={item.text}
                primary={item.text}
                sx={{ margin: 0 }}
              />
              <ListItemIcon>
                <Checkbox
                  sx={{
                    margin: 0,
                    padding: 0,
                    right: 0,
                    color: "var(--secondary-text)",
                    "&.Mui-checked": {
                      color: "var(--filter-range-active)",
                    },
                  }}
                  edge="start"
                  checked={checked.includes(item.text)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": item.text }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <button
        className={s.notification__btn_save}
        onClick={() => {
          console.log(checked)
        }}
      >
        Сохранить изменения
      </button>
    </div>
  )
}
export default NotificationSettings
