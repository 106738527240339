import type { SVGProps } from "react"

export function LocationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity={0}
        fillRule="evenodd"
        d="M12.398 19.804C13.881 19.034 19 16.016 19 11a7 7 0 1 0-14 0c0 5.016 5.119 8.035 6.602 8.804a.855.855 0 0 0 .796 0M12 14a3 3 0 1 0 0-6a3 3 0 0 0 0 6"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="m12.398 19.804l.276.533zm-.796 0l-.276.533zM18.4 11c0 2.29-1.167 4.156-2.571 5.572c-1.403 1.416-2.993 2.329-3.707 2.7l.552 1.065c.769-.399 2.48-1.38 4.007-2.92C18.208 15.877 19.6 13.726 19.6 11zM12 4.6a6.4 6.4 0 0 1 6.4 6.4h1.2A7.6 7.6 0 0 0 12 3.4zM5.6 11A6.4 6.4 0 0 1 12 4.6V3.4A7.6 7.6 0 0 0 4.4 11zm6.278 8.271c-.714-.37-2.304-1.283-3.707-2.699C6.767 15.156 5.6 13.291 5.6 11H4.4c0 2.726 1.392 4.877 2.919 6.417c1.527 1.54 3.238 2.521 4.007 2.92zm.244 0a.254.254 0 0 1-.244 0l-.552 1.066c.426.22.922.22 1.348 0zM14.4 11a2.4 2.4 0 0 1-2.4 2.4v1.2a3.6 3.6 0 0 0 3.6-3.6zM12 8.6a2.4 2.4 0 0 1 2.4 2.4h1.2A3.6 3.6 0 0 0 12 7.4zM9.6 11A2.4 2.4 0 0 1 12 8.6V7.4A3.6 3.6 0 0 0 8.4 11zm2.4 2.4A2.4 2.4 0 0 1 9.6 11H8.4a3.6 3.6 0 0 0 3.6 3.6z"
      ></path>
    </svg>
  )
}
