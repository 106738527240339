import { Button, CardActions, CircularProgress } from "@mui/material"
import { FC } from "react"
import {
  cardBtnExtra,
  cardBtnExtraText,
  cardBtnImGoing,
  cardBtnsCont,
} from "../../../../../components/CardEvent/CardEvent/CardEventStyles"
import { useTheme } from "../../../../../hooks/useTheme"
import { TStepVariables } from "../../../types"
import s from "./styles.module.css"

type TButtonProps = {
  step: TStepVariables
  next: () => void
  back: () => void
  disabled?: boolean
  loading?: boolean
}

const Buttons: FC<TButtonProps> = ({
  step,
  next,
  back,
  disabled,
  loading = false,
}) => {
  const { isDarkTheme } = useTheme()

  return (
    <div className={s.wrapper}>
      {typeof step === "number" && (
        <p className={s.info_step}>{`шаг ${step}/5`}</p>
      )}

      <div className={s.btns}>
        <CardActions sx={{ ...cardBtnsCont, width: "100%" }}>
          {step !== 1 && (
            <Button onClick={back} variant="contained" sx={cardBtnExtra}>
              <p style={cardBtnExtraText}>
                {(step === "invite" && "Закрыть") || "Назад"}
              </p>
            </Button>
          )}
          <Button
            sx={{
              ...cardBtnImGoing,
              background: disabled
                ? "var(--btns-hover)"
                : cardBtnImGoing?.background,
              color: disabled
                ? isDarkTheme
                  ? "var(--white) !important"
                  : "var(--header-text) !important"
                : cardBtnImGoing?.color,
              boxShadow: disabled ? "none" : cardBtnImGoing?.boxShadow,
              padding: loading ? "3px 8px !important" : "10px 8px",
            }}
            onClick={next}
            disabled={disabled}
          >
            {loading ? (
              <div className={s.preloader}>
                <CircularProgress size={28} sx={{ color: "var(--white)" }} />
              </div>
            ) : (
              <p>
                {(step === 5 && "Опубликовать") ||
                  (step === "invite" && "Пригласить") ||
                  "Далее"}
              </p>
            )}
          </Button>
        </CardActions>
      </div>
    </div>
  )
}

export default Buttons
