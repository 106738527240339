import React, { FC, useEffect, useState } from "react"
import style from "./styles.module.css"
import EmailIcon from "../../../assets/svg/email_icon.svg?react"
import CodeInputs from "../../../common/codeInputs"
import useConfirmCodeFetch from "../useConfirmCodeFetch"

export interface CodeProps {
  email: string
  handleSubmit: () => void
  registrantId: number
}
const Code: FC<CodeProps> = ({ email, handleSubmit, registrantId }) => {
  const [codeValue, setCodeValue] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const {
    isSuccessful,
    fetchConfirmCode,
    error: CodeError,
    isLoading,
  } = useConfirmCodeFetch()

  useEffect(() => {
    if (codeValue && !isLoading) {
      const code = Number.parseInt(codeValue)
      fetchConfirmCode(code, registrantId)
    }
    if (isSuccessful) {
      handleSubmit()
    }
    if (CodeError) {
      setError(CodeError)
    }
  }, [codeValue, CodeError, isSuccessful])

  return (
    <form className={style.form_box}>
      <div className={style.form_box_container}>
        <div className={style.icon}>
          <EmailIcon />
        </div>
        <p className={style.title}>{`Введите код из ${email}`}</p>
        <div className={style.code_input}>
          <CodeInputs
            handleChange={(code) => setCodeValue(code)}
            errorText={error ? error : null}
            clearError={() => {
              setError(null)
            }}
          />
        </div>
      </div>
    </form>
  )
}

export default Code
