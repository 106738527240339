import { Avatar, SxProps, Theme } from "@mui/material"
import { FC } from "react"
import { cardAvatarIcons } from "./cardAvatarStyles"

type Props = {
  src: string
  alt: string
  styles?: SxProps<Theme>
}

const CardAvatar: FC<Props> = ({ src, alt, styles }) => (
  <Avatar sx={{ ...cardAvatarIcons, ...styles }} alt={alt} src={src} />
)

export default CardAvatar
