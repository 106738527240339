import { FC } from "react";
import s from "./styles.module.css";

type TTitleProps = {
  title: string
  align?: string
}

const Title: FC<TTitleProps> = ({ title, align = "center" }) => {
  return <div className={s.title} style={{ justifyContent: align }}>{title}</div>;
};

export default Title;
