import styled from "@emotion/styled"
import { Switch } from "@mui/material"

const IOSSwitch = styled((props: { sx: { m: number } }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "var(--white)",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--profile-ntfc-text)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "var(--profile-ntfc-text)",
      border: "6px solid var(--white)",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "var(--white)",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    // transition: theme.transitions.create(['background-color'], {
    //     duration: 500,
    // }),
  },
}))

export default IOSSwitch
