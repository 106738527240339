import { SxProps, Theme } from "@mui/material"

export const tableCont: SxProps<Theme> = {
  width: "100%",
  height: "100%",
  p: 2,
  overflow: "hidden",
}

export const noEventsStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}

export const table: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gap: "20px",
  padding: "12px",
  border: "1px solid var(--borders)",
  borderRadius: "8px",
  marginBottom: "16px",

  "@media (min-width: 1023px)": {
    gridTemplateColumns: "2fr 1fr 1fr 1fr",
  },
}
