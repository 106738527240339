import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const profileEventCards: SxProps<Theme> = {
  margin: 0,
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(317px, 1fr))",
  gridAutoRows: "1fr",
  gap: "12px 8px",
  "@media (max-width: 1360px)": {
    gridTemplateColumns: "repeat(auto-fill, minmax(251px, 1fr))",
  },
  "@media (max-width: 960px)": {
    gridTemplateColumns: "repeat(auto-fill, minmax(224px, 1fr))",
  },
  "@media (max-width: 360px)": {
    gridTemplateColumns: "1fr",
  },
}
