import Box from "@mui/material/Box"
import { Outlet } from "react-router-dom"
import { promoCont } from "./indexStyle"
import SideMenu from "./SideMenu"

export default function Promotion() {
  return (
    <Box sx={promoCont}>
      <SideMenu />

      <Outlet />
    </Box>
  )
}
