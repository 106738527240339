import { useEffect, useState } from "react"

const useModalControler = () => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    if (!mounted) {
      return
    }
    const onKeyDownEsc = (e: KeyboardEvent) => {
      if (e.code !== "Escape") {
        return
      }
      if (e.code === "Escape") {
        setMounted(!mounted)
      }
    }

    document.addEventListener("keydown", onKeyDownEsc)

    return () => {
      document.removeEventListener("keydown", onKeyDownEsc)
    }
  }, [mounted])

  const handleToggleMounted = () => {
    setMounted(!mounted)
  }

  return {
    mounted,
    handleToggleMounted,
  }
}

export default useModalControler
