import { FC } from "react"
import { useYandexMap } from "../../common/yandex/provider/YandexMapProvider"
import { ErrorPage } from "./ErrorPage"
import { LoadingPage } from "./LoadingPage"
import { FeedYMapContent } from "./content/FeedYMapContent"

const FeedMap: FC = () => {
  const { isLoaded, data, error } = useYandexMap()

  if (isLoaded) {
    return <LoadingPage />
  }

  if (error) {
    return <ErrorPage message={error.message} />
  }

  if (data) {
    return <FeedYMapContent ydata={data} />
  }
}
export default FeedMap
