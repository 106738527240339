import { SxProps, Theme } from "@mui/material"

export const rowCont: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  height: "calc(100vh - 160px)",
  overflow: "auto",
}

export const halfIconStyle = {
  alignSelf: "center",
  justifySelf: "flex-start",
  marginLeft: "13px",
}

export const cardCont = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  justifyContent: "space-between",
  p: 1,
  gap: "20px",
  bgcolor: "transparent",
  borderRadius: "8px",
  cursor: "pointer",
  transition: "all 0.3s ease",

  "&:hover": {
    bgcolor: "var(--promo-card-bg)",
  },

  "@media (min-width: 1023px)": {
    gridTemplateColumns: "2fr 1fr 1fr 1fr",
  },
}

export const textStyle: SxProps<Theme> = {
  alignSelf: "center",
  justifySelf: "flex-start",
  marginLeft: "16px",
  fontWeight: 500,
  color: "var(--secondary-text)",
}
