import { FC } from "react"
import Card from "@mui/material/Card"
import {
  cardAvatarCont,
  cardInfo,
  cardInfoIcon,
} from "../../../CardEvent/CardEvent/CardEventStyles"
import { Box, Stack } from "@mui/material"
import { Link } from "react-router-dom"
import CardMedia from "@mui/material/CardMedia"
import { monthsByIndex } from "../../../../consts"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import WalletIconCard from "/src/assets/svg/card-icons-pack/Wallet-icon.svg?react"
import CalendarIconCard from "/src/assets/svg/card-icons-pack/calendar-icon.svg?react"
import ClockIconCard from "/src/assets/svg/card-icons-pack/clock-icon.svg?react"
import LocationIconCard from "/src/assets/svg/card-icons-pack/location-icon.svg?react"
import ShareIconCard from "/src/assets/svg/card-icons-pack/share-icon.svg?react"
import CloseIcon from "@mui/icons-material/Close"
import { z } from "zod"
import { schema } from "../../schema"
import eventPhoto from "../../../../app/data/avatarUrl"
import CardAvatar from "../../../CardEvent/CardAvatar"

export type PopupCardEventModel = z.infer<typeof schema>
export type PopupCardEventProps = {
  model?: PopupCardEventModel
  onClose?: () => void
}
export const PopupCardEvent: FC<PopupCardEventProps> = ({ model, onClose }) => {
  if (!model) return null
  const { date, time, dayNum, monthNum } = formatDateFromString(model.startTime)
  const avatars: string[] = selectAvatars(model.participants)
  const closeHandle = () => {
    if (onClose) {
      onClose()
    }
  }
  const price = "150" //Нужно получать цены с сервера
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        position: "relative",
        borderRadius: "16px",
        minHeight: "160px",
        minWidth: "600px",
        padding: "8px",
        backgroundColor: `var(--surface-bg)`,
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 151, borderRadius: "16px" }}
        image={model.avatarUrl[0]}
      />
      <CardContent sx={{ padding: "4 !important", mb: "12px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <Link
            to={`/fid/${model.eventId}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{
                fontWeight: 800,
                fontSize: "20px",
                lineHeight: "24px",
                margin: "0 !important",
                color: "var(--primary-text)",
                "&:first-letter": { textTransform: "uppercase" },
              }}
            >
              {model.eventName}
            </Typography>
          </Link>

          <Link
            to="#"
            style={{
              flexShrink: "0",
              marginLeft: "11px",
            }}
          >
            <CloseIcon fill={"var(--secondary-text)"} onClick={closeHandle} />
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            paddingBottom: "8px",
            borderBottom: "1px solid var(--borders)",
            marginBottom: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Link to="#" style={{ display: "contents" }}>
              <LocationIconCard fill={"var(--secondary-text)"} />

              <Typography variant="body2" color={`var(--secondary-text)`}>
                {`${model.eventAddress.city}, ${model.eventAddress.street}, ${model.eventAddress.house_number}`}
              </Typography>
            </Link>
          </Box>

          <Box sx={cardInfo}>
            <Link to="#" style={cardInfoIcon}>
              <CalendarIconCard stroke={"var(--secondary-text)"} />

              <Typography variant="body2" color={`var(--secondary-text)`}>
                {date}
              </Typography>
            </Link>
          </Box>

          <Box sx={cardInfo}>
            <Link to="/" style={cardInfoIcon}>
              <ClockIconCard stroke={"var(--secondary-text)"} />

              <Typography variant="body2" color={`var(--secondary-text)`}>
                {time}
              </Typography>
            </Link>
          </Box>

          <Box sx={cardInfo}>
            <Link to="#" style={cardInfoIcon}>
              <WalletIconCard stroke={"var(--secondary-text)"} />

              <Typography variant="body2" color={`var(--secondary-text)`}>
                { null }
              </Typography>
            </Link>
          </Box>
        </Box>

        <Box>
          <Box sx={cardAvatarCont}>
            <Stack direction="row" spacing={-1.5}>
              {avatars &&
                avatars.map(
                  (avatar, i) =>
                    avatars && (
                      <CardAvatar src={avatar} alt={avatar} key={avatar} />
                    ),
                )}
            </Stack>

            <Typography variant="body2" color={`var(--secondary-text)`}>
              {3 > 100 && `+100 уже идут`}

              {3 > 1000 && `+${"1000".toString()[0]}k уже идут`}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

function formatDateFromString(strTime: string) {
  const date = new Date(strTime)
  const dayNum = date.getDate()
  const monthNum = date.getMonth()

  const formattedDate =
    date.getDate() +
    " " +
    monthsByIndex[date.getMonth()] +
    " " +
    date.getFullYear()

  const formattedTime = date.toLocaleTimeString("ru-RU", {
    hour: "numeric",
    minute: "numeric",
  })

  return {
    dayNum,
    monthNum,
    date: formattedDate,
    time: formattedTime,
  }
}

const selectAvatars = (
  raw: Array<{ participant_avatar_url: string | null }>,
): string[] => {
  return raw
    .map<string | null>((item) => item.participant_avatar_url)
    .filter((item) => item !== null)
    .map(eventPhoto)
}
