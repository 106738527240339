import { ReactElement } from "react";
import s from "../styles.module.css";
import { Box } from "@mui/material";
import { tabCont } from "./styles";
import { Item } from "./item";

export type ListModel<T extends any> = {
  title: string
  value: T
}
export type ItemListProps<T> = {
  items: ListModel<T>[]
  onSelect?: (value: T, index: number, name: string) => void
  isSelectedAdapter: (value: ListModel<T>) => boolean
}
export function ItemList<T>(props: ItemListProps<T>): ReactElement {
  const {items, onSelect, isSelectedAdapter} = props
  const selectHandler = (value: T, index: number, name: string) => {
    if (onSelect) {
      onSelect(value, index, name)
    }
  }
  return (<div className={s.box}>
    <Box sx={tabCont(items.length)}>
      {items.map((item, index) => {
        return <Item<T>
          title={item.title}
          value={item.value}
          key={index}
          onClick={()=>{
            selectHandler(item.value, index, item.title)
          }}
          isSelected={isSelectedAdapter(item)}
          isLast={index !== items.length - 1}
        />
      })}
    </Box>
  </div>)
}