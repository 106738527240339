import { z } from "zod";

export const CommonEventSchema = z.object({
  event_name: z.string().min(5),
  is_private: z.boolean(),
  start_time: z.string(),
  end_time: z.string(),
  event_type_id: z.number(),
})

export const EventsTypeSchema = z.object({
  id: z.number(),
  type_name: z.string()
})