import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultPaginationState } from "./types";

const paginationSlice = createSlice({
  name: "pagination",
  initialState: defaultPaginationState(),
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setTotalElement: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload


    }
  },
})

export const {setTotalElement, setPage} = paginationSlice.actions
export default paginationSlice.reducer