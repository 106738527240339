import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getLocation, saveLocation } from "./persistentStore";
import { LocationIp, LocationState } from "./types";

const initialState = ():LocationState=> {
  return {
    lastPosition: getLocation()
  }
}
const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    addLocationIp: (state, action: PayloadAction<LocationIp>) => {
      const newPos = action.payload
      const {currentPosition, lastPosition} =  state
      if (!currentPosition){
        state.lastPosition = newPos
        state.currentPosition = newPos
      } else {
        state.lastPosition = lastPosition
        state.currentPosition = newPos
      }
      saveLocation(newPos)
    },
  },
})
export  const {addLocationIp}  =  locationSlice.actions
export default  locationSlice.reducer