import * as Api from "../../app/data/tribeSwaggerApi"
import { useEffect, useState } from "react"
import { RegisterFormValues } from "./LoginForm"

export interface RegistrationFetch {
  tryRegistration: (data: RegisterFormValues) => void
  isLoading: boolean
  isError: boolean
  registrantId?: number
  error?: string
}
const useRegistrationFetch = (): RegistrationFetch => {
  const [fetch, { isLoading, error }] = Api.useAddRegistrantWithEmailMutation()
  const [regId, setRegId] = useState<number | undefined>()
  const [fetchError, setFetchError] = useState<string | undefined>()
  const [isFetchError, setIsFetchError] = useState(false)
  useEffect(() => {
    if (error) {
      setFetchError(error.toString)
      setIsFetchError(true)
    }
  }, [error])
  const handleFetch = (formData: RegisterFormValues) => {
    fetch({
      registrantRequestDto: {
        email: formData.email,
        password: formData.password,
        username: formData.userName,
      },
    })
      .unwrap()
      .then((value) => {
        const id = value.registrant_id
        if (id) {
          setRegId(id)
        } else {
          setIsFetchError(true)
          setFetchError("registrant_id is undefined")
        }
      })
      .catch((error) => {
        setIsFetchError(true)
        setFetchError(error.toString)
      })
  }

  return {
    tryRegistration: handleFetch,
    error: fetchError,
    isError: isFetchError,
    isLoading: isLoading,
    registrantId: regId,
  }
}
export default useRegistrationFetch
