import CodeInputs from "../../../common/codeInputs"
import CheckBoxTwo from "../../checkbox/checkbox_2"
import s from "./styles.module.css"

const HobbiesSettings = () => {
  return (
    <div className={s.hobbies_settings_body}>
      <CheckBoxTwo />

      <button className={s.set_profile_btn}>Редактировать профиль</button>
    </div>
  )
}

export default HobbiesSettings
