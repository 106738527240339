import { UiAddress, UiRegion } from "../layout/stepers/step_2";
import { EventPrivateSelect, EventTypeSelection } from "../layout/stepers/step_1/types";

export interface EventDialogState {
  showEvent: boolean,
  region?: UiRegion,
  address?: UiAddress
  eventModePrivate?: EventPrivateSelect,
  eventType?: EventTypeSelection
}

export const defaultEventDialogState = (): EventDialogState => ({
  showEvent: false,
  })