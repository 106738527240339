import { Box, SxProps, Theme } from "@mui/material"
import { ReactNode } from "react"
import { feedCardsCont, feedPageCont } from "./CardsGridContainerStyle"

type Props = {
  children: ReactNode
  wrapStyle?: SxProps<Theme>
  cardContStyle?: SxProps<Theme>
}
export default function CardsGridContainer(props: Props) {
  const { children, wrapStyle, cardContStyle } = props

  return (
    <Box sx={{ ...feedPageCont, ...wrapStyle }}>
      <Box sx={{ ...feedCardsCont, ...cardContStyle }}>{children}</Box>
    </Box>
  )
}
