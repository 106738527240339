import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"

import { MenuItem } from "@mui/material"

import { useAppDispatch } from "../../../../app/hooks"
import { userLogout } from "../../../../app/data/appTokensStore"
import { logout } from "../../../../features/authorization_page/AuthorizationSlice"

import { handleProfileItemStyle, sideMenuItem } from "./headerProfileItemStyles"

type MenuItem = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  alt: {
    ru: string
    en: string
  }
  path: string
  text: string
}

type Props = {
  item: MenuItem
  i: number
  isOpenMenu: boolean
}

const HeaderProfileItem: FC<Props> = ({ item, i, isOpenMenu }) => {
  const { path, icon: Icon, text } = item || {}
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  if (!path || !Icon || !text) {
    return null
  }

  const handleItemClick = () => {
    if (path === "/") {
      dispatch(logout())
      dispatch(userLogout())
      !isOpenMenu
    } else {
      navigate(path, { replace: true })
      !isOpenMenu
    }
  }

  return (
    <Link
      to={path}
      key={text}
      style={handleProfileItemStyle(i, isOpenMenu)}
      onClick={handleItemClick}
    >
      <MenuItem style={sideMenuItem}>
        <Icon />
        {text}
      </MenuItem>
    </Link>
  )
}

export default HeaderProfileItem
