import { SxProps, Theme } from "@mui/material"

export const partTitle: SxProps<Theme> = {
  fontSize: "22px",
  fontWeight: 500,
  color: "var(--primary-text)",
}

export const container: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  minWidth: "232px",
  border: "1px solid var(--borders)",
  borderRadius: "12px",
  padding: "8px",
  transition: "all 0.3s ease",
  cursor: "pointer",

  "&:hover": {
    borderColor: "var(--filter-range-active)",
    background: "var(--filter-selected)",
  },
}

export const descCont: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "50px",
}
