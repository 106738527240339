import type { SVGProps } from "react"

export function UserIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" stroke="" strokeLinecap="round" strokeWidth={1.75}>
        <path d="M19.727 20.447c-.455-1.276-1.46-2.403-2.857-3.207C15.473 16.436 13.761 16 12 16c-1.761 0-3.473.436-4.87 1.24c-1.397.804-2.402 1.931-2.857 3.207"></path>
        <circle cx={12} cy={8} r={4}></circle>
      </g>
    </svg>
  )
}
