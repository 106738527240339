import { Button, SxProps, Theme } from "@mui/material"
import { CSSProperties } from "react"
import { cardBtnExtra, cardBtnExtraText } from "./GradientTextBtn"

type Props = {
  title: string
  btnStyle?: SxProps<Theme>
  textStyle?: CSSProperties
  isShow?: boolean
  handleAction?: () => void
}

export default function GradientTextBtn({
  title,
  btnStyle,
  textStyle,
  handleAction = () => {},
}: Props) {
  return (
    <Button
      onClick={handleAction}
      variant="contained"
      sx={{ ...cardBtnExtra, ...btnStyle }}
    >
      <p style={{ ...cardBtnExtraText, ...textStyle }}>{title}</p>
    </Button>
  )
}
