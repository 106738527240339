import { useState } from "react"
import { NavLink } from "react-router-dom"
import { List, Typography } from "@mui/material"

import { sideMenuSettingsTabs } from "../../consts"
import { useTheme } from "../../hooks/useTheme"
import { RightArrow } from "../../assets/svg/menu-icons-pack/RightArrow"
import { menuList } from "./ProfileSettinsTabStyles"
import s from "./styles.module.css"

const isActiveStyle = (v: { isActive: boolean }) =>
  v.isActive ? `${s.tab_item} ${s.tab_item_active}` : `${s.tab_item}`

export default function ProfileSettingsTab() {
  const { isDarkTheme, onToggleTheme } = useTheme()
  const [isThemeOn, setIsThemeOn] = useState(false)

  const toggleTheme = () => {
    setIsThemeOn(!isThemeOn)
    onToggleTheme()
  }
  //TODO: разобраться с темой => сделать как у людей через context
  //TODO: : посмотреть куда выходим
  //TODO: : геолокация => 1-сделать, 2- удалить отдельный компонент, 3- перенаправить путь
  return (
    <List sx={menuList}>
      {sideMenuSettingsTabs.map((tab) =>
        tab.link === "/profile/settings/location" ? (
          <div key={tab.link} className={s.tab_item}>
            <tab.icon />
            <Typography variant="body2" fontWeight={500} width="100%">
              {tab.title}
            </Typography>
            <RightArrow />
          </div>
        ) : tab.link === "/profile/settings/theme" ? (
          <div key={tab.link} className={s.tab_item} onClick={toggleTheme}>
            <tab.icon />
            <Typography variant="body2" fontWeight={500} width="100%">
              {tab.title}
            </Typography>
            <span>{isDarkTheme ? "Темная" : "Светлая"}</span>
          </div>
        ) : (
          <NavLink to={tab.link} className={isActiveStyle} key={tab.link}>
            <tab.icon />
            <Typography variant="body2" fontWeight={500} width="100%">
              {tab.title}
            </Typography>
            <RightArrow />
          </NavLink>
        ),
      )}
    </List>
  )
}

/*
type ProfileSettingTabsProps = {
  setTab: (tab: string) => void
  currentTab: string
}

const isActiveStyle = (v: { isActive: boolean }) =>
  v.isActive ? `${s.tab_item} ${s.tab_item_active}` : `${s.tab_item}`

const ProfileSettingsTab = ({ setTab }: ProfileSettingTabsProps) => {
  return (
    <div className={s.settings_tab__wrapper}>
      <ul className={s.settings_tab__body}>
        {Object.keys(ProfileSettingsTabNames).map((tabName, i) => (
          <NavLink
            to={`${Object.values(ProfileSettingsTabNames)[i]}`}
            className={isActiveStyle}
            key={i}
            tabIndex={0}
            data-tab={tabName}
            onClick={(e) =>
              setTab(e.currentTarget.getAttribute("data-tab") as string)
            }
          >
            {tabName}
          </NavLink>
        ))}
      </ul>
    </div>
  )
}

export default ProfileSettingsTab
*/
//function geoFindMe() {
//  function success(position) {
//    console.log(position.toJSON())
//  }
//
//  function error() {
//    console.log("Unable to retrieve your location")
//  }
//
//  if (!navigator.geolocation) {
//    console.log("Geolocation is not supported by your browser")
//  } else {
//    console.log("Locating…")
//    navigator.geolocation.getCurrentPosition(success, error)
//  }
//}
