import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { FixedSizeList } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import { tribeApi } from "../../../../../app/data/tribeSwaggerApi"
import InputPassword from "../../../../../common/ui-kit/inputs/input_password"
import { TStepProps, TUser } from "../../../types"
import Buttons from "../../common/buttons"
import Title from "../../common/title"
import InvitedList from "./invited_user_list/invited_user_list"
import people from "./mock"
import s from "./styles.module.css"
import User from "./user/user"
import classNames from "classnames"
import { useValidateFormikData } from "../../../hooks/useValidateFormikData"
import Alert from "@mui/material/Alert"
import CircularProgress from "@mui/material/CircularProgress"
import SearchIcon from "@mui/icons-material/Search"

type TStep4Props = TStepProps & {
  toggleUsersList: (choosenUser: TUser) => void
  invitedUsers: Array<TUser>
}

type TItemProps = {
  index: number
  style: {}
}

const Step4: FC<TStep4Props> = ({
  handlerClose,
  formik,
  makeStep,
  step,
  toggleUsersList,
  invitedUsers,
}) => {
  const [typeList, setTypeList] = useState<string>("all")
  const [isNextPageLoading, setIsNextPageLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [size] = useState(4)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [listParams, setListParams] = useState({
    height: 0,
    itemSize: 60,
    width: 0,
  })
  const isFieldEmpty = !invitedUsers || invitedUsers?.length === 0

  const listRef = useRef<HTMLDivElement | null>(null)
  const { invited_user_ids = [] } = formik.values
  const [usersList, setUsersList] = useState<Array<TUser>>(people)
  const [filterValue, setFilterValue] = useState("")
  const [fakeLoading, setFakeLoading] = useState<boolean>(false)
  const { data } = tribeApi.useGetAllFriendsForInvitingQuery({ page, size })

  useEffect(() => {
    if (data) {
      const { content } = data
      setTotalPageCount(data.totalPages!)
      setUsersList((prev) => [])
      setIsNextPageLoading(false)
    }
  }, [data])

  const loadNextPage = () => {
    setIsNextPageLoading(true)
    setPage((prev) => prev + 1)
  }

  useEffect(() => {
    setHasNextPage((prev) => page < totalPageCount)
  }, [page, totalPageCount])

  const searchedList = useMemo(() => {
    return usersList.filter(
      (user) =>
        user.first_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        user.last_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        user.username.toLowerCase().includes(filterValue.toLowerCase()),
    )
  }, [usersList, filterValue])

  const getStatus = () => {
    if (fakeLoading) {
      return (
        <div className={s.success}>
          <CircularProgress />
        </div>
      )
    }
    // if (isError) {
    //   return (
    //     <Alert severity="error" className={s.error}>
    //       Произошла ошибка
    //     </Alert>
    //   );
    // }
  }

  const handleInvite = () => {
    setFakeLoading(true)

    setTimeout(() => {
      setFakeLoading(false)
      makeStep("invite_confirm")
    }, 800)
  }

  const updateListParams = useCallback(() => {
    if (listRef.current) {
      const height = listRef.current.offsetHeight
      const width = listRef.current.offsetWidth
      setListParams((prev) => ({ ...prev, height, width }))
    }
  }, [listRef, searchedList])

  const itemCount = hasNextPage ? searchedList.length + 1 : searchedList.length

  useLayoutEffect(updateListParams, [updateListParams])

  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage

  const isItemLoaded = (index: number) => Boolean(usersList[index])

  const Item: FC<TItemProps> = memo(({ index, style }) => {
    let content
    if (!isItemLoaded(index)) {
      content = <h1>Загружается</h1>
    } else {
      const element = searchedList[index]

      content = (
        <User
          user={element}
          choose={chooseUser}
          invitedIds={invited_user_ids}
        />
      )
    }

    return <div style={style}>{content}</div>
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterValue(value)
  }

  const chooseUser = (choosenUser: TUser) => {
    const isAlreadyInvited = invited_user_ids.includes(choosenUser.id)
    toggleUsersList(choosenUser)
    if (isAlreadyInvited) {
      formik.setFieldValue(
        "invited_user_ids",
        invited_user_ids.filter((id: number) => id !== choosenUser.id),
      )
    } else {
      formik.setFieldValue("invited_user_ids", [
        ...invited_user_ids,
        choosenUser.id,
      ])
    }
  }

  const removeInvitedUser = (user: TUser) => {
    formik.setFieldValue(
      "invited_user_ids",
      invited_user_ids.filter((id: number) => id !== user.id),
    )
    toggleUsersList(user)
  }

  return (
    <div className={s.container}>
      <Title align={"flex-start"} title={"Пригласить участников"} />
      <div className={s.content}>
        <div className={s.input_search}>
          <div className={s.input_search_icon}>
            <SearchIcon sx={{ color: "var(--profile-ntfc-text)" }} />
          </div>
          <InputPassword
            className={s.input_search_input}
            // isSearch
            title={""}
            name={"place"}
            value={filterValue}
            handleChange={handleChange}
            type={"text"}
            placeholder={"@Nickname"}
            errorText={null}
            onBlur={() => {}}
          />
        </div>

        <div className={s.type_list}>
          <div
            onClick={() => setTypeList("all")}
            className={classNames(
              s.type_item,
              typeList === "all" && s.type_item_active,
            )}
          >
            Все
          </div>
          <div
            onClick={() => setTypeList("friend")}
            className={classNames(
              s.type_item,
              typeList === "friend" && s.type_item_active,
            )}
          >
            Друзья
          </div>
        </div>
        {/*<InvitedList list={invitedUsers} removeUser={removeInvitedUser} />*/}
        <div className={s.friends_box} ref={listRef}>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                className={s.infiniteList}
                height={listParams.height}
                itemCount={itemCount}
                itemSize={listParams.itemSize}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={listParams.width}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        </div>
      </div>
      <Buttons
        step={step}
        disabled={isFieldEmpty}
        next={() => {
          if (isFieldEmpty) return
          handleInvite()
        }}
        back={() => {
          if (handlerClose) {
            handlerClose()
          }
        }}
      />

      {getStatus()}
    </div>
  )
}

export default Step4
