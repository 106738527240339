import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"
import { btnStyle } from "../SearchParticipantsCard/style"

export const contentCont = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
  minHeight: "390px",
  paddingTop: "40px",
}

export const avatar = { width: "100px", height: "100px" }

export const btnCont = {
  display: "flex",
  justifyContent: "space-between",
}

export const btnBack = { width: "65px", minWidth: "152px", height: "auto" }

export const btnSubmit = {
  ...btnStyle,
  m: 0,
  p: "8px 16px",
  minWidth: "unset",
  maxWidth: "unset",
}

export const contactInfoStyle: SxProps<Theme> = {
  display: "flex",
  gap: "4px",
  flexDirection: "column",
  color: "var(--primary-text)",
}
