import { Box } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import {
  PromoCard,
  useGetEventPromouters,
} from "../../../app/data/promotion/promotionApi"
import ErrorNotification from "../ErrorNotification"
import Loading from "../Loading"
import SearchEmployeesInput from "../SearchEmployeesInput"
import { PromoutersCardDetails } from "./PromoutersCardDetails"
import "./style"
import { cardsCont, container, searchCont } from "./style"

export default function PromoutersDetails() {
  const [query, setQuery] = useState("")
  const [animateOnLoad, setAnimateOnLoad] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const { state }: { state: PromoCard } = useLocation()
  const { data, isLoading, isError } = useGetEventPromouters({
    eventId: state.id,
  })

  useEffect(() => {
    setAnimateOnLoad(true)
  }, [])

  if (isError && !isLoading) {
    return <ErrorNotification />
  }

  if ((isLoading || !data) && !isError) {
    return <Loading />
  }

  return (
    <Box sx={container}>
      <Box sx={searchCont}>
        <SearchEmployeesInput query={query} setQuery={setQuery} />
      </Box>

      <CSSTransition
        timeout={300}
        in={animateOnLoad}
        nodeRef={nodeRef}
        classNames="appearance"
        mountOnEnter
        unmountOnExit
      >
        <Box ref={nodeRef} sx={cardsCont}>
          {data &&
            data.content.length > 0 &&
            data.content.map((card) => (
              <PromoutersCardDetails
                key={card.promoter_id}
                promoter={card}
                event={state}
              />
            ))}
        </Box>
      </CSSTransition>
    </Box>
  )
}
