import img1 from "../../assets/image/chat4.png"
import img2 from "../../assets/image/chat6.png"
import { ChatUserDataSource } from "./DataType"

export const mock: ChatUserDataSource = {
  1: {
    nickname: "@KeksMops",
    img: img1,
    fullname: "Вера Ивановна",
    isOnline: true,
    chats: [
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "Конечно, бери с собой пару сочных бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Дедушка сказал, не пойдет...",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Ты супер!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "(((",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "Обожаю тебя бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Врешь мне ирод !!!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: false,
        avatar: img1,
        text: "Не вру иродка !!!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "Конечно, бери с собой пару сочных бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Дедушка сказал, не пойдет...",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Ты супер!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "(((",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "Обожаю тебя бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Врешь мне ирод !!!",
      },
      {
        type: 0,
        isRead: false,
        avatar: img1,
        text: "Не вру иродка !!!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: false,
        avatar: img1,
        text: "Не вру иродка !!!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "Конечно, бери с собой пару сочных бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Дедушка сказал, не пойдет...",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Ты супер!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "(((",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img1,
        text: "Обожаю тебя бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img2,
        text: "Врешь мне ирод !!!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: false,
        avatar: img1,
        text: "Не вру иродка !!!",
      },
    ],
  },
  2: {
    nickname: "@LybitelBabyl",
    img: img2,
    fullname: "Денис Дорох",
    isOnline: false,
    chats: [
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "Конечно, бери с собой пару сочных бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Дедушка сказал, не пойдет...",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Ты супер!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "(((",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Ты супер!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "(((",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "Обожаю тебя бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Врешь мне ирод !!!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Дедушка сказал, не пойдет...",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Ты супер!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "(((",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "Обожаю тебя бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Врешь мне ирод !!!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: false,
        avatar: img2,
        text: "Не вру иродка !!!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Ты супер!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "(((",
      },
      {
        type: 0,
        time: "12:23",
        isRead: true,
        avatar: img2,
        text: "Обожаю тебя бабуль!",
      },
      {
        type: 1,
        time: "12:23",
        avatar: img1,
        text: "Врешь мне ирод !!!",
      },
      {
        type: 0,
        time: "12:23",
        isRead: false,
        avatar: img2,
        text: "Не вру иродка !!!",
      },
    ],
  },
}
