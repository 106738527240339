import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const iconContainer = {
  p: "12px",
  borderRadius: "8px",
  backgroundColor: "var(--filter-range-active)",
  cursor: "pointer",
  zIndex: 1400,
}

export const iconQntyCircle: SxProps<Theme> = {
  "& .MuiBadge-badge": {
    fontSize: "10px",
    padding: "2px",
    right: "1.5px",
    top: "4px",
    backgroundColor: "var(--error-color)",
    color: "var(--white)",
  },
}

export const iconBell = { color: "var(--error-color)" }
