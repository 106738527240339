import { EventFilterResult } from "./types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectFilter } from "../../selectors";
import { NumberRange, PartOfDay, PartsOfDay, SortType, TimeRange } from "../../types";
import {
  resetFilter,
  setQueryText, updateAgeArgs,
  updateAlcohol, updateDateRange, updateDuration,
  updateFreeArgs, updateParticipants,
  updatePartOfDays,
  updateSort
} from "../../filterSlice";

const useEventFilter = (): EventFilterResult => {
  const filterState = useAppSelector(selectFilter)
  const dispatch = useAppDispatch()

  const updateSortHandler = (type: SortType) => {
    if (filterState.sort === type) return
    dispatch(updateSort(type))
  }
  const updateQueryTextHandler = (text: string) => {
    if (filterState.text === text) return
    dispatch(setQueryText(text))
  }
  const resetFilterHandler = () => {
    dispatch(resetFilter())
  }
  const updatePartsOfDayHandler = (value: PartOfDay) => {
    const {partsOfDay} = filterState
    if (!partsOfDay) {
      dispatch(updatePartOfDays([value]))
    } else {
      const newArr = toggleTimeOfDay(value, partsOfDay)
      dispatch(updatePartOfDays(newArr))
    }
  }
  const updateIsFreeHandler = (value: boolean | undefined) => {
    dispatch(updateFreeArgs(value))
  }
  const updateAlcoholHandler = (value: boolean | undefined) => {
    dispatch(updateAlcohol(value))
  }
  const updateAgeFilterHandler = (value: boolean | undefined) => {
    dispatch(updateAgeArgs(value))
  }
  const updateParticipantsRangeHandler = (value: NumberRange) => {
    dispatch(updateParticipants(value))
  }
  const updateDurationRangeHandler = (value: NumberRange) => {
    dispatch(updateDuration(value))
  }
  const updateTimeRageHandler = (value: TimeRange) => {
    dispatch(updateDateRange(value))
  }
  return {
    currentFilter: filterState,
    updateSort: updateSortHandler,
    updateQueryText:updateQueryTextHandler,
    resetFilter: resetFilterHandler,
    updatePartsOfDay: updatePartsOfDayHandler,
    updateIsFree: updateIsFreeHandler,
    updateIsAlcohol: updateAlcoholHandler,
    updateAgeLimit: updateAgeFilterHandler,
    updateParticipantsRange: updateParticipantsRangeHandler,
    updateDurationRange: updateDurationRangeHandler,
    updateTimeRange: updateTimeRageHandler
  }
}

export default useEventFilter

function toggleTimeOfDay(value: PartOfDay, arr: PartOfDay[]): PartsOfDay | undefined {
  const set = new Set<PartOfDay>(arr)
  if (set.has(value)) {
    set.delete(value)
  } else {
    set.add(value)
  }
  return set.size > 0 ? [...set] : undefined;
}