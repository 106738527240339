import { z } from "zod"

export const schema = z.object({
  eventId: z.number(),
  eventAddress: z.object({
    city: z.string(),
    street: z.string().nullable(),
    event_longitude: z.number(),
    event_latitude: z.number(),
    house_number: z.string().nullable(),
  }),
  avatarUrl: z.array(z.string()),
  eventName: z.string(),
  eventType: z.string(),
  organizerUsername: z.string(),
  startTime: z.string(),
  participants: z.array(
    z.object({
      participant_avatar_url: z.string().nullable(),
    }),
  ),
})

export const popupCardSchema = z.object({
  eventId: z.number(),
  eventAddress: z.string(),
  avatarUrl: z.array(z.string()),
  eventName: z.string(),
  startTime: z.string(),
  participants: z.array(z.string()),
})
