import { useAppSelector } from "../app/hooks"
import { selectUserId } from "../app/data/appTokensStore"
import {
  useGetUserInvitationEventsQuery,
  useGetUserOrganizationEventsQuery,
  useGetUserParticipantEventsQuery,
  useGetUserProfileQuery
} from "../app/data/userDataSlice/userApi";

export const useIsAuthorizedUser = (): boolean => {
  const credentials = useAppSelector(selectUserId)
   return !!credentials
}
