import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"
import "/src/theme/colors.css"

export const categoryTabsWrapper: SxProps<Theme> = {
  height: "89px",
  backgroundColor: "var(--surface-bg)",
  padding: "4px 0px",
  "@media (min-width: 638px)": {
    padding: "16px 0px 8px",
  },
}

export const categoryTabsCont: SxProps<Theme> = {
  display: "flex",
  gap: "8px",
  alignItems: "center",
  width: "100%",
  height: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  scrollbarWidth: "none",
  padding: "0 8px",
  "@media (min-width: 638px)": {
    padding: "0 32px",
  },
  "@media (min-width: 1600px)": {
    justifyContent: "center",
  },
  "&::-webkit-scrollbar": {
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f0f0f0",
  },
}

export const categoryTab: SxProps<Theme> = {
  height: "100%",
  minHeight: "65px",
  minWidth: "100px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  "&:hover": {
    filter: "none !important",
  },
}
