import { SxProps, Theme } from "@mui/material"

export const contactsContOuter: SxProps<Theme> = {
  position: "relative",
  bgcolor: "var(--dialog-bg)",
  borderRadius: "8px",
  WebkitUserSelect: "none" /* Safari */,
  msUserSelect: "none" /* IE 10 and IE 11 */,
  userSelect: "none",
}

export const contactsContInner: SxProps<Theme> = {
  display: "grid",
  gridTemplateRows: "minmax(50px, auto)",
  alignContent: "start",
  minHeight: "300px",
  maxHeight: "372px",
  overflow: "auto",
}

export const contactStyle: SxProps<Theme> = {
  display: "flex",
  gap: "16px",
  alignItems: "center",
  p: "8px",
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  border: "1px solid transparent",
  borderRadius: "8px",
  transition: "all 0.2s ease",
  "&:hover": {
    border: "1px solid var(--bg-outside-modal)",
  },
}

export const contactStyleItem: SxProps<Theme> = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  columnGap: "16px",
  rowGap: "4px",
  "&::after": {
    content: "''",
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: "-8px",
    width: "500px",
    height: "500px",
    backgroundColor: "var(--bg-outside-modal)",
    borderRadius: "50%",
    opacity: 0,
    transition: "opacity 0.5s ease",
  },

  "&:hover::after": {
    opacity: 1,
    transition: "opacity 0.5s ease",
  },
}

export const contactAvatarStyle: SxProps<Theme> = {
  width: "50px",
  height: "50px",
}

export const contactInfoStyle: SxProps<Theme> = {
  display: "flex",
  gap: "4px",
  flexDirection: "column",
  color: "var(--primary-text)",
  overflowWrap: "anywhere",
}

export const btnStyle: SxProps<Theme> = {
  boxShadow: "none",
  borderRadius: "16px",
  height: "48px",
  padding: "12px 16px 12px 16px",
  fontSize: "16px",
  fontWeight: 500,
  minWidth: "152px",
  maxWidth: "152px",
  width: "fit-content",
  textTransform: "none",
  alignSelf: "center",
}

export const btnDisabled = {
  ...btnStyle,
  background: "var(--borders)",

  "&:hover": {
    background: "var(--borders)",
  },
}
