import { FormControlLabel } from "@mui/material"
import Alert from "@mui/material/Alert"
import CircularProgress from "@mui/material/CircularProgress"
import { FC, useEffect, useState } from "react"
import { tribeApi } from "../../../../../app/data/tribeSwaggerApi"
import IOSSwitch from "../../../../../components/IOSSwitch"
import { TStepProps, TUser } from "../../../types"
import Buttons from "../../common/buttons"
import Title from "../../common/title"
import s from "./styles.module.css"
import { calculateTimeDifference, getRussianDate, getTimeZone } from "./utils"
import { useAppSelector } from "../../../../../app/hooks"
import { selectUserId } from "../../../../../app/data/appTokensStore"
import { useNavigate } from "react-router-dom"

type TStep5Props = TStepProps & {
  handlerClose: () => void
  invitedUsers: Array<TUser>
  photo: string
}

const Step5: FC<TStep5Props> = ({
  makeStep,
  step,
  handlerClose,
  formik,
  invitedUsers,

  photo,
}) => {
  const [fetch, status] = tribeApi.useCreateEventMutation()
  const userId = useAppSelector(selectUserId)
  const [isError, setIsError] = useState(false)
  const {
    send_to_all_users_by_interests,
    show_event_in_search,
    has_alcohol,
    is_eighteen_year_limit,
    is_private,
    event_type_id,
    start_time,
    event_name,
    description,
    avatars_for_adding,
    end_time,
    event_address,
    is_free,
  } = formik.values

  useEffect(() => {
    if (status.isError) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    }
  }, [status])

  const getStatus = () => {
    if (status.isLoading) {
      return (
        <div className={s.success}>
          <CircularProgress />
        </div>
      )
    }
    if (isError) {
      return (
        <Alert severity="error" className={s.error}>
          Произошла ошибка
        </Alert>
      )
    }
  }

  const handleCreateEvent = () => {
    console.log(formik.values)
    fetch({
      requestTemplateForCreatingEventDto: {
        // ...formik?.values,
        description: description,
        is_private: is_private,
        has_alcohol: has_alcohol,
        send_to_all_users_by_interests: send_to_all_users_by_interests,
        is_eighteen_year_limit: is_eighteen_year_limit,
        is_free: is_free,
        time_zone: getTimeZone(),
        event_type_id: event_type_id,
        event_name: event_name,
        event_address: { ...event_address },
        start_time: new Date(start_time)?.toISOString(),
        end_time: new Date(end_time)?.toISOString(),
        avatars_for_adding: avatars_for_adding,
        show_event_in_search: show_event_in_search,
        organizer_id: userId ?? 0,
      },
    })
      .unwrap()
      .then((res) => {
        makeStep("confirm")
        formik.setFieldValue("event_id", res?.event_id)
      })
      .catch((e) => {
        alert("Ошибка")
      })
  }
  return (
    <div className={s.container}>
      <Title align={"flex-start"} title={"Ограничения"} />

      <div className={s.action}>
        <div className={s.switch_item}>
          <p>Выводить в поиске</p>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={<IOSSwitch sx={{ m: 1 }} />}
            label={null}
            onChange={() => {
              formik.setFieldValue(
                "show_event_in_search",
                !show_event_in_search,
              )
            }}
            value={show_event_in_search}
          />
        </div>
        <div className={s.switch_item}>
          <p>Наличие алкоголя</p>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={<IOSSwitch sx={{ m: 1 }} />}
            label={null}
            onChange={() => {
              formik.setFieldValue("has_alcohol", !has_alcohol)
            }}
            value={has_alcohol}
          />
        </div>
        <div className={s.switch_item}>
          <p>Разослать всем по интересам</p>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={<IOSSwitch sx={{ m: 1 }} />}
            label={null}
            onChange={() => {
              formik.setFieldValue(
                "send_to_all_users_by_interests",
                !send_to_all_users_by_interests,
              )
            }}
            value={send_to_all_users_by_interests}
          />
        </div>
        <div className={s.switch_item}>
          <p>
            Ограничение <b>18+</b>
          </p>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={<IOSSwitch sx={{ m: 1 }} />}
            label={null}
            onChange={() => {
              formik.setFieldValue(
                "is_eighteen_year_limit",
                !is_eighteen_year_limit,
              )
            }}
            value={is_eighteen_year_limit}
          />
        </div>

        <div className={s.switch_item}>
          <p>Бесплатно</p>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={<IOSSwitch sx={{ m: 1 }} />}
            label={null}
            onChange={() => {
              formik.setFieldValue("is_free", !is_free)
            }}
            value={is_free}
          />
        </div>
      </div>

      <Buttons
        step={step}
        loading={status?.isLoading}
        next={() => {
          handleCreateEvent()
        }}
        back={() => makeStep("-")}
      />
      {getStatus()}
    </div>
  )
}

export default Step5
