import { Avatar } from "@mui/material"
import { FC } from "react"
import eventPhoto from "../../app/data/avatarUrl"
import ded2 from "../../assets/image/img1.png"
import avatar from "../../assets/image/img5.png"
import Calendar from "../../assets/svg/calendar_item.svg?react"
import Close from "../../assets/svg/close.svg?react"
import Location from "../../assets/svg/location_item.svg?react"
import Time from "../../assets/svg/time_item.svg?react"
import s from "./styles.module.css"

export type FavoriteItemProps = {
  id: number
  disabled?: boolean
  img: string
  location: string
  calendar?: string
  time: string
  title: string
  description: string
  category?: string
  isRemove?: boolean
  removeItem: (id: number) => void
}

const FavoriteItem: FC<FavoriteItemProps> = ({
  img,
  title,
  disabled,
  description,
  location,
  calendar,
  time,
  removeItem,
  id,
}) => {
  const removeButtonClick = () => {
    removeItem(id)
  }
  return (
    <div className={s.item}>
      {disabled && <div className={s.disabled_screen} />}
      <Avatar
        src={eventPhoto(img)}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "16px 0px 0px 16px",
          maxWidth: "180px",
          maxHeight: "180px",
        }}
      />
      <div className={s.content}>
        <div className={s.content_left}>
          <div className={s.content_left_top}>
            <h2 className={s.title}>{title}</h2>
            <p className={s.description}>{description}</p>
          </div>
          <div className={s.content_left_bottom}>
            <div className={s.content_left_bottom_info}>
              <div className={s.content_left_bottom_info_item}>
                <Location />
                <p>{location}</p>
              </div>
              <div className={s.content_left_bottom_info_item}>
                <Calendar />
                <p>{calendar}</p>
              </div>
              <div className={s.content_left_bottom_info_item}>
                <Time />
                <p>{time}</p>
              </div>
            </div>
            <div className={s.stock_avatar}>
              <Avatar
                src={avatar}
                sx={{
                  border: "2px solid var(--main-bg)",
                  height: "30px",
                  width: "30px",
                  marginLeft: "-16px",
                }}
              />
              <Avatar
                src={ded2}
                sx={{
                  border: "2px solid var(--main-bg)",
                  height: "30px",
                  width: "30px",
                  marginLeft: "-16px",
                }}
              />
              <div className={s.total_avatar}>+4k</div>
            </div>
          </div>
        </div>
        <div
          className={`${s.content_right}
       `}
        >
          <Close onClick={removeButtonClick} width={24} height={24} />
        </div>
      </div>
    </div>
  )
}

export default FavoriteItem
