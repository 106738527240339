import { SxProps, Theme } from "@mui/material"

export const contentContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: "100vh",
  width: "100%",
  padding: "16px",
}

export const warnStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  height: "100px",
  borderRadius: "16px",
}

export const contentWrapper: SxProps<Theme> = {
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
  width: "100%",
  flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "space-between",
  marginBottom: "16px",
}

export const noEmployees: SxProps<Theme> = { color: "var(--unselected-text)" }
