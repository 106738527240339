import { useEffect } from "react"

export function useTheme() {
  const userTheme = window.matchMedia("(prefers-color-scheme: dark)")
  const themeInStorage = localStorage.getItem("theme")

  useEffect(() => {
    if (!themeInStorage) {
      const darkOrLight = userTheme.matches ? "dark" : "light"
      localStorage.setItem("theme", darkOrLight)
      document.documentElement.setAttribute("data-theme", darkOrLight)
    } else {
      localStorage.setItem("theme", themeInStorage)
      document.documentElement.setAttribute("data-theme", themeInStorage)
    }
  }, [])

  const onToggleTheme = () => {
    switch (themeInStorage) {
      case "dark":
        localStorage.setItem("theme", "light")
        document.documentElement.setAttribute("data-theme", "light")
        break
      case "light":
        localStorage.setItem("theme", "dark")
        document.documentElement.setAttribute("data-theme", "dark")
        break
      default:
        break
    }
  }

  const isDarkTheme = themeInStorage === "dark"

  return { isDarkTheme, onToggleTheme }
}
