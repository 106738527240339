import { SortType } from "../../shared/filter/types";

export enum SelectedValue {
  DATE = "По дате",
  DISTANCE = "По удаленности",
  ALCOHOL = "Алкоголь",
}

export function isSortType(value: any): value is SortType {
  return value === "DATE" || value === "DISTANCE" || value === "ALCOHOL";
}