import s from "../EventDialog/styles.module.css"
import BtnClose from "../../../assets/svg/close.svg?react"
import { EventModel } from "../../../types"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import CardEvent, { CardEventFormats } from "../../CardEvent/CardEvent"
import { Link } from "react-router-dom"
import Telegram from "../../../assets/svg/socials-icons/Telegram.svg?react"
import Instagram from "../../../assets/svg/socials-icons/Instagram.svg?react"
import VK from "../../../assets/svg/socials-icons/Vkontakte.svg?react"
import WatsApp from "../../../assets/svg/socials-icons/WhatsApp.svg?react"
import Skype from "../../../assets/svg/socials-icons/Skype.svg?react"
import GradientButton, { GradienButtonStyles } from "../../GradientStateBtn"
import { setEventData } from "../../../app/data/popupSlice/popupSlice"
import { useRef } from "react"
import classNames from "classnames"
import { createPortal } from "react-dom"
import useFocusLockModal from "../../../hooks/useFocusLockModal"
import { useIsAuthorizedUser } from "../../../hooks/useIsAuthorizedUser"
import { show } from "../../../pages/signIn/SignInSlice"
import { useSendRequestToParticipationInPublicEventMutation } from "../../../app/data/tribeSwaggerApi"
import LocationSpinner from "../../profile_settings/location/location_spinner"

export enum EventDialogFormats {
  share = "Share",
  invite = "Invite",
}

type EventDialogProps = {
  isVisible: boolean
  visibleToggler: () => void
  eventLink: string
}
const getCurrentDomain = (): string => {
  const protocol = window.location.protocol
  const host = window.location.host
  return `${protocol}//${host}`
}

const EventDialog = ({
  isVisible,
  visibleToggler,
  eventLink,
}: EventDialogProps): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const eventData = useAppSelector((state) => state.popupsState.eventData)
  const userId = useAppSelector((state) => state.tokenStorage.userId)
  const format = useAppSelector((state) => state.popupsState.eventDialogFormat)
  const ref = useRef<HTMLDivElement | null>(null)
  const isAuth = useIsAuthorizedUser()
  const [setParticipation, { isLoading, isError, isSuccess }] =
    useSendRequestToParticipationInPublicEventMutation()
  useFocusLockModal({ ref: ref, isVisible })
  const onClickCloseModal = () => {
    dispatch(setEventData(null))
    visibleToggler()
  }

  const textCopyHandler = (elementValue: string) => {
    navigator.clipboard.writeText(elementValue)
  }

  if (eventData === null) {
    return null
  }

  const onClickWantToGo = async () => {
    if (!isAuth) {
      visibleToggler()
      dispatch(show())
    } else {
      await setParticipation({
        eventId: eventData.id,
        userId: userId?.toString()!!, // TODO(Костыль привединия типов надо переделать)
      }).unwrap()
      visibleToggler()
    }
  }

  return createPortal(
    <div
      className={classNames(s.modal_overlay, !isVisible && s.hidden)}
      style={{
        gap: `${format === EventDialogFormats.invite && 49.33}px`,
      }}
    >
      <div
        className={
          format === EventDialogFormats.invite ? s.modal_invite : s.modal
        }
      >
        <div className={s.modal_body} ref={ref}>
          <div className={s.modal_header}>
            <BtnClose
              className={s.close_btn}
              onClick={() => onClickCloseModal()}
              tabIndex={1}
            />
          </div>
          <div
            className={s.modal_content}
            style={{
              gap: `${format === EventDialogFormats.invite && 49.33}px`,
            }}
          >
            <h2 className={s.modal_title}>
              {format === EventDialogFormats.share
                ? "Поделиться"
                : "Подтверждение участия"}
            </h2>

            {format === EventDialogFormats.share ? (
              <>
                <div className={s.card_container}>
                  {eventData && (
                    <CardEvent
                      event={eventData as EventModel}
                      format={CardEventFormats.ModalThumb}
                    />
                  )}
                </div>
                <Link to="#" className={s.event_link} tabIndex={1}>
                  {`${getCurrentDomain()}/#/${eventLink}`}
                </Link>
                <GradientButton
                  style={GradienButtonStyles.borderGradient}
                  params={{ text: "Копировать" }}
                  onAction={() =>
                    textCopyHandler(`${getCurrentDomain()}/#/${eventLink}`)
                  }
                />
                <ul className={s.socials}>
                  <VK />
                  <Instagram />
                  <WatsApp />
                  <Skype />
                  <Telegram />
                </ul>
              </>
            ) : (
              <>
                <div className={s.card_container}>
                  {eventData && (
                    <CardEvent
                      event={eventData as EventModel}
                      format={CardEventFormats.ModalThumb}
                    />
                  )}
                  <div className={s.spinner_container}>
                    {isLoading && <LocationSpinner />}
                  </div>
                  {}

                  <p className={s.modal_text}>
                    Нажимая на кнопку вы подтверждаете,
                    <br /> что хотите принять участие в этом событии.
                  </p>
                </div>
                <GradientButton
                  fetchIsLoading={isLoading}
                  style={GradienButtonStyles.fullGradient}
                  params={{ text: "Подтведить" }}
                  onAction={() => onClickWantToGo()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body,
  )
}

export default EventDialog
