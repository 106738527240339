export const validatePattern = {
	OnlyLetters: /^[А-Яа-яA-Za-z]+$/
}

const validation = 
(inputText: string, patternName: keyof typeof validatePattern): boolean => {
	if (validatePattern[patternName].test(inputText)) {
		return true
	} else return false
}

export default validation