import { Box } from "@mui/material"
import React, { useState } from "react"
import { selectValues } from "../../consts"
import { isSortType, SelectedValue } from "./SelectedValueEnum";
import {
  dropDownCont,
  handleVisibleStyle,
  selectActive,
  selectBtn,
  selectCont,
  selectIcon,
  selectItems,
  selectedIcon,
} from "./selectStyles"
import arrow from "/src/assets/svg/button-icons/arrow-down-selector.svg"
import arrowSelected from "/src/assets/svg/button-icons/selected-icon.svg"
import useEventFilter from "../../shared/filter/hooks/useEventFilter";

export type SortBy = keyof typeof SelectedValue

const Select = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {currentFilter, updateSort} = useEventFilter()
  const {sort: activeSelect} = currentFilter

  const activeStyle = { ...selectItems, ...selectActive }

  const handleSelection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { value } = e.currentTarget.dataset
    if (isSortType(value)){
      updateSort(value)
    }
  }

  return (
    <div style={selectCont} onBlur={() => setIsOpen(false)}>
      <button style={selectBtn} onClick={() => setIsOpen((prev) => !prev)}>
        {SelectedValue[activeSelect as SortBy]}

        <img
          src={arrow}
          alt="стрелка"
          style={selectIcon}
          className={isOpen ? "rotate-180" : "rotate-0"}
        />
      </button>

      <Box style={dropDownCont} sx={handleVisibleStyle(isOpen)}>
        {selectValues.map((item) => (
          <Box
            sx={item === activeSelect ? activeStyle : selectItems}
            key={item}
            onMouseDown={handleSelection}
            data-value={item}
          >
            {SelectedValue[item as SortBy]}

            {item === activeSelect && (
              <img src={arrowSelected} alt="галочка" style={selectedIcon} />
            )}
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default Select
