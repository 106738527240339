export const transformNameToNeededLength = (
  name: string,
  lastName: string,
  maxLength: number = 11,
) => {
  const nameLength = name ? name.length : 0
  const surnameLength = lastName ? lastName.length : 0
  const lengthUserName = nameLength + surnameLength

  return lengthUserName > maxLength
    ? `${name} ${lastName}`.slice(0, maxLength) + "..."
    : `${name} ${lastName}`
}
