import { Box, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {  useGetEventStatisticQuery } from "../../../app/data/promotion/promotionApi";
import DownloadIcon from "../../../assets/svg/promotion-icon/downloadIcon"
import FileIcon from "../../../assets/svg/promotion-icon/fileIcon"
import "../../../index.css"
import ErrorNotification from "../ErrorNotification"
import PromoEventCard from "../EventsPage/PromoEventCard"
import PromoBtnCard from "../PromoBtnCard"
import PromotionModal from "../PromotionModal"
import InvitedPeopleCard from "../PromotionModal/InvitedPeopleCard"
import ParticipantsPeopleCard from "../PromotionModal/ParticipantsPeopleCard"
import * as s from "../PromotionModal/style"
import { cardsCont, cont, downloadStyle, promoEventStyle } from "./style"
import Loading from "../Loading";
import { downloadDocument } from "./downloadDocument";


export default function EventDetailsPage() {
  const navigate = useNavigate()
  const { cardId } = useParams()
  const [qntyOfGuests] = useState(101)
  const { data, isLoading} = useGetEventStatisticQuery(cardId!!)
  const [animation, setAnimation] = useState(s.fadeIn)
  const [isError, setIsError] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const [isShow, setIsShow] = useState<"invited" | "participants" | "">("")
  const cardTitle =
    isShow === "invited" ? `Приглашенные на:` : "Участники идущие на:"


  const handleClose = () => {
    setIsShow("")
    setAnimation(s.fadeIn)
  }

  useEffect(() => {
    if (!isError) return

    const timer = setTimeout(() => {
      setIsError(false)
    }, 1500)

    return () => clearTimeout(timer)
  }, [isError])
  if (isLoading) return <Loading/>
  if (isError) return <ErrorNotification
    styles={{ position: "absolute", top: 0, left: 0 }}
  />
  if (data) return (
    <Box sx={cont}>
      <PromoEventCard
        date={data.start_at}
        img={data.img_url}
        title={data.name}
        contStyle={promoEventStyle}
      />

      <PromotionModal
        cardTitle={cardTitle}
        cardName={data.name}
        isLoading={false}
        handleClickAction={handleClose}
        isModal={!!isShow}
        animation={animation}
      >
        {isShow === "invited" && <InvitedPeopleCard eventId={data.id} />}

        {isShow === "participants" && (
          <ParticipantsPeopleCard eventId={data.id} />
        )}
      </PromotionModal>

      <Box sx={cardsCont}>
        <PromoBtnCard
          avatars={[]} // TODO: Получения реальных аватарок
          participants={data.promoters_count}
          qntyOfGuests={qntyOfGuests}
          title="Промоутеры"
          handleClick={()=>data.promoters_count > 0
            ? navigate(`/profile/promotion/events/${cardId}/promotes`, {
              state: data,
            })
            : () => {}}
        />
        <PromoBtnCard
          avatars={[]} // TODO: Получения реальных аватарок
          participants={data.invited_count}
          qntyOfGuests={qntyOfGuests}
          title="Приглашенные"
          handleClick={()=>data.invited_count > 0 && setIsShow("invited")}
        />
        <PromoBtnCard
          avatars={[]} // TODO: Получения реальных аватарок
          participants={data.participants_count}
          qntyOfGuests={qntyOfGuests}
          title="Участники"
          handleClick={()=>data.participants_count > 0 && setIsShow("participants")}
        />
        <PromoBtnCard
          avatars={[]} // TODO: Получения реальных аватарок
          participants={data.cashiers_count}
          qntyOfGuests={qntyOfGuests}
          title="Кассиры"
          handleClick={()=>data.cashiers_count > 0
            ? navigate(`/profile/promotion/events/${cardId}/cashiers`, {
              state: data,
            })
            : () => {}}
        />

        <Box sx={downloadStyle} onClick={downloadDocument(data.id, ()=>setIsError(true))}>
          <DownloadIcon color="var(--secondary-text)" />

          <Typography
            variant="body2"
            fontWeight={500}
            color={"var(--primary-text)"}
          >
            Скачать таблицу
          </Typography>

          <FileIcon color="var(--secondary-text)" />
        </Box>
      </Box>
    </Box>
  )
  return null
}
