import { FC, PropsWithChildren } from "react"
import { useYandexReactComponents } from "../../../common/yandex/provider/YandexMapProvider"
import { LngLat } from "ymaps3"

export type ContainerPopupCardEventProps = PropsWithChildren<{
  location?: LngLat
  isVisible: boolean
}>
export const ContainerPopupCardEvent: FC<ContainerPopupCardEventProps> = ({
  children,
  location,
  isVisible,
}) => {
  const components = useYandexReactComponents()
  if (!location) return null
  if (!isVisible) return null
  if (!components) return null
  const { YMapMarker } = components.yandexMap

  return (
    <>
      <YMapMarker
        coordinates={location}
        zIndex={YMapMarker?.defaultProps?.zIndex!! + 1_000}
      >
        {children}
      </YMapMarker>
    </>
  )
}
