import LocationIcon from "../../../assets/svg/locationIcon.svg?react"
import CheckBoxTwo from "../../checkbox/checkbox_2"
import LocationSpinner from "./location_spinner"
import s from "./styles.module.css"

const LocationSettings = () => {
  return (
    <div className={s.location_settings_body}>
      <div className={s.location_checkbox_wrapper}>
        <LocationIcon />
        <CheckBoxTwo />
      </div>
      <LocationSpinner />
      <p className="location_description">Ваша локация Ростов-на-Дону</p>
      <button className={s.set_profile_btn}>Редактировать профиль</button>
    </div>
  )
}

export default LocationSettings
