import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { setUserCredentials } from "../../app/data/appTokensStore"
import { persistentStorage } from "../../app/data/persistentStorage"
import * as Api from "../../app/data/tribeSwaggerApi"
import { RootState } from "../../app/store"

const externalStorage = persistentStorage()
export interface AuthorizationState {
  isAuthorization: boolean
}
const authorizationProduce = (
  userId: number | string | undefined | null,
): AuthorizationState => {
  if (userId) {
    return {
      isAuthorization: true,
    }
  } else {
    return {
      isAuthorization: false,
    }
  }
}

export const loginProcess = createAsyncThunk(
  "login/fetch",
  async (request: Promise<Api.TokensDto>, thunkAPI) => {
    try {
      const result = await request
      thunkAPI.dispatch(
        setUserCredentials({
          applicationToken: result.access_token,
          refreshToken: result.refresh_token,
          userId: result.user_id,
        }),
      )
    } catch (e) {
      thunkAPI.rejectWithValue(e)
    }
  },
)
const authorizationSlice = createSlice({
  name: "login/state",
  initialState: () => authorizationProduce(externalStorage.getData().userId),
  reducers: {
    login: (state) => {
      state.isAuthorization = true
    },
    logout: (state) => {
      state.isAuthorization = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginProcess.fulfilled, (state) => {
      state.isAuthorization = true
    })
    builder.addCase(loginProcess.rejected, (state) => {
      state.isAuthorization = false
    })
  },
})
export default authorizationSlice.reducer

export const isAuthorizationSelector = (state: RootState): boolean =>
  state.loginState.isAuthorization
export const { login, logout } = authorizationSlice.actions
