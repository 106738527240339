import Alert from "@mui/material/Alert"
import CircularProgress from "@mui/material/CircularProgress"
import { ChangeEvent, FC, useEffect, useState } from "react"
import ChangeIcon from "../../../../../assets/svg/change.svg?react"
import PhotoIcon from "../../../../../assets/svg/photo.svg?react"
import RemoveIcon from "../../../../../assets/svg/remove.svg?react"
import { useUploadFotoToServer } from "../../../../../hooks/useUploadFotoToServer"
import s from "./styles.module.css"

type TUploadPhotoProps = {
  setPhoto: (imgArrBuf: string, imgDataUrl: string) => void
  photo: string
}

const UploadPhoto: FC<TUploadPhotoProps> = ({ setPhoto, photo }) => {
  const [photoLocal, setPhotoLocal] = useState<string>(photo)
  const [isError, setIsError] = useState(false)
  const { upload, resultUrl, error, isLoading } = useUploadFotoToServer()

  useEffect(() => {
    if (resultUrl) {
      setPhoto(resultUrl, photoLocal)
    }
  }, [resultUrl])

  useEffect(() => {
    if (error) {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 2000)
    }
  }, [error])

  const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null

    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        const result = reader.result as ArrayBuffer
        upload(result)
      }
      reader.readAsArrayBuffer(file)

      const readerLocal = new FileReader()
      readerLocal.onloadend = () => {
        const result = readerLocal.result as string
        setPhotoLocal(result)
      }
      readerLocal.readAsDataURL(file)
    }
  }

  const handleRemovePhoto = () => {
    setPhoto("", "")
  }

  const content = () => {
    if (isLoading) {
      return <CircularProgress />
    }
    if (photo) {
      return (
        <div className={s.img_box}>
          <img src={photo} alt="Uploaded" />
          <div className={s.action}>
            <div className={s.action_item}>
              <input
                type="file"
                className={s.file}
                onChange={handlePhotoChange}
                accept="image/jpeg, image/png, image/jpg"
              />
              <ChangeIcon />
            </div>
            <div className={s.action_item} onClick={handleRemovePhoto}>
              <RemoveIcon />
            </div>
          </div>
        </div>
      )
    }

    if (isError) {
      return (
        <Alert severity="error" className={s.error}>
          Произошла ошибка
        </Alert>
      )
    }

    return (
      <div className={s.added_img}>
        <PhotoIcon />
      </div>
    )
  }

  return (
    <div
      className={s.item_content}
      style={{
        background: photo ? "" : "var(--promo-card-bg)",
      }}
    >
      <input
        type="file"
        disabled={!!photo}
        className={s.file}
        onChange={handlePhotoChange}
        accept="image/*, .png, .jpg"
      />
      {content()}
    </div>
  )
}

export default UploadPhoto
