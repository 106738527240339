import { Avatar, Box, Typography } from "@mui/material"
import { FC } from "react"
import GradientBtn from "../../../../components/GradientBtn"
import GradientTextBtn from "../../../../components/GradientTextBtn"
import { Action } from "../index"
import * as s from "./style"
import { PotentialPerson } from "../../../../app/data/promotion/promotionApi"

type Props = {
  handleClickAction: (action: Action) => void
  person: PotentialPerson | null
  btnTitle: string
}

const SubmitAddPerson: FC<Props> = ({
  person,
  handleClickAction,
  btnTitle,
}) => (
  <>
    <Box sx={s.contentCont}>
      <Avatar alt={person?.username} src={person?.user_avatar} sx={s.avatar} />

      <Box sx={s.contactInfoStyle}>
        <Typography variant="body1" fontWeight={500}>
          {person?.username}
        </Typography>
      </Box>

      {person?.first_name && person.last_name && (
        <Box sx={s.contactInfoStyle}>
          <Typography variant="body1" fontWeight={500}>
            {`${person.first_name} ${person.last_name}`}
          </Typography>
        </Box>
      )}
    </Box>

    <Box sx={s.btnCont}>
      <GradientTextBtn
        title="Назад"
        handleAction={() => handleClickAction("left")}
        btnStyle={{ ...s.btnBack, minWidth: "120px" }}
      />

      <GradientBtn
        contStyle={{ padding: "0", display: "flex", justifyContent: "end" }}
        title={btnTitle}
        handleAction={() => handleClickAction("submit")}
        btnStyle={s.btnSubmit}
      />
    </Box>
  </>
)

export default SubmitAddPerson
