import { SxProps, Theme } from "@mui/material"
import { CSSProperties } from "react"

export const fidWrapper = {
  display: "flex",
  width: "100%",
}

export const fidContent = {
  width: "100%",
}

export const newEvent: SxProps<Theme> = {
  display: "none",

  "@media (min-width: 768px)": {
    display: "block",
    padding: "0px 16px",
    placeItems: "center",
    height: "36px",
  },
}

export const tabsContainer: SxProps<Theme> = {
  display: "flex",
  gap: "3px",
  width: "100%",
  justifyContent: "end",

  "@media (min-width: 321px)": {
    gap: "8px",
  },

  "@media (min-width: 768px)": {
    justifyContent: "start",
    gap: "8px",
  },
}

export const feedCont = {
  display: "flex",
  justifyContent: "space-between",
  padding: "8px",
  position: "relative",

  "@media (min-width: 638px)": {
    padding: "8px 32px",
    columnGap: "8px",
  },
}

// для фильтра моб версия
export const filterStyle = {
  position: "absolute",
  left: "8px",
  zIndex: 1,

  "@media (min-width: 638px)": {
    padding: "8px 32px",
  },
}

export const btnCreateEventCont = { display: "flex", flexShrink: 0 }

export const btnsTabsCont = {
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
  width: "100%",
}

export const filterForMapPage: CSSProperties = {
  position: "absolute",
  zIndex: 1000,
  transition: "all 0.3s ease",
}

export const filterIconCont: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  width: "40px",
  height: "38px",
  border: "1px solid var(--borders)",
  borderRadius: "8px",
  padding: "0 8px",
  position: "relative",
  transition: "all 0.3s ease",
  cursor: "pointer",

  "&:hover": {
    background: "var(--btns-hover)",
  },
}
