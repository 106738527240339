import { Badge, Box } from "@mui/material"
import React, { FC, ReactElement } from "react"

import { useNavigate } from "react-router-dom"
import { MAX_VALUE_OF_NOTIFICATION_ON_THE_BELL } from "../../../../consts"
import { notificationData } from "../../../../pages/notification/domain/notificationSlice"
import { iconBtns } from "../HeaderIcons/headerIconsStyle"
import { HeaderBtnName } from "./enum"
import { iconQntyCircle } from "./iconWrapperStyles"

type Props = {
  icon: ReactElement
  setActiveBtn: React.Dispatch<React.SetStateAction<HeaderBtnName | null>>
  handleBtnClick?: (event: React.MouseEvent<HTMLElement> | null) => void
  activeBtn: HeaderBtnName | null
  title: HeaderBtnName
}

const HeaderIconsWrapper: FC<Props> = ({
  icon,
  handleBtnClick,
  setActiveBtn,
  activeBtn,
  title,
}) => {
  const styledIcon = React.cloneElement(icon, { sx: { ...iconBtns } })
  const navigate = useNavigate()

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setActiveBtn((prev: HeaderBtnName | null) =>
      prev === title ? null : title,
    )

    switch (title) {
      case HeaderBtnName.mail:
        navigate("/chat")
        handleBtnClick && handleBtnClick(null)
        return
      case HeaderBtnName.ntfcn:
        handleBtnClick && handleBtnClick(e)
        return
      case HeaderBtnName.profile:
        handleBtnClick && handleBtnClick(e)
        return
      default:
        break
    }
  }

  return (
    <Box
      sx={iconBtns}
      onClick={handleClick}
      style={{
        backgroundImage: activeBtn === title ? "var(--btn-gradient)" : "none",
      }}
    >
      {HeaderBtnName.profile !== title ? (
        <Badge
          overlap="circular"
          badgeContent={notificationData.length}
          max={MAX_VALUE_OF_NOTIFICATION_ON_THE_BELL}
          sx={iconQntyCircle}
        >
          {styledIcon}
        </Badge>
      ) : (
        styledIcon
      )}
    </Box>
  )
}

export default HeaderIconsWrapper
