import { FC } from "react"
import { Box } from "@mui/material"
import { CategoryInfo } from "../../filtered/types"
import { categoryTab } from "../categoryTabsStyles"
import style from "../styles.module.css"

export type CategoryItemProps = {
  category: CategoryInfo
  click: (value: number) => void
  isSelected: boolean
}
const CategoryItem: FC<CategoryItemProps> = (props) => {
  const { category: cat, isSelected } = props

  return (
    <Box
      onClick={() => props.click(cat.id)}
      sx={categoryTab}
      style={{
        filter: `${isSelected ? "none" : "grayscale(100%)"}`,
      }}
    >
      <picture>
        <img
          className={style.category_card}
          src={cat.url}
          srcSet={cat.srcset}
          alt={cat.title}
        />
      </picture>
    </Box>
  )
}
export default CategoryItem
