import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Avatar } from "@mui/material"

import { useProfileSettingEditor } from "../hooks/useProfileSetting"
import ClearInputIcon from "../../../assets/svg/menu-icons-pack/close-round-icon-menu.svg?react"
import AvatarFallback from "../../../assets/svg/menu-icons-pack/avatar.svg?react"
import { userAvatar } from "./profileAndViewStyle"
import s from "./styles.module.scss"

export interface FormValues {
  username: string
  firstName?: string
  lastName?: string
  birthday?: string
}
const defaultFormValues: FormValues = {
  username: "",
  firstName: "",
  lastName: "",
  birthday: "",
}
const ProfileAndView = () => {
  const { setValue, handleSubmit, register, resetField } = useForm<FormValues>({
    defaultValues: defaultFormValues,
  })

  const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1) //Имя и фамилия с заглавной буквы
  const maxBirthday = new Date().toISOString().slice(0, 10) //ДР не позже, чем сегодня
  const minBirthday = new Date(Date.now() - 120 * 365.25 * 24 * 60 * 60 * 1000) //ДР не раньше, чем 120 лет назад
    .toISOString()
    .slice(0, 10)

  const onSubmit = ({ username, firstName, lastName, birthday }: FormValues) =>
    updateProfile({
      username,
      first_name: firstName ? capitalize(firstName) : undefined,
      last_name: lastName ? capitalize(lastName) : undefined,
      birthday,
    })

  const {
    updateProfile,
    isLoading,
    error,
    currentUser: state,
  } = useProfileSettingEditor()

  useEffect(() => {
    if (state) {
      setValue("firstName", state.first_name)
      setValue("lastName", state.last_name)
      setValue("username", state.username)
      setValue("birthday", state.birthday)
      console.log("user state:", state) //TODO: временно => не забыть убрать
    }
  }, [state])

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>{error.error}</div>
  if (state) {
    return (
      <div className={s.user}>
        <Avatar src={state.avatar_url} sx={userAvatar}>
          <AvatarFallback className={s.user_avatar} />
        </Avatar>
        <div className={s.user_username}>
          {`@${state.username}`}
          <span></span>
        </div>
        <div className={s.user_fullname}>
          {state?.first_name && state?.last_name
            ? `${state.first_name} ${state.last_name}`
            : ""}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          name="profileSettingsForm"
          className={s.form}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        >
          <div className={s.form__item}>
            <input
              placeholder="Логин"
              className={s.form__input}
              spellCheck="false"
              {...register("username")}
              maxLength={50}
              required
            />
            <button
              className={s.form__input_clear}
              tabIndex={-1}
              onClick={() => {
                resetField("username")
              }}
            >
              <ClearInputIcon />
            </button>
          </div>
          <div className={s.form__item}>
            <input
              placeholder="Имя"
              className={s.form__input}
              maxLength={50}
              required //TODO: временно => убрать
              {...register("firstName")}
            />
            <button
              className={s.form__input_clear}
              tabIndex={-1}
              onClick={() => {
                resetField("firstName")
              }}
            >
              <ClearInputIcon />
            </button>
          </div>
          <div className={s.form__item}>
            <input
              placeholder="Фамилия"
              className={s.form__input}
              {...register("lastName")}
              maxLength={50}
              required //TODO: временно => убрать
            />
            <button
              className={s.form__input_clear}
              tabIndex={-1}
              onClick={() => {
                resetField("lastName")
              }}
            >
              <ClearInputIcon />
            </button>
          </div>
          <div className={s.form__item}>
            <input
              type="date"
              min={minBirthday}
              max={maxBirthday}
              className={s.form__input}
              {...register("birthday")}
              required //TODO: временно => убрать
              maxLength={10}
            />
            <button
              className={s.form__input_clear}
              tabIndex={-1}
              onClick={() => {
                resetField("birthday")
              }}
            >
              <ClearInputIcon />
            </button>
          </div>
          <button
            className={
              s.form__btn_save
            } /*TODO: сделать кнопку неактивной, если обязательные поля не заполнены*/
          >
            Сохранить изменения
          </button>
        </form>
        <button
          className={s.user__delete}
          onClick={() => console.log("Аккаунт удален!! 😂")} //TODO: временно => доделать, в т.ч. модалку "вы уверены?"
        >
          удалить аккаунт
        </button>
      </div>
    )
  }
  return null
}

export default ProfileAndView
