import { swaggerSplitApi as api } from "../swaggerApi"
import { buildQuery } from "./QueryBuilder"
import { EventsSearchArg, Query } from "./schema"

import {
  EventInfo,
  SearchEventsData
} from "../../../shared/events-feed/types";
import { SearchEventInfo } from "./schemas/eventsInfoSchema";
import { searchFilteredEventsTransform } from "./tranforms";
import { searchFilteredEventsQuery } from "./Querys";
import { FilterType } from "../../../shared/filter/types";
import { setTotalElement } from "../../../shared/feed-pagination/paginationSlice";


export const injectedEventsApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    searchEventsOpt: build.query<SearchEventsData<EventInfo>, EventsSearchArg>({
      query(arg) {
        const { page, size, filter } = arg
        const url = `/api/v1/events/search`
        return queryBuilder(size, page, url, filter)
      },
      transformResponse: (response: SearchEventsData<EventInfo>): SearchEventsData<EventInfo> => {
        return {
          ...response,
          content: response.content.map((item) => {
            if (item.avatarUrl) {
              const { avatarUrl: avatars } = item
              item.avatarUrl = avatars.map(mapAvatars)
              return item
            } else {
              return item
            }
          }),
        }
      },
    }),
    searchEvents: build.mutation<SearchEventsData<EventInfo>, EventsSearchArg>({
      query(arg) {
        const { page, size, filter } = arg
        const url = `/api/v1/events/search`
        return queryBuilder(size, page, url, filter)
      },
      transformResponse: (response: SearchEventsData<EventInfo>): SearchEventsData<EventInfo> => {
        return {
          ...response,
          content: response.content.map((item) => {
            if (item.avatarUrl) {
              const { avatarUrl: avatars } = item
              item.avatarUrl = avatars.map(mapAvatars)
              return item
            } else {
              return item
            }
          }),
        }
      },
    }),
    searchFilteredEvents: build.query<SearchEventsData<SearchEventInfo>, EventsSearchArg>({
      query: searchFilteredEventsQuery,
      transformResponse: searchFilteredEventsTransform,
      onQueryStarted: async (arg: EventsSearchArg, {dispatch, queryFulfilled}) => {
        const {data} = await queryFulfilled
        const {totalElements} = data.metadata
        dispatch(setTotalElement(totalElements))
      }
    }),

  }),
})

const mapAvatars = (avatars: string): string => {
  return `${import.meta.env.VITE_AVATAR_URL}/v1/events/avatars/${avatars}`
}

export const queryBuilder = (
  size: number,
  page: number,
  url: string,
  filter: FilterType | undefined,
): Query => {
  if (filter) {
    const query = buildQuery(filter)
    return {
      url: url + `?${query}`,
      method: "GET",
      params: {
        page: page,
        size: size,
      },
    }
  } else {
    return {
      url: url,
      method: "GET",
      params: {
        page: page,
        size: size,
      },
    }
  }
}
export const { useLazySearchFilteredEventsQuery,
  useSearchEventsMutation,
  useSearchEventsOptQuery
} =  injectedEventsApi
