import React, { useEffect, useRef, useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import s from "./styles.module.css"
import { mock } from "./mock"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { Avatar } from "@mui/material"
import classNames from "classnames"
import Search from "../../assets/svg/search.svg?react"
import Pin from "../../assets/svg/pin.svg?react"
import Emoji from "../../assets/svg/pin_emoji.svg?react"
import Send from "../../assets/svg/send_message.svg?react"
import Item from "./item"
import img2 from "../../assets/image/chat6.png"
import { ChatUserModel } from "./DataType"
import { ChatMode } from "../../types"

interface ChatUserProps {
  mode: ChatMode
}

const ChatUser = ({ mode }: ChatUserProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { id } = useParams() //TODO(Разобратья с параметром)
  const [value, setValue] = useState("")
  const [currentChat, setCurrentChat] = useState<ChatUserModel>(mock[1])
  // const currentChat = mock[id]

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [currentChat])

  return (
    <div className={mode === ChatMode.Mobile ? s.main : s.main_web}>
      {/* Шапка чата */}
      {mode === ChatMode.Mobile && (
        <div className={s.header}>
          <div className={s.header_left}>
            <NavLink to={"/chat"} className={s.back}>
              <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
            </NavLink>
            <div className={s.header_content}>
              <div className={s.avatar}>
                <Avatar
                  src={currentChat?.img}
                  sx={{ width: "50px", height: "50px" }}
                />
              </div>
              <div className={s.info_user}>
                <p
                  className={classNames(
                    s.nickname,
                    currentChat.isOnline && s.nickname_before,
                  )}
                >
                  {currentChat.nickname}
                </p>
                <p className={s.fullname}>{currentChat.fullname}</p>
              </div>
            </div>
          </div>
          <div className={s.search}>
            <Search />
          </div>
        </div>
      )}
      {/* Все сообщения */}
      <div className={s.content} ref={ref}>
        {currentChat.chats.map((el, i) => (
          <Item
            key={i}
            {...el}
            mode={mode === ChatMode.Mobile ? ChatMode.Mobile : ChatMode.Web}
          />
        ))}
      </div>
      {/* Поле ввода текста */}
      {mode === ChatMode.Mobile && (
        <div className={s.action}>
          <div className={s.input_box}>
            <input
              type="text"
              className={s.input}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <div className={s.pin}>
              <Emoji />
            </div>
            <div className={s.pin}>
              <Pin />
            </div>
          </div>
          {/* Кнопка отправить сообщение */}
          <div
            className={s.btn}
            onClick={() => {
              setCurrentChat({
                ...currentChat,
                chats: [
                  ...currentChat.chats,
                  {
                    type: 0,
                    time: "19:23",
                    isRead: false,
                    avatar: img2,
                    text: value,
                  },
                ],
              })
              setValue("")
            }}
          >
            <Send />
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatUser
