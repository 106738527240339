import { useEffect } from "react"

const useWheelScroll = (containerRef: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault()
      const container = containerRef.current
      if (container) {
        container.scrollTo({
          left: (container.scrollLeft += event.deltaY),
          behavior: "smooth",
        })
      }
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false }) // Disable passive listener
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel)
      }
    }
  }, [])
}

export default useWheelScroll
