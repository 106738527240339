import { CSSProperties } from "react"

export const content: CSSProperties = {
  width: "100%",
  background: "var(--surface-color)",
  border: "1px solid var(--outline-color)",
  color: "var(--primary-text)",
}

export const wrapper: CSSProperties = {
  display: "flex",
  width: "100%",
}

export const contentBox: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
