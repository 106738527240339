import type { SVGProps } from "react"

export function ChatIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="var(--footer-icons)"
        fillOpacity={0}
        fillRule="evenodd"
        d="M19.326 5.778C20 6.787 20 8.19 20 11c0 2.809 0 4.213-.674 5.222a4.003 4.003 0 0 1-1.104 1.104c-.881.589-2.064.663-4.222.673V18l-1.106 2.211a1 1 0 0 1-1.788 0L10 18v-.001c-2.158-.01-3.34-.084-4.222-.673a4.002 4.002 0 0 1-1.104-1.104C4 15.213 4 13.81 4 11c0-2.809 0-4.213.674-5.222a4 4 0 0 1 1.104-1.104C6.787 4 8.19 4 11 4h2c2.809 0 4.213 0 5.222.674a4 4 0 0 1 1.104 1.104"
        clipRule="evenodd"
      ></path>
      <path
        fill="var(--footer-icons)"
        d="m19.326 5.778l-.499.333zm0 10.444l-.499-.333zm-1.104 1.104l-.333-.499zM14 17.999l-.003-.6a.6.6 0 0 0-.597.6zM14 18l.537.268A.6.6 0 0 0 14.6 18zm-4 0h-.6a.6.6 0 0 0 .063.268zm0-.001h.6a.6.6 0 0 0-.597-.6zm-4.222-.673l.333-.499zm-1.104-1.104l.499-.333zm0-10.444l.499.333zm1.104-1.104l.333.499zm12.444 0l-.333.499zM20.6 11c0-1.392 0-2.481-.087-3.347c-.09-.876-.274-1.59-.688-2.209l-.998.667c.26.39.412.883.492 1.664c.08.79.081 1.808.081 3.225zm-.775 5.556c.414-.62.599-1.333.688-2.21c.088-.865.087-1.954.087-3.346h-1.2c0 1.417 0 2.435-.081 3.225c-.08.781-.232 1.274-.492 1.664zm-1.27 1.269a4.6 4.6 0 0 0 1.27-1.27l-.998-.666a3.4 3.4 0 0 1-.938.938zm-4.552.774c1.076-.005 1.948-.026 2.669-.125c.73-.101 1.343-.288 1.884-.65l-.667-.997c-.34.227-.76.372-1.381.458c-.631.087-1.429.109-2.51.114zM14.6 18v-.001h-1.2V18zm-1.169 2.48l1.106-2.212l-1.074-.536l-1.105 2.21zm-2.862 0c.59 1.179 2.272 1.179 2.862 0l-1.073-.537a.4.4 0 0 1-.716 0zm-1.106-2.212l1.106 2.212l1.073-.537l-1.105-2.211zm-.063-.27V18h1.2v-.001zm-3.956-.173c.541.361 1.154.548 1.884.649c.72.1 1.593.12 2.67.125l.005-1.2c-1.082-.005-1.88-.027-2.51-.114c-.622-.086-1.042-.23-1.382-.458zm-1.269-1.27a4.6 4.6 0 0 0 1.27 1.27l.666-.998a3.4 3.4 0 0 1-.938-.938zM3.4 11c0 1.392 0 2.481.087 3.347c.09.876.274 1.59.688 2.209l.998-.667c-.26-.39-.412-.883-.492-1.664c-.08-.79-.081-1.808-.081-3.225zm.775-5.556c-.414.62-.599 1.333-.688 2.21C3.4 8.518 3.4 9.607 3.4 11h1.2c0-1.417 0-2.434.081-3.225c.08-.781.232-1.275.492-1.664zm1.27-1.269a4.6 4.6 0 0 0-1.27 1.27l.998.666a3.4 3.4 0 0 1 .938-.938zM11 3.4c-1.392 0-2.481 0-3.347.087c-.876.09-1.59.274-2.209.688l.667.998c.39-.26.883-.412 1.664-.492c.79-.08 1.808-.081 3.225-.081zm2 0h-2v1.2h2zm5.556.775c-.62-.414-1.333-.599-2.21-.688C15.482 3.4 14.393 3.4 13 3.4v1.2c1.417 0 2.435 0 3.225.081c.781.08 1.274.232 1.664.492zm1.269 1.27a4.6 4.6 0 0 0-1.27-1.27l-.666.998a3.4 3.4 0 0 1 .938.938z"
      ></path>
      <circle
        cx={16}
        cy={11}
        r={1}
        fill="var(--footer-icons)"
        stroke="var(--footer-icons)"
        strokeLinecap="round"
        strokeWidth={0.2}
      ></circle>
      <circle
        cx={12}
        cy={11}
        r={1}
        fill="var(--footer-icons)"
        stroke="var(--footer-icons)"
        strokeLinecap="round"
        strokeWidth={0.2}
      ></circle>
      <circle
        cx={8}
        cy={11}
        r={1}
        fill="var(--footer-icons)"
        stroke="var(--footer-icons)"
        strokeLinecap="round"
        strokeWidth={0.2}
      ></circle>
    </svg>
  )
}
