import { RootState } from "../../app/store"
import { FilterType } from "./types";
import { createSelector } from "@reduxjs/toolkit";

export const selectCurrentTypeIds = (state: RootState): number[] | undefined => state.filter.eventTypeId
export const selectQueryText = (state: RootState): string | undefined => state.filter.text
export const selectFilter = (state:RootState): FilterType => state.filter

export const selectDateRange = createSelector(
  (state: RootState) => state.filter.startDate,
  (state: RootState) => state.filter.endDate,
  (startDate, endDate) => [startDate, endDate],
)

export const selectParticipants = createSelector(
  (state: RootState) => state.filter.numberOfParticipantsMin,
  (state: RootState) => state.filter.numberOfParticipantsMax,
  (min, max) => [min, max],
)

export const selectDuration = createSelector(
  (state: RootState) => state.filter.durationEventInHoursMin,
  (state: RootState) => state.filter.numberOfParticipantsMax,
  (min, max) => [min, max],
)