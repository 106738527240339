import { FC } from "react"
import { NavLink, useLocation } from "react-router-dom"
import classNames from "classnames"

import { useAppDispatch } from "../../app/hooks"
import { useIsAuthorizedUser } from "../../hooks/useIsAuthorizedUser"
import { show } from "../../pages/signIn/SignInSlice"
import { show as showEvent } from "../../pages/makeEvent/slice/MakeEventSlice"
import s from "./styles.module.scss"

import { CreateIcon } from "../../assets/svg/footer-nav-icons-pack/CreateIcon"
import { UserIcon } from "../../assets/svg/footer-nav-icons-pack/UserIcon"
import { FavoriteIcon } from "../../assets/svg/footer-nav-icons-pack/FavoriteIcon"
import { ChatIcon } from "../../assets/svg/footer-nav-icons-pack/ChatIcon"
import { HomeIcon } from "../../assets/svg/footer-nav-icons-pack/HomeIcon"

const Footer: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isAuth = useIsAuthorizedUser()

  const createEventHandler = isAuth
    ? () => dispatch(showEvent())
    : () => dispatch(show())

  const footerNavLinks = [
    { to: "/profile", icon: UserIcon, text: "Профиль" },
    { to: "/favorite", icon: FavoriteIcon, text: "Избранное" },
    {
      to: "/create",
      icon: CreateIcon,
      text: "Создать",
      onClick: createEventHandler,
    },
    { to: "/chat", icon: ChatIcon, text: "Чат" },
    { to: "/", icon: HomeIcon, text: "Главная" },
  ]

  return (
    <nav className={s.footerContainer}>
      <ul className={s.footerNavList}>
        {footerNavLinks.map(({ to, icon: Icon, text, onClick }, i) => (
          <li
            key={i}
            className={classNames(
              s.footerNavItem,
              location.pathname === to && s.active,
            )}
          >
            {to === "/create" ? (
              <button onClick={onClick} className={s.footerNavBtn}>
                <Icon
                  style={{ fill: "#fff", transform: "translateY(-11px)" }}
                />
                <span
                  style={{
                    opacity: 1,
                    color: "#fff",
                    transform: "translateY(26px)",
                  }}
                >
                  {text}
                </span>
              </button>
            ) : (
              <NavLink to={to}>
                <Icon />
                <span>{text}</span>
              </NavLink>
            )}
          </li>
        ))}
        <div className={s.footerNavIndicator} />
        <div className={s.footerBtnIndicator} />
      </ul>
    </nav>
  )
}

export default Footer
