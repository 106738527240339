import { Box, SxProps, Theme, Typography } from "@mui/material"
import { useState } from "react"
import { transformDate } from "../../../../shared/transformDate/transformDate"
import CalendarIcon from "../../../../assets/svg/card-icons-pack/calendar-icon.svg?react"
import TribeLogo from "../../../../assets/svg/profile/logo-icon.svg?react"
import * as s from "./style"

type PromoEventCardProps = {
  img: string
  title: string
  date: string
  contStyle?: SxProps<Theme>
}

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

export default function PromoEventCard({
  img,
  title,
  date,
  contStyle,
}: PromoEventCardProps) {
  const containerStyle = { ...s.promoEventCardCont, ...contStyle }
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false)
  const handleImageError = () => setImageFailedToLoad(true)

  return (
    <Box sx={containerStyle}>
      <Box sx={s.promoEventCardImage}>
        <img
          src={img}
          alt={title}
          onError={handleImageError}
          style={{ display: imageFailedToLoad ? "none" : undefined }}
        />
        {imageFailedToLoad && <TribeLogo />}
      </Box>
      <Box sx={s.promoEventCardText}>
        <Typography
          variant="body2"
          color="var(--primary-text)"
          fontWeight={500}
        >
          {capitalize(title)}
        </Typography>
        <Box sx={s.promoEventCardDescr}>
          <CalendarIcon stroke={s.promonEventCardIcon} />
          <Typography
            variant="body2"
            fontWeight={500}
            color="var(--secondary-text)"
          >
            {transformDate(date)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
