import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const notificationList: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "360px",
  gap: "24px",
  position: "relative",
  padding: "0",
}

export const notificationListItem: SxProps<Theme> = {
  gap: "8px",
  maxWidth: "100%",
  height: "40px",
}

export const notificationListButton: SxProps<Theme> = {
  gap: "8px",
  display: "grid",
  gridTemplateColumns: "24px 1fr 24px",
  maxWidth: "100%",
  height: "100%",
  width: "100%",
  padding: "0 16px",
  alignItems: "center",
  borderRadius: "8px",

  "&:hover": {
    color: "var(--filter-range-active)",
    backgroundColor: "var(--settings-tab-hover)",
    svg: {
      color: "var(--filter-range-active)",
    },
  },
  "&:active": {
    backgroundColor: "var(--settings-tab-hover)",
  },
  span: {
    padding: "0",
  },
}
