import { FC } from "react"

export type ErrorPageProps = {
  message: string
}
export const ErrorPage: FC<ErrorPageProps> = ({ message }) => {
  return (
    <div>
      <p>{message}</p>
    </div>
  )
}
