import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import HeaderMenu from ".."
import { handleVisiblity as handleVisibility } from "../../headerNavStyles"
import { menuBtn } from "./menuButtonStyle"
import menuClose from "/src/assets/svg/header-icons-pack/close-menu.svg"
import menuIcon from "/src/assets/svg/header-icons-pack/menu-icon.svg"

export const MenuButton = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100vh"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
  }, [isOpenMenu])

  return (
    <Box
      sx={{
        ...menuBtn,
        display: pathname.startsWith("/profile/promotion") ? "none" : "block",
      }}
      onClick={() => setIsOpenMenu((prev) => !prev)}
    >
      <img
        src={menuIcon}
        alt="Открыть меню"
        style={handleVisibility("open", isOpenMenu)}
      />

      <img
        src={menuClose}
        alt="Закрыть меню"
        style={handleVisibility("close", isOpenMenu)}
      />

      <HeaderMenu isOpenMenu={isOpenMenu} />
    </Box>
  )
}
