import BtnClose from "../../../assets/svg/close.svg?react"
import s from "./styles.module.scss"
import SearchUser from "./views/search_user"
import AddNewUser from "./views/add_new_user"
import SendQr from "./views/send_qr"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setPromotionPopup } from "../../../app/data/popupSlice/popupSlice"
import { useEffect, useState } from "react"
import AddUserAccess from "./views/add_user_access"
import { useAddNewPotentialParticipant, useCreateTicketForParticipant, useGetEventInvited, useLazyGetEventInvited, UserDataForAddToBaseParticipant } from "../../../app/data/promotion/promotionApi"
import LocationSpinner from "../../profile_settings/location/location_spinner"

export type PotentiaParticipantUserPreData = {
	'firstname': string
	'lastname': string
	'phone_number': string
	'telegram_username': string
}

export enum ViewsNames {
	SendQr = 'SendQr',
	SearchUser = 'SearchUser',
	AddNewUser = 'AddNewUser',
	AccessNewUser = 'AccessNewUser'
}

const AddToBaseModalTitles = {
	'SendQr': 'Отправить QR-код',
	'AddNewUser' : 'Добавить человека в базу',
	'SearchUser': 'Поиск участников на:',
	'AccessNewUser': 'Подтвердите добавление'
}

const AddToBaseModal = () => {
	const currentEventData = useAppSelector((state) => state.popupsState.promotionPopup)
	const dispatch = useAppDispatch()
	const [addParticipant, {isSuccess: addedPaticipantSuccess, isLoading: addedPaticipantLoad, data: addedPaticipantId}] = useAddNewPotentialParticipant()
	const [getTicketUrl, {isSuccess: ticketSuccess, isLoading: ticketIsLoad, data: ticketUrl}] = useCreateTicketForParticipant()
	const [view, setView] = useState<ViewsNames>(ViewsNames.SearchUser)
	const [addedUser, setAddedUser] = 
		useState<UserDataForAddToBaseParticipant>({firstname: '', lastname: '', 
			phone_number: '', telegram_username: '' , event_id: currentEventData?.id  as number})
	
	useEffect(() => {
				if (addedPaticipantSuccess) {
 					getTicketUrl({
						event_id: addedUser.event_id,
						invited_via_telegram: true,
						invited_via_whatsapp: true,
						potential_participant_id: addedPaticipantId
					}) 
					setAddedUser({
						...addedUser,
						event_id: 0,
						firstname: '',
						lastname: '',
						phone_number: '',
						telegram_username: ''
					})
				}
			}, [addedPaticipantId])

	const handleSetAddedUser = 
	(currentKey: keyof PotentiaParticipantUserPreData, targetValue: string) => 
		setAddedUser({...addedUser, [currentKey]: targetValue})

	const handleSetView = (typeofView: ViewsNames) => {
		setView(typeofView)
	}



	const onSubmitAddParticipant =(participantData: UserDataForAddToBaseParticipant) => {
		addParticipant({...participantData, phone_number: addedUser.phone_number.slice(1)})
		setAddedUser({...addedUser, event_id: currentEventData?.id as number})
	}

	const renderCurrentView = () => 
		view === ViewsNames.SearchUser ? <SearchUser eventId={currentEventData?.id as number} onSetVeiew={handleSetView}/> : 
		view === ViewsNames.AddNewUser ? <AddNewUser onChangeSetPhone={writePhoneNumber} userData={addedUser} onWriteUserData={handleSetAddedUser} onSetVeiew={handleSetView}/> :
		view === ViewsNames.SendQr && ticketSuccess ? <SendQr ticket={ticketUrl} userData={addedUser} onSetView={handleSetView}/> :
		<AddUserAccess addParticipantStatus={addedPaticipantSuccess} onAddParticipant={onSubmitAddParticipant} onSetView={handleSetView} userData={{...addedUser, event_id: currentEventData?.id as number}}/>
	
	const checkIsLoading = () => 
		addedPaticipantLoad || ticketIsLoad ? 
		<LocationSpinner></LocationSpinner> :
		renderCurrentView()
	
	const writePhoneNumber = (phoneNumber: string) => {
		setAddedUser({...addedUser, phone_number: phoneNumber})
	}

	const renderSubtitle = () => 
		view === 'SearchUser' ? currentEventData?.title : 
		view === 'AddNewUser' ? 'Введите данные' : null


	const handleClosedPopup = () => {
		dispatch(setPromotionPopup(null))
		setView(ViewsNames.SearchUser)
	}

	return (
		<div className={s.add_to_base} style={{display: currentEventData ? "flex" : "none"}}>
			 	<div className={s.add_to_base_body}>
				<div className={s.add_to_base_header}>
						<BtnClose className={s.btn_closed} onClick={() => handleClosedPopup()}/>
						<div className={s.add_to_base_main_text}>
						<h2 className={s.add_to_base_title}>{AddToBaseModalTitles[view]}</h2>
						{
							renderSubtitle() &&
							<p className={s.add_to_base_description}>{renderSubtitle()}</p>
						}						
						</div>
					</div>
					{
						checkIsLoading()
					}
				</div> 
		</div>
	)
}

export default AddToBaseModal