import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"
import { CSSProperties } from "react"
import "/src/theme/colors.css"

export const selectCont: CSSProperties = {
  position: "relative",

  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "var(--secondary-text)",
  transition: "all 0.3s ease",
}

export const selectBtn: CSSProperties = {
  width: "148px",
  height: "36px",
  padding: "8px 21px 8px 12px",

  position: "relative",

  border: "1px solid var(--borders)",
  borderRadius: "8px",
}

export const selectIcon: CSSProperties = {
  position: "absolute",
  top: "14px",
  right: "8px",
}

export const selectedIcon: CSSProperties = {
  ...selectIcon,
  top: "12px",
}

export const dropDownCont: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  width: "100%",

  backgroundColor: "var(--main-bg)",
  border: "1px solid var(--borders)",
  borderRadius: "8px",

  position: "absolute",
  top: "calc(100% + 2px)",
  zIndex: "23442",
}

export const selectItems: SxProps<Theme> = {
  padding: "8px 21px 8px 12px",
  cursor: "pointer",
  position: "relative",
  transition: "background 0.3s ease",
  borderRadius: "8px",

  "&:hover": {
    background: "var(--btns-hover)",
    borderRadius: "8px",
  },
}

export const selectActive: SxProps<Theme> = {
  background: "var(--btns-selected)",

  "&:hover": {
    background: "var(--btns-selected)",
  },
}

export const visible: CSSProperties = {
  visibility: "visible",
  opacity: "1",
  transition: "opacity 0.3s ease",
}

export const invisible: CSSProperties = {
  visibility: "hidden",
  opacity: "0",
  transition: "visibility 0s 0.3s, opacity 0.3s ease",
}

export const handleVisibleStyle = (isOpen: boolean) => ({
  visibility: isOpen ? "visible" : "hidden",
  opacity: isOpen ? 1 : 0,
  transition: "all 0.3s ease",
})
