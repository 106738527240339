import type { SVGProps } from "react"

export function TimeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" stroke="currentColor" strokeWidth={1.2}>
        <circle
          cx={12}
          cy={12}
          r={8.6}
          fill="currentColor"
          fillOpacity={0}
        ></circle>
        <path
          strokeLinecap="round"
          d="M16.5 12h-4.25a.25.25 0 0 1-.25-.25V8.5"
        ></path>
      </g>
    </svg>
  )
}
