import sub1 from "../../assets/image/sub1.png"
import sub2 from "../../assets/image/sub2.png"
import sub3 from "../../assets/image/sub3.png"
import sub4 from "../../assets/image/sub4.png"
import sub5 from "../../assets/image/sub5.png"
import { SubscriberItemModel } from "./SubscriberItemModel"

export const mock: SubscriberItemModel[] = [
  {
    isSubscribe: true,
    id_chat: 1,
    img: sub1,
    nikname: "@BabushkaBoy",
    fullname: "Петя Евгеньевна",
    isOnline: true,
  },
  {
    isSubscribe: false,
    img: sub2,
    nikname: "@BabushkaBoy",
    fullname: "Петя Евгеньевна",
    isOnline: true,
  },
  {
    isSubscribe: true,
    id_chat: 2,
    img: sub3,
    nikname: "@BabushkaBoy",
    fullname: "Петя Евгеньевна",
    isOnline: false,
  },
  {
    isSubscribe: false,
    img: sub4,
    nikname: "@BabushkaBoy",
    fullname: "Петя Евгеньевна",
    isOnline: true,
  },
  {
    isSubscribe: false,
    img: sub5,
    nikname: "@BabushkaBoy",
    fullname: "Петя Евгеньевна",
    isOnline: true,
  },
  {
    isSubscribe: true,
    id_chat: 1,
    img: sub3,
    nikname: "@BabushkaBoy",
    fullname: "Петя Евгеньевна",
    isOnline: false,
  },
  {
    isSubscribe: true,
    id_chat: 2,
    img: sub2,
    nikname: "@BabushkaBoy",
    fullname: "Петя Евгеньевна",
    isOnline: false,
  },
]
