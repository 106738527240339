import s from "./styles.module.scss"
import { EventsCategoryActions,  } from "../../hooks/profile_page_hooks/useProfileEventFieldData"
import { useAppSelector } from "../../app/hooks"

type ProfileEventsCheckboxProps = {
  onSetEventType: (evtType: EventsCategoryActions) => void
  chekedType: EventsCategoryActions
}

const ProfileEventsCheckbox = ({onSetEventType, chekedType}: ProfileEventsCheckboxProps) => {
  const isPromoter = useAppSelector(state => state.userProfileState.info.isPromoter_or_Cashier)
  return (
    <div className={s.events}>
      <div className={s.events_header}>
        <div className={s.event_header_nav}>
          <div className={s.left_side}>
            <div className={s.evt_type_wrapper}>
            <input type="radio" id="feed" name="evt_type" defaultChecked />
            <label htmlFor="feed">Лента</label>
            </div>
            <div className={s.evt_type_wrapper}>
            <input type="radio" id="on_map" name="evt_type" />
            <label htmlFor="on_map" >На карте</label>
            </div>
          </div>
          {
            isPromoter && 
            <div className={s.evt_type_wrapper}>
            <input type="radio" id="promotion" checked={chekedType === "Promotion"} />
            <label htmlFor="promotion" onClick={() => onSetEventType("Promotion")}>Tribe promotion</label>
            </div>
          }
        </div>  
      </div>
    </div>
  )
}

export default ProfileEventsCheckbox
