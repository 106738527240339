import { Outlet } from "react-router-dom"
import Box from "@mui/material/Box"

import ProfileSettingsTab from "../../../components/setting_profile_tabs"
import { settingsCont } from "./indexStyles"
import s from "./styles.module.css"

const ProfileSettings = () => {
  return (
    <div className={s.main}>
      <Box sx={settingsCont}>
        <ProfileSettingsTab />
        <Outlet />
      </Box>
    </div>
  )
}

export default ProfileSettings

// OLD CODE -> DELETE AFTER TRIBE-70 TESK IS TESTED & VALIDATED
//import { useState } from "react"
//import { Outlet, useLocation } from "react-router-dom"
//import GearIcon from "../../../assets/svg/gearIcon.svg?react"
//import ProfileSettingsTab from "../../../components/setting_profile_tabs"
//import s from "./styles.module.css"
//
//const ProfileSettings = () => {
//  const [tab, setTab] = useState<string>("")
//  const location = useLocation()
//  const setCurrentTab = (currentTab: string) => {
//    setTab(currentTab)
//  }
//
//  return (
//    <div className={s.settings__wrapper}>
//      <div className={s.settings__body}>
//        {!location.pathname.includes("profile_view") && (
//          <div className={s.settings_header}>
//            <GearIcon />
//            <p className="header_title">{tab}</p>
//          </div>
//        )}
//
//        <Outlet />
//      </div>
//      <ProfileSettingsTab currentTab={tab} setTab={setCurrentTab} />
//    </div>
//  )
//}
//
//export default ProfileSettings
