import type { SVGProps } from "react"

export function CreateIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="var(--footer-icons-active)"
        stroke="var(--footer-icons-active)"
        strokeLinecap="round"
        strokeWidth={1.75}
        d="M12 6v12m6-6H6"
      ></path>
    </svg>
  )
}
