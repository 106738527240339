import React from "react"
import ReactDOM from "react-dom"

export const createMapComponent = async () => {
  // For each object in the JS API, there is a React counterpart
  // To use the React version of the API, include the module @yandex/ymaps3-reactify
  const [ymaps3React] = await Promise.all([
    ymaps3.import("@yandex/ymaps3-reactify"),
    ymaps3.ready,
  ])
  const reactify = ymaps3React.reactify.bindTo(React, ReactDOM)
  // Import the package to add a default marker
  const defaultMarker = reactify.module(
    await ymaps3.import("@yandex/ymaps3-markers@0.0.1"),
  )
  const { YMapGeolocationControl, YMapZoomControl } = reactify.module(
    await ymaps3.import("@yandex/ymaps3-controls@0.0.1"),
  )
  return {
    yandexMap: reactify.module(ymaps3),
    locationControl: YMapGeolocationControl,
    zoomControl: YMapZoomControl,
    defaultMarker: defaultMarker,
  }
}
