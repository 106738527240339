import { useEffect, useState } from "react"
import Cancel from "../../assets/svg/cancel.svg?react"
import { EventModel } from "../../types"
import s from "./styles.module.css"

type FavoriteFilterProps = {
  eventData: EventModel[] | null
  setFilteredData: (data: EventModel[] | null) => void
}

const InputFilter = ({ eventData, setFilteredData }: FavoriteFilterProps) => {
  const [searchValue, setSearchValue] = useState<string>("")

  useEffect(() => {
    filterEvents(eventData)
  }, [searchValue])

  const onClickClearInput = () => {
    setSearchValue("")
  }

  const filterEvents = (data: EventModel[] | null) => {
    const filteredEvents = data?.filter((el) =>
      el.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
    )
    if (data) {
      return setFilteredData(filteredEvents as EventModel[])
    } else {
      return setFilteredData(data)
    }
  }

  return (
    <div className="favorite_filter">
      <div className={s.search_bar}>
        <div className={s.search_bar__container}>
          <input
            type="text"
            className={s.search_bar__input}
            placeholder="Поиск"
            id="favorite"
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            value={searchValue}
          />
          <Cancel
            width={16}
            height={16}
            fill="var(--unselected-text)"
            onClick={() => onClickClearInput()}
          />
        </div>
      </div>
    </div>
  )
}

export default InputFilter
