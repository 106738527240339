import { UiAddress } from "./index";
import { EventAddressDto } from "../../../../../app/data/tribeSwaggerApi";
import type { YMapLocationRequest } from "ymaps3";
import { AddressLocation } from "../../../../../app/data/location/schema";

export const AddressToDto = (ui: UiAddress): EventAddressDto => {
  const {street_type,
    region,
    region_type, city, city_type, settlement_type, settlement, house, street, lon, lat} = ui.value

  return  {
    region: `${region} ${region_type}`,
    city: settlement ? `${city_type}. ${city} ${settlement_type}. ${settlement}` : `${city_type}. ${city}`,
    street: `${street_type}. ${street}`,
    event_latitude: Number.parseFloat(lat),
    event_longitude: Number.parseFloat(lon),
    house_number: house
  }
}

export const addressToMap = (address: EventAddressDto): YMapLocationRequest | undefined => {
  if (!address)  return undefined
  const {event_longitude:lon, event_latitude:lat} = address
  return  {
    center: [lon, lat], // starting position [lng, lat]
    zoom: 17, // starting zoom
  }
}

export const formatAddressLocation = (address: AddressLocation): string => {
  const { settlement, city_type, city, region, region_type } = address;

  if (settlement.trim() !== '') {
    return `${settlement} ${city_type}. ${city} ${region} ${region_type}.`;
  }

  if (region === city) {
    return `${city_type}. ${city}`;
  }

  return `${city_type}. ${city} ${region} ${region_type}.`;
};
export const formatDetailAddressLocation = (address: AddressLocation): string => {
  const { street_type, street, house, settlement_type, settlement } = address;
  return `${street_type}. ${street} ${house.trim()}`;
};