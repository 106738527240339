import { FC } from "react";
import { useYandexMap, useYandexReactComponents } from "../../../common/yandex/provider/YandexMapProvider";
import { Loading } from "./Loading";
import { ErrorPage } from "./Error";
import { Container } from "@mui/material";
import { LOCATION } from "../../../components/feed-map/types";
import RoomIcon from '@mui/icons-material/Room';
import type { YMapLocationRequest } from "ymaps3";
import { YMapCenterLocation } from "@yandex/ymaps3-types";

export type EventMapContentProps = {
  location?: YMapLocationRequest
}
const EventMap: FC<EventMapContentProps> = ({location}) => {
  if (!location) return null
  const {center} = location as  YMapCenterLocation
  const { isLoaded, data, error } = useYandexMap()
  const components = useYandexReactComponents()
  if (!components) return null
  const { YMapMarker } = components.yandexMap
  if (isLoaded) return  <Loading/>
  if (error)  return  <ErrorPage/>
  if (!data) return null
  const { YMap,
    YMapDefaultSchemeLayer,
    YMapDefaultFeaturesLayer
  } = data.yandexMap
  return <Container sx={{
    height: "100%",
    width: "100%",
  }} >
    <YMap location={location ?? LOCATION}>
      <YMapDefaultSchemeLayer />
      <YMapDefaultFeaturesLayer />
      <YMapMarker coordinates={center}>
        <RoomIcon/>
      </YMapMarker>
    </YMap>
  </Container>
}
export default EventMap