import { FC, PropsWithChildren, ReactNode } from "react"
import { useIsAuthorizedUser } from "../hooks/useIsAuthorizedUser"
import { Navigate } from "react-router-dom"
import { useAppDispatch } from "../app/hooks"
import { show } from "../pages/signIn/SignInSlice"

export type PrivateRouteProps = PropsWithChildren<{
  redirect: string
}>

export const ProtectedRoute: FC<PrivateRouteProps> = ({
  children,
  redirect,
}): ReactNode => {
  const isAuthorization = useIsAuthorizedUser()
  const dispatch = useAppDispatch()
  if (!isAuthorization) {
    dispatch(show())
  }
  return isAuthorization ? children : <Navigate to={redirect} />
}
