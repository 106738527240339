import {
  useLazyGetAutocompleteCityQuery
} from "../../app/data/location/locationApi";
import {  ResponseLocationRegion } from "./types";
import { ioErrorOf } from "../../app/data/errors";
import { AddressLocation, AutocompleteResult } from "../../app/data/location/schema";
import _ from "lodash"

const regionSelect = (response: AutocompleteResult | undefined): AddressLocation[]  => {
  if (!response) return []
  if (response.status !== "success") return []
  return response.data
}
const count = 10

export const useRegionAutocomplete = (): ResponseLocationRegion => {
  const [response, {isLoading, error, data}] = useLazyGetAutocompleteCityQuery()
  const  search = (city: string) => {
    if (!city) return
    if (city.length < 1) return
    response({
      result_amount: count,
      partial_address: city,
      from_bound: "city",
      to_bound: "settlement"
    })
  }
  return {
    isLoading: isLoading,
    error: ioErrorOf(error),
    search: _.debounce(search, 400),
    cityList: regionSelect(data)
  }
}
