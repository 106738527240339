import { MutableRefObject, useLayoutEffect } from "react"
import { useEffect } from "react"

type useFocusLockProps = {
  ref: MutableRefObject<HTMLDivElement | null>
  isVisible: boolean
}

const useFocusLockModal = ({ ref, isVisible }: useFocusLockProps) => {
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100vh"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }

    const focusableModalElements = ref.current?.querySelectorAll(
      "a[href], button:not([disabled]), textarea, input, select",
    )

    if (!focusableModalElements) {
      return
    }

    const firstElement = focusableModalElements[0] as HTMLElement
    const lastElement = focusableModalElements[
      focusableModalElements.length - 1
    ] as HTMLElement
    setTimeout(() => {
      firstElement.focus()
    }, 300)

    const onTabKyedown = (e: KeyboardEvent) => {
      if (!isVisible) {
        return
      }

      if (e.code !== "Tab") {
        return
      }
      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus()
        return e.preventDefault()
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus()
        e.preventDefault()
      }
    }

    document.addEventListener("keydown", onTabKyedown)

    return () => {
      document.removeEventListener("keydown", onTabKyedown)
    }
  }, [ref, isVisible])
}

export default useFocusLockModal
