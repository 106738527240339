import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const menuBtn: SxProps<Theme> = {
  position: "relative",
  width: "48px",
  height: "48px",
  placeItems: "center",
  cursor: "pointer",
}
