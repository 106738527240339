import s from "./styles.module.scss"
import UnknownAvatar from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import Telegram from "../../../../../assets/svg/socials-icons/Telegram.svg?react"
import Whatsapp from "../../../../../assets/svg/socials-icons/WhatsApp.svg?react"
import GradientButton, { GradienButtonStyles } from "../../../../GradientStateBtn"
import { ViewsNames } from "../.."
import { useState } from "react"
import { UserDataForAddToBaseParticipant } from "../../../../../app/data/promotion/promotionApi"
import QrCode from '../../../../../assets/Qrcode.svg?react'

type SendQrProps = {
	ticket: undefined | string
	userData: UserDataForAddToBaseParticipant
	onSetView: (popupVeiew: ViewsNames
	) => void
}

enum Messengers {
	Telegram = "Telegram",
	Watsapp = "Watsapp"
}

const SendQr = ({onSetView, userData, ticket}: SendQrProps) => {
	const { firstname, phone_number, lastname, telegram_username} = userData
	const [messenger, setMessenger] = useState<Messengers | null>(null)


	const onClickBtnGoToView = (view: ViewsNames) =>  {
		onSetView(view)
	}

	const sendInMessenger = () => {
		const parsTicket = Object.values(ticket as string)[0]
		messenger === Messengers.Telegram ?
			window.open(`https://t.me/
			${telegram_username}?text=${parsTicket}`) : 
			window.open(`https://wa.me/${phone_number}?text=${parsTicket}`)
	}

	return (
		<section className={s.send_qr}>
			<div className={s.current_user_info}>
					<UnknownAvatar className={s.user_avatar} />
					<h4 className="user_name">
						{`${firstname} ${lastname}`} 
					</h4>
					<p className={s.user_phone}>
						{phone_number}
					</p>
			</div>
		<div className={s.socials}>
				<h4 className={s.socials_title}>
					Выберите куда отправить:
				</h4>
				<div className={s.socials_icons_block}>
					<div className={s.icon_wrapper}>
						<input className={s.socials_icon} type="radio" id="telegram" name="socials"/>
						<label htmlFor="telegram" onClick={() => setMessenger(Messengers.Telegram)}><Telegram/></label>
					</div>
					<div className={s.icon_wrapper}>
						<input className={s.socials_icon} type="radio" id="whatsapp" name="socials"/>
						<label htmlFor="whatsapp" onClick={() => setMessenger(Messengers.Watsapp)}><Whatsapp/></label>
					</div>
				</div>
				<div className={s.qr_code_wrapper}>
					<QrCode className={s.qr_code} />
				</div>
			</div>
			
			 <div className={s.buttons_block}>
			<GradientButton<Object> onAction={() => onClickBtnGoToView(ViewsNames.AddNewUser)} style={GradienButtonStyles.borderGradient} params={{text: "Назад" , width: 79, height: 48} }  />
			<GradientButton<Object> onAction={() => sendInMessenger()} style={GradienButtonStyles.fullGradient} params={{text: "Отправить QR-код" , width: 171, height: 48, disabled: !messenger && true} }  />
			</div>
		</section>
	)
}

export default SendQr