import { SxProps, Theme } from "@mui/material"

export const notifCont: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
}

export const warnStyle = {
  display: "flex",
  alignItems: "center",
  height: "100px",
  borderRadius: "16px",
}
