import React, { FC } from "react";
import s from "./styles.module.css";
import { TStepProps } from "../../../types";
import Title from "../../common/title";
import ConfirmIcon from "../../../../../assets/svg/inviteConfirm.svg?react";
import classNames from "classnames";
import LocationIcon from "../../../../../assets/svg/location_item.svg?react";
import CalendarIcon from "../../../../../assets/svg/calendar_item.svg?react";
import TimeIcon from "../../../../../assets/svg/time_item.svg?react";
import { getRussianDate } from "../step_5/utils";
import {
  cardBtnExtra,
  cardBtnExtraText, cardBtnImGoing,
  cardBtnsCont
} from "../../../../../components/CardEvent/CardEvent/CardEventStyles";
import { Button, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";


const InviteConfirm: FC<TStepProps> = ({ makeStep, handlerClose, step, formik }) => {
  const navigate = useNavigate();

  // @ts-ignore TODO: Косячные типы
  const { event_name, is_free, address, start_time, event_id } =
    formik.values;
  const dateStart = new Date(start_time);

  const handleCreateEvent = () => {
    navigate(`fid/${event_id}`);
    if (handlerClose) {
      handlerClose();
    }
  };
  const handleInvite = () => {
    makeStep("invite");
  };

  return (
    <div className={s.container}>
      <Title align={"center"} title={"Приглашения высланны"} />

      <div className={s.icon_box}>
        <ConfirmIcon />
      </div>

      <CardActions sx={{
        ...cardBtnsCont,
        width: "100%",
        marginTop: "81px",

        // maxWidth: "220px",
        margin: "0 auto"
      }}>
        <Button onClick={handleInvite} sx={{
          ...cardBtnImGoing, height: "48px"
        }}>
          <p>Назад в ленту</p>
        </Button>

        <Button onClick={handleCreateEvent} variant="contained"
                sx={{ ...cardBtnExtra, minHeight: "48px", whiteSpace: "nowrap" }}>
          <p style={cardBtnExtraText}>
            Перейти в мероприятие
          </p>
        </Button>

      </CardActions>
    </div>
  );
};

export default InviteConfirm;
