import { FC } from "react";
import { month } from "../../consts";
import CardSkeleton from "../CardSkeleton";

export const LoadingCardsSkeleton: FC = () => {
  return <>
    {[...month].map((item, i) => {
      return <CardSkeleton key={item + i} />
    })
      }
  </>
}