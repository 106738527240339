import type {
  LngLatBounds,
  YMapCenterLocation,
  YMapLocationRequest,
  YMapZoomLocation,
} from "@yandex/ymaps3-types"
import { YMapBoundsLocation } from "@yandex/ymaps3-types/imperative/YMap"
import { LngLat } from "ymaps3"

export const LOCATION: YMapLocationRequest = {
  center: [37.623082, 55.75254], // starting position [lng, lat]
  zoom: 9, // starting zoom
}

export interface YmapUpdateEvent {
  type: string
  camera?: Camera
  location: Location
  mapInAction: boolean
}

export interface Camera {
  azimuth?: number
  tilt?: number
}

export type Location = YMapCenterLocation &
  YMapZoomLocation & {
    bounds: LngLatBounds
  }
