import { FC } from "react"
import Container from "../../components/container"
import { content, contentBox, wrapper } from "./ErrorStyles"
import { IoError } from "../../app/data/errors";

export interface ErrorPageProps {
  error: IoError
}
const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  return (
    <Container>
      <div style={wrapper}>
        <div style={content}>
          <div style={contentBox}>
            <p>{error?.error ?? "Упс что то пошло не так"}</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ErrorPage
