import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import Arrow from "../../assets/svg//arrow.svg?react"
import Compass from "../../assets/svg/compass.svg?react"
import Setting from "../../assets/svg/settings.svg?react"
import Sort from "../../assets/svg/sort.svg?react"
import s from "./styles.module.css"

import ItemFid from "../../components/item_fid"
import ModalFind from "./modals/modal_find"
import ModalSort from "./modals/modal_sort"

const FidAll = () => {
  const { name } = useParams()

  const [openFindModal, setOpenFindModal] = useState(false)
  const [openSortModal, setOpenSortModal] = useState(false)

  return (
    <div className={s.main}>
      <ModalSort open={openSortModal} setOpen={setOpenSortModal} />
      <ModalFind
        open={openFindModal}
        handleClose={() => setOpenFindModal(false)}
      />
      <div className={s.header}>
        <NavLink to={"/"} className={s.back}>
          <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
        </NavLink>
        <p className={s.header_title}>{name}</p>
      </div>

      <div className={s.sort_box}>
        <div className={s.sort_box_top}>
          <div
            className={s.sort_box_top_left}
            onClick={() => setOpenFindModal(true)}
          >
            <Compass />
            <p className={s.sort_box_top_left_title}>Где искать?</p>
            <Arrow />
          </div>
          <div className={s.setting_icon}>
            <Setting />
          </div>
        </div>
        <div
          className={s.sort_box_bottom}
          onClick={() => setOpenSortModal(true)}
        >
          <Sort />
          <p className={s.sort_title}>Сортировка</p>
        </div>
      </div>

      <div className={s.content}></div>
    </div>
  )
}

export default FidAll
