import { SxProps, Theme } from "@mui/material"

export const inputCont = {
  display: "flex",
  justifyContent: "center",
  position: "relative",
}

export const inputStyle: SxProps<Theme> = {
  display: "flex",
  width: "100%",
  //margin: "0 20px",
  p: "1px 8px",
  bgcolor: "var(--dialog-bg)",
  border: "1px solid transparent",
  borderRadius: "8px",
  color: "var(--primary-text)",
}

export const clearInputStyle: SxProps<Theme> = {
  cursor: "pointer",
  fontSize: "20px",
  transition: "all 0.3s ease",
  stroke: "var(--profile-ntfc-text)",
  fill: "var(--profile-ntfc-text)",
}

export const alertStyle = {
  width: "300px",
  height: "60px",
  borderRadius: "8px",
  position: "absolute",
  top: "45px",
  zIndex: 1500,
  alignItems: "center",
  textAlign: "center",
  textWrap: "wrap",
  fontSize: "1rem",
  overflow: "hidden",
}
