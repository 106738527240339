import SearchIcon from "@mui/icons-material/Search"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { FC, useState } from "react"
import s from "./styles.module.css"
import classNames from "classnames"

interface MyFormValues {
  type: string
  placeholder: string
  value: string
  handleChange: (e: any) => void
  name: string
  title?: string
  onBlur: (e: any) => void
  searchClick?: (e: any) => void
  onKeyPress?: (e: any) => void
  errorText: string | null
  isSearch?: boolean
  className?: any
}

const InputPassword: FC<MyFormValues> = ({
  type = "text",
  errorText,
  onBlur,
  placeholder,
  className,
  name,
  value,
  title = null,
  handleChange,
  isSearch = false,
  searchClick,
  onKeyPress = () => {},
}) => {
  const [visible, setVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  return (
    <div style={{ width: "100%" }}>
      {title && <span className={s.title}>{title}</span>}
      <div className={s.input_box}>
        {isSearch && (
          <div className={s.visible_box} onClick={searchClick}>
            <SearchIcon
              sx={{
                color: "var(--primary-color)",
                height: "24px",
                width: "24px",
              }}
            />
          </div>
        )}
        <input
          style={{
            paddingLeft: isSearch ? "0" : "",
            backgroundColor: isFocused ? "var(--inputs-bg)" : "var(--white)",
          }}
          onKeyPress={onKeyPress}
          name={name}
          // onChange={handleChange}
          onChange={(e) => handleChange(e)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          className={classNames(s.input, className)}
          type={!visible ? type : "text"}
          autoComplete={"new-password"}
          placeholder={placeholder}
        />

        {type === "password" && (
          <div className={s.visible_box} onClick={() => setVisible(!visible)}>
            {!visible ? (
              <VisibilityOffIcon sx={{ color: "var(--borders)" }} />
            ) : (
              <VisibilityIcon sx={{ color: "var(--borders)" }} />
            )}
          </div>
        )}
      </div>
      {errorText && <p className={s.error_text}>{errorText}</p>}
    </div>
  )
}

export default InputPassword
