import { Visibility } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { FC, useState } from "react"
import { container, descCont, partTitle } from "./style"

type ButtonProps = {
  title: string
  participants: number
  avatars: {
    alt: string
    src: string
  }[]
  qntyOfGuests: number
  handleClick: () => void
}

export const PromoBtnCard: FC<ButtonProps> = ({
  title,
  participants,
  avatars,
  qntyOfGuests,
  handleClick,
}) => {
  const [isHover, setIsHover] = useState(false)
  const participantsText: { [key: string]: string } = {
    Промоутеры: "промоутеров",
    Приглашенные: "приглашенных",
    Участники: "участников",
    Кассиры: "кассиров",
  }

  return (
    <Box
      onClick={() => handleClick()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={container}
      key={title}
    >
      <Box sx={descCont}>
        <Typography sx={{ ...partTitle, fontSize: 14 }}>{title}</Typography>

        <Typography
          sx={
            participants < 1
              ? { fontSize: "12px", color: "var(--secondary-text)" }
              : partTitle
          }
        >
          {participants < 1
            ? `У вас еще нет ${participantsText[title]}`
            : participants}
        </Typography>
      </Box>

      <Visibility
        sx={{
          color: "var(--filter-range-active)",
          opacity: isHover ? 1 : 0,
          transition: "all 0.3s ease",
        }}
      />
      {/* <CardAvatarRow
        avatars={avatars}
        quantity={qntyOfGuests}
        titleOfQuantity="Участников"
      /> */}
    </Box>
  )
}

export default PromoBtnCard
