import React, { FC, useState } from "react"
import s from "./styles.module.css"
import Search from "../../assets/svg/search.svg?react"
import EditSearch from "../../assets/svg/editSearch.svg?react"
import { mock } from "./mock"
import Item from "./item"
import MessagesSort from "../../components/messagesSort"

const Chat: FC = () => {
  const [value, setValue] = useState("")
  const [navigate, setNavigate] = useState(1)

  const filter_data = navigate === 2 ? mock.filter((f) => f.isOnline) : mock
  return (
    <div className={s.main}>
      <div className={s.input_box}>
        <div className={s.search}>
          <Search width={24} height={24} />
        </div>
        <input
          placeholder={"Поиск..."}
          className={s.input}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className={s.cancel} onClick={() => setValue("")}>
          <EditSearch />
        </div>
      </div>
      <MessagesSort />
      <div className={s.navigate}>
        <div className={s.navigate_item} onClick={() => setNavigate(1)}>
          Все
        </div>
        <div className={s.navigate_item} onClick={() => setNavigate(2)}>
          Онлайн
        </div>
        <div
          className={s.line}
          style={{
            left: navigate === 1 ? 0 : "50%",
          }}
        />
      </div>
      <div className={s.content}>
        {filter_data.map((el, i) => (
          <Item key={i} {...el} />
        ))}
      </div>
    </div>
  )
}

export default Chat
