import { createMapComponent } from "../common"
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react"

export type YandexMapState = {
  isLoaded: boolean
  error?: YandexMapError
  data?: YandexMap
}
export type YandexMap = Awaited<ReturnType<typeof createMapComponent>>

export type YandexMapError = {
  message: string
}

const Context = createContext<YandexMapState>({ isLoaded: true })

export const YandexMapProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [error, setError] = useState<YandexMapError | undefined>()
  const [data, setData] = useState<YandexMap | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    createMapComponent()
      .then((ymap) => {
        setData(ymap)
      })
      .catch((error) => {
        setError({
          message: "Dont load Yandex map",
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  return (
    <Context.Provider
      value={{
        isLoaded: loading,
        error: error,
        data: data,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useYandexMap = (): YandexMapState => {
  return useContext(Context)
}

export type YandexReactComponents =
  | Pick<Awaited<ReturnType<typeof createMapComponent>>, "yandexMap">
  | undefined

export const useYandexReactComponents = (): YandexReactComponents => {
  const { data } = useContext(Context)
  return data
}
