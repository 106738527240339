import {
  useGetUserInfoWritableQuery,
  useUpdateUserSettingsMutation,
} from "../../../app/data/userDataSlice/userApi"
import { ResponseStatus } from "../../../shared/user-profile/types"
import {
  UserInfoWritable,
  UserProfileUpdate,
} from "../../../app/data/userDataSlice/types"
import { IoError, ioErrorOf } from "../../../app/data/errors"
import { useAppSelector } from "../../../app/hooks"
import { selectUserId } from "../../../app/data/appTokensStore"
import { useEffect, useState } from "react"

export const useProfileSetting = (): ResponseStatus<UserInfoWritable> => {
  const credentials = useAppSelector(selectUserId)
  const { isLoading, data, error } = useGetUserInfoWritableQuery({
    userId: credentials!!,
  })

  return {
    isLoading: isLoading,
    error: ioErrorOf(error),
    state: data,
  }
}
export type UpdateProfileArgs = Omit<UserProfileUpdate, "user_id">
export type PathProfile = {
  patch: (value: UpdateProfileArgs) => void
  isLoading: boolean
  error?: IoError
}
export const useUpdateProfileSetting = (): PathProfile => {
  const credentials = useAppSelector(selectUserId)
  const [fetch, { isLoading, error }] = useUpdateUserSettingsMutation()
  const fetchHandler = (value: UpdateProfileArgs) => {
    fetch({
      user_id: credentials!!,
      ...value,
    })
  }

  return {
    isLoading: isLoading,
    error: ioErrorOf(error),
    patch: fetchHandler,
  }
}
export type SettingEditor = {
  setUsername: (value: string) => void
  setLastName: (value: string) => void
  setFirstName: (value: string) => void
  setBirthday: (value: string) => void
  updateProfile: (value: Partial<Omit<UserProfileUpdate, "user_id">>) => void
  isLoading: boolean
  error?: IoError
  currentUser?: UserInfoWritable
  pathError?: IoError
}

const initial = (id: number | undefined): UserProfileUpdate => {
  return {
    avatars_filenames_for_deleting: [],
    birthday: "",
    first_name: "",
    interesting_event_type: [],
    is_geolocation_available: false,
    last_name: "",
    new_professions: [],
    profession_ids: [],
    user_avatar: "",
    user_id: id,
    username: "",
  }
}
export const useProfileSettingEditor = (): SettingEditor => {
  const credentials = useAppSelector(selectUserId)
  const [state, setState] = useState<UserProfileUpdate>(initial(credentials))
  const { isLoading, data, error } = useGetUserInfoWritableQuery({
    userId: credentials!!,
  })
  const [fetch, { error: PatchError }] = useUpdateUserSettingsMutation()
  useEffect(() => {
    if (data) {
      setState((profile) => {
        return {
          ...profile,
          birthday: data.birthday,
          first_name: data.first_name,
          last_name: data.last_name,
          username: data.username,
        }
      })
    }
  }, [data])

  return {
    updateProfile(value) {
      fetch({ ...state, ...value })
    },
    isLoading: isLoading,
    error: ioErrorOf(error),
    currentUser: data,
    pathError: ioErrorOf(PatchError),
    setUsername: (value: string) => {
      fetch({ ...state, username: value })
    },
    setLastName: (value: string) => {
      fetch({ ...state, last_name: value })
    },
    setFirstName: (value: string) => {
      fetch({ ...state, first_name: value })
    },
    setBirthday: (value: string) => {
      fetch({ ...state, birthday: value })
    },
  }
}
