import { useAppDispatch } from "../app/hooks"
import * as Api from "../app/data/tribeSwaggerApi"
import { useEffect } from "react"
import { setUserCredentials } from "../app/data/appTokensStore"
import { hide } from "../pages/signIn/SignInSlice"

const useSingIn = (
  onError: (error: string) => void,
): ((login: string, password: string, save: boolean) => Promise<void>) => {
  const dispatch = useAppDispatch()
  const [loginReq, { data, error }] = Api.useLoginUserWithEmailMutation()
  useEffect(() => {
    if (data) {
      dispatch(
        setUserCredentials({
          applicationToken: data.access_token,
          refreshToken: data.refresh_token,
          userId: data.user_id,
        }),
      )
      dispatch(hide())
    }
    if (error) {
      onError("Неверный логин или пароль")
    }
  }, [data, error])
  return async (login: string, password: string, save: boolean) => {
    try {
      await loginReq({
        emailLoginDto: {
          email: login,
          password: password,
        },
      })
    } catch (e) {}
  }
}
export default useSingIn
