import { ReactElement } from "react";
import s from "../styles.module.css"
import {  selectSchema } from "./types";
import { ItemList, ListModel } from "./ItemList";


type Props<T> = {
  title: string
  tabsMap: selectSchema<T>,
  value: T | T[]
  onSelect: (value: T) => void
}

function FilteredSelectedBox<T>({onSelect, title, value,  tabsMap }: Props<T>):ReactElement {

  const handleSelectTab = (value: T) => {
    onSelect(value)
  }
  const isSelected = (valueItem: ListModel<T>): boolean => {
    if (Array.isArray(value)){
      return value.includes(valueItem.value)
    } else {
      return valueItem.value === value
    }
  }

  return (
    <div className={s.category_box}>
      <h4 className={s.title}>{title}:</h4>

      <div className={s.box}>
        <ItemList<T>
          items={tabsMap.items.map(i=>({title: i.name, value: i.value}))}
          isSelectedAdapter={isSelected}
          onSelect={handleSelectTab}
        />
      </div>
    </div>
  )
}

export default FilteredSelectedBox
