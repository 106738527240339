import { FC, useEffect } from "react"
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import { EventInfoProfile } from "../../../shared/user-profile/types"
import {
  card,
  cardContent,
  cardImg,
  cardQrButton,
  cardQrButtonText,
} from "./styles"
import s from "./styles.module.css"
import FallbackImg from "../../../assets/svg/profile/logo-icon.svg"
import { DateIcon } from "../../../assets/svg/profile/DateIcon"
import { LocationIcon } from "../../../assets/svg/profile/LocationIcon"
import { TimeIcon } from "../../../assets/svg/profile/TimeIcon"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setPromotionPopup } from "../../../app/data/popupSlice/popupSlice"
import { useLazyGetEventParticipants } from "../../../app/data/promotion/promotionApi"

export type ProfileEventCardProps = {
  info: EventInfoProfile
  qrCodePush?: (id: number) => void
  isPromotion?: boolean
}
const ProfileEventCard: FC<ProfileEventCardProps> = ({ info, qrCodePush, isPromotion }) => {
  const currentEventModel = 
    useAppSelector((state) => state.userProfileState.Promotion)
    .filter((e) => e.id === info.id)

 const [getUsersBaseByEventId, {data}] = useLazyGetEventParticipants()
 const dispatch = useAppDispatch()
  const qrButtonPush = () => {
    if (qrCodePush) {
      qrCodePush(info.id)
    }
  }

  const handleSetPromotionPopup = () => {
    dispatch(setPromotionPopup(currentEventModel[0]))
    getUsersBaseByEventId({eventId: info.id, promoterId: 65})
  }

  const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)
  const eventDatetime = Date.parse(info.start_time)
  const dateFormatter = new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const timeFormatter = new Intl.DateTimeFormat("ru", {
    hour: "numeric",
    minute: "numeric",
  })

  return (
    <Card sx={card}>
      <Link to={`/fid/${info.id}`}>
        <CardMedia
          sx={cardImg}
          image={info.event_photo_url || FallbackImg}
          title={info.event_name}
        />
      </Link>
      <CardContent sx={cardContent}>
        <Link to={`/fid/${info.id}`}>
          <h2 className={s.cardTitle}>{capitalize(info.event_name)}</h2>
        </Link>
        <ul className={s.infoItems}>
          <li className={s.infoItem}>
            <LocationIcon className={s.infoIcon} />
            {info.city}
          </li>
          <li className={s.infoItem}>
            <DateIcon className={s.infoIcon} />
            {dateFormatter.format(eventDatetime)}
          </li>
          <li className={s.infoItem}>
            <TimeIcon className={s.infoIcon} />
            {timeFormatter.format(eventDatetime)}
          </li>
        </ul>
      </CardContent>
      <CardActions
        sx={{ padding: 0, justifyContent: "flex-end", position: "relative" }}
      >
        <Button sx={cardQrButton} onClick={isPromotion ? handleSetPromotionPopup : qrButtonPush} variant="contained">
          <p style={cardQrButtonText}>Выслать Qr-код</p>
        </Button>
      </CardActions>
    </Card>
  )
}

export default ProfileEventCard
