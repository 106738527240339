import { Box } from "@mui/material"
import { createSelector } from "@reduxjs/toolkit"
import { useState } from "react"
import * as api from "../../app/data/tribeSwaggerApi"
import ErrorPage from "../../pages/ErrorPage/error-page"
import useModalControler from "../../hooks/useModalControler"
import EventDialog from "../popups/EventDialog"
import { feedCardsCont, feedPageCont } from "./feedPageStyles"
import useFeedEventsFilteredList from "../../shared/events-feed/hooks/useFeedEventsFilteredList"
import { FilteredEventList } from "./FilteredEventList"
import { LoadingCardsSkeleton } from "./LoadingCardsSkeleton"
import { mapSearchEventInfoToModel } from "./mapper"

type AvatarUrls = Record<number, string[]>

export const getAvatarUrls = createSelector(
  (response: api.GetAllEventByFilterApiResponse) => response.content,
  (events: api.SearchEventDto[]) =>
    events.reduce((acc: AvatarUrls, event) => {
      if (event.avatarUrl && event.eventId) {
        acc[event.eventId] = event.avatarUrl.flatMap(
          (url: string) =>
            `${import.meta.env.VITE_AVATAR_URL}/v1/events/avatars/${url}`,
        )
      }
      return acc
    }, {}),
)

const FeedPage = () => {
  const { mounted, handleToggleMounted } = useModalControler()
  const [modalLink, setModalLink] = useState("")
  const { isLoading, error, filteredList, pagination } =
    useFeedEventsFilteredList()
  if (error) {
    return <ErrorPage error={error} />
  }
  if (pagination && pagination?.totalElements === 0) {
    return (
      <div style={feedPageCont}>
        <h2 style={{ textAlign: "center" }}>Ничего не найдено</h2>
      </div>
    )
  }
  return (
    <div style={feedPageCont}>
      <Box sx={feedCardsCont}>
        {isLoading && !error ? <LoadingCardsSkeleton /> : null}
        {filteredList && (
          <FilteredEventList
            handleToggleMounted={handleToggleMounted}
            setModalLink={setModalLink}
            events={filteredList.map(mapSearchEventInfoToModel)}
          />
        )}
      </Box>
      <EventDialog
        isVisible={mounted}
        visibleToggler={handleToggleMounted}
        eventLink={modalLink}
      />
    </div>
  )
}
export default FeedPage
