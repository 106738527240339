import { FC } from "react"
import { Box, MenuList } from "@mui/material"

import { menuIconsPack } from "../../../consts"
import HeaderItem from "./HeaderItem"
import HeaderThemeItem from "./HeaderThemeItem"
import {
  handleOpenCloseMenu,
  menuBlurCont,
  sideMenuCont,
} from "./headerMenuStyles"

type Props = {
  isOpenMenu: boolean
}

const HeaderMenu: FC<Props> = ({ isOpenMenu }) => {
  return (
    <MenuList sx={sideMenuCont} style={handleOpenCloseMenu(isOpenMenu)}>
      {menuIconsPack.map((item, i) =>
        item.icon ? (
          <HeaderItem item={item} i={i} isOpenMenu={isOpenMenu} key={i} />
        ) : (
          <HeaderThemeItem i={i} item={item} isOpenMenu={isOpenMenu} key={i} />
        ),
      )}
      <Box sx={menuBlurCont} onClick={() => handleOpenCloseMenu(false)} />
    </MenuList>
  )
}

export default HeaderMenu
