import { Close } from "@mui/icons-material"
import {
  Alert,
  Box,
  InputAdornment,
  InputBase,
  SxProps,
  Theme,
} from "@mui/material"
import { useEffect, useState } from "react"
import SearchIcon from "../../../assets/svg/header-icons-pack/Search-icon.svg?react"
import { fadeIn, fadeOut } from "../PromotionModal/style"
import * as s from "./style"

type Props = {
  errorTitle?: string
  serErrorTitle?: (v: string) => void
  query: string
  setQuery: (v: string) => void
  style?: SxProps<Theme>
}

export default function SearchEmployeesInput({
  errorTitle,
  serErrorTitle = () => {},
  query,
  setQuery,
  style,
}: Props) {
  const [fadeAnimation, setFadeAnimation] = useState(fadeIn)
  const [isErrorPopup, setIsErrorPopUp] = useState("")
  const inputStyle = { ...s.inputStyle, ...style }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const regex = /^[a-zA-Zа-яА-Я0-9!@№#$&*()_\-+=?:,~±§ ]*$/

    if (regex.test(value)) {
      setIsErrorPopUp("")
      setQuery(value)
      return
    }
    setIsErrorPopUp("Вы ввели недопустимый символ....")
  }

  useEffect(() => {
    const fadeId = setTimeout(() => {
      setFadeAnimation(fadeOut)
    }, 1000)

    const timerId = setTimeout(() => {
      serErrorTitle("")
      setIsErrorPopUp("")
      setFadeAnimation(fadeIn)
    }, 1100)

    return () => {
      clearTimeout(fadeId)
      clearTimeout(timerId)
    }
  }, [isErrorPopup, errorTitle])

  return (
    <Box sx={s.inputCont}>
      <InputBase
        id="promotion-input"
        value={query}
        onChange={handleInput}
        sx={inputStyle}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon stroke="var(--profile-ntfc-text)" />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <Close
              onClick={() => setQuery("")}
              sx={s.clearInputStyle}
              style={{
                opacity: query ? "1" : "0",
                visibility: query ? "visible" : "hidden",
              }}
            />
          </InputAdornment>
        }
      />

      <Alert
        variant="filled"
        severity="error"
        sx={{
          ...s.alertStyle,
          display: errorTitle || isErrorPopup ? "flex" : "none",
          opacity: errorTitle || isErrorPopup ? 1 : 0,
          animation: `${fadeAnimation} 0.15s ease-in-out`,
        }}
      >
        {errorTitle ? errorTitle : isErrorPopup}
      </Alert>
    </Box>
  )
}
