import { createSlice } from "@reduxjs/toolkit"

// Для уведомления о добавлении в событие:
// type AddDataTypes = {
//   eventId: string // Идентификатор события, в которое добавили
//   organizerId: string // Идентификатор организатора события
//   eventName: string // Название события
// }

// Для уведомления о подписке на событие:
// type SubscriptionDataTypes = {
//   eventId: string // Идентификатор события, на которое подписались
//   subscriberId: string // Идентификатор пользователя, который подписался
//   eventName: string // Название события
// }

// Для уведомления о лайке события:
// type LikeDataTypes = {
//   eventId: string // Идентификатор события, получившего лайк
//   likerId: string // Идентификатор пользователя, поставившего лайк
//   eventName: string // Название события
// }

// Для уведомления об отзыве на событие:
// type ReviewDataTypes = {
//   eventId: string // Идентификатор события, на котором оставили отзыв
//   reviewerId: string // Идентификатор пользователя, оставившего отзыв
//   reviewText: string // Текст отзыва
// }

// Для уведомления о новой функциональности:
// type FeatureDataTypes = {
//   featureName: string // Название новой функциональности
//   description: string // Описание новой функциональности
// }

// Для уведомления об изменении в пользовательском соглашении:
// type PolicyDataTypes = {
//   changeType: string // Тип изменения (например, "Добавлен пункт", "Изменена формулировка" и т.д.)
//   description: string // Описание изменения
// }

export type NotificationData = {
  titleName?: string
  reviewText?: string
  eventLocation?: string
  eventDate?: string
  eventImg?: string
  eventName?: string
  eventLink?: string
}

export enum TypeOfNotification {
  invitation = "eventInvitation",
  subscription = "eventSubscription",
  like = "eventLike",
  review = "eventReview",
  feature = "newFeature",
  policy = "policyUpdate",
}

export type UserNotification = {
  id: string
  name: string
  type: TypeOfNotification
  timestamp: string
  isRead: boolean
  link: string
  avatar: string
  data: NotificationData
}

export const getLocalDayAndHour = () => {
  const date = new Date()

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }

  return date.toLocaleDateString(navigator.language, options)
}

function formatDate(): string {
  const date = new Date()
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  }
  return new Intl.DateTimeFormat("ru-RU", options).format(date)
}

type State = {
  notificationData: UserNotification[]
  idsOfNotifications: string[]
}

const initialState: State = {
  notificationData: [
    {
      id: "1",
      name: "Михаил Вишневый",
      type: TypeOfNotification.invitation,
      timestamp: getLocalDayAndHour(),
      isRead: false,
      link: "/notification",
      avatar: "/src/assets/image/prof.png",
      data: {
        eventDate: formatDate(),
        eventLocation: "Moscow",
        eventLink: "/notification",
        eventImg: "/src/assets/image/img6.png",
        eventName: "Выставка искусства",
      },
    },
    {
      id: "2",
      name: "Михаил Вишневый",
      type: TypeOfNotification.subscription,
      timestamp: getLocalDayAndHour(),
      isRead: false,
      link: "/notification",
      avatar: "/src/assets/image/fav1.png",
      data: { titleName: "подписался на Вас" },
    },
    {
      id: "3",
      name: "Михаил Вишневый",
      type: TypeOfNotification.like,
      timestamp: getLocalDayAndHour(),
      isRead: false,
      link: "/notification",
      avatar: "/src/assets/image/fav2.png",
      data: { titleName: "Михаилу понравилось Ваше событие!" },
    },
    {
      id: "4",
      name: "Михаил Вишневый",
      type: TypeOfNotification.review,
      timestamp: getLocalDayAndHour(),
      isRead: false,
      link: "/notification",
      avatar: "/src/assets/image/fav3.png",
      data: {
        reviewText: "Отличное мероприятие, я получил массу удовольствия!",
      },
    },
    {
      id: "5",
      name: "Команда Tribe",
      type: TypeOfNotification.feature,
      timestamp: getLocalDayAndHour(),
      isRead: false,
      link: "/notification",
      avatar: "/src/assets/svg/logo.svg",
      data: {
        titleName:
          "Теперь вы можете общаться с друзьями в режиме реального времени через наш новый видеочат!",
      },
    },
    {
      id: "6",
      name: "Команда Tribe",
      type: TypeOfNotification.policy,
      timestamp: getLocalDayAndHour(),
      isRead: false,
      link: "/notification",
      avatar: "/src/assets/svg/logo.svg",
      data: { titleName: "Добавлен новый пункт в Пользовательское соглашение" },
    },
  ],
  idsOfNotifications: [],
}

export const notificationData: UserNotification[] = [
  {
    id: "1",
    name: "Михаил Вишневый",
    type: TypeOfNotification.invitation,
    timestamp: getLocalDayAndHour(),
    isRead: false,
    link: "/notification",
    avatar: "/src/assets/image/prof.png",
    data: {
      eventDate: formatDate(),
      eventLocation: "Moscow",
      eventLink: "/notification",
      eventImg: "/src/assets/image/img6.png",
      eventName: "Выставка искусства",
    },
  },
  {
    id: "2",
    name: "Михаил Вишневый",
    type: TypeOfNotification.subscription,
    timestamp: getLocalDayAndHour(),
    isRead: false,
    link: "/notification",
    avatar: "/src/assets/image/fav1.png",
    data: { titleName: "подписался на Вас" },
  },
  {
    id: "3",
    name: "Михаил Вишневый",
    type: TypeOfNotification.like,
    timestamp: getLocalDayAndHour(),
    isRead: false,
    link: "/notification",
    avatar: "/src/assets/image/fav2.png",
    data: { titleName: "Михаилу понравилось Ваше событие!" },
  },
  {
    id: "4",
    name: "Михаил Вишневый",
    type: TypeOfNotification.review,
    timestamp: getLocalDayAndHour(),
    isRead: false,
    link: "/notification",
    avatar: "/src/assets/image/fav3.png",
    data: { reviewText: "Отличное мероприятие, я получил массу удовольствия!" },
  },
  {
    id: "5",
    name: "Команда Tribe",
    type: TypeOfNotification.feature,
    timestamp: getLocalDayAndHour(),
    isRead: false,
    link: "/notification",
    avatar: "/src/assets/svg/logo.svg",
    data: {
      titleName:
        "Теперь вы можете общаться с друзьями в режиме реального времени через наш новый видеочат!",
    },
  },
  {
    id: "6",
    name: "Команда Tribe",
    type: TypeOfNotification.policy,
    timestamp: getLocalDayAndHour(),
    isRead: false,
    link: "/notification",
    avatar: "/src/assets/svg/logo.svg",
    data: { titleName: "Добавлен новый пункт в Пользовательское соглашение" },
  },
]

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    setIdsOfUnread: (state, payload) => {
      state.idsOfNotifications.push(payload.payload)
    },

    readAll: (state) => {
      state.notificationData.forEach((card) => {
        if (state.idsOfNotifications.includes(card.id)) {
          card.isRead = true
        }
      })
    },
  },
})

export default notificationSlice.reducer