import { Slider } from "@mui/material"
import ld from "lodash"
import { FC } from "react"
import s from "../styles.module.css"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";

export const FilterMemberCount: FC = () => {
  const {currentFilter, updateParticipantsRange} = useEventFilter()
  const [min, max] = [currentFilter.numberOfParticipantsMin, currentFilter.numberOfParticipantsMax]
  const onChange = (_: Event, v: number | number[]) => {
    if (ld.isArray(v)) {
      const [newMin, newMax] = v

      updateParticipantsRange([newMin, newMax])
    }
  }

  return (
    <div className={s.category_box}>
      <div className={s.category_box_title}>
        <h4 className={s.title}>
          Количество участников: от {min} до {max}
        </h4>
      </div>

      <div className={s.box}>
        <Slider
          min={1}
          max={12}
          step={1}
          marks
          sx={{
            color: "var(--filter-range-active)",
            marginLeft: "10.5px",
            width: `calc(100% - 21px)`,
            padding: "16px 0",
          }}
          getAriaLabel={() => "Minimum participants"}
          value={[min, max]}
          onChange={onChange}
          valueLabelDisplay="auto"
          disableSwap
        />
      </div>
    </div>
  )
}
