import OverlayHobbiesBlock from "../../components/mobile_overlay/overlay_hobbies_block"
import OverlayHobbiesScrollBar from "../../components/mobile_overlay/overlay_hobbies_scrollbar"
import s from "./styles.module.css"
import HeaderLogo from "../../assets/svg/MobileLogo.svg?react"

const hobbies = [
  "Посиделки",
  "Прогулки",
  "Детективы",
  "Древний рим",
  "Киберпанк",
  "Кобочки",
  "Аюрведа",
]

const MobileOverlay = () => {
  return (
    <section className={s.mobile_ovelay_container}>
      <header className={s.mobile_overlay_header}>
        <HeaderLogo />
      </header>
      <div className={s.mobile_overlay_body}>
        <OverlayHobbiesScrollBar />
      </div>
      <footer className={s.mobile_overlay_foooter}>
        <div className={s.footer_content}>
          <h2 className={s.footer_title}>
            Выбери, что
            <br />
            <span> нравиться тебе?</span>
          </h2>
          <button className={s.next_btn}>Далее</button>
        </div>
      </footer>
    </section>
  )
}

export default MobileOverlay
