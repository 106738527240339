import { UsersWhoParticipantsOfEventDto } from "./app/data/tribeSwaggerApi"

export type EventModel = {
  id: number
  disabled?: boolean
  img: string
  location: string
  calendar?: string
  time: string
  title: string
  description: string
  category?: string
  isRemove?: boolean
  isFree?: boolean
  isFavorite: boolean
  isAlcohol?: boolean | undefined
  participants?: UsersWhoParticipantsOfEventDto[]
  coordinates: number[]
}

export interface FidViewState {
  isLoading: boolean
  isError: boolean
  errorMessage?: string
  data: EventModel[] | null
}

export enum ChatMode {
  Mobile,
  Web,
}

export enum ThemeColor {
  Dark,
  Light,
}

export enum SecurityItemNames {
  Password = "Пароль",
  Mail = "Почта",
  Phone = "Телефон",
}
