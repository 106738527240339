export type SvgType = {
  color?: string
  width?: string
  height?: string
}

export default function Pencil({ color, width, height }: SvgType) {
  return (
    <svg
      width={width ? width : 16}
      height={height ? height : 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0985 12.3154L6.39139 11.6083L7.0985 12.3154L11.7068 7.70711C11.726 7.68785 11.7459 7.66814 11.7662 7.64797C11.9733 7.44243 12.2281 7.1896 12.3775 6.88803L11.5909 6.49826L12.3775 6.88803C12.6548 6.32848 12.6548 5.67152 12.3775 5.11197L11.4815 5.55598L12.3775 5.11197C12.2281 4.81039 11.9733 4.55756 11.7662 4.35202C11.7459 4.33186 11.726 4.31215 11.7068 4.29289C11.6875 4.27364 11.6678 4.25378 11.6476 4.23346C11.4421 4.02636 11.1893 3.77162 10.8877 3.62218C10.3282 3.34489 9.6712 3.34489 9.11164 3.62218C8.81007 3.77162 8.55724 4.02636 8.3517 4.23346C8.33153 4.25378 8.31182 4.27364 8.29257 4.29289L3.68428 8.90118C3.67121 8.91425 3.65773 8.92765 3.64391 8.94138C3.49174 9.09263 3.29912 9.28409 3.16224 9.52585C3.02535 9.76762 2.96029 10.0313 2.90888 10.2396C2.90421 10.2585 2.89966 10.277 2.89518 10.2949L2.56498 11.6157C2.56218 11.6269 2.55925 11.6385 2.55623 11.6505C2.51941 11.797 2.46841 11.9999 2.45084 12.1795C2.43062 12.3862 2.42394 12.8382 2.79272 13.207C3.16149 13.5757 3.61345 13.5691 3.82015 13.5488C3.99976 13.5313 4.20268 13.4803 4.34916 13.4434C4.36117 13.4404 4.37279 13.4375 4.38399 13.4347L5.70478 13.1045C5.72272 13.1 5.74117 13.0955 5.76008 13.0908C5.96839 13.0394 6.23206 12.9743 6.47382 12.8374C6.71559 12.7006 6.90703 12.5079 7.05828 12.3558C7.07202 12.342 7.08542 12.3285 7.0985 12.3154Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M8.33301 4.9974L10.333 3.66406L12.333 5.66406L10.9997 7.66406L8.33301 4.9974Z"
        fill={color}
      />
    </svg>
  )
}
