import { SxProps, Theme } from "@mui/material"

export const contentContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  padding: "16px",
}

export const notifCont = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}

export const contentWrapper: SxProps<Theme> = {
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
  width: "100%",
  flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "space-between",
  marginBottom: "16px",
}

export const loadingStyle: SxProps<Theme> = {
  width: "60px !important",
  height: "60px !important",
}

export const noEmployees: SxProps<Theme> = { color: "var(--unselected-text)" }
