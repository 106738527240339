import { useEffect, useRef, useState } from "react"
import { divideByArrays } from "../../../utils/overlay_mobile"
import OverlayHobbiesBlock from "../overlay_hobbies_block"
import s from "./stylex.module.css"

const hobbies = [
  "Посиделки",
  "Прогулки",
  "Президентские выборовские",
  "Настольные игры",
  "Древний рим",
  "Киберпанк",
  "Кобочки",
  "Аюрведа",
  "Арты",
  "Реп",
  "Мусульманство",
  "Христианство",
  "Буддизм",
  "Посиделки",
  "Прогулки",
  "Президентские выборовские",
  "Настольные игры",
  "Древний рим",
  "Киберпанк",
  "Кобочки",
  "Аюрведа",
  "Арты",
  "Реп",
  "Мусульманство",
  "Христианство",
  "Буддизм",
]

const OverlayHobbiesScrollBar = () => {
  const countHobbiesBlocks = Math.ceil(hobbies.length / 4)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [hobbiesData, setHobbiesGroup] = useState<string[]>([])

  const onClickSetHobbies = (hobbie: string) => {
    if (hobbiesData.join().includes(hobbie)) {
      setHobbiesGroup((prev) => prev.filter((el) => el !== hobbie))
    } else {
      setHobbiesGroup((prev) => [...prev, hobbie])
    }
  }

  return (
    <div className={s.hobbies_scrollbar}>
      {Array.from({ length: countHobbiesBlocks }, (_, i) => (
        <OverlayHobbiesBlock
          hobbiesData={hobbiesData}
          setHobbies={onClickSetHobbies}
          index={i}
          key={i}
          hobbies={divideByArrays(hobbies)[i]}
        />
      ))}
    </div>
  )
}

export default OverlayHobbiesScrollBar
