import { FC } from "react"
import { useAppDispatch } from "../../../app/hooks"
import { useUpdateSearchParam } from "../../../hooks/useUpdateSearchParam"
import {
  resetFilter,
  updateCategory,
} from "../../../shared/left-filter/filterSlice"
import s from "../styles.module.css"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";

export const ResetBox: FC = () => {
    const {resetFilter} = useEventFilter()

  const onClick = () => {
    resetFilter()
  }

  return (
    <div onClick={onClick} className={s.reset_box}>
      <p className={s.reset}>Сбросить фильтр</p>
    </div>
  )
}
