import { FilterType } from "../types";

export function urlSearchParamsToFilter(params: URLSearchParams): FilterType {
  const filter: FilterType = {
    city: "", sort: "DATE",
    durationEventInHoursMax: 20,
    durationEventInHoursMin: 1,
    numberOfParticipantsMax: 12,
    numberOfParticipantsMin: 1
  };

  for (const [key, value] of params.entries()) {
    if (key === 'eventTypeId') {
      if (filter.eventTypeId){
        filter.eventTypeId.push(Number(value))
      } else {
        filter.eventTypeId = [Number(value)];
      }
    } else if (['isPresenceOfAlcohol', 'isFree', 'isFinished', 'isEighteenYearLimit', 'strictEventSort'].includes(key)) {
      // @ts-ignore
      filter[key as keyof FilterType] = value === 'true';
    } else if (['distanceInMeters', 'longitude', 'latitude', 'numberOfParticipantsMin', 'numberOfParticipantsMax', 'durationEventInHoursMin', 'durationEventInHoursMax'].includes(key)) {
      // @ts-ignore
      filter[key as keyof FilterType] = Number(value);
    } else if(key === "partsOfDay") {
      if (filter.partsOfDay){
        // @ts-ignore
        filter.partsOfDay.push(value)
      } else {
        // @ts-ignore
        filter.partsOfDay = [value]
      }
    } else {
      // @ts-ignore
      filter[key as keyof FilterType] = value;
    }
  }

  const order = filter.order;
  if (order !== 'ASC' && order !== 'DESC') {
    delete filter.order; // Удаляем неправильное значение order
  }

  return filter;
}