import { createTheme, keyframes, SxProps, Theme } from "@mui/material"
import { bottom } from "@popperjs/core"

export const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--bg-outside-modal) !important",
          transition: "all 0.3s ease",
        },
      },
    },
  },
})

export const cardCont = {
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  display: "flex",
  height: "100%",
  width: "100%",
  overflowY: "auto",
  padding: "16px",
}

export const style: SxProps<Theme> = {
  position: "relative",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "16px",

  width: "392px",
  maxWidth: "100%",
  padding: "32px 16px",
  bgcolor: "var(--modal-bg-color)",
  color: "var(--primary-text)",
  borderRadius: "8px",
  boxShadow: 5,
}

export const textHeader = {
  fontSize: "1.25rem",
  lineHeight: "1.6",
  letterSpacing: "0.0075em",
  fontWeight: "800",
  color: "var(--secondary-text)",
}

export const closeBtnStyle: SxProps<Theme> = {
  alignSelf: "flex-end",
  position: "absolute",
  top: 15,
  right: 15,
  fontSize: "20px",
  stroke: "var(--secondary-text)",
  fill: "var(--secondary-text)",
  cursor: "pointer",
}

export const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`

export const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

export const slideRight = keyframes`
0% {
  transform: translateX(600px);
  opacity: 0;
}
100% {
  transform: translateX(0);
  opacity: 1;
}
`

export const slideLeft = keyframes`
0% {
  transform: translateX(-600px);
  opacity: 0;
}
100% {
  transform: translateX(0);
  opacity: 1;
}
`
