import { CalendarToday } from "@mui/icons-material"
import { FC, ForwardedRef, forwardRef, useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"
import s from "../styles.module.css"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter"

type CustomElem = {
  value?: string
  onClick?: () => void
}

export const FilterDateRange: FC = () => {
  const { currentFilter, updateTimeRange } = useEventFilter()
  const [show, setShow] = useState(false)

  const handleOpenCalendar = () => {
    setShow((prev) => !prev)
  }
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  const [startDate, endDate] = dateRange
  useEffect(() => {
    if (startDate && endDate) {
      updateTimeRange([
        startDate.toLocaleDateString(),
        endDate.toLocaleDateString(),
      ])
    }
    if (!startDate && !endDate) {
      updateTimeRange([undefined, undefined])
    }
  }, [dateRange])
  const CustomInput = forwardRef(
    (props?: CustomElem, ref?: ForwardedRef<HTMLButtonElement>) => {
      const onClick = props?.onClick
      const value = props?.value

      return (
        <button
          style={{
            width: "100%",
            display: "flex",
            padding: "8px",
            paddingLeft: "40px",
            backgroundColor: "var(--inputs-bg)",
            color: "var(--secondary-text)",
            borderRadius: "0.5rem",
            minHeight: "35px",
          }}
          onClick={onClick}
          ref={ref}
        >
          {value ? value : "c дд/мм/гг по дд/мм/гг"}
        </button>
      )
    },
  )

  return (
    <div className={s.category_box}>
      <h4 className={s.title}>Временной диапазон:</h4>

      <div className={s.box}>
        <div className={s.input_box}>
          <CalendarToday
            sx={{ padding: "2px", color: "var(--secondary-text)" }}
          />

          <ReactDatePicker
            locale={"ru"}
            selectsRange={true}
            onCalendarOpen={handleOpenCalendar}
            onCalendarClose={handleOpenCalendar}
            shouldCloseOnSelect
            allowSameDay={true}
            minDate={new Date()}
            customInput={<CustomInput />}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update)
            }}
            placeholderText={"c дд/мм/гг по дд/мм/гг"}
            dateFormat="dd/MM/yyyy"
            isClearable={true}
          />
        </div>
      </div>
    </div>
  )
}
