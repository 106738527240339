import { FC } from "react"
import Container from "../../components/container"
import s from "../fid/styles.module.css"

const LoadingPage: FC = () => {
  return (
    <Container>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.content_box}>
            <p>ЗАГРУЗКА</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default LoadingPage
