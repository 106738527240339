import { FC, useEffect } from "react"
import * as api from "../../app/data/tribeSwaggerApi"
// import LoadingPage from "../../pages/fid/loading-page"
import ErrorPage from "../../pages/ErrorPage/error-page"
import { FavoriteEventList } from "./list/FavoriteEventList"
import { favoriteDtoToModel } from "./mapper"

export type FavoritePageProps = {
  userId: string
}
const FavoritePage: FC<FavoritePageProps> = ({ userId }) => {
  const { isLoading, isError, data, isSuccess, refetch } =
    api.useGetAllFavoritesByUserIdQuery({
      userId: userId,
    })
  const changeList = () => {
    refetch()
  }
  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [])
  if (isLoading) return "...Загрузка"
  if (isSuccess && data)
    return (
      <FavoriteEventList
        onChange={changeList}
        list={data.map(favoriteDtoToModel)}
      />
    )
}

export default FavoritePage
