import { monthsByIndex } from "../../consts";

const formatDateFromString = (strTime: string) => {
  const date = new Date(strTime)
  const dayNum = date.getDate()
  const monthNum = date.getMonth()

  const formattedDate =
    date.getDate() +
    " " +
    monthsByIndex[date.getMonth()] +
    " " +
    date.getFullYear()

  const formattedTime = date.toLocaleTimeString("ru-RU", {
    hour: "numeric",
    minute: "numeric",
  })

  return {
    dayNum,
    monthNum,
    date: formattedDate,
    time: formattedTime,
  }
}

export default formatDateFromString
