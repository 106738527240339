import { YMap } from "@yandex/ymaps3-types"
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"

export type YandexMapState = {
  data: typeof ymaps3 | null
  isLoading: boolean
  isError?: string
}

const initialState: YandexMapState = {
  data: null,
  isLoading: true,
}

const Context = createContext<YandexMapState>(initialState)

export const YandexMapProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [localContext, setLocalContext] = useState<YandexMapState>(initialState)

  useEffect(() => {
    setLocalContext({ data: null, isLoading: true })
    ymaps3.ready
      .then(() => {
        setLocalContext({
          data: ymaps3,
          isLoading: false,
        })
      })
      .catch(() => {
        setLocalContext({
          data: null,
          isLoading: false,
          isError: "Error loading map",
        })
      })
  }, [])

  return <Context.Provider value={localContext}>{children}</Context.Provider>
}

export const useYandexMap = (): YandexMapState => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    ymaps3.ready
      .then(() => {
        setState({
          data: ymaps3,
          isLoading: false,
        })
      })
      .catch(() => {
        setState({
          data: null,
          isLoading: false,
          isError: "Dont load map",
        })
      })
  }, [])

  return state
}
