import { FC } from "react"
import { Link, useParams } from "react-router-dom"
import { Box, CardActions, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"

import classNames from "classnames"

import { EventModel } from "../../types"
import { YandexMapBox } from "../../common/yandex/YandexMapBox"
import { useAppDispatch } from "../../app/hooks"
import { useIsAuthorizedUser } from "../../hooks/useIsAuthorizedUser"
import { useGetDetailEvent } from "../../hooks/useGetDetailEvent"
import useModalControler from "../../hooks/useModalControler"
import { selectCoordinate } from "../../shared/selectors/address"

import eventPhoto from "../../app/data/avatarUrl"
import {
  setEventData,
  setEventDialogFormat,
} from "../../app/data/popupSlice/popupSlice"

import GradientBtn from "../../components/GradientBtn"
import CardAvatarRow from "../../components/CardEvent/CardAvatarRow"
import { EventDialogFormats } from "../../components/popups/EventDialog"
import EventDialog from "../../components/popups/EventDialog"
import { show } from "../../pages/signIn/SignInSlice"
import { ErrorPage } from "./Error"
import { Loading } from "./Loading"

import CalendarIconCard from "/src/assets/svg/card-icons-pack/calendar-icon.svg?react"
import ClockIconCard from "/src/assets/svg/card-icons-pack/clock-icon.svg?react"
import LocationIconCard from "/src/assets/svg/card-icons-pack/location-icon.svg?react"
import ShareIconCard from "/src/assets/svg/card-icons-pack/share-icon.svg?react"
import WalletIconCard from "/src/assets/svg/card-icons-pack/Wallet-icon.svg?react"
import LinkIconCard from "/src/assets/svg/card-icons-pack/link-icon.svg?react"

import {
  cardInfo,
  cardInfoIcon,
  cardTitle,
} from "../../components/CardEvent/CardEvent/CardEventStyles"
import s from "./styles.module.css"

const FidView: FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const { isLoading, error, data: eventInfo } = useGetDetailEvent(id)

  const isUserAuth = useIsAuthorizedUser()
  const dispatch = useAppDispatch()
  const { mounted, handleToggleMounted } = useModalControler()

  if (isLoading) return <Loading />
  if (error) return <ErrorPage />

  const event: EventModel = {
    id: Number(id),
    img: eventInfo?.event_photo?.[0]
      ? eventPhoto(eventInfo.event_photo[0])
      : "",
    location: `${eventInfo?.event_address?.city}, ${
      eventInfo?.event_address?.street ?? ""
    } ${eventInfo?.event_address?.house_number ?? ""}`,
    time: eventInfo?.start_time ?? "",
    title:
      eventInfo?.event_name?.charAt(0).toUpperCase() +
      (eventInfo?.event_name?.slice(1) ?? ""),
    description: eventInfo?.description ?? "",
    isFavorite: Boolean(eventInfo?.favorite_event),
    isFree: Boolean(eventInfo?.favorite_event),
    coordinates: [
      eventInfo?.event_address?.event_latitude ?? 0,
      eventInfo?.event_address?.event_longitude ?? 0,
    ],
    participants: eventInfo?.users_who_participants_of_event,
  }

  let organizerUrl = eventInfo?.contact_info?.find(
    (item) => item?.contact_type === "URL",
  )?.contact

  if (organizerUrl && !organizerUrl.startsWith("http")) {
    organizerUrl = `//${organizerUrl}`
  }

  const eventDatetime = Date.parse(eventInfo?.start_time || "")
  const dateFormatter = new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const timeFormatter = new Intl.DateTimeFormat("ru", {
    hour: "numeric",
    minute: "numeric",
  })

  const handleBtnClick = (format: EventDialogFormats) => {
    if (format === EventDialogFormats.invite && !isUserAuth) {
      dispatch(show())
    } else {
      dispatch(setEventData(event))
      dispatch(setEventDialogFormat(format))
      handleToggleMounted()
    }
  }

  const HeaderContent = () => {
    return (
      <>
        <h3 className={s.title}>{event.title}</h3>
        <Box sx={cardInfo}>
          <div style={cardInfoIcon}>
            <LocationIconCard />
            <Typography variant="body2">{event.location}</Typography>
          </div>
        </Box>
        <CardActions
          sx={{
            display: "flex",
            p: 0,
            m: 0,
            width: "fit-content",
          }}
        >
          <GradientBtn
            title="Я пойду"
            aria-label="i will go"
            btnStyle={{
              width: "fit-content",
              minWidth: "128px",
              padding: "12px 16px",
            }}
            handleAction={() => handleBtnClick(EventDialogFormats.invite)}
          />
          <IconButton
            aria-label="share"
            onClick={() => handleBtnClick(EventDialogFormats.share)}
          >
            <ShareIconCard
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </IconButton>
        </CardActions>
      </>
    )
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {event.img ? (
          <div
            className={s.avatar_box}
            style={{
              backgroundImage: `url(${event.img})`,
            }}
          >
            <div className={s.container}>
              <div className={s.header_content}>
                <HeaderContent />
              </div>
            </div>
          </div>
        ) : (
          <div className={s.container}>
            <div
              className={classNames(
                s.header_content,
                s.header_content_no_photo,
              )}
            >
              <HeaderContent />
            </div>
          </div>
        )}
        <div className={classNames(s.container, s.container_content)}>
          <div className={s.description_box}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={cardTitle}
            >
              Описание:
            </Typography>
            <Typography variant="body2" color={`var(--secondary-text)`}>
              {event.description}
            </Typography>
          </div>
          {organizerUrl && (
            <div className={s.description_box}>
              <Box sx={cardInfo}>
                <Link to={organizerUrl} style={cardInfoIcon}>
                  <LinkIconCard stroke={"var(--secondary-text)"} />
                  <Typography variant="body2" color={`var(--secondary-text)`}>
                    Перейти на{" "}
                    <span
                      style={{
                        color: "var(--filter-range-active)",
                      }}
                    >
                      сайт организатора
                    </span>
                  </Typography>
                </Link>
              </Box>
            </div>
          )}
          <div className={s.description_box}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={cardTitle}
            >
              Дата и время проведения:
            </Typography>
            <Box sx={cardInfo}>
              <div style={cardInfoIcon}>
                <CalendarIconCard stroke={"var(--secondary-text)"} />
                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {dateFormatter.format(eventDatetime)}
                </Typography>
              </div>
            </Box>
            <Box sx={cardInfo}>
              <div style={cardInfoIcon}>
                <ClockIconCard stroke={"var(--secondary-text)"} />
                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {timeFormatter.format(eventDatetime)}
                </Typography>
              </div>
            </Box>
          </div>
          <div className={s.description_box}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={cardTitle}
            >
              Стоимость:
            </Typography>
            <Box sx={cardInfo}>
              <div style={cardInfoIcon}>
                <WalletIconCard stroke={"var(--secondary-text)"} />
                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {event.isFree ? "Бесплатно" : "Требуется плата за вход"}
                </Typography>
              </div>
            </Box>
            {event.participants && (
              <CardAvatarRow avatars={event.participants} />
            )}
          </div>
          <div className={s.description_box}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={cardTitle}
            >
              Расположение:
            </Typography>
            <div className={s.map}>
              {eventInfo && eventInfo.event_address && (
                <YandexMapBox
                  width={300}
                  height={280}
                  center={selectCoordinate(eventInfo.event_address)}
                  zoom={9}
                />
              )}
            </div>
            <Box sx={cardInfo}>
              <div style={cardInfoIcon}>
                <LocationIconCard fill={"var(--secondary-text)"} />
                <Typography variant="body2" color={`var(--secondary-text)`}>
                  {event.location}
                </Typography>
              </div>
            </Box>
          </div>
        </div>{" "}
      </Box>
      <EventDialog
        isVisible={mounted}
        visibleToggler={handleToggleMounted}
        eventLink={`fid/${id}`}
      />
    </>
  )
}

export default FidView
