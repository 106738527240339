import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { HashRouter } from "react-router-dom"
import App from "./App"
import { store } from "./app/store"
import "./index.css"
import { YandexMapProvider } from "./common/yandex/provider/YandexMapProvider"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <HashRouter>
      <YandexMapProvider>
        <App />
      </YandexMapProvider>
    </HashRouter>
  </Provider>,
)
