import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EventInfo, SearchEventsData, SearchEventsState } from "./types";

const initialState: SearchEventsState = { data: null, isLoading: false }

export const searchEventsSlice = createSlice({
  name: "search/events",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload
    },
    updateEventList: (state, action: PayloadAction<SearchEventsData<EventInfo>>) => {
      state.data = action.payload
    },
  },
})
export const { setLoading, setError, updateEventList } =
  searchEventsSlice.actions
export default searchEventsSlice.reducer
