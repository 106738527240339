export function calculateTimeDifference(startDate: string, endDate: string) {
  const startDateTime: any = new Date(startDate);
  const endDateTime: any = new Date(endDate);

  if (isNaN(startDateTime) || isNaN(endDateTime)) {
    return "-";
  }

  const timeDifference = endDateTime - startDateTime;

  if (timeDifference < 0) {
    return "-";
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  let result = "";

  if (days > 0) {
    result += `${days}д `;
  }

  if (hours > 0) {
    result += `${hours}ч `;
  }

  if (minutes > 0 || (days === 0 && hours === 0)) {
    result += `${minutes}м`;
  }

  return result.trim();
}

export function getRussianDate(dateString: string) {
  if (!dateString) {
    return "-";
  }
  const monthNames = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
  ];

  const date = new Date(dateString);

  const monthNumber = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  return `${monthNames[monthNumber]} ${day}, ${year}`;
}


export const getTimeZone = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const sign = offset > 0 ? "-" : "+";
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.abs(offset % 60);

  const formattedTimeZone = `GMT${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
  return formattedTimeZone;
};
