import { EventModel } from "../../types";
import React, { FC } from "react";
import CardEvent from "../CardEvent/CardEvent";

export type FilteredEventListProps = {
  events:  EventModel[]
  handleToggleMounted: () => void
  setModalLink: React.Dispatch<React.SetStateAction<string>>
}
export const FilteredEventList: FC<FilteredEventListProps> = ({events, handleToggleMounted, setModalLink}) => {
  return <>
    {events.map((item, i) => {
      return (
        <CardEvent
          setModalLink={setModalLink}
          key={item.id}
          event={item}
          modalToggler={handleToggleMounted}
        />
      )
    })}
  </>
}

