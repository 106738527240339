import { Avatar } from "@mui/material"
import { FC } from "react"
import ChooseUserIcon from "../../../../../../assets/svg/choose_user.svg?react"
import { TUser } from "../../../../types"
import s from "./user.module.scss"

type TUserProps = {
  user: TUser
  choose: (user: TUser) => void
  invitedIds: Array<number>
}

const User: FC<TUserProps> = (props) => {
  const { user, choose, invitedIds } = props

  if (!user) {
    return <></>
  }

  return (
    <div key={user.id} className={s.user} onClick={() => choose(user)}>
      <div className={s.avatar}>
        <Avatar
          src={user.user_avatar}
          sx={{
            borderRadius: "50%",
            height: "50px",
            width: "50px",
            border: invitedIds.some((id: number) => id === user.id)
              ? "2px solid var(--profile-ntfc-text)"
              : "",
          }}
        />

        {invitedIds.some((id: number) => id === user.id) && (
          <div className={s.choose_icon}>
            <ChooseUserIcon />
          </div>
        )}
      </div>
      <div className={s.user_info}>
        <p
          className={s.nickname}
          style={{
            color: invitedIds.some((id: number) => id === user.id)
              ? "var(--profile-ntfc-text)"
              : "",
          }}
        >
          {user.username}
        </p>
        <p
          className={s.fullname}
          style={{
            color: invitedIds.some((id: number) => id === user.id)
              ? "var(--profile-ntfc-text)"
              : "",
          }}
        >
          {`${user.first_name} ${user.last_name}`}
        </p>
      </div>
    </div>
  )
}

export default User
