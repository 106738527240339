import { Box, Typography } from "@mui/material"
import { FC, useState } from "react"
import {
  CashiersData,
  PromoCard,
  useDeleteCashier,
} from "../../../../app/data/promotion/promotionApi"
import CardAvatar from "../../../../components/CardEvent/CardAvatar"
import PromotionModal from "../../PromotionModal"
import ParticipantsPeopleCard from "../../PromotionModal/ParticipantsPeopleCard"
import * as s from "../../PromotionModal/style"
import * as st from "./style"

type Props = {
  cashier: CashiersData
  event: PromoCard
}

export const CashiersCardDetails: FC<Props> = ({ cashier, event }) => {
  const {
    avatar_url: avatarUrl,
    cashier_id: id,
    firstname,
    lastname,
    participant_count,
    phone_number,
  } = cashier
  const cashierFullName = `${firstname} ${lastname}`
  const [deleteCashier] = useDeleteCashier()
  const [animation, setAnimation] = useState(s.fadeIn)
  const [isShow, setIsShow] = useState<"invited" | "participants" | "">("")
  const cardTitle =
    isShow === "invited"
      ? `${cashierFullName} пригласил на:`
      : `${cashierFullName} добавил участников на:`

  const handleClose = () => {
    setIsShow("")
    setAnimation(s.fadeIn)
  }

  return (
    <>
      <PromotionModal
        cardTitle={cardTitle}
        cardName={event.name}
        isLoading={false}
        handleClickAction={handleClose}
        isModal={!!isShow}
        animation={animation}
      >
        <ParticipantsPeopleCard eventId={event.id} cashierId={id} />
      </PromotionModal>

      <Box sx={st.card}>
        <Box sx={st.userInfoCont}>
          <CardAvatar
            alt={cashierFullName}
            src={avatarUrl}
            styles={st.avatar}
          />

          <Box sx={st.btnCont}>
            <Typography sx={st.btnTitle}>{cashierFullName}</Typography>

            <Typography sx={st.btnInfo2}>{phone_number}</Typography>
          </Box>
        </Box>

        <Box
          sx={st.btnInfoCont}
          onClick={() => participant_count > 0 && setIsShow("participants")}
        >
          <Typography sx={st.btnTitle}>Участники</Typography>

          <Typography sx={st.btnInfo}>{participant_count}</Typography>
        </Box>

        <Box sx={st.rateStyle}>
          <Typography sx={st.btnTitle}>Результат работы:</Typography>

          <Typography sx={st.btnInfo}>{0}%</Typography>
        </Box>

        {/* <Box sx={st.deleteUser} onClick={() => deleteCashier(id)}>
          <Pencil
            color="var(--filter-range-active)"
            width="20px"
            height="20px"
          />

          <Typography sx={st.deleteUserText}>Разжаловать</Typography>
        </Box> */}
      </Box>
    </>
  )
}

export default CashiersCardDetails
