import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { SerializedError } from "@reduxjs/toolkit"

export const extractErrorMsg = (
  error: FetchBaseQueryError | SerializedError,
): string => {
  if ("status" in error) {
    return "error" in error ? error.error : "Не верно введен код"
  } else {
    return error.message ?? "Error"
  }
}

export type IoError = {
  code?: number
  status: string
  error: string
}

export const ioErrorOf = (
  error: FetchBaseQueryError | SerializedError | undefined,
): IoError | undefined => {
  if (!error) return undefined
  if ("status" in error) {
    if (typeof error.status === "number") {
      return {
        code: error.status,
        error: error?.data?.toString() ?? "UNKNOWN_HTTPS_ERROR",
        status: "HTTPS_ERROR",
      }
    }
    return { error: error.error, status: error.status }
  } else {
    return {
      error: error.message ?? "SerializedError",
      status: "SerializedError",
    }
  }
}
