import React, { FC, useState } from "react"
import s from "./styles.module.css"
import Logo from "../../assets/svg/logo_auth.svg?react"
import Step1 from "./step_1"
import Step2 from "./step_2"
import Step3 from "./step_3"

export type ResetFormProps = {
  login: string
  back: () => void
}
export type ResetFormSteps = 1 | 2 | 3
const ResetForm: FC<ResetFormProps> = ({ login, back }) => {
  const [step, setStep] = useState<ResetFormSteps>(1)
  const [code, setCode] = useState<number>(0)

  return (
    <div className={s.content}>
      <div className={s.logo}>
        <Logo />
      </div>

      {step === 1 && <Step1 email={login} back={back} setStep={setStep} />}
      {step === 2 && <Step2 onCode={setCode} login={login} setStep={setStep} />}
      {step === 3 && <Step3 code={code} back={back} />}
    </div>
  )
}

export default ResetForm
