export const feedPageCont = {
  height: "100%",
  width: "100%",
}

export const feedCardsCont = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(302px, 589px))",
  rowGap: "24px",
  columnGap: "8px",
  height: "100%",
  marginBottom: "16px",

  "@media (max-width: 638px)": {
    placeItems: "center",
  },

  "@media (min-width: 1024px)": {
    gridTemplateColumns: "repeat(auto-fill, minmax(316px, 1fr))",
  },
}
