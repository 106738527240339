import * as api from "../app/data/tribeSwaggerApi"
import { useEffect, useState } from "react"

export type UploadFotoQuery = {
  upload: (image: ArrayBuffer) => void
  isLoading: boolean
  error?: string
  resultUrl?: string
}

export const useUploadFotoToServer = (): UploadFotoQuery => {
  const [trigger, { isLoading, error, data }] =
    api.useAddEventAvatarToTempDirectoryMutation()
  const [result, setResult] = useState<string | undefined>()
  useEffect(() => {
    if (data) {
      setResult(data.unique_file_name)
    }
  }, [data])
  const uploadHandler = (image: ArrayBuffer) => {
    const uint8Array = new Uint8Array(image)
    trigger({
      imageDto: {
        // @ts-ignore
        image: [...uint8Array],
        content_type: "image/jpeg",
      },
    })
  }

  return {
    isLoading: isLoading,
    upload: uploadHandler,
    error: error ? "Ошибка загрузки" : undefined,
    resultUrl: result,
  }
}
