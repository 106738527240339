import { FC, useEffect, useRef } from "react"
import { useTheme } from "../../hooks/useTheme"
import { useYandexMap } from "./yandex-map"

export type YandexMapBoxProps = {
  center: [number, number]
  height: number
  width: number
  zoom: number
}
export const YandexMapBox: FC<YandexMapBoxProps> = (
  props: YandexMapBoxProps,
) => {
  const { data, isLoading } = useYandexMap()
  const ref = useRef<HTMLDivElement | null>(null)
  const { isDarkTheme } = useTheme()

  useEffect(() => {
    if (ref.current && data) {
      const map = new data.YMap(ref.current, {
        location: {
          center: props.center,
          zoom: props.zoom,
        },
        theme: isDarkTheme ? "dark" : "light",
      })
      const markerElement = document.createElement("div")
      Object.assign(markerElement.style, {
        width: "16px",
        height: "16px",
        background: `var(--profile-ntfc-text)`,
        borderRadius: "50%",
        border: "2px solid var(--main-bg)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
      })
      map.addChild(new data.YMapDefaultSchemeLayer({}))
      map.addChild(new data.YMapDefaultFeaturesLayer({}))
      map.addChild(
        new data.YMapMarker({ coordinates: props.center }, markerElement),
      )
      return () => {
        map.destroy()
      }
    }
  }, [data, isLoading, props.center])

  return (
    // <section ref={ref} style={{ height: props.height, width: props.width }} />
    <section ref={ref} style={{ height: "100%", width: "100%" }} />
  )
}
