import { Close } from "@mui/icons-material"
import { Box, Modal, ThemeProvider, Typography } from "@mui/material"
import { ReactNode } from "react"
import Loading from "../Loading"
import * as s from "./style"

export type Action = "left" | "right" | "submit" | "close"

type Props = {
  isModal: boolean
  handleClickAction: () => void
  cardTitle: string
  isLoading: boolean
  errorTitle?: string
  children: ReactNode
  animation: string
  cardName?: string
}

export default function PromotionModal({
  isModal,
  handleClickAction,
  cardTitle,
  isLoading,
  errorTitle,
  children,
  animation,
  cardName,
}: Props) {
  return (
    <ThemeProvider theme={s.theme}>
      <Modal open={isModal}>
        <Box sx={{ ...s.cardCont, animation: `${animation} 0.35s ease` }}>
          <Box sx={s.style}>
            <Close onClick={handleClickAction} sx={s.closeBtnStyle} />

            {isLoading && !errorTitle && (
              <Loading styles={{ position: "absolute", top: 0, left: 0 }} />
            )}

            {!isLoading && errorTitle && (
              <Box>
                <Typography sx={s.textHeader}>{errorTitle}</Typography>
              </Box>
            )}

            <Box>
              <Typography sx={s.textHeader}>{cardTitle}</Typography>

              {cardName && (
                <Typography variant="body1" fontWeight={400}>
                  {cardName}
                </Typography>
              )}
            </Box>

            {children}
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  )
}
