import { AddressLocation } from "../../../../../app/data/location/schema";

export const defaultCityList: readonly AddressLocation[] = [
  {
    "region": "Москва",
    "region_type": "г",
    "region_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
    "area": "",
    "area_type": "",
    "area_fias_id": "",
    "city": "Москва",
    "city_type": "г",
    "city_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
    "settlement": "",
    "settlement_type": "",
    "settlement_fias_id": "",
    "street": "",
    "street_type": "",
    "street_fias_id": "",
    "house": "",
    "block": "",
    "block_type": "",
    "lat": "55.75396",
    "lon": "37.620393"
  },
  {
    "region": "Санкт-Петербург",
    "region_type": "г",
    "region_fias_id": "c2deb16a-0330-4f05-821f-1d09c93331e6",
    "area": "",
    "area_type": "",
    "area_fias_id": "",
    "city": "Санкт-Петербург",
    "city_type": "г",
    "city_fias_id": "c2deb16a-0330-4f05-821f-1d09c93331e6",
    "settlement": "",
    "settlement_type": "",
    "settlement_fias_id": "",
    "street": "",
    "street_type": "",
    "street_fias_id": "",
    "house": "",
    "block": "",
    "block_type": "",
    "lat": "59.939084",
    "lon": "30.315879"
  },
  {
    "region": "Татарстан",
    "region_type": "Респ",
    "region_fias_id": "0c089b04-099e-4e0e-955a-6bf1ce525f1a",
    "area": "",
    "area_type": "",
    "area_fias_id": "",
    "city": "Казань",
    "city_type": "г",
    "city_fias_id": "93b3df57-4c89-44df-ac42-96f05e9cd3b9",
    "settlement": "",
    "settlement_type": "",
    "settlement_fias_id": "",
    "street": "",
    "street_type": "",
    "street_fias_id": "",
    "house": "",
    "block": "",
    "block_type": "",
    "lat": "55.794438",
    "lon": "49.111451"
  },
  {
    "region": "Свердловская",
    "region_type": "обл",
    "region_fias_id": "92b30014-4d52-4e2e-892d-928142b924bf",
    "area": "",
    "area_type": "",
    "area_fias_id": "",
    "city": "Екатеринбург",
    "city_type": "г",
    "city_fias_id": "2763c110-cb8b-416a-9dac-ad28a55b4402",
    "settlement": "",
    "settlement_type": "",
    "settlement_fias_id": "",
    "street": "",
    "street_type": "",
    "street_fias_id": "",
    "house": "",
    "block": "",
    "block_type": "",
    "lat": "56.838607",
    "lon": "60.605514"
  }
]