import _ from "lodash"
const baseUrl = import.meta.env.VITE_API_BASE_URL

export interface FetchResult<T> {
  data: T
  error?: string
}
export const isCheckUsername = async (
  username: string | undefined,
): Promise<FetchResult<boolean>> => {
  if (!username)
    return {
      data: true,
      error: "Обязательное поле",
    }
  try {
    const url = `${baseUrl}/api/v1/user/username/check/${username}`
    const response = await fetch(url)
    const result = (await response.json()) as boolean

    return result
      ? {
          data: result,
          error: "Имя пользователя уже занято",
        }
      : {
          data: result,
        }
  } catch (error) {
    return {
      data: true,
      error: "Проблема с запросом к серверу",
    }
  }
}

export const isCheckEmail = async (
  email: string | undefined,
): Promise<FetchResult<boolean>> => {
  if (!email)
    return {
      data: true,
      error: "Обязательное поле",
    }
  try {
    const url = `${baseUrl}/api/v1/user/email/check/${email}`
    const response = await fetch(url)
    const result = (await response.json()) as boolean
    return result
      ? {
          data: result,
          error: "Данная почта уже занята",
        }
      : {
          data: result,
        }
  } catch (error) {
    return {
      data: true,
      error: "Проблема с запросом к серверу",
    }
  }
}
