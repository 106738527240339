import { useEffect, useState } from "react"
import { Box } from "@mui/material"

import { useAppDispatch } from "../../../../app/hooks"
import { useIsAuthorizedUser } from "../../../../hooks/useIsAuthorizedUser"
import { show } from "../../../../pages/signIn/SignInSlice"
import HeaderIconBtn from "../HeaderIconsWrapper"
import { HeaderBtnName } from "../HeaderIconsWrapper/enum"
import HeaderProfileMenu from "../HeaderProfileMenu"
import avatar from "/src/assets/svg/header-icons-pack/avatar-empty-icon.svg"

export const HeaderIcons = () => {
  const isUserAuth = useIsAuthorizedUser()
  const dispatch = useAppDispatch()
  const [activeBtn, setActiveBtn] = useState<HeaderBtnName | null>(null)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const isOrganizerRole = true

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100vh"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
  }, [isOpenMenu])

  const handleProfileMenuOpen = (): void => {
    if (!isUserAuth) {
      dispatch(show())
    } else {
      setIsOpenMenu(!isOpenMenu)
      setActiveBtn(null)
    }
  }

  return (
    <Box onClick={handleProfileMenuOpen}>
      <HeaderIconBtn
        icon={<img src={avatar} alt="profile icon" />}
        setActiveBtn={setActiveBtn}
        activeBtn={activeBtn}
        title={HeaderBtnName.profile}
      />
      <HeaderProfileMenu
        isOpenMenu={isOpenMenu}
        // isOrganizerRole={isOrganizerRole}
      />
    </Box>
  )
}

/* OLD COMPONENT CODE: keep for reference

import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import SettingsIcon from "@mui/icons-material/Settings"
import { Box, Menu, MenuItem } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { userLogout } from "../../../app/data/appTokensStore"
import { useAppDispatch } from "../../../app/hooks"
import ProfileUserIcon from "../../../assets/svg/menu-icons-pack/user-icon-menu.svg?react"
import { logout } from "../../../features/authorization_page/AuthorizationSlice"
import { useIsAuthorizedUser } from "../../../hooks/useIsAuthorizedUser"
import { show } from "../../../pages/signIn/SignInSlice"
import HeaderIconBtn from "../HeaderIconsWrapper"
import { HeaderBtnName } from "../HeaderIconsWrapper/enum"
import { NotificationMenu } from "../NotificationMenu/NotificationMenu"
import { iconBtns, listContainer, menuItemTab } from "./headerIconsStyle"
import avatar from "/src/assets/svg/header-icons-pack/avatar-empty-icon.svg"
import ntfcnIcon from "/src/assets/svg/header-icons-pack/notification-icon.svg"

const HeaderIcons = () => {
  const isAuthorization = useIsAuthorizedUser()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const screenUserWidth = window.screen.width
  const [activeBtn, setActiveBtn] = useState<HeaderBtnName | null>(null)

  const [anchorProfile, setAnchorProfile] = useState<null | HTMLElement>(null)
  const isProfileOpen = Boolean(anchorProfile)

  const [anchorNtfctn, setAnchorNtfctn] = useState<null | HTMLElement>(null)
  const isOpenNtfctn = Boolean(anchorNtfctn)

  const showLoginWindow = () => {
    dispatch(show())
  }

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLElement> | null,
  ) => {
    if (anchorProfile || !event) {
      setAnchorProfile(null)
      setActiveBtn(null)
    } else {
      setAnchorNtfctn(null)
      setAnchorProfile(event.currentTarget)
    }
  }

  const handleOpenNtfctn = (event: React.MouseEvent<HTMLElement> | null) => {
    if (anchorNtfctn || !event) {
      setAnchorNtfctn(null)
      setActiveBtn(null)
    } else {
      setAnchorProfile(null)
      setAnchorNtfctn(event.currentTarget)
    }
  }

  const handleClickExit = () => {
    setAnchorProfile(null)
    dispatch(logout())
    dispatch(userLogout())
    navigate("/")
  }

  const menuId = "primary-search-account-menu"

  const profileMenu = (
    <Menu
      anchorEl={anchorProfile}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isProfileOpen}
      onClose={() => handleProfileMenuOpen(null)}
      sx={listContainer}
    >
      <MenuItem
        onClick={(e) => {
          navigate("/profile")
          handleProfileMenuOpen(e)
        }}
      >
        <ProfileUserIcon style={menuItemTab} />
        Профиль
      </MenuItem>

      <MenuItem
        onClick={(e) => {
          navigate("/profile/settings/profile_view")
          handleProfileMenuOpen(e)
        }}
      >
        <SettingsIcon sx={menuItemTab} />
        Настройки
      </MenuItem>

      <MenuItem onClick={handleClickExit}>
        <ExitToAppIcon sx={menuItemTab} />
        Выйти
      </MenuItem>
    </Menu>
  )

  return isAuthorization ? (
    <>
      {screenUserWidth > 320 && (
        <>
          <HeaderIconBtn
            icon={<img src={avatar} alt="Профиль - не зарегистрирован" />}
            handleBtnClick={handleProfileMenuOpen}
            setActiveBtn={setActiveBtn}
            activeBtn={activeBtn}
            title={HeaderBtnName.profile}
          />
          {profileMenu}
        </>
      )}
    </>
  ) : (
    <Box onClick={() => showLoginWindow()} sx={iconBtns} aria-controls={menuId}>
      <img src={avatar} alt="Профиль - не зарегистрирован" />
    </Box>
  )
}

export default HeaderIcons
 */
