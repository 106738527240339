import { EventInFavoriteDto } from "../../app/data/tribeSwaggerApi"
import { FavoriteEvent } from "../../pages/favorite/types"

export const favoriteDtoToModel = (dto: EventInFavoriteDto): FavoriteEvent => {
  return {
    event_address: dto.event_address!!,
    event_id: dto.event_id,
    event_name: dto.event_name,
    event_photo: dto.event_photo ? dto.event_photo : [],
    event_type_name: dto.event_type_name ? dto.event_type_name : "",
    is_deleted: dto.is_deleted,
    is_finished: dto.is_finished,
    start_time: dto.start_time,
  }
}
