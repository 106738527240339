import { AddressLocation } from "../../../../../../app/data/location/schema";

export const labelOfAddress = (address:AddressLocation): string =>{
  const {street, house, street_type} = address
  if (house.trim() === ""){
    return `${street_type}. ${street}`
  } else {
    return `${street_type}. ${street} ${house}`
  }
}
export const labelOfRegion = (address:AddressLocation): string =>{
  const { city_type, city, settlement_type, settlement} = address
  if (settlement.trim() === '') {
    return `${city_type}. ${city}`
  } else {
    return `${settlement_type}. ${settlement}, ${city_type}. ${city}`
  }
}