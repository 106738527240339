import { FilterParams } from "./types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectFilter } from "../../selectors";
import { filterToURLSearchParams } from "../../filterBuilderParams/filterToSearchParams";
import { urlSearchParamsToFilter } from "../../filterBuilderParams/urlSearchParamsToFilter";
import { updateFilter } from "../../filterSlice";

const useFilterAsSearchParams = (): FilterParams => {
  const currentFilter = useAppSelector(selectFilter)
  const dispatch = useAppDispatch()
  const setParams = (params: URLSearchParams) => {
    dispatch(updateFilter(urlSearchParamsToFilter(params)))
  }

  return [filterToURLSearchParams(currentFilter), setParams]
}

export default useFilterAsSearchParams