import React, { FC, useEffect, useState } from "react";
import InputPassword from "../../../../../common/ui-kit/inputs/input_password";
import { useValidateFormikData } from "../../../hooks/useValidateFormikData";
import { TStepProps } from "../../../types";
import Buttons from "../../common/buttons";
import Title from "../../common/title";
import s from "./styles.module.css";
import InputDate from "../../../../../common/ui-kit/inputs/input_date";
import InputSelect from "../../../../../common/ui-kit/inputs/input_select";
import { CommonEventSchema } from "./schema";
import { useEventsTypes } from "./useEventsTypes";
import { EventModeVariants, EventPrivateSelect, EventPrivateSelectList, EventType, EventTypeSelection } from "./types";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { selectEventMode, selectEventType } from "../../../slice/selectors";
import { setEventMode, setEventTypeId } from "../../../slice/MakeEventSlice";

type TSelectOption = {
  value: boolean
  label: "Закрытое" | "Открытое"
}


const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};


const Step1: FC<TStepProps> = ({ makeStep, step, formik }) => {
    const { event_name, is_private, start_time, end_time, event_type_id } =
      formik.values;
    const isFieldEmpty = useValidateFormikData(CommonEventSchema, {
      is_private,
      event_name,
      start_time,
      end_time,
      event_type_id
    });
    const eventMode = useAppSelector(selectEventMode)
    const eventTypeInfo = useAppSelector(selectEventType)
    const dispatch = useAppDispatch();
    const setEventModeCallback = (newValue: EventPrivateSelect) => {
      formik.setFieldValue("is_private", newValue.value);
      dispatch(setEventMode(newValue));
    };

    const { eventTypesList, isLoading: EventsIsLoading } = useEventsTypes();

    const typeEventChange = (newValue: EventTypeSelection) => {
      formik.setFieldValue("event_type_id", newValue.value)
      dispatch(setEventTypeId(newValue))
    };
    return (
      <div className={s.container}>
        <Title align={"flex-start"} title={"Создание события"} />
        <div className={s.input_content}>
          <InputSelect
            isLoading={EventsIsLoading}
            onChange={typeEventChange}
            value={eventTypeInfo}
            title={"Тип события"}
            options={eventTypesList.map(m => ({
              value: m.id,
              label: m.type_name
            }))}
            placeholder={"Выберите тип события"}
            defaultInputValue={""}
            errorText={null} handleChange={() => {
          }} name={""} onBlur={(e) => {
          }} type={""} />
          <InputPassword
            title={"Название"}
            name={"event_name"}
            value={formik.values.event_name}
            handleChange={formik.handleChange}
            type={"text"}

            placeholder={"Название"}
            errorText={null}
            onBlur={() => {
            }}
          />
          <InputSelect
            onChange={setEventModeCallback}
            value={eventMode}
            title={"Конфиденциальность события"}
            options={EventModeVariants}
            placeholder={"Открытое/Закрытое"}
            defaultInputValue={""}
            errorText={null} handleChange={() => {
          }} name={""} onBlur={(e) => {
          }} type={""}
          />

          <div className={s.time_and_date_box}>
            <InputDate
              title={"Дата начала"}
              value={start_time}
              onChange={(e) => {
                console.log(e);
                formik.setFieldValue("start_time", e.target.value);

                const choosenEndDate = new Date(end_time);
                const choosenStartDate = new Date(e.target.value);
                if (choosenEndDate <= choosenStartDate) {
                  formik.setFieldValue("end_time", "");
                }
              }}
              min={getCurrentDateTime()}
              max="2030-12-31T00:00"
              onBlur={(e) => {
                const choosenDate = new Date(start_time);
                const currentDate = new Date(getCurrentDateTime());

                if (choosenDate < currentDate) {
                  formik.setFieldValue("start_time", start_time);
                }
              }}
              errorText={null} handleChange={() => {
            }} name={""} placeholder={""} type={""} />
          </div>


          <div className={s.time_and_date_box}>
            <InputDate
              title={"Дата окончания"}
              value={end_time}
              onChange={(e) => {
                formik.setFieldValue("end_time", e.target.value);

                const choosenEndDate = new Date(e.target.value);
                const choosenStartDate = new Date(start_time);
                if (choosenEndDate < choosenStartDate) {
                  formik.setFieldValue("start_time", "");
                }
              }}
              type="datetime-local"

              max="2030-12-31T00:00"
              min={getCurrentDateTime()}
              errorText={null} handleChange={() => {
            }} name={""} onBlur={(e) => {
            }} placeholder={""} />
          </div>
        </div>
        <Buttons
          disabled={isFieldEmpty}
          step={step}
          next={() => {
            if (isFieldEmpty) {
              return;
            }
            makeStep("+");
          }}
          back={() => makeStep("-")}
        />

      </div>
    );
  }
;

export default Step1;
