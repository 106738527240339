import type { SVGProps } from "react"

export function FaAngleRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.48rem"
      height="1rem"
      viewBox="0 0 608 1280"
      {...props}
    >
      <g transform="translate(608 0) scale(-1 1)">
        <path
          fill="var(--primary-text)"
          d="M595 288q0 13-10 23L192 704l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L23 727q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23"
        ></path>
      </g>
    </svg>
  )
}
