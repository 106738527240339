import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { persistentStorage } from "./persistentStorage"

export interface TokenStorageModel {
  applicationToken?: string
  refreshToken?: string
  userId?: number
}

const externalStorage = persistentStorage()

export const tokenStorageSlice = createSlice({
  name: "tokenStorage",
  initialState: () => externalStorage.getData(),
  reducers: {
    setUserCredentials: (state, action: PayloadAction<TokenStorageModel>) => {
      const { payload } = action
      state.applicationToken = payload.applicationToken
      state.refreshToken = payload.refreshToken
      state.userId = payload.userId
      externalStorage.setData(payload)
    },
    userLogout: (state) => {
      state.applicationToken = undefined
      state.refreshToken = undefined
      state.userId = undefined
      externalStorage.clean()
    },
    updateApplicationToken: (state, action: PayloadAction<string>) => {
      state.applicationToken = action.payload
      const newState = { ...state, applicationToken: action.payload }
      externalStorage.setData(newState)
    },
  },
})

export const selectUserId = (state: RootState): number | undefined =>
  state.tokenStorage.userId

export const selectApplicationToken = (state: RootState) =>
  state.tokenStorage.applicationToken

export const selectRefreshToken = (state: RootState) =>
  state.tokenStorage.refreshToken

export const { setUserCredentials, updateApplicationToken, userLogout } =
  tokenStorageSlice.actions

export default tokenStorageSlice.reducer
