import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const actionsCheckbox: SxProps<Theme> = {
  padding: "0",
  color: "var(--primary-text)",
  svg: {
    fill: "var(--primary-text)",
    stroke: "var(--primary-text)",
    width: "24px",
    height: "24px",
  },
}
