import classNames from "classnames"
import s from "./styles.module.css"

export enum GradienButtonStyles {
  borderGradient = "border",
  fullGradient = "full",
}

type GradientButtonProps<yourType> = {
  style: GradienButtonStyles
  fetchIsLoading?: boolean
  onAction: (props?: yourType) => void
  params: {
    width?: number
    text: string
    height?: number
    disabled?: boolean
  }
}

function GradientButton<yourType>({
  onAction,
  style,
  params,
  fetchIsLoading,
}: GradientButtonProps<yourType>) {
  const { text, height, width, disabled} = params

  const backgroundWidth = width && width - 3 // толщина бордера = 1.5px и того 1.5++
  const backgroundHeight = height && height - 3 // аналогично c width

  const handleCurrentAction = (e?: yourType) => {
    e ? onAction(e) : onAction()
  }

  return (
    <>
      {style === GradienButtonStyles.borderGradient ? (
        <button
          tabIndex={1}
          className={classNames(
            s.button_border,
            fetchIsLoading && s.disabled_button,
            disabled && s.disabled_button,
          )}  
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <div
            className={s.button_background}
            style={{
              width: `${backgroundWidth}px`,
              height: `${backgroundHeight}px`,
            }}
          >
            <div
              className={s.border_button}
              onClick={() => handleCurrentAction()}
            >
              {text}
            </div>
          </div>
        </button>
      ) : (
        <button
          onClick={() => handleCurrentAction()}
          className={classNames(
            s.full_button,
            fetchIsLoading && s.disabled_button,
            disabled && s.disabled_button,
          )}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          {text}
        </button>
      )}
    </>
  )
}

export default GradientButton
