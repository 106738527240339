import { useEffect, useState } from "react"
import eventPhoto from "../../../app/data/avatarUrl"
import * as api from "../../../app/data/tribeSwaggerApi"
import { SearchEventDto } from "../../../app/data/tribeSwaggerApi"
import { EventModel, FidViewState } from "../../../types"

export const useGetAllEvents = (): FidViewState => {
  const [viewModels, setViewModels] = useState<EventModel[] | null>(null)

  const { isLoading, isError, error, data } = api.useGetAllEventByFilterQuery({
    page: 0,
    size: 20,
    eventFilter: {},
  })

  useEffect(() => {
    const allEvents = async () => {
      try {
        const data = fetch("/api/v1/events")
      } catch (error) {}
    }
  }, [])

  useEffect(() => {
    if (data) {
      try {
        // @ts-ignore
        const result = data.content.map(mapDtoToModel)
        setViewModels(result)
      } catch (error) {
        //TODO("Нормально обработать ошибки")
      }
    }
  }, [data])
  return {
    data: viewModels,
    isError: isError,
    isLoading: isLoading,
    errorMessage: error?.toString() ?? "unknown error",
  }
}

function mapDtoToModel(dto: SearchEventDto): EventModel {
  const avatar = dto?.avatarUrl?.at(0)
  return {
    coordinates: [],
    calendar: dto.startTime ?? "",
    description: dto.description ?? "",
    id: dto?.eventId!!,
    img: avatar!!,
    location: dto.eventAddress?.city ?? "",
    time: dto.startTime ?? "",
    title: dto.eventName ?? "",
    isFavorite: !!dto.favoriteEvent,
  }
}
