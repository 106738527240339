import React, { FC } from "react"
import { NavLink } from "react-router-dom"
import s from "./styles.module.css"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"

export interface BackProps {
  link?: string
  top?: any
  left?: any
}
const Back: FC<BackProps> = ({ link = "/", top, left }: BackProps) => {
  return (
    <NavLink to={link} className={s.back} style={{ top: top, left: left }}>
      <ArrowBackIosNewIcon
        sx={{
          fontSize: "15px",
          marginRight: "3px",
          marginBottom: "2px",
          lineHeight: 0,
        }}
      />
    </NavLink>
  )
}

export default Back
