import { Alert, Avatar, Box, CircularProgress, Typography } from "@mui/material"
import { FC, useState } from "react"
import {
  InvitedUser,
  useGetEventParticipants,
} from "../../../../app/data/promotion/promotionApi"
import { transformDate } from "../../../../shared/transformDate/transformDate"
import { transformNameToNeededLength } from "../../../../shared/transformNameToNeededLength/transformNameToNeededLength"
import SearchPhoneEmployees from "../../SearchPhoneEmployees"
import * as s from "./style"

type Props = {
  eventId: number
  promoterId?: number
  cashierId?: number
}

const ParticipantsPeopleCard: FC<Props> = ({
  eventId,
  promoterId,
  cashierId,
}) => {
  const [query, setQuery] = useState("")
  const [isErrorPopup, setIsErrorPopUp] = useState("")
  const { data, isLoading, isError } = useGetEventParticipants({
    phone: query.slice(1).length < 6 ? "" : query.slice(1),
    eventId: eventId,
    promoterId: promoterId,
    cashierId: cashierId,
  })

  const handleSelectPerson = (user: InvitedUser) => {}

  return (
    <>
      <SearchPhoneEmployees
        errorTitle={isErrorPopup}
        serErrorTitle={setIsErrorPopUp}
        query={query}
        setQuery={setQuery}
      />

      <Box sx={{ ...s.contactsContOuter, flexGrow: 1 }}>
        <Box
          sx={{
            ...s.contactsContInner,
            justifyItems: isLoading ? "center" : "unset",
            alignContent: isLoading ? "center" : "start",
          }}
        >
          {isError && (
            <Alert severity="error">{`Упс, что то пошло не так....`}</Alert>
          )}

          {!data && isLoading && !isError && <CircularProgress />}

          {data && data.content.length === 0 && !isLoading && !isError && (
            <Alert severity="warning">
              <Typography>Пользователь не найден</Typography>
            </Alert>
          )}

          {data &&
            data.content.length > 0 &&
            !isLoading &&
            !isError &&
            data.content.map((user) => {
              const { firstname, lastname, phone_number, activated, id } = user
              const username = transformNameToNeededLength(firstname, lastname)
              const date = transformDate(activated)

              return (
                <Box
                  key={id}
                  onClick={() => handleSelectPerson(user)}
                  sx={s.contactStyle}
                >
                  <Avatar alt={username} src={""} sx={s.contactAvatarStyle} />

                  <Box sx={s.contactStyleItem}>
                    <Box sx={s.contactInfoStyle}>
                      <Typography variant="body1" fontWeight={500}>
                        {username}
                      </Typography>

                      <Typography variant="body2" fontWeight={400}>
                        {phone_number}
                      </Typography>
                    </Box>

                    <Typography
                      variant="caption"
                      color={"var(--unselected-text)"}
                      fontWeight={400}
                    >
                      {date}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
        </Box>
      </Box>
    </>
  )
}

export default ParticipantsPeopleCard
