import * as Api from "../../app/data/tribeSwaggerApi"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch } from "../../app/hooks"
import { setUserCredentials } from "../../app/data/appTokensStore"
import { extractErrorMsg } from "../../app/data/errors"

export interface ConfirmCodeData {
  fetchConfirmCode: (code: number, regId: number) => void
  isLoading: boolean
  isError: boolean
  error?: string
  isSuccessful: boolean
}
const useConfirmCodeFetch = (): ConfirmCodeData => {
  const [fetch, { isError, error, isLoading }] =
    Api.useConfirmEmailRegistrationMutation()
  const [ok, setOk] = useState<boolean>(false)
  const [fetchError, setFetchError] = useState<string | undefined>()
  const dispatch = useAppDispatch()
  const [code, setCode] = useState<number | null>(null)

  useEffect(() => {
    if (error) {
      setFetchError(extractErrorMsg(error))
    }
  }, [error])
  const handleCode = useCallback(
    (code: number, regId: number) => {
      setCode(code)
      fetch({
        confirmRegistrationDto: {
          registrant_id: regId,
          verification_code: code,
          firebase_id: "2f4wf",
        },
      })
        .unwrap()
        .then((value) => {
          dispatch(
            setUserCredentials({
              applicationToken: value.access_token,
              refreshToken: value.refresh_token,
              userId: value.user_id,
            }),
          )
          setOk(true)
        })
    },
    [code],
  )

  return {
    fetchConfirmCode: handleCode,
    error: fetchError,
    isError: isError,
    isLoading: isLoading,
    isSuccessful: ok,
  }
}
export default useConfirmCodeFetch
