import { SxProps, Theme } from "@mui/material"

export const notifCont: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
}

export const loadingStyle: SxProps<Theme> = {
  width: "60px !important",
  height: "60px !important",
}
