import { Box, Typography } from "@mui/material"
import GradientBtn from "../../../components/GradientBtn"
import { contentWrapper } from "./style"

type Props = {
  onClick: () => void
  title: string
  btnTitle: string
}

export default function NoPromotionEmployees({
  onClick,
  title,
  btnTitle,
}: Props) {
  return (
    <Box sx={contentWrapper}>
      <Typography variant="body2" color={"var(--unselected-text)"}>
        {title}
      </Typography>

      <GradientBtn
        contStyle={{ width: "max-content" }}
        btnStyle={{ width: "max-content", padding: "10px 20px" }}
        title={btnTitle}
        handleAction={onClick}
      />
    </Box>
  )
}
