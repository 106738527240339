import { FC } from "react"
import { selectSchema } from "../FilteredSelectedBox/types";
import FilteredSelectedBox from "../FilteredSelectedBox";
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";
import { PartOfDay } from "../../../shared/filter/types";

const schema: selectSchema<PartOfDay> = {
  items: [
    {
    name: "Утро",
    value: "MORNING",
    },
    {
      name: "День",
      value: "AFTERNOON",
    },
    {
      name: "Вечер",
      value: "EVENING",
    },
    {
      name: "Ночь",
      value: "NIGHT",
    },
  ]
}
export const FilterChooseDay: FC = () => {
  const {currentFilter, updatePartsOfDay} = useEventFilter()
  const {partsOfDay} = currentFilter
  const  selectHandler = (value: string) => {
    if (isPartOfDay(value)) {
      updatePartsOfDay(value)
    }
  }
 return <FilteredSelectedBox<PartOfDay> onSelect={selectHandler} title="Время проведения:" tabsMap={schema} value={partsOfDay ?? []} />
}


function isPartOfDay(value: any): value is PartOfDay {
  return value === "MORNING" || value === "AFTERNOON" || value === "EVENING" || value === "NIGHT";
}
