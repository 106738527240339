import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"
import { CSSProperties } from "react"

export const tabStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",

  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "500",

  color: "var(--primary-text)",
  background: "var(--surface-bg)",

  cursor: "pointer",
  transition: "all 0.3s ease",

  "&:hover": {
    background: "var(--filter-selected)",
  },
}

export const tabCont = (length: number): CSSProperties => ({
  width: "100%",
  borderRadius: "8px",
  border: "1px solid var(--borders)",
  height: "42px",
  overflow: "hidden",
  display: "grid",
  gridTemplateColumns: `repeat(${length}, 1fr)`,
})

export const tabSvg: SxProps<Theme> = { width: "18px" }
