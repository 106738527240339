import { Theme } from "@mui/system";
import { SxProps } from "@mui/material";

export const styles: SxProps<Theme> = {
  color: "var(--primary-color)",
  fontFamily: "Roboto, sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  backgroundColor: "var(--white)",
  borderStyle: "none",
  borderRadius: "8px",
}