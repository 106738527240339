import { SvgType } from "./homeIcon"

export default function QrCodeIcon({ color, width, height }: SvgType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16230_118315)">
        <path
          d="M3 9H9V3H3V9ZM4 4H8V8H4V4ZM5 5H7V7H5V5ZM15 9H21V3H15V9ZM16 4H20V8H16V4ZM17 5H19V7H17V5ZM3 21H9V15H3V21ZM4 16H8V20H4V16ZM5 17H7V19H5V17ZM20 19H21V21H19V18H20V19ZM20 16H21V17H20V16ZM20 15V16H19V15H20ZM10 17H11V21H10V17ZM6 10V12H4V11H3V10H6ZM10 7H11V8H10V7ZM13 4V6H12V3H14V4H13ZM10 4H11V5H10V4ZM20 12H21V14H19V13H20V12ZM19 10V11H17V13H15V12H16V10H19ZM12 14H11V13H10V12H12V14ZM18 16H19V17H18V16ZM20 11V12H19V11H20ZM11 14V15H10V14H11ZM17 19H18V21H16V19H17ZM14 19H15V20H14V21H12V20H13V19H14ZM14 18V17H16V18H14ZM14 13H15V16H14V17H13V18H12V16H11V15H14V14H13V13H14ZM5 13V14H4V13H5ZM17 17H16V16H17V17ZM18 15H16V14H18V15ZM8 10H9V11H8V12H9V14H8V13H7V14H6V12H7V10H8ZM11 10V8H14V11H12V10H13V9H12V10H11ZM11 6H12V7H11V6ZM10 10H11V11H10V10ZM13 7V6H14V7H13Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_16230_118315">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
