import { FC, useState } from "react"
import { LngLat } from "ymaps3"
import { useYandexReactComponents } from "../../../common/yandex/provider/YandexMapProvider"
import { PopupCardEvent } from "./PopupCard/PopupCardEvent"
import { EventsDtoToMarker, MapMarker } from "./MapMarker"
import { ContainerPopupCardEvent } from "./ContainerPopupCardEvent"
import { z } from "zod"
import { schema } from "../schema"
type CustomMarkerModels = z.infer<typeof schema>
export type CustomMarkerWithPopupProps = {
  events: CustomMarkerModels[]
}
export const CustomMarkerWithPopup: FC<CustomMarkerWithPopupProps> = ({
  events,
}) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [event, setEvent] = useState<CustomMarkerModels | undefined>()
  const components = useYandexReactComponents()

  if (!components) return null
  const clickHandler = (id: number) => {
    const event = events?.find((e) => e.eventId === id)
    if (!event) return
    setEvent(event)
    setPopupOpen(true)
  }
  const closeHandler = () => {
    setPopupOpen(false)
  }
  return (
    <>
      {events?.map((item) => (
        <MapMarker
          key={item.eventId}
          id={item.eventId}
          coords={[
            item.eventAddress.event_longitude,
            item.eventAddress.event_latitude,
          ]}
          onClick={clickHandler}
        />
      ))}
      <ContainerPopupCardEvent
        location={pickLocation(event)}
        isVisible={popupOpen}
      >
        <PopupCardEvent onClose={closeHandler} model={event} />
      </ContainerPopupCardEvent>
    </>
  )
}

const pickLocation = (
  dto: CustomMarkerModels | undefined,
): LngLat | undefined => {
  if (!dto) return undefined
  const { eventAddress } = dto
  if (!eventAddress) return undefined
  return [eventAddress.event_longitude, eventAddress.event_latitude]
}
