import { getAvatarUrls } from "../../components/feed_page"
import {
  mapDtoToModel,
  mapProfileEventDtoToModel,
} from "../../components/feed_page/mapper"
import { swaggerSplitApi as api } from "./swaggerApi"
import {
  setIWillGo,
  setInvites,
  setMyEvents,
  setSubscribeData,
} from "./userDataSlice/userDataSlice"
export const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    unsubscribeFromUser: build.mutation<
      UnsubscribeFromUserApiResponse,
      UnsubscribeFromUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/unsubscribe`,
        method: "PUT",
        body: queryArg.subscriptionDto,
      }),
    }),
    sendResetCodeToEmail: build.mutation<
      SendResetCodeToEmailApiResponse,
      SendResetCodeToEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/email/password/reset/code`,
        method: "PUT",
        body: queryArg.resetPasswordDto,
      }),
    }),
    changePassword: build.mutation<
      ChangePasswordApiResponse,
      ChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/email/password/change`,
        method: "PUT",
        body: queryArg.changePasswordDto,
      }),
    }),
    subscribeToUser: build.mutation<
      SubscribeToUserApiResponse,
      SubscribeToUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/subscribe`,
        method: "POST",
        body: queryArg.subscriptionDto,
      }),
    }),
    sendEmailConfirmationCode: build.mutation<
      SendEmailConfirmationCodeApiResponse,
      SendEmailConfirmationCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/email/change/code`,
        method: "POST",
        body: queryArg.userEmailDto,
      }),
    }),
    uploadAvatarToTempFolder: build.mutation<
      UploadAvatarToTempFolderApiResponse,
      UploadAvatarToTempFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/avatar/${queryArg.userId}`,
        method: "POST",
        body: queryArg.imageDto,
      }),
    }),
    saveUnknownUserWithInterests: build.mutation<
      SaveUnknownUserWithInterestsApiResponse,
      SaveUnknownUserWithInterestsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/unknown-user/interests`,
        method: "POST",
        body: queryArg.unknownUserWithInterestsDto,
      }),
    }),
    createEvent: build.mutation<CreateEventApiResponse, CreateEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/events`,
        method: "POST",
        body: queryArg.requestTemplateForCreatingEventDto,
      }),
    }),
    saveEventToFavorites: build.mutation<
      SaveEventToFavoritesApiResponse,
      SaveEventToFavoritesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/favorite`,
        method: "POST",
        body: queryArg.userFavoriteEventDto,
      }),
    }),
    getKudaGoEvents: build.mutation<
      GetKudaGoEventsApiResponse,
      GetKudaGoEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/external/kudago/${queryArg.minPublicationDate}`,
        method: "POST",
      }),
    }),
    addEventAvatarToTempDirectory: build.mutation<
      AddEventAvatarToTempDirectoryApiResponse,
      AddEventAvatarToTempDirectoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/avatars`,
        method: "POST",
        body: queryArg.imageDto,
      }),
    }),
    getChatsByUser: build.query<
      GetChatsByUserApiResponse,
      GetChatsByUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/chat`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    createPrivateChat: build.mutation<
      CreatePrivateChatApiResponse,
      CreatePrivateChatApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/chat`,
        method: "POST",
        body: queryArg.privateChatInvitedUserDto,
      }),
    }),
    confirmEmailRegistration: build.mutation<
      ConfirmEmailRegistrationApiResponse,
      ConfirmEmailRegistrationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/registration/email/confirm`,
        method: "POST",
        body: queryArg.confirmRegistrationDto,
      }),
    }),
    addRegistrantWithEmail: build.mutation<
      AddRegistrantWithEmailApiResponse,
      AddRegistrantWithEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/registration/email/code`,
        method: "POST",
        body: queryArg.registrantRequestDto,
      }),
    }),
    signInUpUser: build.mutation<SignInUpUserApiResponse, SignInUpUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/auth/login/social`,
        method: "POST",
        body: queryArg.userForSignInUpDto,
        headers: {
          Type: queryArg["type"],
          Authorization: queryArg.authorization,
        },
      }),
    }),
    getCodeForLoginWithWhatsApp: build.mutation<
      GetCodeForLoginWithWhatsAppApiResponse,
      GetCodeForLoginWithWhatsAppApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/login/phone/whatsapp/code`,
        method: "POST",
        body: queryArg.phoneNumberDto,
      }),
    }),
    confirmCodeForLoginWithWhatsApp: build.mutation<
      ConfirmCodeForLoginWithWhatsAppApiResponse,
      ConfirmCodeForLoginWithWhatsAppApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/login/phone/code/confirm`,
        method: "POST",
        body: queryArg.phoneConfirmCodeDto,
      }),
    }),
    loginUserWithEmail: build.mutation<
      LoginUserWithEmailApiResponse,
      LoginUserWithEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/login/email`,
        method: "POST",
        body: queryArg.emailLoginDto,
      }),
    }),
    confirmResetCode: build.mutation<
      ConfirmResetCodeApiResponse,
      ConfirmResetCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/email/password/reset/code/confirm`,
        method: "POST",
        body: queryArg.emailConfirmCodeDto,
      }),
    }),
    updateUserProfile: build.mutation<
      UpdateUserProfileApiResponse,
      UpdateUserProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user`,
        method: "PATCH",
        body: queryArg.userProfileUpdateDto,
      }),
    }),
    confirmEmailChange: build.mutation<
      ConfirmEmailChangeApiResponse,
      ConfirmEmailChangeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/email/change/confirm`,
        method: "PATCH",
        body: queryArg.emailChangeDto,
      }),
    }),
    updateEventsSearchUnits: build.mutation<
      UpdateEventsSearchUnitsApiResponse,
      UpdateEventsSearchUnitsApiArg
    >({
      query: () => ({ url: `/api/v1/replica/create_events`, method: "PATCH" }),
    }),
    updateEventStatusToSendToRework: build.mutation<
      UpdateEventStatusToSendToReworkApiResponse,
      UpdateEventStatusToSendToReworkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/verification/rework/${queryArg.eventId}`,
        method: "PATCH",
      }),
    }),
    updateEventStatusToPublished: build.mutation<
      UpdateEventStatusToPublishedApiResponse,
      UpdateEventStatusToPublishedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/verification/confirm/${queryArg.eventId}`,
        method: "PATCH",
      }),
    }),
    updateEvent: build.mutation<UpdateEventApiResponse, UpdateEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/events/update`,
        method: "PATCH",
        body: queryArg.updateEventDto,
      }),
    }),
    updateEventsPartsOfDay: build.mutation<
      UpdateEventsPartsOfDayApiResponse,
      UpdateEventsPartsOfDayApiArg
    >({
      query: () => ({ url: `/api/v1/events/parts_of_day`, method: "PATCH" }),
    }),
    sendRequestToParticipationInPublicEvent: build.mutation<
      SendRequestToParticipationInPublicEventApiResponse,
      SendRequestToParticipationInPublicEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/participant/request/public/${queryArg.eventId}/${queryArg.userId}`,
        method: "PATCH",
      }),
    }),
    sendToOrganizerARequestToParticipationInPrivateEvent: build.mutation<
      SendToOrganizerARequestToParticipationInPrivateEventApiResponse,
      SendToOrganizerARequestToParticipationInPrivateEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/participant/request/private/${queryArg.eventId}/${queryArg.userId}`,
        method: "PATCH",
      }),
    }),
    withdrawalRequestToParticipateInPrivateEvent: build.mutation<
      WithdrawalRequestToParticipateInPrivateEventApiResponse,
      WithdrawalRequestToParticipateInPrivateEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/participant/request/private/decline/${queryArg.eventId}/${queryArg.userId}`,
        method: "PATCH",
      }),
    }),
    declineToParticipantInEvent: build.mutation<
      DeclineToParticipantInEventApiResponse,
      DeclineToParticipantInEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/participant/decline/${queryArg.eventId}/${queryArg.userId}`,
        method: "PATCH",
      }),
    }),
    addUserToEventAsParticipant: build.mutation<
      AddUserToEventAsParticipantApiResponse,
      AddUserToEventAsParticipantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/organizer/participation/confirm/${queryArg.eventId}/${queryArg.organizerId}`,
        method: "PATCH",
      }),
    }),
    addUserToEventAsParticipant1: build.mutation<
      AddUserToEventAsParticipant1ApiResponse,
      AddUserToEventAsParticipant1ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/organizer/participation/confirm/${queryArg.eventId}/${queryArg.organizerId}/${queryArg.userId}`,
        method: "PATCH",
      }),
    }),
    inviteUsersToEvent: build.mutation<
      InviteUsersToEventApiResponse,
      InviteUsersToEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/invitation/invite/${queryArg.eventId}`,
        method: "PATCH",
        body: queryArg.invitedUserDto,
      }),
    }),
    declineInvitationToEvent: build.mutation<
      DeclineInvitationToEventApiResponse,
      DeclineInvitationToEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/invitation/decline/${queryArg.eventId}/${queryArg.userId}`,
        method: "PATCH",
      }),
    }),
    confirmInvitationToEvent: build.mutation<
      ConfirmInvitationToEventApiResponse,
      ConfirmInvitationToEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/invitation/confirm/${queryArg.eventId}/${queryArg.userId}`,
        method: "PATCH",
      }),
    }),
    setLastReadMessage: build.mutation<
      SetLastReadMessageApiResponse,
      SetLastReadMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/chat/message/${queryArg.chatId}/${queryArg.messageId}/read`,
        method: "PATCH",
      }),
    }),
    unblockUserByAdmin: build.mutation<
      UnblockUserByAdminApiResponse,
      UnblockUserByAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/user/${queryArg.userId}/unblock`,
        method: "PATCH",
      }),
    }),
    blockUserByAdmin: build.mutation<
      BlockUserByAdminApiResponse,
      BlockUserByAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/user/${queryArg.userId}/block`,
        method: "PATCH",
      }),
    }),
    updateEventByAdmin: build.mutation<
      UpdateEventByAdminApiResponse,
      UpdateEventByAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/event/update`,
        method: "PATCH",
        body: queryArg.updateEventDto,
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/user/${queryArg.userId}` }),
    }),
    findUserByUsernameForSendInvite: build.query<
      FindUserByUsernameForSendInviteApiResponse,
      FindUserByUsernameForSendInviteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/username/partial/${queryArg.username}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    isUsernameExistCheck: build.query<
      IsUsernameExistCheckApiResponse,
      IsUsernameExistCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/username/check/${queryArg.username}`,
      }),
    }),
    findAllUnSubscribers: build.query<
      FindAllUnSubscribersApiResponse,
      FindAllUnSubscribersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/unsubscriber/${queryArg.userId}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    findAllUnSubscribersByUsername: build.query<
      FindAllUnSubscribersByUsernameApiResponse,
      FindAllUnSubscribersByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/unsubscriber/partial/${queryArg.userId}`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          unsubscriber_username: queryArg.unsubscriberUsername,
        },
      }),
    }),
    findAllSubscribersById: build.query<
      FindAllSubscribersByIdApiResponse,
      FindAllSubscribersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/subscriber/${queryArg.userId}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    findAllSubscribersByUsername: build.query<
      FindAllSubscribersByUsernameApiResponse,
      FindAllSubscribersByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/subscriber/partial/${queryArg.userId}`,
        params: {
          subscriber_username: queryArg.subscriberUsername,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    getAllFriendsForInviting: build.query<
      GetAllFriendsForInvitingApiResponse,
      GetAllFriendsForInvitingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/relationship/friends`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getAllFriendsByPartialUsername: build.query<
      GetAllFriendsByPartialUsernameApiResponse,
      GetAllFriendsByPartialUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/relationship/friend/${queryArg.partialFriendUsername}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getUserProfile: build.query<
      GetUserProfileApiResponse,
      GetUserProfileApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/user/profile/${queryArg.userId}` }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            setSubscribeData({
              subscribeData: {
                subscribers: data?.followers_count ?? 0,
                subscription: data.followings_count ?? 0,
              },
            }),          
          )
        } catch (e) {
          console.log(e)
        }
      },
    }),
    findAllFollowingById: build.query<
      FindAllFollowingByIdApiResponse,
      FindAllFollowingByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/following/${queryArg.userId}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    findAllFollowingsByUsername: build.query<
      FindAllFollowingsByUsernameApiResponse,
      FindAllFollowingsByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/following/partial/${queryArg.userId}`,
        params: {
          user_name: queryArg.userName,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    isEmailExistCheck: build.query<
      IsEmailExistCheckApiResponse,
      IsEmailExistCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user/email/check/${queryArg.email}`,
      }),
    }),
    getUserAvatar: build.query<GetUserAvatarApiResponse, GetUserAvatarApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/avatar/${queryArg.addedDate}/${queryArg.fileName}`,
      }),
    }),
    getAllTagsByEventTypeId: build.query<
      GetAllTagsByEventTypeIdApiResponse,
      GetAllTagsByEventTypeIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/tags/${queryArg.eventTypeId}` }),
    }),
    getAllProfessions: build.query<
      GetAllProfessionsApiResponse,
      GetAllProfessionsApiArg
    >({
      query: () => ({ url: `/api/v1/profession` }),
    }),
    getEventById: build.query<GetEventByIdApiResponse, GetEventByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/events/${queryArg.eventId}` }),
    }),
    getEventWithVerificationPendingStatus: build.query<
      GetEventWithVerificationPendingStatusApiResponse,
      GetEventWithVerificationPendingStatusApiArg
    >({
      query: () => ({ url: `/api/v1/events/verification` }),
    }),
    getEventForUpdating: build.query<
      GetEventForUpdatingApiResponse,
      GetEventForUpdatingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/update/${queryArg.eventId}/${queryArg.organizerId}`,
      }),
    }),
    getAllEventByFilter: build.query<
      GetAllEventByFilterApiResponse,
      GetAllEventByFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/search`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          eventFilter: queryArg.eventFilter,
        },
      }),
      transformResponse: (response: GetAllEventByFilterApiResponse) => {
        const avatarUrls = getAvatarUrls(response)
        return {
          ...response,
          content: response.content.map((event) => {
            if (event.avatarUrl && event.eventId) {
              event.avatarUrl = avatarUrls[event.eventId]
            }
            return event
          }),
        }
      },
    }),
    getFilteredEventQuantity: build.query<
      GetFilteredEventQuantityApiResponse,
      GetFilteredEventQuantityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/search/quantity`,
        params: { eventFilter: queryArg.eventFilter },
      }),
    }),
    findEventsByUserIdWhichUserIsParticipant: build.query<
      FindEventsByUserIdWhichUserIsParticipantApiResponse,
      FindEventsByUserIdWhichUserIsParticipantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/participant/${queryArg.userId}`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const modelData = data.map((el) => mapProfileEventDtoToModel(el))
          dispatch(setIWillGo(modelData))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    findEventsByOrganizerId: build.query<
      FindEventsByOrganizerIdApiResponse,
      FindEventsByOrganizerIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/organisation/${queryArg.organizerId}`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const modelData = data.map((el) => mapProfileEventDtoToModel(el));
          dispatch(setMyEvents(modelData));
        } catch (e) {
          console.log(e)
        }
      },
    }),
    findEventsByUserIdWhichUserIsInvited: build.query<
      FindEventsByUserIdWhichUserIsInvitedApiResponse,
      FindEventsByUserIdWhichUserIsInvitedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/invitation/${queryArg.userId}`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const modelData = data.map((el) => mapProfileEventDtoToModel(el))
          dispatch(setInvites(modelData))
        } catch (e) {
          console.log(e)
        }
      },
    }),
    getAllFavoritesByUserId: build.query<
      GetAllFavoritesByUserIdApiResponse,
      GetAllFavoritesByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/favorite/${queryArg.userId}`,
      }),
    }),
    getEventAvatar: build.query<
      GetEventAvatarApiResponse,
      GetEventAvatarApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/avatars/${queryArg.addedDate}/${queryArg.avatarFileName}`,
      }),
    }),
    getRectangleEventTypes: build.query<
      GetRectangleEventTypesApiResponse,
      GetRectangleEventTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/event/type/rectangle`,
        params: { is_dark: queryArg.isDark },
      }),
    }),
    getEventTypeInfo: build.query<
      GetEventTypeInfoApiResponse,
      GetEventTypeInfoApiArg
    >({
      query: () => ({ url: `/api/v1/event/type/info` }),
    }),
    getCircleEventTypes: build.query<
      GetCircleEventTypesApiResponse,
      GetCircleEventTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/event/type/circle`,
        params: { is_dark: queryArg.isDark },
      }),
    }),
    getMessagesByChatId: build.query<
      GetMessagesByChatIdApiResponse,
      GetMessagesByChatIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/chat/message/${queryArg.chatId}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getAllUnreadMessageCount: build.query<
      GetAllUnreadMessageCountApiResponse,
      GetAllUnreadMessageCountApiArg
    >({
      query: () => ({ url: `/api/v1/chat/message/unread/count` }),
    }),
    refreshTokens: build.query<RefreshTokensApiResponse, RefreshTokensApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/auth/token/refresh`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getUsersToAdmin: build.query<
      GetUsersToAdminApiResponse,
      GetUsersToAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/user`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getUserToAdminByUsername: build.query<
      GetUserToAdminByUsernameApiResponse,
      GetUserToAdminByUsernameApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/admin/user/${queryArg.username}` }),
    }),
    getEventsToAdminById: build.query<
      GetEventsToAdminByIdApiResponse,
      GetEventsToAdminByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/admin/event/${queryArg.eventId}` }),
    }),
    getChatsByUser1: build.query<
      GetChatsByUser1ApiResponse,
      GetChatsByUser1ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1.1/chat`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getMessagesByChatId1: build.query<
      GetMessagesByChatId1ApiResponse,
      GetMessagesByChatId1ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1.1/chat/message/${queryArg.chatId}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getPost: build.query<
      GetMessagesByChatId1ApiResponse,
      GetMessagesByChatId1ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1.1/chat/message/${queryArg.chatId}`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/delete/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    deleteEventFromFavorites: build.mutation<
      DeleteEventFromFavoritesApiResponse,
      DeleteEventFromFavoritesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/events/favorite/${queryArg.userId}/${queryArg.eventId}`,
        method: "DELETE",
      }),
    }),
    deleteEvent: build.mutation<DeleteEventApiResponse, DeleteEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/events/delete/${queryArg.organizerId}/${queryArg.eventId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
})

//#region TYPES

export { injectedRtkApi as tribeApi }
export type UnsubscribeFromUserApiResponse = /** status 202 Accepted */ object
export type UnsubscribeFromUserApiArg = {
  subscriptionDto: SubscriptionDto
}
export type SendResetCodeToEmailApiResponse =
  /** status 205 Reset Content */ object
export type SendResetCodeToEmailApiArg = {
  resetPasswordDto: ResetPasswordDto
}
export type ChangePasswordApiResponse = /** status 201 Created */ object
export type ChangePasswordApiArg = {
  changePasswordDto: ChangePasswordDto
}
export type SubscribeToUserApiResponse = /** status 202 Accepted */ object
export type SubscribeToUserApiArg = {
  subscriptionDto: SubscriptionDto
}
export type SendEmailConfirmationCodeApiResponse = /** status 200 OK */ object
export type SendEmailConfirmationCodeApiArg = {
  userEmailDto: UserEmailDto
}
export type UploadAvatarToTempFolderApiResponse =
  /** status 201 Created */ object
export type UploadAvatarToTempFolderApiArg = {
  userId: string
  imageDto: ImageDto
}
export type SaveUnknownUserWithInterestsApiResponse =
  /** status 200 OK */ number
export type SaveUnknownUserWithInterestsApiArg = {
  unknownUserWithInterestsDto: UnknownUserWithInterestsDto
}
export type CreateEventApiResponse =
  /** status 200 OK */ DetailedEventInSearchDto
export type CreateEventApiArg = {
  requestTemplateForCreatingEventDto: RequestTemplateForCreatingEventDto
}
export type SaveEventToFavoritesApiResponse = /** status 200 OK */ object
export type SaveEventToFavoritesApiArg = {
  userFavoriteEventDto: UserFavoriteEventDto
}
export type GetKudaGoEventsApiResponse = /** status 200 OK */ object
export type GetKudaGoEventsApiArg = {
  minPublicationDate: string
}
export type AddEventAvatarToTempDirectoryApiResponse =
  /** status 201 Created */ TempFileDto
export type AddEventAvatarToTempDirectoryApiArg = {
  imageDto: ImageDto
}
export type GetChatsByUserApiResponse = /** status 200 OK */ ChatDto[]
export type GetChatsByUserApiArg = {
  page?: number
  size?: number
}
export type CreatePrivateChatApiResponse =
  /** status 200 OK */ PrivateChatInfoDto
export type CreatePrivateChatApiArg = {
  privateChatInvitedUserDto: PrivateChatInvitedUserDto
}
export type ConfirmEmailRegistrationApiResponse =
  /** status 201 Created */ TokensDto
export type ConfirmEmailRegistrationApiArg = {
  confirmRegistrationDto: ConfirmRegistrationDto
}
export type AddRegistrantWithEmailApiResponse =
  /** status 200 OK */ RegistrantResponseDto
export type AddRegistrantWithEmailApiArg = {
  registrantRequestDto: RegistrantRequestDto
}
export type SignInUpUserApiResponse = /** status 200 OK */ TokensDto
export type SignInUpUserApiArg = {
  type: string
  authorization: string
  userForSignInUpDto: UserForSignInUpDto
}
export type GetCodeForLoginWithWhatsAppApiResponse = /** status 200 OK */ object
export type GetCodeForLoginWithWhatsAppApiArg = {
  phoneNumberDto: PhoneNumberDto
}
export type ConfirmCodeForLoginWithWhatsAppApiResponse =
  /** status 202 Accepted */ TokensDto
export type ConfirmCodeForLoginWithWhatsAppApiArg = {
  phoneConfirmCodeDto: PhoneConfirmCodeDto
}
export type LoginUserWithEmailApiResponse = /** status 200 OK */ TokensDto
export type LoginUserWithEmailApiArg = {
  emailLoginDto: EmailLoginDto
}
export type ConfirmResetCodeApiResponse = /** status 200 OK */ TokensDto
export type ConfirmResetCodeApiArg = {
  emailConfirmCodeDto: EmailConfirmCodeDto
}
export type UpdateUserProfileApiResponse = /** status 202 Accepted */ object
export type UpdateUserProfileApiArg = {
  userProfileUpdateDto: UserProfileUpdateDto
}
export type ConfirmEmailChangeApiResponse = /** status 200 OK */ object
export type ConfirmEmailChangeApiArg = {
  emailChangeDto: EmailChangeDto
}
export type UpdateEventsSearchUnitsApiResponse = /** status 200 OK */ void
export type UpdateEventsSearchUnitsApiArg = void
export type UpdateEventStatusToSendToReworkApiResponse =
  /** status 200 OK */ object
export type UpdateEventStatusToSendToReworkApiArg = {
  eventId: number
}
export type UpdateEventStatusToPublishedApiResponse =
  /** status 200 OK */ object
export type UpdateEventStatusToPublishedApiArg = {
  eventId: number
}
export type UpdateEventApiResponse =
  /** status 200 OK */ DetailedEventInSearchDto
export type UpdateEventApiArg = {
  updateEventDto: UpdateEventDto
}
export type UpdateEventsPartsOfDayApiResponse = /** status 200 OK */ void
export type UpdateEventsPartsOfDayApiArg = void
export type SendRequestToParticipationInPublicEventApiResponse =
  /** status 202 Accepted */ object
export type SendRequestToParticipationInPublicEventApiArg = {
  eventId: number
  userId: string
}
export type SendToOrganizerARequestToParticipationInPrivateEventApiResponse =
  /** status 202 Accepted */ object
export type SendToOrganizerARequestToParticipationInPrivateEventApiArg = {
  eventId: number
  userId: string
}
export type WithdrawalRequestToParticipateInPrivateEventApiResponse =
  /** status 202 Accepted */ object
export type WithdrawalRequestToParticipateInPrivateEventApiArg = {
  eventId: number
  userId: string
}
export type DeclineToParticipantInEventApiResponse =
  /** status 202 Accepted */ object
export type DeclineToParticipantInEventApiArg = {
  eventId: number
  userId: string
}
export type AddUserToEventAsParticipantApiResponse =
  /** status 202 Accepted */ object
export type AddUserToEventAsParticipantApiArg = {
  eventId: number
  organizerId: string
}
export type AddUserToEventAsParticipant1ApiResponse =
  /** status 202 Accepted */ object
export type AddUserToEventAsParticipant1ApiArg = {
  eventId: number
  organizerId: string
  userId: number
}
export type InviteUsersToEventApiResponse = /** status 200 OK */ object
export type InviteUsersToEventApiArg = {
  eventId: number
  invitedUserDto: InvitedUserDto
}
export type DeclineInvitationToEventApiResponse = /** status 200 OK */ object
export type DeclineInvitationToEventApiArg = {
  eventId: number
  userId: string
}
export type ConfirmInvitationToEventApiResponse = /** status 200 OK */ object
export type ConfirmInvitationToEventApiArg = {
  eventId: number
  userId: string
}
export type SetLastReadMessageApiResponse = /** status 200 OK */ object
export type SetLastReadMessageApiArg = {
  chatId: number
  messageId: number
}
export type UnblockUserByAdminApiResponse = /** status 200 OK */ AdminUserDto
export type UnblockUserByAdminApiArg = {
  userId: number
}
export type BlockUserByAdminApiResponse = /** status 200 OK */ AdminUserDto
export type BlockUserByAdminApiArg = {
  userId: number
}
export type UpdateEventByAdminApiResponse = /** status 201 Created */ EventDto
export type UpdateEventByAdminApiArg = {
  updateEventDto: UpdateEventDto
}
export type GetUserApiResponse = /** status 200 OK */ UserGetDto
export type GetUserApiArg = {
  userId: string
}
export type FindUserByUsernameForSendInviteApiResponse =
  /** status 200 OK */ UserToSendInvitationDto[]
export type FindUserByUsernameForSendInviteApiArg = {
  username: string
  page?: number
  size?: number
}
export type IsUsernameExistCheckApiResponse = /** status 200 OK */ boolean
export type IsUsernameExistCheckApiArg = {
  username: string
}
export type FindAllUnSubscribersApiResponse =
  /** status 200 OK */ UserUnSubscriberDto[]
export type FindAllUnSubscribersApiArg = {
  page?: number
  size?: number
  userId: string
}
export type FindAllUnSubscribersByUsernameApiResponse =
  /** status 200 OK */ UserUnSubscriberDto[]
export type FindAllUnSubscribersByUsernameApiArg = {
  page?: number
  size?: number
  unsubscriberUsername: string
  userId: string
}
export type FindAllSubscribersByIdApiResponse =
  /** status 200 OK */ UserSubscriberDto[]
export type FindAllSubscribersByIdApiArg = {
  userId: string
  page?: number
  size?: number
}
export type FindAllSubscribersByUsernameApiResponse =
  /** status 200 OK */ UserSubscriberDto[]
export type FindAllSubscribersByUsernameApiArg = {
  subscriberUsername: string
  userId: string
  page?: number
  size?: number
}
export type GetAllFriendsForInvitingApiResponse =
  /** status 200 OK */ FriendshipPage
export type GetAllFriendsForInvitingApiArg = {
  page?: number
  size?: number
}
export type GetAllFriendsByPartialUsernameApiResponse =
  /** status 200 OK */ FriendshipPage
export type GetAllFriendsByPartialUsernameApiArg = {
  partialFriendUsername: string
  page?: number
  size?: number
}
export type GetUserProfileApiResponse = /** status 200 OK */ ProfileDto
export type GetUserProfileApiArg = {
  userId: string
}
export type FindAllFollowingByIdApiResponse =
  /** status 200 OK */ UserSubscriberDto[]
export type FindAllFollowingByIdApiArg = {
  userId: string
  page?: number
  size?: number
}
export type FindAllFollowingsByUsernameApiResponse =
  /** status 200 OK */ UserSubscriberDto[]
export type FindAllFollowingsByUsernameApiArg = {
  userId: string
  userName: string
  page?: number
  size?: number
}
export type IsEmailExistCheckApiResponse = /** status 200 OK */ boolean
export type IsEmailExistCheckApiArg = {
  email: string
}
export type GetUserAvatarApiResponse = /** status 200 OK */ string[]
export type GetUserAvatarApiArg = {
  fileName: string
  addedDate: string
}
export type GetAllTagsByEventTypeIdApiResponse =
  /** status 200 OK */ EventTagDto[]
export type GetAllTagsByEventTypeIdApiArg = {
  eventTypeId: number
}
export type GetAllProfessionsApiResponse = /** status 200 OK */ ProfessionDto
export type GetAllProfessionsApiArg = void
export type GetEventByIdApiResponse =
  /** status 200 OK */ DetailedEventInSearchDto
export type GetEventByIdApiArg = {
  eventId: string
}
export type GetEventWithVerificationPendingStatusApiResponse =
  /** status 200 OK */ EventVerificationDto[]
export type GetEventWithVerificationPendingStatusApiArg = void
export type GetEventForUpdatingApiResponse = /** status 200 OK */ EventDto
export type GetEventForUpdatingApiArg = {
  eventId: number
  organizerId: string
}
// export type GetAllEventByFilterApiResponse = SearchEventDto[]
export type GetAllEventByFilterApiResponse = {
  content: SearchEventDto[]
  metadata: GetAllEventByFilterApiArg
}
// /** status 200 OK */
export type GetAllEventByFilterApiArg = {
  page?: number
  size?: number
  eventFilter: EventFilter
}
export type GetFilteredEventQuantityApiResponse =
  /** status 200 OK */ FilteredEventQuantityDto
export type GetFilteredEventQuantityApiArg = {
  eventFilter: EventFilter
}
export type FindEventsByUserIdWhichUserIsParticipantApiResponse =
  /** status 200 OK */ EventInUserProfileDto[]
export type FindEventsByUserIdWhichUserIsParticipantApiArg = {
  userId: string
}
export type FindEventsByOrganizerIdApiResponse =
  /** status 200 OK */ EventInUserProfileDto[]
export type FindEventsByOrganizerIdApiArg = {
  organizerId: string
}
export type FindEventsByUserIdWhichUserIsInvitedApiResponse =
  /** status 200 OK */ EventInUserProfileDto[]
export type FindEventsByUserIdWhichUserIsInvitedApiArg = {
  userId: string
}
export type GetAllFavoritesByUserIdApiResponse =
  /** status 200 OK */ EventInFavoriteDto[]
export type GetAllFavoritesByUserIdApiArg = {
  userId: string
}
export type GetEventAvatarApiResponse = /** status 200 OK */ string[]
export type GetEventAvatarApiArg = {
  addedDate: string
  avatarFileName: string
}
export type GetRectangleEventTypesApiResponse =
  /** status 200 OK */ EventTypeDto
export type GetRectangleEventTypesApiArg = {
  isDark: boolean
}
export type GetEventTypeInfoApiResponse =
  /** status 200 OK */ EventTypeInfoDto[]
export type GetEventTypeInfoApiArg = void
export type GetCircleEventTypesApiResponse = /** status 200 OK */ EventTypeDto
export type GetCircleEventTypesApiArg = {
  isDark: boolean
}
export type GetMessagesByChatIdApiResponse =
  /** status 200 OK */ ChatMessageDto[]
export type GetMessagesByChatIdApiArg = {
  chatId: number
  page?: number
  size?: number
}
export type GetAllUnreadMessageCountApiResponse =
  /** status 200 OK */ UnreadMessageCountDto
export type GetAllUnreadMessageCountApiArg = void
export type RefreshTokensApiResponse = /** status 200 OK */ TokensDto
export type RefreshTokensApiArg = {
  authorization: string
}
export type GetUsersToAdminApiResponse = /** status 200 OK */ AdminUserDto
export type GetUsersToAdminApiArg = {
  page?: number
  size?: number
}
export type GetUserToAdminByUsernameApiResponse =
  /** status 200 OK */ AdminUserDto
export type GetUserToAdminByUsernameApiArg = {
  username: string
}
export type GetEventsToAdminByIdApiResponse = /** status 200 OK */ EventDto
export type GetEventsToAdminByIdApiArg = {
  eventId: number
}
export type GetChatsByUser1ApiResponse = /** status 200 OK */ ChatDto[]
export type GetChatsByUser1ApiArg = {
  page?: number
  size?: number
}
export type GetMessagesByChatId1ApiResponse =
  /** status 200 OK */ ChatMessageDto[]
export type GetMessagesByChatId1ApiArg = {
  chatId: number
  page?: number
  size?: number
}
export type DeleteUserApiResponse = /** status 200 OK */ object
export type DeleteUserApiArg = {
  userId: string
}
export type DeleteEventFromFavoritesApiResponse = /** status 200 OK */ object
export type DeleteEventFromFavoritesApiArg = {
  userId: string
  eventId: number
}
export type DeleteEventApiResponse = /** status 202 Accepted */ object
export type DeleteEventApiArg = {
  organizerId: number
  eventId: number
}
export type ResponseErrorDto = {
  time?: string
  status?:
    | "100 CONTINUE"
    | "101 SWITCHING_PROTOCOLS"
    | "102 PROCESSING"
    | "103 EARLY_HINTS"
    | "103 CHECKPOINT"
    | "200 OK"
    | "201 CREATED"
    | "202 ACCEPTED"
    | "203 NON_AUTHORITATIVE_INFORMATION"
    | "204 NO_CONTENT"
    | "205 RESET_CONTENT"
    | "206 PARTIAL_CONTENT"
    | "207 MULTI_STATUS"
    | "208 ALREADY_REPORTED"
    | "226 IM_USED"
    | "300 MULTIPLE_CHOICES"
    | "301 MOVED_PERMANENTLY"
    | "302 FOUND"
    | "302 MOVED_TEMPORARILY"
    | "303 SEE_OTHER"
    | "304 NOT_MODIFIED"
    | "305 USE_PROXY"
    | "307 TEMPORARY_REDIRECT"
    | "308 PERMANENT_REDIRECT"
    | "400 BAD_REQUEST"
    | "401 UNAUTHORIZED"
    | "402 PAYMENT_REQUIRED"
    | "403 FORBIDDEN"
    | "404 NOT_FOUND"
    | "405 METHOD_NOT_ALLOWED"
    | "406 NOT_ACCEPTABLE"
    | "407 PROXY_AUTHENTICATION_REQUIRED"
    | "408 REQUEST_TIMEOUT"
    | "409 CONFLICT"
    | "410 GONE"
    | "411 LENGTH_REQUIRED"
    | "412 PRECONDITION_FAILED"
    | "413 PAYLOAD_TOO_LARGE"
    | "413 REQUEST_ENTITY_TOO_LARGE"
    | "414 URI_TOO_LONG"
    | "414 REQUEST_URI_TOO_LONG"
    | "415 UNSUPPORTED_MEDIA_TYPE"
    | "416 REQUESTED_RANGE_NOT_SATISFIABLE"
    | "417 EXPECTATION_FAILED"
    | "418 I_AM_A_TEAPOT"
    | "419 INSUFFICIENT_SPACE_ON_RESOURCE"
    | "420 METHOD_FAILURE"
    | "421 DESTINATION_LOCKED"
    | "422 UNPROCESSABLE_ENTITY"
    | "423 LOCKED"
    | "424 FAILED_DEPENDENCY"
    | "425 TOO_EARLY"
    | "426 UPGRADE_REQUIRED"
    | "428 PRECONDITION_REQUIRED"
    | "429 TOO_MANY_REQUESTS"
    | "431 REQUEST_HEADER_FIELDS_TOO_LARGE"
    | "451 UNAVAILABLE_FOR_LEGAL_REASONS"
    | "500 INTERNAL_SERVER_ERROR"
    | "501 NOT_IMPLEMENTED"
    | "502 BAD_GATEWAY"
    | "503 SERVICE_UNAVAILABLE"
    | "504 GATEWAY_TIMEOUT"
    | "505 HTTP_VERSION_NOT_SUPPORTED"
    | "506 VARIANT_ALSO_NEGOTIATES"
    | "507 INSUFFICIENT_STORAGE"
    | "508 LOOP_DETECTED"
    | "509 BANDWIDTH_LIMIT_EXCEEDED"
    | "510 NOT_EXTENDED"
    | "511 NETWORK_AUTHENTICATION_REQUIRED"
  error_message?: string[]
}
export type SubscriptionDto = {
  follower_user_id: number
  following_user_id: number
}
export type ResetPasswordDto = {
  email: string
}
export type ChangePasswordDto = {
  user_id: number
  old_password: string
  new_password: string
}
export type UserEmailDto = {
  user_id: string
  old_email: string
  new_email: string
}
export type ImageDto = {
  image?: string[]
  content_type?: string
}
export type UnknownUserWithInterestsDto = {
  firebase_id?: string
  event_type_ids?: number[]
}
export type EventContactInfoDto = {
  contact?: string
  contact_type?: "URL" | "PHONE" | "TELEGRAM" | "WHATSAPP"
}
export type EventAddressDto = {
  city: string
  region: string
  street: string
  district?: string
  building?: string
  floor?: string
  event_longitude: number
  event_latitude: number
  house_number: string
}
export type UsersWhoParticipantsOfEventDto = {
  participant_id: number
  participant_avatar_url?: string
}
export type DetailedEventInSearchDto = {
  event_id: number
  event_photo?: string[]
  favorite_event: boolean
  organizer_photo?: string
  event_type_name?: string
  contact_info?: EventContactInfoDto[]
  event_name: string
  organizer_username: string
  organizer_id?: number
  event_address?: EventAddressDto
  start_time?: string
  event_duration: string
  description?: string
  users_who_participants_of_event?: UsersWhoParticipantsOfEventDto[]
  is_private?: boolean
  is_free?: boolean
  is_finished?: boolean
  is_participant?: boolean
  is_want_to_go?: boolean
  is_invited?: boolean
}
export type RequestTemplateForCreatingEventDto = {
  event_type_id: number
  event_name: string
  event_address: EventAddressDto
  start_time: string
  end_time: string
  new_event_tags_names?: string[]
  event_tag_ids?: number[]
  description: string
  contact_info?: EventContactInfoDto[]
  avatars_for_deleting?: string[]
  avatars_for_adding?: string[]
  invited_user_ids?: number[]
  show_event_in_search: boolean
  is_private: boolean
  has_alcohol: boolean
  send_to_all_users_by_interests: boolean
  is_eighteen_year_limit: boolean
  organizer_id: number
  is_free: boolean
  time_zone: string
}
export type UserFavoriteEventDto = {
  user_id?: string
  event_id?: number
}
export type TempFileDto = {
  unique_file_name?: string
}
export type ChatDto = {
  chat_id?: number
  author_name?: string
  author_surname?: string
  author_id?: number
  event_id?: number
  is_second_participant_online?: boolean
  second_participant_status?: "ENABLED" | "BLOCKED" | "DELETED"
  last_message?: string
  last_message_time?: string
  unread_message_count?: number
  chat_avatar?: string
  is_group?: boolean
  chat_name?: string
}
export type PrivateChatInfoDto = {
  chat_id?: number
}
export type PrivateChatInvitedUserDto = {
  invited_user_id?: number
}
export type TokensDto = {
  user_id?: number
  access_token?: string
  refresh_token?: string
}
export type ConfirmRegistrationDto = {
  registrant_id?: number
  verification_code?: number
  firebase_id: string
}
export type RegistrantResponseDto = {
  registrant_id?: number
}
export type RegistrantRequestDto = {
  email: string
  password: string
  username: string
}
export type UserForSignInUpDto = {
  email?: string
  username: string
  firebase_id: string
  phone_number?: string
}
export type PhoneNumberDto = {
  phoneNumber: string
}
export type PhoneConfirmCodeDto = {
  verification_code?: number
  phone_number: string
  firebase_id: string
}
export type EmailLoginDto = {
  email: string
  password: string
}
export type EmailConfirmCodeDto = {
  email: string
  verification_code?: number
}
export type UserProfileUpdateDto = {
  username: string
  birthday?: string
  user_id?: number
  user_avatar?: string
  avatars_filenames_for_deleting?: string[]
  first_name?: string
  last_name?: string
  interesting_event_type?: number[]
  profession_ids?: number[]
  new_professions?: string[]
  is_geolocation_available?: boolean
}
export type EmailChangeDto = {
  verification_code?: number
  new_email: string
  old_email: string
}
export type UpdateEventDto = {
  name: string
  description: string
  timezone: string
  event_id: number
  organizer_id: string
  event_type_id: number
  event_contact_info?: EventContactInfoDto[]
  avatars_for_deleting?: string[]
  avatars_for_adding?: string[]
  address_dto: EventAddressDto
  event_start_date_time: string
  event_end_date_time: string
  tag_ids_for_deleting?: number[]
  tag_ids_for_adding?: number[]
  new_tags?: string[]
  participant_ids_for_adding?: number[]
  participant_ids_for_deleting?: number[]
  is_private?: boolean
  is_show_in_search?: boolean
  is_send_by_interests?: boolean
  is_eighteen_year_limit?: boolean
  has_alcohol?: boolean
  is_free?: boolean
}
export type InvitedUserDto = {
  invited_user_ids?: number[]
}
export type AdminUserDto = {
  id?: number
  firstName?: string
  username?: string
  avatar?: string
  status?: "ENABLED" | "BLOCKED" | "DELETED"
}
export type UserToSendInvitationDto = {
  username?: string
  user_id?: number
  user_avatar?: string
  first_name?: string
  last_name?: string
}
export type EventTypeInfoDto = {
  id?: number
  type_name?: string
  priority?: number
  type_img_png_path?: string
}
export type EventTagDto = {
  id?: number
  name?: string
}
export type EventDto = {
  name?: string
  description?: string
  invitations?: UserToSendInvitationDto[]
  timezone?: string
  event_type_info_dto?: EventTypeInfoDto
  event_contacts?: EventContactInfoDto[]
  avatar_urls?: string[]
  address_dto?: EventAddressDto
  event_start_date_time?: string
  event_end_date_time?: string
  event_tags?: EventTagDto[]
  is_private?: boolean
  is_show_in_search?: boolean
  is_send_by_interests?: boolean
  is_eighteen_year_limit?: boolean
  has_alcohol?: boolean
  is_free?: boolean
}
export type ProfessionDto = {
  id?: number
  profession_name?: string
}
export type AuthMethodsDto = {
  has_email_authentication?: boolean
  has_google_authentication?: boolean
  has_vk_authentication?: boolean
  has_whatsapp_authentication?: boolean
  has_telegram_authentication?: boolean
}
export type UserGetDto = {
  username?: string
  birthday?: string
  professions?: ProfessionDto[]
  email?: string
  avatar_url?: string
  first_name?: string
  last_name?: string
  interesting_event_type?: EventTypeInfoDto[]
  phone_number?: string
  available_auth_methods?: AuthMethodsDto
  is_geolocation_available?: boolean
}
export type UserUnSubscriberDto = {
  username?: string
  user_id?: number
  user_avatar?: string
  first_name?: string
  last_name?: string
}
export type UserSubscriberDto = {
  username?: string
  user_id?: number
  user_avatar?: string
  first_name?: string
  last_name?: string
  is_user_subscribe_to_subscriber?: boolean
  chat_id?: number
}
export type FriendDto = {
  user_id?: number
  user_avatar?: string
  first_name?: string
  last_name?: string
  username?: string
}
export type SortObject = {
  empty?: boolean
  sorted?: boolean
  unsorted?: boolean
}
export type PageableObject = {
  offset?: number
  sort?: SortObject
  pageNumber?: number
  pageSize?: number
  paged?: boolean
  unpaged?: boolean
}
export type FriendshipPage = {
  content?: FriendDto[]
  pageable?: PageableObject
  last?: boolean
  totalPages?: number
  totalElements?: number
  size?: number
  number?: number
  sort?: SortObject
  first?: boolean
  numberOfElements?: number
  empty?: boolean
}
export type ProfileDto = {
  username?: string
  age?: number
  professions?: string[]
  interests?: string[]
  user_id?: number
  avatar_url?: string
  full_name?: string
  followers_count?: number
  followings_count?: number
  is_followed?: boolean
  is_following?: boolean
  chat_id?: number
}
export type EventVerificationDto = {
  event_id?: number
  organizer_id?: number
  event_photos?: string[]
  created_at?: string
  event_address?: EventAddressDto
  event_name?: string
  event_description?: string
  start_time?: string
  end_time?: string
  eighteen_year_limit?: boolean
  event_type?: string
  event_tags?: string[]
}
export type SearchEventDto = {
  eventId?: number
  eventAddress?: EventAddressDto
  avatarUrl?: string[]
  favoriteEvent?: boolean
  eventName?: string
  eventType?: string
  organizerUsername?: string
  description?: string
  startTime?: string
  isPrivate?: boolean
  isFree?: boolean
  isFinished?: boolean
  isPresenceOfAlcohol?: boolean
  participants?: UsersWhoParticipantsOfEventDto[]
}
export type EventFilter = {
  text?: string
  eventTypeId?: number[]
  distanceInMeters?: number
  longitude?: number
  latitude?: number
  startDate?: string
  endDate?: string
  zoneId?: string
  numberOfParticipantsMin?: number
  numberOfParticipantsMax?: number
  partsOfDay?: string
  durationEventInHoursMin?: number
  durationEventInHoursMax?: number
  isPresenceOfAlcohol?: boolean
  isFree?: boolean
  isFinished?: boolean
  isEighteenYearLimit?: boolean
  /** Available: DISTANCE, DATE, ALCOHOL */
  sort?: "DATE" | "DISTANCE" | "ALCOHOL"
  strictEventSort?: boolean
  /** Can be: ASC, DSC */
  order?: "ASC" | "DSC"
}
export type FilteredEventQuantityDto = {
  event_quantity?: number
}
export type EventInUserProfileDto = {
  id?: number
  event_photo_url?: string[]
  event_name?: string
  city?: string
  event_type_name?: string
  start_time?: string
  event_status?:
    | "VERIFICATION_PENDING"
    | "SEND_TO_REWORK"
    | "PUBLISHED"
    | "DELETED"
    | "FINISHED"
  is_finished?: boolean
}
export type EventInFavoriteDto = {
  event_id: number
  event_photo?: string[]
  event_name: string
  event_type_name?: string
  event_address?: EventAddressDto
  start_time?: string
  is_finished: boolean
  is_deleted: boolean
}
export type EventTypeDto = {
  id?: number
  type_name: string
  animation_json?: string
}
export type AuthorDto = {
  username?: string
  firstname?: string
  lastname?: string
  userStatus?: "ENABLED" | "BLOCKED" | "DELETED"
  author_id?: number
  avatar_url?: string
}
export type ChatMessageDto = {
  author?: AuthorDto
  content?: string
  chat_id?: number
  message_id?: number
  is_read?: boolean
  created_at?: string
}
export type UnreadMessageCountDto = {
  unread_message_count?: number
}

//#endregion

export const {
  useUnsubscribeFromUserMutation,
  useSendResetCodeToEmailMutation,
  useChangePasswordMutation,
  useSubscribeToUserMutation,
  useSendEmailConfirmationCodeMutation,
  useUploadAvatarToTempFolderMutation,
  useSaveUnknownUserWithInterestsMutation,
  useCreateEventMutation,
  useSaveEventToFavoritesMutation,
  useGetKudaGoEventsMutation,
  useAddEventAvatarToTempDirectoryMutation,
  useGetChatsByUserQuery,
  useCreatePrivateChatMutation,
  useConfirmEmailRegistrationMutation,
  useAddRegistrantWithEmailMutation,
  useSignInUpUserMutation,
  useGetCodeForLoginWithWhatsAppMutation,
  useConfirmCodeForLoginWithWhatsAppMutation,
  useLoginUserWithEmailMutation,
  useConfirmResetCodeMutation,
  useUpdateUserProfileMutation,
  useConfirmEmailChangeMutation,
  useUpdateEventsSearchUnitsMutation,
  useUpdateEventStatusToSendToReworkMutation,
  useUpdateEventStatusToPublishedMutation,
  useUpdateEventMutation,
  useUpdateEventsPartsOfDayMutation,
  useSendRequestToParticipationInPublicEventMutation,
  useSendToOrganizerARequestToParticipationInPrivateEventMutation,
  useWithdrawalRequestToParticipateInPrivateEventMutation,
  useDeclineToParticipantInEventMutation,
  useAddUserToEventAsParticipantMutation,
  useAddUserToEventAsParticipant1Mutation,
  useInviteUsersToEventMutation,
  useDeclineInvitationToEventMutation,
  useConfirmInvitationToEventMutation,
  useSetLastReadMessageMutation,
  useUnblockUserByAdminMutation,
  useBlockUserByAdminMutation,
  useUpdateEventByAdminMutation,
  useGetUserQuery,
  useFindUserByUsernameForSendInviteQuery,
  useIsUsernameExistCheckQuery,
  useFindAllUnSubscribersQuery,
  useFindAllUnSubscribersByUsernameQuery,
  useFindAllSubscribersByIdQuery,
  useFindAllSubscribersByUsernameQuery,
  useGetAllFriendsForInvitingQuery,
  useGetAllFriendsByPartialUsernameQuery,
  useGetUserProfileQuery,
  useFindAllFollowingByIdQuery,
  useFindAllFollowingsByUsernameQuery,
  useIsEmailExistCheckQuery,
  useGetUserAvatarQuery,
  useGetAllTagsByEventTypeIdQuery,
  useGetAllProfessionsQuery,
  useGetEventByIdQuery,
  useGetEventWithVerificationPendingStatusQuery,
  useGetEventForUpdatingQuery,
  useGetAllEventByFilterQuery,
  useGetFilteredEventQuantityQuery,
  useFindEventsByUserIdWhichUserIsParticipantQuery,
  useLazyFindEventsByUserIdWhichUserIsParticipantQuery,
  useFindEventsByOrganizerIdQuery,
  useLazyFindEventsByOrganizerIdQuery,
  useFindEventsByUserIdWhichUserIsInvitedQuery,
  useLazyFindEventsByUserIdWhichUserIsInvitedQuery,
  useGetAllFavoritesByUserIdQuery,
  useGetEventAvatarQuery,
  useGetRectangleEventTypesQuery,
  useGetEventTypeInfoQuery,
  useLazyGetEventTypeInfoQuery,
  useGetCircleEventTypesQuery,
  useGetMessagesByChatIdQuery,
  useGetAllUnreadMessageCountQuery,
  useRefreshTokensQuery,
  useGetUsersToAdminQuery,
  useGetUserToAdminByUsernameQuery,
  useGetEventsToAdminByIdQuery,
  useGetChatsByUser1Query,
  useGetMessagesByChatId1Query,
  useDeleteUserMutation,
  useDeleteEventFromFavoritesMutation,
  useDeleteEventMutation,
  // useGetImageFromServerQuery,
} = injectedRtkApi
