import React, { ReactNode } from "react";
import { AddressLocation } from "../../../../../../app/data/location/schema";

import LocationCityIcon from '@mui/icons-material/LocationCity';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import SignpostIcon from '@mui/icons-material/Signpost';
import Box from "@mui/material/Box";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Grid, SvgIconTypeMap, Typography } from "@mui/material";
import { formatDetailAddressLocation } from "../utils";

export interface OptionsItemState{
  selected: boolean
  index: number
  inputValue: string
}
export const OptionsItem = (item: React.HTMLAttributes<HTMLLIElement>, option: AddressLocation, state: OptionsItemState): ReactNode => {
  return <li {...item} key={state.index}>
    <Grid container sx={{alignItems: 'center' }}>
      <Grid item sx={{ display: 'flex', width: 44 }}>
        <Box component={addressIcons(option)} />
      </Grid>
      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
        <Box
          key={state.index}
          component="span"
          sx={{ fontWeight: 'regular' }}
        >
          {formatDetailAddressLocation(option)}
        </Box>
        <Typography variant="body2" color="text.secondary">
          {option.settlement.trim() === "" ? `${option.city_type}. ${option.city}` : `${option.settlement_type}. ${option.settlement}`}
        </Typography>
      </Grid>
    </Grid>
  </li>
}
export const CityItem = (item: React.HTMLAttributes<HTMLLIElement>, option: AddressLocation, state: OptionsItemState): ReactNode => {
  return <li {...item} key={state.index}>
    <Grid container sx={{alignItems: 'center' }}>
      <Grid item sx={{ display: 'flex', width: 44 }}>
        <Box component={cityIcons(option)} />
      </Grid>
      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
        <Box
          key={state.index}
          component="span"
          sx={{ fontWeight: 'regular' }}
        >
          {formattedCityTitle(option)}
        </Box>
        <Typography variant="body2" color="text.secondary">
          {formattedCityTitleSecond(option)}
        </Typography>
      </Grid>
    </Grid>
  </li>
}
const addressIcons = (address: AddressLocation):  OverridableComponent<SvgIconTypeMap<{}, "svg">> & {   muiName: string } => {
  const {house} = address
  if (house.trim() === ""){
    return SignpostIcon
  } else  {
    return ApartmentIcon
  }
}
const cityIcons = (address: AddressLocation):  OverridableComponent<SvgIconTypeMap<{}, "svg">> & {   muiName: string } => {
  const {settlement} = address
  if (settlement.trim() === ""){
    return LocationCityIcon
  } else  {
    return HolidayVillageIcon
  }
}

const formattedCityTitle = (address: AddressLocation): string => {
  const { settlement } = address
  if (settlement.trim() === "") {
    return `${address.city_type}. ${address.city}`
  } else {
    return `${address.settlement_type}. ${address.settlement}`

  }
}
const formattedCityTitleSecond = (address: AddressLocation): string => {
  const { settlement } = address
  if (settlement.trim() === "") {
    return `${address.region}. ${address.region_type}`
  } else {
    const {city, city_type} = address
    if (city.trim() === "") {
      return `${address.region}. ${address.region_type} `
    }
    return `${address.region}. ${address.region_type}, ${city_type}. ${city} `
  }
}

//const isOnlyStreet = (address: AddressLocation): boolean => address.house.trim() === ""