import { Box, Button, SxProps, Theme, Typography } from "@mui/material"
import { btnCont, cardBtnImGoing } from "./gradientBtnStyle"

type Props = {
  title: string
  btnStyle?: SxProps<Theme>
  contStyle?: SxProps<Theme>
  textStyle?: SxProps<Theme>
  isShow?: boolean
  handleAction?: () => void
}

export default function GradientBtn({
  title,
  btnStyle,
  textStyle,
  contStyle,
  handleAction = () => {},
}: Props) {
  const combinedStyle = { ...cardBtnImGoing, ...btnStyle }
  const combConStyle = { ...btnCont, ...contStyle }

  return (
    <Box sx={combConStyle}>
      <Button onClick={handleAction} sx={combinedStyle}>
        <Typography sx={textStyle}>{title}</Typography>
      </Button>
    </Box>
  )
}
