import React, { FC, useState } from "react";
import { useValidateFormikData } from "../../../hooks/useValidateFormikData";
import { TStepProps } from "../../../types";
import Buttons from "../../common/buttons";
import Title from "../../common/title";
import s from "./styles.module.css";
import EventMap from "../../../event-map";
import { useRegionAutocomplete } from "../../../../../shared/autocomplete-location/useRegionAutocomplete";
import { useAddressAutocomplete } from "../../../../../shared/autocomplete-location/useAddressLocation";
import { AddressLocation } from "../../../../../app/data/location/schema";
import { addressToMap} from "./utils";
import { AddressSchema } from "./schema";
import AutocompleteInputAddress from "./autocompleteInputAddress";
import { labelOfAddress, labelOfRegion } from "./autocompleteInputAddress/labelOfAddress";
import { AutocompleteChangeReason } from "@mui/material";
import { CityItem, OptionsItem } from "./autocompleteInputAddress/OptionsItem";
import { EventAddressDto } from "../../../../../app/data/tribeSwaggerApi";
import { defaultCityList } from "./defaultCityList";


export type UiRegion = {
  value?: AddressLocation
  label: string
}

export type UiAddress = {
  label: string
  value: AddressLocation
}




const Step2: FC<TStepProps> = ({ makeStep, step, formik }) => {
  const { event_address } =
    formik.values;
  const [currentCity, setCurrentCity] = useState<AddressLocation | null>(null)
  const [currentAddress, setCurrentAddress] = useState<EventAddressDto>(event_address)
  const isFieldEmpty = useValidateFormikData(AddressSchema, currentAddress)
  const {cityList: cityList, search, isLoading, error} = useRegionAutocomplete()
  const { isLoading: isAddressListLoading, error: AddressError, addressList, search: addressSearch } = useAddressAutocomplete()
  const [addressInputError, setAddressInputError] = useState<string>("")
  const [regionInputError, setRegionInputError] = useState<string>("")



  const inputAddressChangeHandler = (input: string)=>{
    if (currentCity) {
      addressSearch(currentCity, input)
    }
  }
  const inputRegionChangeHandler = (input: string)=>{
    if (input.trim() !== "") {
      search(input)
    }
  }

  const onChangeAddress = (value: AddressLocation | null, reason: AutocompleteChangeReason, isFullAddress: boolean) => {
    if (isFullAddress && value && reason === "selectOption"){
      setCurrentAddress((prevState)=>({
        ...prevState,
        street: `${value.street_type}. ${value.street}`,
        house_number: value.house,
        event_longitude: Number.parseFloat(value.lon),
        event_latitude: Number.parseFloat(value.lat),
      }))
      setAddressInputError("")
    }
    if (!isFullAddress && value){
      setAddressInputError("Введите полный адресс")
    }
    if (reason === "clear") {
      setAddressInputError("")
    }
  }
  const onChangeRegion = (value: AddressLocation | null, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption"){
      setCurrentCity(value)
      if (value) {
        setCurrentAddress((prev:EventAddressDto)=>({
          ...prev,
          region: `${value.region_type}. ${value.region}`,
          city: value.settlement.trim() === "" ? `${value.city_type}. ${value.city}`: `${value.settlement_type}. ${value.settlement}`
        }))
      }
    }
    if (reason === "clear"){
      setCurrentCity(null)
    }
  }
  return (
    <div className={s.container}>
      <Title align={"flex-start"} title={"Адрес"} />
      <div className={s.content}>
        <AutocompleteInputAddress
          labelBuilder={labelOfRegion}
          isLoading={isLoading}
          addressList={cityList}
          placeholder={"Выберите Город"}
          title="Город"
          defaultList={defaultCityList}
          optionBuilder={CityItem}
          onChange={onChangeRegion}
          onInputChange={inputRegionChangeHandler}
          errorText={regionInputError}/>
        <AutocompleteInputAddress
          labelBuilder={labelOfAddress}
          isLoading={isAddressListLoading}
          addressList={addressList}
          placeholder={"Выберите адресс"}
          title="Адресc"
          optionBuilder={OptionsItem}
          onChange={onChangeAddress}
          onInputChange={inputAddressChangeHandler}
          errorText={addressInputError}/>
        <div className={s.map}>
          <EventMap location={addressToMap(currentAddress)}/>
        </div>
      </div>
      <Buttons
        disabled={isFieldEmpty}
        step={step}
        next={() => {
          formik.setFieldValue("event_address", currentAddress)
          if (isFieldEmpty) return;
          makeStep("+");
        }}
        back={() => makeStep("-")}
      />
    </div>
  );
};

export default Step2;
