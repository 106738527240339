import { FC } from "react"

import { selectSchema } from "../FilteredSelectedBox/types";
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";
import FilteredSelectedBox from "../FilteredSelectedBox";

const schema: selectSchema<boolean | undefined> = {
  items: [
    {
      name: "Все",
      value: undefined
    },
    {
      name: "Да",
      value: true
    },
    {
      name: "Нет",
      value: false
    },
  ]
}
export const AlcoholCheckedBox: FC = () => {
  const  {currentFilter, updateIsAlcohol} = useEventFilter()
  const selectHandler = (value: boolean | undefined) => {
    updateIsAlcohol(value)
  }
  return <FilteredSelectedBox<boolean | undefined>
    tabsMap={schema}
    value={currentFilter.isPresenceOfAlcohol}
    onSelect={selectHandler}
    title="Алкоголь"
  />
}
