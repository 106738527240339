import { SearchParamQuery } from "../types/SearchParam"
import useFilterAsSearchParams from "../shared/filter/hooks/useFilterAsSearchParams";


const paramToDelete: SearchParamQuery[] = [
  "city",
  "daysrange",
  "day",
  "isFree",
  "duration",
]

const resetParam: Partial<Record<SearchParamQuery, any>> = {
  participants: "1-20",
  duration: "1-12",
  isEighteenYearLimit: "true",
  isPresenceOfAlcohol: "false",
}

export function parseParams(str: string[]): string[] {
  if (!str.length) {
    return ["undefined"]
  }

  return str[0].split("-")
}

export const useUpdateSearchParam = (key: SearchParamQuery) => {
  const [searchParams, setSearchParams] = useFilterAsSearchParams()
  const param = new URLSearchParams(searchParams)
  const currentParams = searchParams.getAll(key)

  const setParam = (value: any) => {
    const valueStr = String(value).toLowerCase()

    switch (true) {
      case valueStr === "undefined":
        param.delete(key)
        setSearchParams(param)
        break

      case key === "sort":
      case key === "isFree":
      case key === "isEighteenYearLimit":
      case key === "isPresenceOfAlcohol":
      case key === "duration":
      case key === "participants":
        param.set(key, valueStr)
        setSearchParams(param)
        break

      case key === "day":
      case key === "category":
        const rawParams = parseParams(currentParams).filter(
          (q) => q !== "undefined",
        )

        if (
          key === "day" &&
          rawParams.length === 3 &&
          !rawParams.includes(valueStr)
        ) {
          param.delete(key)
          setSearchParams(param)
          return
        }

        const newParams = rawParams.includes(valueStr)
          ? rawParams.filter((q) => q !== valueStr).join("-")
          : [...rawParams, valueStr].join("-")

        newParams.length ? param.set(key, newParams) : param.delete(key)
        setSearchParams(param)
        break

      case key === "daysrange":
        const [start, end] = value
        if (!value) {
          param.delete(key)
          setSearchParams(param)
          return
        }

        start && end
          ? param.set(key, `${start}to${end}`)
          : param.set(key, start)
        setSearchParams(param)
        break

      case key === "reset":
        paramToDelete.forEach((q) => param.delete(q))
        setSearchParams({ ...resetParam, ...param })
        return

      default:
        break
    }
  }

  return { setParam, currentParams }
}
