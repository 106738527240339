import React, { CSSProperties } from "react";
import { Box } from "@mui/material";
import { tabStyle, tabSvg } from "./styles";
import DoneIcon from "@mui/icons-material/Done";

export type ItemProps<T> = {
  value: T
  isSelected: boolean
  onClick: (value: T) => void
  title: string,
  isLast: boolean
}

export function Item<T>(props: ItemProps<T>): React.ReactElement {
  const {title, isSelected, onClick, value, isLast} = props

  return (
      <Box
        onClick={()=>onClick(value)}
        sx={tabStyle}
        style={handleActiveTab(isSelected, isLast)}
      >
        {isSelected && <DoneIcon sx={tabSvg} />}
        {title}
      </Box>
    )
}

const handleActiveTab = (isActive: boolean,  isLast: boolean = false): CSSProperties => ({
  background: isActive ? "var(--filter-selected)" : undefined,
  padding: isActive ? "0 7px" : "0 4px",
  borderRight: isLast ? "1px solid var(--borders)" : "",
})