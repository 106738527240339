import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface SignInDialogState {
  showDialog: boolean
}

const initial: SignInDialogState = { showDialog: false }

const signDialogSlice = createSlice({
  name: "sighDialog",
  initialState: initial,
  reducers: {
    show: (state) => {
      state.showDialog = true
    },
    hide: (state) => {
      state.showDialog = false
    },
  },
})

export const { show, hide } = signDialogSlice.actions
export const selectDialog = (state: RootState): boolean =>
  state.signDialog.showDialog
export default signDialogSlice.reducer
