import { SxProps, Theme } from "@mui/material"

export const menuCont = {
  width: "170px",
  height: "100%",
  color: "var(--surface-bg)",
  bgcolor: "var(--calendar-fav-bg)",
  p: 1,
  pt: 2,
  backgroundColor: "var(--surface-bg)",

  "@media (min-width: 1023px)": {
    width: "250px",
    p: 2,
  },
}

export const menuList: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  transition: "all 0.3s ease",
}
