import { FC, useEffect } from "react"
import { selectUserId } from "../../../app/data/appTokensStore"
import * as api from "../../../app/data/tribeSwaggerApi"
import { useAppSelector } from "../../../app/hooks"
import type { UserFavoriteEvents } from "../../../pages/favorite/types"
import FavoriteItem from "../../favotite_item"

export type FavoriteEventListProps = {
  list: UserFavoriteEvents
  onChange: () => void
}

export const FavoriteEventList: FC<FavoriteEventListProps> = ({
  list,
  onChange,
}) => {
  const userId = useAppSelector(selectUserId)
  const [remove, { isSuccess, isLoading, reset }] =
    api.useDeleteEventFromFavoritesMutation()

  useEffect(() => {
    if (isSuccess) {
      onChange()
    }
  }, [isSuccess])

  const tryRemoveItem = (id: number) => {
    if (!isLoading) {
      remove({
        userId: userId?.toString()!!,
        eventId: id,
      })
    }
  }

  return (
    <>
      {list.map((item) => {
        return (
          <FavoriteItem
            key={item.event_id}
            id={item.event_id}
            img={item.event_photo[0]}
            location={item.event_address.city}
            time={item.start_time!!}
            title={item.event_name}
            description={""}
            removeItem={tryRemoveItem}
          />
        )
      })}
    </>
  )
}
