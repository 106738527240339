import { CategoryInfo } from "../../components/filtered/types"
import pets from "../../assets/local_categories/animals.png"
import kids from "../../assets/local_categories/children.png"
import countryside from "../../assets/local_categories/countryside_weekend.png"
import culture from "../../assets/local_categories/culture.png"
import education from "../../assets/local_categories/education.png"
import games from "../../assets/local_categories/games.png"
import love from "../../assets/local_categories/love.png"
import parties from "../../assets/local_categories/party.png"
import religion from "../../assets/local_categories/religion.png"
import shopping from "../../assets/local_categories/shopping.png"
import sport from "../../assets/local_categories/sport.png"
import tourism from "../../assets/local_categories/tourism.png"
import trips from "../../assets/local_categories/trips.png"
import walks from "../../assets/local_categories/walking.png"
import other from "../../assets/local_categories/others.png"
import { useGetAllCategoriesQuery } from "../../app/data/category/categoryApi"

export type CategoriesState = {
  categories: CategoryInfo[]
  isLoading: boolean
}
export const useCategoryProvider = (): CategoriesState => {
  const { data, isLoading } = useGetAllCategoriesQuery()
  if (isLoading || !data) {
    return {
      categories: localCategory,
      isLoading: isLoading,
    }
  } else {
    return {
      isLoading: isLoading,
      categories: data
        .map((item) => ({
          id: item.id,
          title: item.type_name,
          priority: item.priority,
          url: item.image_url.x1,
          srcset:
            item.image_url.x1 +
            " 1x, " +
            item.image_url.x2 +
            " 2x, " +
            item.image_url.x3 +
            " 3x, " +
            item.image_url.x4 +
            " 4x",
        }))
        .sort((a, b) => a.priority - b.priority),
    }
  }
}

const localCategory: CategoryInfo[] = [
  {
    id: 15,
    title: "Любовь",
    priority: 0,
    url: love,
    srcset: love,
  },
  {
    id: 4,
    title: "Вечеринки",
    priority: 1,
    url: parties,
    srcset: parties,
  },
  {
    id: 17,
    title: "Игры",
    priority: 2,
    url: games,
    srcset: games,
  },
  {
    id: 18,
    title: "Шопинг",
    priority: 3,
    url: shopping,
    srcset: shopping,
  },
  {
    id: 1,
    title: "Спорт",
    priority: 4,
    url: sport,
    srcset: sport,
  },
  {
    id: 2,
    title: "Поездки",
    priority: 5,
    url: trips,
    srcset: trips,
  },
  {
    id: 5,
    title: "Обучение",
    priority: 6,
    url: education,
    srcset: education,
  },
  {
    id: 7,
    title: "Для детей",
    priority: 7,
    url: kids,
    srcset: kids,
  },
  {
    id: 8,
    title: "Отдых за городом",
    priority: 8,
    url: countryside,
    srcset: countryside,
  },
  {
    id: 9,
    title: "Религия",
    priority: 9,
    url: religion,
    srcset: religion,
  },
  {
    id: 12,
    title: "Туризм",
    priority: 10,
    url: tourism,
    srcset: tourism,
  },
  {
    id: 13,
    title: "Прогулки",
    priority: 11,
    url: walks,
    srcset: walks,
  },
  {
    id: 16,
    title: "Культура",
    priority: 12,
    url: culture,
    srcset: culture,
  },
  {
    id: 14,
    title: "Для животных",
    priority: 13,
    url: pets,
    srcset: pets,
  },
  {
    id: 19,
    title: "Другое",
    priority: 14,
    url: other,
    srcset: other,
  },
]
