import { FC } from "react"
import { Route, Routes } from "react-router-dom"
import Chat from "../../src/pages/chat"
import ChatUser from "../../src/pages/chat_user"
import Favorite from "../../src/pages/favorite"
import Fid from "../../src/pages/fid"
import FidAll from "../../src/pages/fid_all"
import FidView from "../../src/pages/fid_view"
import Profile from "../../src/pages/profile"
import Subscribers from "../../src/pages/subscribers"
import FeedMap from "../components/feed-map"
import FeedPage from "../components/feed_page"
import Layout from "../components/layout/layout"
import HobbiesSettings from "../components/profile_settings/hobbies"
import LocationSettings from "../components/profile_settings/location"
import NotificationSettings from "../components/profile_settings/notification"
import ProfileAndView from "../components/profile_settings/profile_and_view"
import Security from "../components/profile_settings/security"
import useResize from "../hooks/useResize"
import MobileOverlay from "../pages/mobile_overlay"
import MyEvents from "../pages/myEvents"
import ProfileEvents from "../pages/profile/profile_events"
import ProfileSettings from "../pages/profile/profile_settings"
import Promotion from "../pages/promotion"
import CashiersDetails from "../pages/promotion/CashiersDetails"
import CashiersPage from "../pages/promotion/CashiersPage"
import EventDetailsPage from "../pages/promotion/EventDetailsPage"
import PromoTable from "../pages/promotion/EventsPage/PromoTable"
import PromoutersDetails from "../pages/promotion/PromoutersDetails"
import PromoutersPage from "../pages/promotion/PromoutersPage"
import ServerError from "../pages/serverError"
import ServiceError from "../pages/serviceError"
import WebChat from "../pages/web_chat"
import { ChatMode } from "../types"
import { ProtectedRoute } from "./ProtectedRoute"

export const AppRouters: FC = () => {
  const widthScreen = useResize()

  return (
    <Routes>
      <Route path="/overlay" element={<MobileOverlay />} />
      <Route path={"/"} element={<Layout />}>
        <Route path="/" element={<Fid />}>
          <Route index element={<FeedPage />} />
          <Route path="map" element={<FeedMap />} />
        </Route>
        <Route path={"fid/:id"} element={<FidView />} />
        <Route path={"fid/all/:name"} element={<FidAll />} />
        <Route path="profile/promotion" element={<Promotion />}>
          <Route index element={<PromoTable />} />
          <Route path={"events"} element={<PromoTable />} />
          <Route path={"events/:cardId"} element={<EventDetailsPage />} />
          <Route
            path={"events/:cardId/promotes"}
            element={<PromoutersDetails />}
          />
          <Route
            path={"events/:cardId/cashiers"}
            element={<CashiersDetails />}
          />
          <Route path={"promotes"} element={<PromoutersPage />} />
          <Route path={"cashier"} element={<CashiersPage />} />
        </Route>

        <Route
          path={"favorite"}
          element={
            <ProtectedRoute redirect={"/"}>
              <Favorite />
            </ProtectedRoute>
          }
        />

        {widthScreen <= 640 ? (
          <>
            <Route
              path={"/chat"}
              element={
                <ProtectedRoute redirect={"/"}>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/chat/:id"}
              element={<ChatUser mode={ChatMode.Mobile} />}
            />
          </>
        ) : (
          <Route
            path={"/chat"}
            element={
              <ProtectedRoute redirect={"/"}>
                <WebChat />
              </ProtectedRoute>
            }
          >
            <Route
              path={"/chat/:id"}
              element={<ChatUser mode={ChatMode.Web} />}
            />
          </Route>
        )}
        <Route
          path={"profile"}
          element={
            <ProtectedRoute redirect={"/"}>
              <Profile />
            </ProtectedRoute>
          }
        >
          {/*<Route index element={<ProfileEvents />} />*/}// TODO Если ничего не сломается удалить
          {/*<Route path="events" element={<ProfileEvents />} />*/}
          <Route path="map" element={<FeedMap />} />
        </Route>
        {/* Роуты настроек профиля */}
        <Route path={"profile/settings"} element={<ProfileSettings />}>
          <Route
            path={"/profile/settings/profile_view"}
            element={<ProfileAndView />}
          />
          <Route path={"/profile/settings/security"} element={<Security />} />
          <Route
            path={"/profile/settings/hobbies"}
            element={<HobbiesSettings />}
          />
          <Route
            path={"/profile/settings/notification"}
            element={<NotificationSettings />}
          />
          <Route
            path={"/profile/settings/location"}
            element={<LocationSettings />}
          />
        </Route>

        <Route
          path={"subscribers"}
          element={
            <ProtectedRoute redirect={"/"}>
              <Subscribers />
            </ProtectedRoute>
          }
        />
        <Route
          path={"my-events"}
          element={
            <ProtectedRoute redirect={"/"}>
              <MyEvents />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path={"server-error"} element={<ServerError />} />
      <Route path={"service-error"} element={<ServiceError />} />
    </Routes>
  )
}
