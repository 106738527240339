import { FC } from "react"
import { useYandexReactComponents } from "../../../common/yandex/provider/YandexMapProvider"
import style from "../style.module.css"
import { LngLat } from "ymaps3"
import { SearchEventDto } from "../../../app/data/tribeSwaggerApi"

export type MapMarkerProps = {
  coords: LngLat
  id: number
  onClick?: (id: number) => void
}
export const MapMarker: FC<MapMarkerProps> = ({ coords, id, onClick }) => {
  const components = useYandexReactComponents()
  if (!components) return null
  const { YMapMarker } = components.yandexMap
  const clickHandler = () => {
    if (onClick) {
      onClick(id)
    }
  }
  return (
    <>
      <YMapMarker coordinates={coords}>
        <div className={style.marker} onClick={clickHandler}></div>
      </YMapMarker>
    </>
  )
}

const defaultMarkerProps: MapMarkerProps = {
  coords: [0, 0],
  id: 0,
  onClick: () => {},
}

export const EventsDtoToMarker = (dto: SearchEventDto): MapMarkerProps => {
  const { eventAddress } = dto
  if (!eventAddress) return defaultMarkerProps
  if (!dto.eventId) return defaultMarkerProps
  return {
    coords: [eventAddress.event_longitude, eventAddress.event_latitude],
    id: dto.eventId,
  }
}
