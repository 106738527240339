import * as api from "../app/data/tribeSwaggerApi"
import type { DetailEvent } from "../pages/fid_view/types"

export const useGetDetailEvent = (id: string | undefined): DetailEventState => {
  if (!id) {
    return {
      isLoading: false,
      error: "Invalid id",
    }
  }
  const { isLoading, error, data } = api.useGetEventByIdQuery({ eventId: id })
  return {
    data: data,
    error: error ? "Loading Error" : undefined,
    isLoading: isLoading,
  }
}

export type DetailEventState = {
  isLoading: boolean
  error?: string
  data?: DetailEvent
}
