import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultFilter, FilterType, NumberRange, PartsOfDay, SortType, TimeRange } from "./types";

const filterSlice = createSlice({
  name: "filter",
  initialState: defaultFilter,
  reducers: {
    updateCategoryId: (state, action: PayloadAction<number[] | number>) => {
      const { payload } = action
      if (Array.isArray(payload)) {
        state.eventTypeId = payload
      } else {
        const category = state.eventTypeId
        if (category){
          const index = category.indexOf(payload)
          if (index!== -1){
            category.splice(index, 1)
            state.eventTypeId = category
          }else {
            state.eventTypeId = [...category, payload]
          }
        }else {
          state.eventTypeId = [payload]
        }
      }
    },
    setQueryText: (state, action: PayloadAction<string | undefined>) => {
      state.text = action.payload
    },
    updateFilter: (_, action: PayloadAction<FilterType>) => {
      return action.payload
    },
    updateParticipants: (state, action: PayloadAction<NumberRange>) => {
      const [min, max] = action.payload
      state.numberOfParticipantsMin = min
      state.numberOfParticipantsMax = max
    },
    updateDateRange: (state, action: PayloadAction<TimeRange>) => {
      const [start, end] = action.payload
      state.startDate = start
      state.endDate = end
    },
    updateDuration: (state, action: PayloadAction<NumberRange>) => {
      const [min, max] = action.payload
      state.durationEventInHoursMin = min
      state.durationEventInHoursMax = max
    },
    updateSort: (state, action: PayloadAction<SortType>) => {
      const { payload } = action
      state.sort = payload
    },
    resetFilter: (state) => {
      return defaultFilter()
    },
    updatePartOfDays: (state, action: PayloadAction<PartsOfDay | undefined>) => {
      state.partsOfDay = action.payload
    },
    updateFreeArgs: (state, action: PayloadAction<boolean | undefined>) => {
      state.isFree = action.payload
    },
    updateAlcohol: (state, action: PayloadAction<boolean | undefined>) => {
      state.isPresenceOfAlcohol = action.payload
    },
    updateAgeArgs: (state, action: PayloadAction<boolean | undefined>) => {
      state.isEighteenYearLimit = action.payload
    },
  },
})
export const {
  updateAgeArgs,
  updateAlcohol,
  updateFreeArgs,
  updatePartOfDays,
  resetFilter,
  updateSort,
  updateDuration,
  updateDateRange,
  updateParticipants,
  updateFilter,
  updateCategoryId,
  setQueryText
} = filterSlice.actions
export default filterSlice.reducer