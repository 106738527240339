import CloseIcon from "@mui/icons-material/Close"
import { Box } from "@mui/material"
import { FC } from "react"
import { useLocation } from "react-router-dom"
import useResize from "../../../hooks/useResize"
import s from "../styles.module.css"
import FilterIcon from "/src/assets/svg/filter-pack/filter.svg?react"

type Props = {
  isOpenFilter?: boolean
  setIsOpenFilter?: (v: boolean) => void
}

const FilterTitle: FC<Props> = ({
  isOpenFilter = false,
  setIsOpenFilter = () => {},
}) => {
  const { pathname } = useLocation()
  const resize = useResize()

  return (
    <Box className={s.filter_title}>
      <p className={s.title}>Фильтр</p>
      {isOpenFilter ? (
        <CloseIcon
          onClick={() => setIsOpenFilter(false)}
          style={{
            cursor: pathname === "/" && resize > 678 ? "default" : "pointer",
          }}
        />
      ) : (
        <FilterIcon
          stroke="var(--primary-text)"
          style={{
            cursor: pathname === "/" && resize > 678 ? "default" : "pointer",
          }}
        />
      )}
    </Box>
  )
}

export default FilterTitle
