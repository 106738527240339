import GradientBtn from "../../../../GradientBtn"
import s from "./styles.module.scss"
import Search from "../../../../../assets/svg/header-icons-pack/Search-icon.svg?react"
import { ViewsNames } from "../.."
import {useLazyGetEventParticipants} from "../../../../../app/data/promotion/promotionApi"
import { useEffect, useState } from "react"
import UnknownAvatar from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"

type SearchUserProps = {
	onSetVeiew: (popupVeiew: ViewsNames
	) => void
	eventId: number
}

const SearchUser = ({onSetVeiew, eventId}: SearchUserProps) => {
	const [trigger, {data} ] = useLazyGetEventParticipants()
	const [searchedValue, setSearchedValue] = useState<string>("")
	const hadleGoToAddNewUser = () => {
		onSetVeiew(ViewsNames.AddNewUser)
	}

	useEffect(() => {
		if (eventId) {
			trigger({eventId: eventId  as number})

		}
	}, [eventId])

	const searchProducts = data?.content.filter((el) => `${el.firstname.toLocaleLowerCase()}${el.lastname.toLocaleLowerCase()}`.includes(searchedValue.length > 0 ? 
		searchedValue.toLocaleLowerCase() : '$$$'.toLocaleLowerCase()));
 
	 	const renderParticipantsItems = () => 
		searchProducts?.map((participant) => 	
		<li className={s.search_item}>
		<UnknownAvatar className={s.user_avatar} />
		<div className={s.user_info}>
			<h3 className={s.user_name}>{`${participant.firstname} ${participant.lastname}`}</h3>
			<p className={s.user_phone}>{participant.phone_number}</p>
		</div>
	</li> )

	return (
		<>
				<div className={s.add_to_base_search}>
					<div className={s.input_search_wrapper}>
						<label className={s.search_label} htmlFor="search">
							<Search className={s.search_icon}/>
						</label>
						<input className={s.input_search} id="search" onChange={(e) => setSearchedValue(e.currentTarget.value)}></input>
					</div>
					<ul className={s.add_to_base_search_list}>
					{data?.content.length === 0 ? "В базе нет ни одного пользователя" : renderParticipantsItems()}
					</ul>
				</div>
				<GradientBtn title="Добавить нового человека в базу"  handleAction={() => hadleGoToAddNewUser()}
					btnStyle={{width: "288px", marginTop: "26px"}} textStyle={{fontSize: "16px", fontWeight: "500", textTransform: "none"}}/>
		</>
	)
}

export default SearchUser