import type { SVGProps } from "react"

export function IWillGoIconChecked(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none">
        <path
          fill="var(--profile-ntfc-text)"
          stroke="var(--profile-ntfc-text)"
          fillRule="evenodd"
          d="M2.879 3.879C2 4.757 2 6.172 2 9v6c0 2.828 0 4.243.879 5.121C3.757 21 5.172 21 8 21h10c.93 0 1.395 0 1.776-.102a3 3 0 0 0 2.122-2.122C22 18.396 22 17.93 22 17h-6a3 3 0 1 1 0-6h6V9c0-2.828 0-4.243-.879-5.121C20.243 3 18.828 3 16 3H8c-2.828 0-4.243 0-5.121.879M7 7a1 1 0 0 0 0 2h3a1 1 0 1 0 0-2z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="var(--profile-ntfc-text)"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M17 14h-1"
        ></path>
      </g>
    </svg>
  )
}
