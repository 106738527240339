import { Box, Typography } from "@mui/material"
import { useGetPromotionListQuery } from "../../../../app/data/promotion/promotionApi"
import ErrorNotification from "../../ErrorNotification"
import Loading from "../../Loading"
import PromotionEventRow from "../PromotionEventRow"
import { noEventsStyle, table, tableCont } from "./PromoTableStyle"

export const PromoTableTitles = [
  "События",
  "Приглашенные",
  "Участники",
  "Промоутеры",
]

export default function PromoTable() {
  const { data, isError, isLoading } = useGetPromotionListQuery({
    page: 0,
    size: 20,
  })

  if (isError && !isLoading) {
    return <ErrorNotification />
  }

  if ((isLoading || !data) && !isError) {
    return <Loading />
  }

  return (
    <Box sx={tableCont}>
      {data && data.content.length > 0 && (
        <>
          <Box sx={table}>
            {PromoTableTitles.map((title) => (
              <Typography
                variant="body2"
                fontWeight={500}
                color={"var(--primary-text)"}
                key={title}
              >
                {title}
              </Typography>
            ))}
          </Box>

          <PromotionEventRow content={data.content} />
        </>
      )}

      {data && data.content.length < 1 && (
        <Box sx={noEventsStyle}>
          <Typography
            variant="h6"
            color={"var(--primary-text)"}
            fontWeight={400}
          >
            У вас пока нет событий
          </Typography>
        </Box>
      )}
    </Box>
  )
}
